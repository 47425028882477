import React from 'react';
export const SwimmingPool3 = ({ className, onClick }) => (React.createElement("svg", { className: className, onClick: onClick, stroke: "currentColor", fill: "currentColor", height: "1em", width: "1em", strokeMiterlimit: "10", strokeWidth: "0.02px", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 5.84 8.8" },
    React.createElement("g", { id: "Layer_2", "data-name": "Layer 2" },
        React.createElement("g", { id: "Layer_1-2", "data-name": "Layer 1" },
            React.createElement("path", { className: "cls-1", d: "M3.55,1.16a.54.54,0,0,1-.75,0,.66.66,0,0,0-.95,0,.53.53,0,0,1-.74,0,.07.07,0,0,0-.1.1.66.66,0,0,0,.94,0,.53.53,0,0,1,.75,0,.66.66,0,0,0,.47.19.68.68,0,0,0,.48-.19.51.51,0,0,1,.74,0,.07.07,0,0,0,.1-.1.65.65,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,2A.53.53,0,0,1,2.8,2a.68.68,0,0,0-.95,0,.51.51,0,0,1-.74,0A.09.09,0,0,0,1,2a.08.08,0,0,0,0,.11.66.66,0,0,0,.94,0,.53.53,0,0,1,.75,0,.66.66,0,0,0,.47.19.68.68,0,0,0,.48-.19.51.51,0,0,1,.74,0,.09.09,0,0,0,.1,0,.08.08,0,0,0,0-.11.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,2.93a.54.54,0,0,1-.38.15.51.51,0,0,1-.37-.15.68.68,0,0,0-.95,0,.51.51,0,0,1-.74,0A.07.07,0,0,0,1,3,.65.65,0,0,0,2,3,.54.54,0,0,1,2.7,3a.65.65,0,0,0,.47.2A.67.67,0,0,0,3.65,3a.53.53,0,0,1,.74,0,.07.07,0,0,0,.1-.1.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,3.82a.54.54,0,0,1-.75,0,.68.68,0,0,0-.95,0,.53.53,0,0,1-.74,0,.07.07,0,0,0-.1.1.66.66,0,0,0,.94,0,.54.54,0,0,1,.75,0,.67.67,0,0,0,.47.2.69.69,0,0,0,.48-.2.53.53,0,0,1,.74,0,.07.07,0,0,0,.1-.1.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,4.71a.54.54,0,0,1-.75,0,.68.68,0,0,0-.95,0,.53.53,0,0,1-.74,0,.07.07,0,0,0-.1.1.66.66,0,0,0,.94,0,.53.53,0,0,1,.75,0A.66.66,0,0,0,3.17,5a.68.68,0,0,0,.48-.19.51.51,0,0,1,.74,0,.07.07,0,0,0,.1-.1.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,5.59a.08.08,0,0,0,0,.11l.05,0,0,0a.51.51,0,0,1,.74,0,.09.09,0,0,0,.1,0,.08.08,0,0,0,0-.11.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M1.85,5.59a.51.51,0,0,1-.74,0,.09.09,0,0,0-.1,0A.08.08,0,0,0,1,5.7.68.68,0,0,0,2,5.7a.08.08,0,0,0,0-.11.09.09,0,0,0-.1,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M3.55,6.48a.07.07,0,0,0,0,.1.05.05,0,0,0,.05,0l0,0a.53.53,0,0,1,.74,0,.07.07,0,0,0,.1-.1.66.66,0,0,0-.94,0Z" }),
            React.createElement("path", { className: "cls-1", d: "M1.85,6.48a.51.51,0,0,1-.74,0,.07.07,0,0,0-.1.1.63.63,0,0,0,.47.2A.63.63,0,0,0,2,6.58a.07.07,0,0,0-.1-.1Z" }),
            React.createElement("path", { className: "cls-1", d: "M5.42,0H.08A.07.07,0,0,0,0,.08V7.67a.07.07,0,0,0,.07.07H2.19v1a.07.07,0,0,0,.07.07h1a.07.07,0,0,0,.07-.07v-1H5.42a.07.07,0,0,0,.07-.07V2.31h.27a.07.07,0,1,0,0-.14H5.49V1.34h.27a.07.07,0,0,0,.07-.07.08.08,0,0,0-.07-.08H5.49V.08A.07.07,0,0,0,5.42,0ZM.15,7.6V.15h5.2v1H5.07V.5A.07.07,0,0,0,5,.43H.5A.07.07,0,0,0,.43.5V7.25a.07.07,0,0,0,.07.07H2.19V7.6ZM4.68,1.19a.08.08,0,0,0-.07.08v1a.07.07,0,0,0,.07.07h.25V7.18H3.31V5.48a.07.07,0,0,0-.07-.07h-1a.07.07,0,0,0-.07.07v1.7H.57V.58H4.93v.61Zm.25.15v.83H4.75V1.34ZM3.16,8.65H2.34V5.55h.82ZM5.35,7.6h-2V7.32H5a.07.07,0,0,0,.07-.07V2.31h.28V7.6Zm0-5.43H5.07V1.34h.28v.83Z" })))));

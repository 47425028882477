export const minutesToHoursMap = {
    [0]: '00:00',
    [5]: '00:05',
    [10]: '00:10',
    [15]: '00:15',
    [20]: '00:20',
    [25]: '00:25',
    [30]: '00:30',
    [35]: '00:35',
    [40]: '00:40',
    [45]: '00:45',
    [50]: '00:50',
    [55]: '00:55',
    [60]: '01:00',
    [65]: '01:05',
    [70]: '01:10',
    [75]: '01:15',
    [80]: '01:20',
    [85]: '01:25',
    [90]: '01:30',
    [95]: '01:35',
    [100]: '01:40',
    [105]: '01:45',
    [110]: '01:50',
    [115]: '01:55',
    [120]: '02:00',
    [125]: '02:05',
    [130]: '02:10',
    [135]: '02:15',
    [140]: '02:20',
    [145]: '02:25',
    [150]: '02:30',
    [155]: '02:35',
    [160]: '02:40',
    [165]: '02:45',
    [170]: '02:50',
    [175]: '02:55',
    [180]: '03:00',
    [185]: '03:05',
    [190]: '03:10',
    [195]: '03:15',
    [200]: '03:20',
    [205]: '03:25',
    [210]: '03:30',
    [215]: '03:35',
    [220]: '03:40',
    [225]: '03:45',
    [230]: '03:50',
    [235]: '03:55',
    [240]: '04:00',
    [245]: '04:05',
    [250]: '04:10',
    [255]: '04:15',
    [260]: '04:20',
    [265]: '04:25',
    [270]: '04:30',
    [275]: '04:35',
    [280]: '04:40',
    [285]: '04:45',
    [290]: '04:50',
    [295]: '04:55',
    [300]: '05:00',
    [305]: '05:05',
    [310]: '05:10',
    [315]: '05:15',
    [320]: '05:20',
    [325]: '05:25',
    [330]: '05:30',
    [335]: '05:35',
    [340]: '05:40',
    [345]: '05:45',
    [350]: '05:50',
    [355]: '05:55',
    [360]: '06:00',
    [365]: '06:05',
    [370]: '06:10',
    [375]: '06:15',
    [380]: '06:20',
    [385]: '06:25',
    [390]: '06:30',
    [395]: '06:35',
    [400]: '06:40',
    [405]: '06:45',
    [410]: '06:50',
    [415]: '06:55',
    [420]: '07:00',
    [425]: '07:05',
    [430]: '07:10',
    [435]: '07:15',
    [440]: '07:20',
    [445]: '07:25',
    [450]: '07:30',
    [455]: '07:35',
    [460]: '07:40',
    [465]: '07:45',
    [470]: '07:50',
    [475]: '07:55',
    [480]: '08:00',
    [485]: '08:05',
    [490]: '08:10',
    [495]: '08:15',
    [500]: '08:20',
    [505]: '08:25',
    [510]: '08:30',
    [515]: '08:35',
    [520]: '08:40',
    [525]: '08:45',
    [530]: '08:50',
    [535]: '08:55',
    [540]: '09:00',
    [545]: '09:05',
    [550]: '09:10',
    [555]: '09:15',
    [560]: '09:20',
    [565]: '09:25',
    [570]: '09:30',
    [575]: '09:35',
    [580]: '09:40',
    [585]: '09:45',
    [590]: '09:50',
    [595]: '09:55',
    [600]: '10:00',
    [605]: '10:05',
    [610]: '10:10',
    [615]: '10:15',
    [620]: '10:20',
    [625]: '10:25',
    [630]: '10:30',
    [635]: '10:35',
    [640]: '10:40',
    [645]: '10:45',
    [650]: '10:50',
    [655]: '10:55',
    [660]: '11:00',
    [665]: '11:05',
    [670]: '11:10',
    [675]: '11:15',
    [680]: '11:20',
    [685]: '11:25',
    [690]: '11:30',
    [695]: '11:35',
    [700]: '11:40',
    [705]: '11:45',
    [710]: '11:50',
    [715]: '11:55',
    [720]: '12:00',
    [725]: '12:05',
    [730]: '12:10',
    [735]: '12:15',
    [740]: '12:20',
    [745]: '12:25',
    [750]: '12:30',
    [755]: '12:35',
    [760]: '12:40',
    [765]: '12:45',
    [770]: '12:50',
    [775]: '12:55',
    [780]: '13:00',
    [785]: '13:05',
    [790]: '13:10',
    [795]: '13:15',
    [800]: '13:20',
    [805]: '13:25',
    [810]: '13:30',
    [815]: '13:35',
    [820]: '13:40',
    [825]: '13:45',
    [830]: '13:50',
    [835]: '13:55',
    [840]: '14:00',
    [845]: '14:05',
    [850]: '14:10',
    [855]: '14:15',
    [860]: '14:20',
    [865]: '14:25',
    [870]: '14:30',
    [875]: '14:35',
    [880]: '14:40',
    [885]: '14:45',
    [890]: '14:50',
    [895]: '14:55',
    [900]: '15:00',
    [905]: '15:05',
    [910]: '15:10',
    [915]: '15:15',
    [920]: '15:20',
    [925]: '15:25',
    [930]: '15:30',
    [935]: '15:35',
    [940]: '15:40',
    [945]: '15:45',
    [950]: '15:50',
    [955]: '15:55',
    [960]: '16:00',
    [965]: '16:05',
    [970]: '16:10',
    [975]: '16:15',
    [980]: '16:20',
    [985]: '16:25',
    [990]: '16:30',
    [995]: '16:35',
    [1000]: '16:40',
    [1005]: '16:45',
    [1010]: '16:50',
    [1015]: '16:55',
    [1020]: '17:00',
    [1025]: '17:05',
    [1030]: '17:10',
    [1035]: '17:15',
    [1040]: '17:20',
    [1045]: '17:25',
    [1050]: '17:30',
    [1055]: '17:35',
    [1060]: '17:40',
    [1065]: '17:45',
    [1070]: '17:50',
    [1075]: '17:55',
    [1080]: '18:00',
    [1085]: '18:05',
    [1090]: '18:10',
    [1095]: '18:15',
    [1100]: '18:20',
    [1105]: '18:25',
    [1110]: '18:30',
    [1115]: '18:35',
    [1120]: '18:40',
    [1125]: '18:45',
    [1130]: '18:50',
    [1135]: '18:55',
    [1140]: '19:00',
    [1145]: '19:05',
    [1150]: '19:10',
    [1155]: '19:15',
    [1160]: '19:20',
    [1165]: '19:25',
    [1170]: '19:30',
    [1175]: '19:35',
    [1180]: '19:40',
    [1185]: '19:45',
    [1190]: '19:50',
    [1195]: '19:55',
    [1200]: '20:00',
    [1205]: '20:05',
    [1210]: '20:10',
    [1215]: '20:15',
    [1220]: '20:20',
    [1225]: '20:25',
    [1230]: '20:30',
    [1235]: '20:35',
    [1240]: '20:40',
    [1245]: '20:45',
    [1250]: '20:50',
    [1255]: '20:55',
    [1260]: '21:00',
    [1265]: '21:05',
    [1270]: '21:10',
    [1275]: '21:15',
    [1280]: '21:20',
    [1285]: '21:25',
    [1290]: '21:30',
    [1295]: '21:35',
    [1300]: '21:40',
    [1305]: '21:45',
    [1310]: '21:50',
    [1315]: '21:55',
    [1320]: '22:00',
    [1325]: '22:05',
    [1330]: '22:10',
    [1335]: '22:15',
    [1340]: '22:20',
    [1345]: '22:25',
    [1350]: '22:30',
    [1355]: '22:35',
    [1360]: '22:40',
    [1365]: '22:45',
    [1370]: '22:50',
    [1375]: '22:55',
    [1380]: '23:00',
    [1385]: '23:05',
    [1390]: '23:10',
    [1395]: '23:15',
    [1400]: '23:20',
    [1405]: '23:25',
    [1410]: '23:30',
    [1415]: '23:35',
    [1420]: '23:40',
    [1425]: '23:45',
    [1430]: '23:50',
    [1435]: '23:55',
    [1440]: '24:00',
};

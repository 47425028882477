import { ConsentTypes } from '@reservation-app/common-used-in-web';
import React from 'react';
import { Helmet } from 'react-helmet';
import { usePageContext } from '../contexts/page-context';
import { COOKIES_ACCEPTED_LS_KEY } from './layout/cookie-banner';

export const GoogleTagManager = () => {
  const pageContext = usePageContext();
  const didUserGiveConsentToCookies =
    typeof window !== 'undefined'
      ? localStorage.getItem(COOKIES_ACCEPTED_LS_KEY) === ConsentTypes.ALLOW_ALL
      : false;

  const googleAnalyticsId = pageContext.analyticsConfig?.googleAnalyticsCode;

  if (
    typeof window === 'undefined' ||
    process.env.GATSBY_ACTIVE_ENV !== 'production' ||
    !googleAnalyticsId ||
    !didUserGiveConsentToCookies
  ) {
    return null;
  }

  return (
    <Helmet>
      <link
        rel="preconnect"
        key="preconnect-google-gtag"
        href="https://www.googletagmanager.com"
      />
      <link
        rel="dns-prefetch"
        key="dns-prefetch-google-gtag"
        href="https://www.googletagmanager.com"
      />
      <script
        key="gatsby-plugin-google-gtag"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <script key="gatsby-plugin-google-gtag-config">
        {`
    function gaOptout(){document.cookie=disableStr+'=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/',window[disableStr]=!0}var gaProperty='${googleAnalyticsId}',disableStr='ga-disable-'+gaProperty;document.cookie.indexOf(disableStr+'=true')>-1&&(window[disableStr]=!0);

    if(!(navigator.doNotTrack == "1" || window.doNotTrack == "1")) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer && window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}', ${JSON.stringify({
          anonymize_ip: true,
          send_page_view: false,
        })});
    }
    `}
      </script>
    </Helmet>
  );
};

import { areIntervalsIntersecting } from './are-intervals-intersecting';
export const getExtraAvailableCapacityForInterval = ({ entities, extra, extraReservableInterval, }) => {
    let availableCapacity;
    if (extra.withCapacity) {
        let alreadyReservedExtraNumber = 0;
        entities.forEach((entity) => {
            entity.reservedIntervals.forEach((reservedInterval) => {
                const currentExtraOnInterval = reservedInterval.selectedExtras.find((selectedExtra) => selectedExtra.historicExtra._id === extra._id &&
                    areIntervalsIntersecting(extraReservableInterval, Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval)));
                if (currentExtraOnInterval) {
                    alreadyReservedExtraNumber += currentExtraOnInterval.amount;
                }
            });
        });
        availableCapacity = extra.capacity - alreadyReservedExtraNumber;
    }
    return availableCapacity;
};

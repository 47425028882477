const SwitchBaseClasses = (theme) => ({
    '.counter-input-container': {
        display: 'flex',
        backgroundColor: theme('colors.primary.main'),
        borderRadius: theme('borderRadius.2xl'),
    },
    '.counter-input-button-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme('spacing.1'),
        color: theme('colors.primary.main'),
    },
    '.counter-input-button': {
        backgroundColor: theme('colors.white'),
        borderRadius: theme('borderRadius.full'),
    },
    '.counter-input-input': {
        outline: '2px solid transparent',
        outlineOffset: '2px',
        backgroundColor: theme('colors.primary.main'),
        color: theme('colors.white'),
        marginLeft: theme('spacing.1'),
        marginRight: theme('spacing.1'),
    },
});
module.exports = SwitchBaseClasses;

import { usePrevious } from '@reservation-app/common-used-in-web';
import { useEffect } from 'react';
import { useStaticSlice, useStepperStateSlice } from '../store';
export const useIsWindowFocused = () => {
    const { staticProps: { useWindowFocus }, } = useStaticSlice();
    const { setIsFocused } = useStepperStateSlice();
    const isFocused = useWindowFocus();
    const isPrevFocused = usePrevious(isFocused);
    useEffect(() => {
        if (isFocused !== isPrevFocused) {
            setIsFocused(isFocused);
        }
    }, [isFocused]);
};

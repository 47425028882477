const MenuBaseClasses = (theme) => ({
    '.menu-container': {
        borderRadius: theme('borderRadius.DEFAULT'),
        boxShadow: theme('boxShadow.md'),
        width: theme('spacing.56'),
        paddingTop: theme('spacing.4'),
        paddingBottom: theme('spacing.4'),
    },
    '.menu-type-item': {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme('fontSize.lg'),
        lineHeight: theme('lineHeight.7'),
        paddingTop: theme('spacing.1'),
        paddingBottom: theme('spacing.1'),
        paddingRight: theme('spacing.4'),
        paddingLeft: theme('spacing.4'),
        color: theme('colors.gray.400'),
        cursor: 'pointer',
    },
    '.menu-active': {
        backgroundColor: theme('colors.primary.50'),
    },
    '.menu-type-item-icon': {
        marginRight: theme('spacing.2'),
    },
    '.menu-type-group-label': {
        paddingTop: theme('spacing.1'),
        paddingHeight: theme('spacing.1'),
        paddingRight: theme('spacing.4'),
        paddingLeft: theme('spacing.4'),
        fontWeight: theme('fontWeight.bold'),
        fontSize: theme('fontSize.sm'),
        lineHeight: theme('lineHeight.5'),
        color: theme('colors.gray.700'),
    },
    '.menu-type-group': {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme('fontSize.lg'),
        lineHeight: theme('lineHeight.7'),
        paddingTop: theme('spacing.1'),
        paddingBottom: theme('spacing.1'),
        paddingRight: theme('spacing.4'),
        paddingLeft: theme('spacing.4'),
        color: theme('colors.gray.700'),
        cursor: 'pointer',
    },
    '.menu-type-group-active': {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme('fontSize.lg'),
        lineHeight: theme('lineHeight.7'),
        paddingTop: theme('spacing.1'),
        paddingBottom: theme('spacing.1'),
        paddingRight: theme('spacing.4'),
        paddingLeft: theme('spacing.4'),
        color: theme('colors.gray.700'),
        cursor: 'pointer',
    },
    '.menu-type-divider': {
        width: '100%',
        height: theme('spacing.px'),
        backgroundColor: theme('colors.gray.200'),
        marginTop: theme('spacing.2'),
        marginBottom: theme('spacing.2'),
    },
});
module.exports = MenuBaseClasses;

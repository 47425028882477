import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useTextInputValues, } from './input-hooks';
import { Label } from '@reservation-app/common-used-in-web';
export const TextInput = forwardRef(({ id, label, secondaryLabel, Icon, LeftIcon, withRequiredIndicator, name, value, onChange, onClick, onKeyDown, onEnterPress, error, containerClassName, labelClassName, inputClassName, type = 'text', max, withMax, placeholder, field, form, onBlur, onFocus, withErrorInLabel, isDisabled, errorClassName, }, ref) => {
    const { formikCompatibleValue, formikCompatibleError, formikCompatibleOnBlur, formikCompatibleOnChange, formikCompatibleName, } = useTextInputValues({
        field,
        form,
        value,
        error,
        name,
        onChange,
        onBlur,
    });
    return (React.createElement("div", { className: classNames('text-input-container', containerClassName) },
        label && (React.createElement(Label, { className: labelClassName, secondaryText: secondaryLabel, withRequiredIndicator: withRequiredIndicator, errorText: withErrorInLabel ? formikCompatibleError : undefined, htmlFor: formikCompatibleName },
            Icon ? React.createElement(Icon, { className: "text-input-label-icon" }) : null,
            label)),
        React.createElement("div", { className: classNames('text-input-input-wrapper focus-within:ring-2 focus-within:ring-primary-main') },
            LeftIcon ? (React.createElement("div", { className: "flex justify-center items-center" },
                React.createElement(LeftIcon, { className: "text-input-label-icon" }))) : null,
            React.createElement("input", { onFocus: (event) => {
                    if (onFocus) {
                        onFocus(event);
                    }
                }, onBlur: (event) => {
                    formikCompatibleOnBlur(event);
                }, ref: ref, placeholder: placeholder, type: type, className: classNames('text-input-input', {
                    'text-input-input-error': Boolean(formikCompatibleError),
                    'text-input-input-disabled': isDisabled,
                }, inputClassName), value: formikCompatibleValue, onChange: formikCompatibleOnChange, onClick: onClick, onKeyDown: (event) => {
                    if ((event.code === 'Enter' || event.code === 'NumpadEnter') &&
                        onEnterPress) {
                        onEnterPress(formikCompatibleValue);
                    }
                    if (onKeyDown) {
                        onKeyDown(event);
                    }
                }, name: formikCompatibleName, id: id || formikCompatibleName, max: max, disabled: isDisabled })),
        withMax && (React.createElement("p", { className: "text-input-with-max" }, `${String(formikCompatibleValue).length}/${max}`)),
        !withErrorInLabel && formikCompatibleError && (React.createElement("p", { className: classNames('text-error-700 text-sm', errorClassName) }, formikCompatibleError))));
});

import React from 'react';
import { BillingDetailsStep } from '../steps/billing-details';
import { CongratulationsStep } from '../steps/congratulations';
import { PaymentDetailsStep } from '../steps/payment-details';
import { SelectEntityTypeStep } from '../steps/select-entity-type';
import { SelectExtrasStep } from '../steps/select-extras';
import { SelectServiceStep } from '../steps/select-service';
import { AnimatePresence, motion } from 'framer-motion';
import { PasswordStep } from '../steps/password';
import { SelectPeriodStep } from '../steps/select-period';
import { SelectEntityStep } from '../steps/select-entity';
import { SelectPeriodAndEntityStep } from '../steps/select-period-and-entity';
import { ReservationSteps } from '@reservation-app/common-used-in-web';
import { useStepperStateSlice } from '../store';
export const CurrentStep = () => {
    const { currentStep } = useStepperStateSlice();
    let content;
    switch (currentStep) {
        case ReservationSteps.SELECT_ENTITY_TYPE:
            content = React.createElement(SelectEntityTypeStep, null);
            break;
        case ReservationSteps.SELECT_SERVICE:
            content = React.createElement(SelectServiceStep, null);
            break;
        case ReservationSteps.SELECT_PERIOD:
            content = React.createElement(SelectPeriodStep, null);
            break;
        case ReservationSteps.SELECT_ENTITY:
            content = React.createElement(SelectEntityStep, null);
            break;
        case ReservationSteps.SELECT_PERIOD_AND_ENTITY:
            content = React.createElement(SelectPeriodAndEntityStep, null);
            break;
        case ReservationSteps.SELECT_EXTRAS:
            content = React.createElement(SelectExtrasStep, null);
            break;
        case ReservationSteps.BILLING_DETAILS:
            content = React.createElement(BillingDetailsStep, null);
            break;
        case ReservationSteps.PAYMENT_DETAILS:
            content = React.createElement(PaymentDetailsStep, null);
            break;
        case ReservationSteps.PASSWORD:
            content = React.createElement(PasswordStep, null);
            break;
        case ReservationSteps.CONGRATULATIONS:
            content = React.createElement(CongratulationsStep, null);
            break;
        default:
            content = null;
            break;
    }
    return (React.createElement(AnimatePresence, { initial: false, mode: "wait" },
        React.createElement(motion.div, { key: currentStep, className: "w-full flex-1 min-w-0", initial: {
                opacity: 0,
            }, animate: {
                opacity: 1,
                transition: {
                    type: 'tween',
                    duration: 0.5,
                },
            }, exit: {
                opacity: 0,
            } }, content)));
};

import { isDevEnv } from '@reservation-app/common-used-in-web';

type Options = {
  shouldRemoveLastCharIfSlash?: boolean;
  ensureSlashAtTheEnd?: boolean;
};

export const sanitizeUrlSlashes = (str: string, options?: Options) => {
  const splitOn = isDevEnv ? 'http://' : 'https://';

  const splitString = str.split(isDevEnv ? 'http://' : 'https://');

  splitString[splitString.length - 1] = splitString[
    splitString.length - 1
  ].replace(new RegExp('///|//', 'g'), '/');

  let result = splitString.join(splitOn);

  if (
    options?.shouldRemoveLastCharIfSlash &&
    result[result.length - 1] === '/'
  ) {
    result = result.substring(0, result.length - 1);
  }

  if (
    options?.ensureSlashAtTheEnd &&
    (result[result.length - 1] !== '/' || !result)
  ) {
    result = result.concat('/');
  }

  return result;
};

export var Languages;
(function (Languages) {
    Languages["EN"] = "en";
    Languages["RO"] = "ro";
    Languages["HU"] = "hu";
})(Languages || (Languages = {}));
export var Currencies;
(function (Currencies) {
    Currencies["RON"] = "RON";
    Currencies["EUR"] = "EUR";
    Currencies["USD"] = "USD";
    Currencies["HUF"] = "HUF";
})(Currencies || (Currencies = {}));
export var Days;
(function (Days) {
    Days["MONDAY"] = "monday";
    Days["TUESDAY"] = "tuesday";
    Days["WEDNESDAY"] = "wednesday";
    Days["THURSDAY"] = "thursday";
    Days["FRIDAY"] = "friday";
    Days["SATURDAY"] = "saturday";
    Days["SUNDAY"] = "sunday";
})(Days || (Days = {}));
export var CronPatterns;
(function (CronPatterns) {
    CronPatterns["EVERY_WEEK"] = "Every week";
    CronPatterns["EVERY_ODD_WEEK"] = "Every odd week";
    CronPatterns["EVERY_EVEN_WEEK"] = "Every even week";
})(CronPatterns || (CronPatterns = {}));
export var Permissions;
(function (Permissions) {
    Permissions["ADMIN"] = "ADMIN";
    Permissions["MANAGER"] = "MANAGER";
    Permissions["EDITOR"] = "EDITOR";
})(Permissions || (Permissions = {}));
export var OrderStatuses;
(function (OrderStatuses) {
    OrderStatuses["ACCEPTED"] = "ACCEPTED";
    OrderStatuses["REJECTED"] = "REJECTED";
    OrderStatuses["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    OrderStatuses["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    OrderStatuses["APPROVAL_PENDING"] = "APPROVAL_PENDING";
    OrderStatuses["DEACTIVATED"] = "DEACTIVATED";
})(OrderStatuses || (OrderStatuses = {}));
export var TileStatuses;
(function (TileStatuses) {
    TileStatuses["AVAILABLE"] = "AVAILABLE";
    TileStatuses["RESERVED"] = "RESERVED";
    TileStatuses["CLOSED"] = "CLOSED";
    TileStatuses["FIRST_HALF_AVAILABLE_SECOND_HALF_CLOSED"] = "FIRST-HALF-AVAILABLE-SECOND-HALF-CLOSED";
    TileStatuses["FIRST_HALF_CLOSED_SECOND_HALF_AVAILABLE"] = "FIRST-HALF-CLOSED-SECOND-HALF-AVAILABLE";
    TileStatuses["FIRST_HALF_RESERVED_SECOND_HALF_CLOSED"] = "FIRST-HALF-RESERVED-SECOND-HALF-CLOSED";
    TileStatuses["FIRST_HALF_CLOSED_SECOND_HALF_RESERVED"] = "FIRST-HALF-CLOSED-SECOND-HALF-RESERVED";
    TileStatuses["FIRST_HALF_AVAILABLE_SECOND_HALF_RESERVED"] = "FIRST-HALF-AVAILABLE-SECOND-HALF-RESERVED";
    TileStatuses["FIRST_HALF_RESERVED_SECOND_HALF_AVAILABLE"] = "FIRST-HALF-RESERVED-SECOND-HALF-AVAILABLE";
    TileStatuses["SELECTED"] = "SELECTED";
    TileStatuses["FIRST_HALF_SELECTED_SECOND_HALF_CLOSED"] = "FIRST-HALF-SELECTED-SECOND-HALF-CLOSED";
    TileStatuses["FIRST_HALF_SELECTED_SECOND_HALF_AVAILABLE"] = "FIRST-HALF-SELECTED-SECOND-HALF-AVAILABLE";
    TileStatuses["FIRST_HALF_SELECTED_SECOND_HALF_RESERVED"] = "FIRST-HALF-SELECTED-SECOND-HALF-RESERVED";
    TileStatuses["FIRST_HALF_CLOSED_SECOND_HALF_SELECTED"] = "FIRST-HALF-CLOSED-SECOND-HALF-SELECTED";
    TileStatuses["FIRST_HALF_AVAILABLE_SECOND_HALF_SELECTED"] = "FIRST-HALF-AVAILABLE-SECOND-HALF-SELECTED";
    TileStatuses["FIRST_HALF_RESERVED_SECOND_HALF_SELECTED"] = "FIRST-HALF-RESERVED-SECOND-HALF-SELECTED";
})(TileStatuses || (TileStatuses = {}));
export var EntityStatuses;
(function (EntityStatuses) {
    EntityStatuses["AVAILABLE"] = "AVAILABLE";
    EntityStatuses["RESERVED"] = "RESERVED";
    EntityStatuses["CLOSED"] = "CLOSED";
})(EntityStatuses || (EntityStatuses = {}));
export var NumberOfPersonsOptionStatuses;
(function (NumberOfPersonsOptionStatuses) {
    NumberOfPersonsOptionStatuses["AVAILABLE"] = "AVAILABLE";
    NumberOfPersonsOptionStatuses["RESERVED"] = "RESERVED";
    NumberOfPersonsOptionStatuses["CLOSED"] = "CLOSED";
})(NumberOfPersonsOptionStatuses || (NumberOfPersonsOptionStatuses = {}));
export var IntervalStatuses;
(function (IntervalStatuses) {
    IntervalStatuses["CLOSED"] = "closed";
    IntervalStatuses["AVAILABLE"] = "available";
    IntervalStatuses["RESERVED"] = "reserved";
})(IntervalStatuses || (IntervalStatuses = {}));
export var ShortcutStatuses;
(function (ShortcutStatuses) {
    ShortcutStatuses["CLOSED"] = "closed";
    ShortcutStatuses["AVAILABLE"] = "available";
    ShortcutStatuses["RESERVED"] = "reserved";
    ShortcutStatuses["PARTIALLY_CLOSED"] = "partially-closed";
    ShortcutStatuses["PARTIALLY_RESERVED"] = "partially-reserved";
})(ShortcutStatuses || (ShortcutStatuses = {}));
export var SectionTypes;
(function (SectionTypes) {
    SectionTypes["SERVICES"] = "services";
    SectionTypes["TESTIMONIALS"] = "testimonials";
    SectionTypes["GALLERY"] = "gallery";
    SectionTypes["ALTERNATING_CONTENT"] = "alternating-content";
    SectionTypes["RECORDS"] = "records";
    SectionTypes["FOOTER"] = "footer";
    SectionTypes["HERO"] = "hero";
    SectionTypes["SCHEDULE"] = "schedule";
    SectionTypes["ECOSYSTEM"] = "ecosystem";
    SectionTypes["HTML"] = "html";
    SectionTypes["CONTACTS"] = "contacts";
    SectionTypes["PRICES"] = "prices";
    SectionTypes["PARTNERS"] = "partners";
    SectionTypes["STATISTICS"] = "statistics";
    SectionTypes["FISHING_SPECIFIC"] = "fishing-specific";
})(SectionTypes || (SectionTypes = {}));
export var FieldTypes;
(function (FieldTypes) {
    FieldTypes["HIDDEN"] = "hidden";
    FieldTypes["SECRET"] = "secret";
    FieldTypes["TEXT"] = "text";
    FieldTypes["TEXTAREA"] = "textarea";
    FieldTypes["ICON"] = "icon";
    FieldTypes["NUMBER"] = "number";
    FieldTypes["TRANSLATION"] = "translation";
    FieldTypes["TRANSLATION_TEXTAREA"] = "translation-text-area";
    FieldTypes["BOOLEAN"] = "boolean";
    FieldTypes["SELECT"] = "select";
    FieldTypes["MULTI_SELECT"] = "multi-select";
    FieldTypes["ASYNC_SELECT"] = "async-select";
    FieldTypes["ASYNC_MULTI_SELECT"] = "async-multi-select";
    FieldTypes["IMAGE_UPLOAD"] = "image-upload";
    FieldTypes["MULTI_IMAGE_UPLOAD"] = "multi-image-upload";
    FieldTypes["NESTED"] = "nested";
    FieldTypes["NESTED_ARRAY"] = "nested-array";
    FieldTypes["ARRAY"] = "array";
    FieldTypes["TIME_ARRAY"] = "time-array";
    FieldTypes["RELATION"] = "relation";
    FieldTypes["RELATIONS"] = "relations";
    FieldTypes["LOCAL_RELATION"] = "local-relation";
    FieldTypes["DATE"] = "date";
    FieldTypes["CUSTOM"] = "custom";
    FieldTypes["HOURS_AND_MINUTES"] = "hours-and-minutes";
    FieldTypes["MINUTES"] = "minutes";
    FieldTypes["ID"] = "ID";
})(FieldTypes || (FieldTypes = {}));
export var ViewModes;
(function (ViewModes) {
    ViewModes["DAY"] = "DAY";
    ViewModes["THREEDAY"] = "THREEDAY";
    ViewModes["WEEK"] = "WEEK";
    ViewModes["MONTH"] = "MONTH";
})(ViewModes || (ViewModes = {}));
export var ReservationSteps;
(function (ReservationSteps) {
    ReservationSteps["SELECT_ENTITY_TYPE"] = "select-entity-type";
    ReservationSteps["SELECT_SERVICE"] = "select-service";
    ReservationSteps["SELECT_ENTITY"] = "select-entity";
    ReservationSteps["SELECT_PERIOD"] = "select-period";
    ReservationSteps["SELECT_PERIOD_AND_ENTITY"] = "select-period-and-entity";
    ReservationSteps["SELECT_PERIOD_AND_SERVICE"] = "select-period-and-service";
    ReservationSteps["SELECT_EXTRAS"] = "select-extras";
    ReservationSteps["BILLING_DETAILS"] = "billing-details";
    ReservationSteps["PASSWORD"] = "password";
    ReservationSteps["PAYMENT_DETAILS"] = "payment-details";
    ReservationSteps["CONGRATULATIONS"] = "congratulations";
})(ReservationSteps || (ReservationSteps = {}));
export var ReservationSubSteps;
(function (ReservationSubSteps) {
    ReservationSubSteps["SELECT_ENTITY_TYPE"] = "select-entity-type";
    ReservationSubSteps["SELECT_SERVICE"] = "select-service";
    ReservationSubSteps["SELECT_ENTITY"] = "select-entity";
    ReservationSubSteps["SELECT_PERIOD"] = "select-period";
    ReservationSubSteps["SELECT_PERIOD_AND_ENTITY"] = "select-period-and-entity";
    ReservationSubSteps["SELECT_PERIOD_AND_SERVICE"] = "select-period-and-service";
    ReservationSubSteps["SELECT_EXTRAS"] = "select-extras";
    ReservationSubSteps["BILLING_DETAILS"] = "billing-details";
    ReservationSubSteps["PASSWORD"] = "password";
    ReservationSubSteps["PAYMENT_DETAILS"] = "payment-details";
    ReservationSubSteps["CONGRATULATIONS"] = "congratulations";
    ReservationSubSteps["SELECT_NUMBER_OF_PERSONS"] = "select-number-of-persons";
})(ReservationSubSteps || (ReservationSubSteps = {}));
export var BillingDetailsModes;
(function (BillingDetailsModes) {
    BillingDetailsModes["INDIVIDUAL"] = "INDIVIDUAL";
    BillingDetailsModes["COMPANY"] = "COMPANY";
})(BillingDetailsModes || (BillingDetailsModes = {}));
export var PromotionTypes;
(function (PromotionTypes) {
    PromotionTypes["FIXED"] = "Fixed";
    PromotionTypes["PERCENTAGE"] = "Percentage";
})(PromotionTypes || (PromotionTypes = {}));
export var RestrictionTypes;
(function (RestrictionTypes) {
    RestrictionTypes["A_B_Z_INTERVALS_ONLY_TOGETHER"] = "A,B,...,Z intervals only together";
    RestrictionTypes["AT_LEAST_X_CONTINOUS_HOURS_TOUCHING_Y_DAYS"] = "At least X continous hours touching Y days";
})(RestrictionTypes || (RestrictionTypes = {}));
export var AlertSeverities;
(function (AlertSeverities) {
    AlertSeverities["INFO"] = "Info";
    AlertSeverities["CRITICAL"] = "Critical";
    AlertSeverities["PRIMARY"] = "Primary";
    AlertSeverities["SUCCESS"] = "Success";
})(AlertSeverities || (AlertSeverities = {}));
export var AlertTypes;
(function (AlertTypes) {
    AlertTypes["SCHEDULE"] = "Schedule";
    AlertTypes["ENTITIES"] = "Entities";
    AlertTypes["GLOBAL"] = "Global";
    AlertTypes["SERVICES"] = "Services";
    AlertTypes["PRICES"] = "Prices";
})(AlertTypes || (AlertTypes = {}));
export var BuildStatuses;
(function (BuildStatuses) {
    BuildStatuses["SUCCESS"] = "Success";
    BuildStatuses["FAIL"] = "Fail";
})(BuildStatuses || (BuildStatuses = {}));
export var BrandColors;
(function (BrandColors) {
    BrandColors["PURPLE"] = "purple";
    BrandColors["BLUE"] = "blue";
    BrandColors["GREEN"] = "green";
})(BrandColors || (BrandColors = {}));
export var SeparatorStyles;
(function (SeparatorStyles) {
    SeparatorStyles["BASIC"] = "basic";
    SeparatorStyles["HIDDEN"] = "hidden";
    SeparatorStyles["TAILWIND"] = "tailwind";
    SeparatorStyles["DIAGONAL_LINE_RIGHT"] = "diagonal-line-right";
    SeparatorStyles["DIAGONAL_LINE_LEFT"] = "diagonal-line-left";
    SeparatorStyles["HORIZONTAL_LINE"] = "horizontal-line";
    SeparatorStyles["FOOTBALL_UPWARDS"] = "football-upwards";
    SeparatorStyles["FOOTBALL_DOWNWARDS"] = "football-downwards";
    SeparatorStyles["FOOTBALL_DOUBLE"] = "football-double";
})(SeparatorStyles || (SeparatorStyles = {}));
export var HeroSectionStyles;
(function (HeroSectionStyles) {
    HeroSectionStyles["BASIC"] = "basic";
    HeroSectionStyles["WITH_STATISTICS"] = "with-statistics";
})(HeroSectionStyles || (HeroSectionStyles = {}));
export var FishingSpecificSectionStyles;
(function (FishingSpecificSectionStyles) {
    FishingSpecificSectionStyles["BASIC"] = "basic";
    FishingSpecificSectionStyles["FULL_WIDTH_IMAGE"] = "full-width-image";
})(FishingSpecificSectionStyles || (FishingSpecificSectionStyles = {}));
export var StatisticsSectionStyles;
(function (StatisticsSectionStyles) {
    StatisticsSectionStyles["BASIC"] = "basic";
})(StatisticsSectionStyles || (StatisticsSectionStyles = {}));
export var ContactsSectionStyles;
(function (ContactsSectionStyles) {
    ContactsSectionStyles["BASIC"] = "basic";
})(ContactsSectionStyles || (ContactsSectionStyles = {}));
export var EcosystemSectionStyles;
(function (EcosystemSectionStyles) {
    EcosystemSectionStyles["BASIC"] = "basic";
})(EcosystemSectionStyles || (EcosystemSectionStyles = {}));
export var FooterSectionStyles;
(function (FooterSectionStyles) {
    FooterSectionStyles["BASIC"] = "basic";
})(FooterSectionStyles || (FooterSectionStyles = {}));
export var AlternatingContentSectionStyles;
(function (AlternatingContentSectionStyles) {
    AlternatingContentSectionStyles["BASIC"] = "basic";
})(AlternatingContentSectionStyles || (AlternatingContentSectionStyles = {}));
export var GallerySectionStyles;
(function (GallerySectionStyles) {
    GallerySectionStyles["BASIC"] = "basic";
    GallerySectionStyles["GRID"] = "grid";
})(GallerySectionStyles || (GallerySectionStyles = {}));
export var GallerySubsectionStyles;
(function (GallerySubsectionStyles) {
    GallerySubsectionStyles["BASIC"] = "basic";
    GallerySubsectionStyles["CATCH"] = "catch";
})(GallerySubsectionStyles || (GallerySubsectionStyles = {}));
export var PricesSectionStyles;
(function (PricesSectionStyles) {
    PricesSectionStyles["BASIC"] = "basic";
    PricesSectionStyles["TABLE"] = "table";
    PricesSectionStyles["CARDS"] = "cards";
})(PricesSectionStyles || (PricesSectionStyles = {}));
export var RecordsSectionStyles;
(function (RecordsSectionStyles) {
    RecordsSectionStyles["BASIC"] = "basic";
    RecordsSectionStyles["TIMELINE"] = "timeline";
})(RecordsSectionStyles || (RecordsSectionStyles = {}));
export var ScheduleSectionStyles;
(function (ScheduleSectionStyles) {
    ScheduleSectionStyles["BASIC"] = "basic";
})(ScheduleSectionStyles || (ScheduleSectionStyles = {}));
export var ServicesSectionStyles;
(function (ServicesSectionStyles) {
    ServicesSectionStyles["BASIC"] = "basic";
})(ServicesSectionStyles || (ServicesSectionStyles = {}));
export var TestimonialsSectionStyles;
(function (TestimonialsSectionStyles) {
    TestimonialsSectionStyles["BASIC"] = "basic";
    TestimonialsSectionStyles["ALTERNATIVE"] = "alternative";
})(TestimonialsSectionStyles || (TestimonialsSectionStyles = {}));
export var PartnersSectionStyles;
(function (PartnersSectionStyles) {
    PartnersSectionStyles["BASIC"] = "basic";
})(PartnersSectionStyles || (PartnersSectionStyles = {}));
export var EntityTypeCardLayouts;
(function (EntityTypeCardLayouts) {
    EntityTypeCardLayouts["ICON_ON_TOP"] = "icon-on-top";
    EntityTypeCardLayouts["ICON_ON_LEFT_SIDE"] = "icon-on-left-side";
    EntityTypeCardLayouts["IMAGE"] = "image";
    EntityTypeCardLayouts["IMAGE_TEXT_INSIDE"] = "image-text-inside";
})(EntityTypeCardLayouts || (EntityTypeCardLayouts = {}));
export var ServiceCardLayouts;
(function (ServiceCardLayouts) {
    ServiceCardLayouts["ICON_ON_TOP"] = "icon-on-top";
    ServiceCardLayouts["ICON_ON_LEFT_SIDE"] = "icon-on-left-side";
    ServiceCardLayouts["IMAGE"] = "image";
    ServiceCardLayouts["IMAGE_TEXT_INSIDE"] = "image-text-inside";
})(ServiceCardLayouts || (ServiceCardLayouts = {}));
export var EntityCardLayouts;
(function (EntityCardLayouts) {
    EntityCardLayouts["IMAGE"] = "image";
    EntityCardLayouts["ICON"] = "icon";
})(EntityCardLayouts || (EntityCardLayouts = {}));
export var ExtraCardLayouts;
(function (ExtraCardLayouts) {
    ExtraCardLayouts["IMAGE"] = "image";
    ExtraCardLayouts["ICON"] = "icon";
    ExtraCardLayouts["NO_IMAGERY"] = "no-imagery";
})(ExtraCardLayouts || (ExtraCardLayouts = {}));
export var PromoCardLayouts;
(function (PromoCardLayouts) {
    PromoCardLayouts["BASIC"] = "Basic";
    PromoCardLayouts["TABLE"] = "Table";
    PromoCardLayouts["HIDDEN"] = "Hidden";
})(PromoCardLayouts || (PromoCardLayouts = {}));
export var ConsentTypes;
(function (ConsentTypes) {
    ConsentTypes["ALLOW_ALL"] = "Allow All";
    ConsentTypes["ALLOW_ONLY_NECESSARY"] = "Allow Only Necessary";
})(ConsentTypes || (ConsentTypes = {}));
export var ListingPageViewModes;
(function (ListingPageViewModes) {
    ListingPageViewModes["CARDS"] = "cards";
    ListingPageViewModes["TABLE"] = "table";
})(ListingPageViewModes || (ListingPageViewModes = {}));
export var StatusColors;
(function (StatusColors) {
    StatusColors["RED"] = "red";
    StatusColors["GREEN"] = "green";
    StatusColors["BLUE"] = "blue";
    StatusColors["GRAY"] = "gray";
    StatusColors["YELLOW"] = "yellow";
    StatusColors["PRIMARY"] = "primary";
})(StatusColors || (StatusColors = {}));
export var NotificationActions;
(function (NotificationActions) {
    NotificationActions["CREATE"] = "create";
    NotificationActions["CANCEL"] = "cancel";
    NotificationActions["REFUND"] = "refund";
    NotificationActions["DECIDE_PENDING"] = "decide_pending";
    NotificationActions["DECIDE_ACCEPT"] = "decide_accept";
    NotificationActions["DECIDE_REJECT"] = "decide_reject";
})(NotificationActions || (NotificationActions = {}));
export var FishTypes;
(function (FishTypes) {
    FishTypes["CARP"] = "carp";
    FishTypes["BROWN_TROUT"] = "brown-trout";
    FishTypes["GRASS_CARP"] = "grass-carp";
    FishTypes["STURGERON"] = "sturgeon";
    FishTypes["NORTHERN_PIKE"] = "northern-pike";
    FishTypes["CATFISH"] = "catfish";
    FishTypes["AFRICAN_CATFISH"] = "african-catfish";
    FishTypes["ZANDER"] = "zander";
    FishTypes["PERCH"] = "perch";
    FishTypes["CRUCIAN_CARP"] = "crucian-carp";
    FishTypes["COMMON_BREAM"] = "common-bream";
    FishTypes["TENCH"] = "tench";
    FishTypes["SQUALIUS_CEPHALUS"] = "squalius-cephalus";
    FishTypes["RUFFLE"] = "ruffle";
    FishTypes["PUMPKINSEED"] = "pumpkinseed";
    FishTypes["SILVER_CARP"] = "silver-carp";
    FishTypes["RUDD"] = "rudd";
})(FishTypes || (FishTypes = {}));

import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useStepperStateSlice } from '../store';
export const useCleanUpOnUnmount = () => {
    const { resetReservationFlow } = useStepperStateSlice();
    const queryClient = useQueryClient();
    useEffect(() => {
        return () => {
            resetReservationFlow(queryClient);
        };
    }, []);
};

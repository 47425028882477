import { useGetTranslation } from '@reservation-app/common-used-in-web';
import React from 'react';
export const TooltipContent = () => {
    const getTranslation = useGetTranslation();
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: "flex items-center mb-2" },
            React.createElement("div", { className: "flex justify-center items-center w-6 h-6 mr-3 rounded-full text-gray-900" }, "2"),
            React.createElement("p", { className: "text-gray-900" }, getTranslation('available'))),
        React.createElement("div", { className: "flex items-center mb-2" },
            React.createElement("div", { className: "flex justify-center items-center w-6 h-6 mr-3 rounded-full bg-primary-light text-gray-900" }, "2"),
            React.createElement("p", { className: "text-gray-900" }, getTranslation('selectedByYou'))),
        React.createElement("div", { className: "flex items-center mb-2" },
            React.createElement("div", { className: "flex justify-center items-center w-6 h-6 mr-3 rounded-full bg-gray-300 text-gray-500" }, "2"),
            React.createElement("p", { className: "text-gray-900" }, getTranslation('closed'))),
        React.createElement("div", { className: "flex items-center mb-2" },
            React.createElement("div", { className: "flex justify-center items-center w-6 h-6 mr-3 rounded-full bg-error-200 text-gray-800" }, "2"),
            React.createElement("p", { className: "text-gray-900" }, getTranslation('reserved')))));
};

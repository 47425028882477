import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { TextInput } from '../../../components';
import { motion, AnimatePresence } from 'framer-motion';
import { useDebouncedValue } from '@mantine/hooks';
import { useGetTranslation, Icon, zIndexes, isLastIndex, } from '@reservation-app/common-used-in-web';
import { useBillingDetailsStepSpecificSlice } from '../../../reservation-flow/store';
import { pick } from 'lodash';
import { replaceLocaleCharactersInText } from '../../helpers/replace-locale-characters-in-string';
const IMPOSSIBLE_STRING = 'Z_X_Z';
export const ProfileSelector = ({ options, buttonClassName, onSelect, onDelete, isAdminPanel, }) => {
    const getTranslation = useGetTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
        placement: 'bottom-start',
    });
    const { validateBillingDetails } = useBillingDetailsStepSpecificSlice();
    const [debouncedFilterValue] = useDebouncedValue(filterValue, 400);
    const filteredOptions = useMemo(() => {
        const searchValue = replaceLocaleCharactersInText(debouncedFilterValue || '');
        return searchValue
            ? options.filter((option) => {
                const filterableOption = pick(option, [
                    'fullName',
                    'email',
                    'phoneNumber',
                ]);
                return Object.entries(filterableOption).some(([optionKey, optionValue]) => {
                    var _a;
                    let matchValue = optionValue;
                    if (optionKey === 'fullName') {
                        matchValue = replaceLocaleCharactersInText(optionValue || '');
                    }
                    return (((_a = matchValue === null || matchValue === void 0 ? void 0 : matchValue.toLowerCase) === null || _a === void 0 ? void 0 : _a.call(matchValue).includes(searchValue || IMPOSSIBLE_STRING)) || false);
                });
            })
            : options;
    }, [options, debouncedFilterValue]);
    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen &&
                popperElement &&
                !popperElement.contains(event.target) &&
                referenceElement &&
                !referenceElement.contains(event.target)) {
                if (isOpen && setIsOpen) {
                    setIsOpen(false);
                    setFilterValue('');
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, setIsOpen, popperElement, referenceElement]);
    const inputRef = useRef();
    return (React.createElement("div", null,
        React.createElement("button", { ref: setReferenceElement, className: classNames('flex justify-between items-center text-gray-900 bg-white px-2 py-2 rounded-md border border-gray-400 cursor-pointer w-full 768:max-w-[330px]', buttonClassName), onClick: () => {
                setIsOpen((isOpen) => !isOpen);
                if (isOpen) {
                    setFilterValue('');
                }
                else {
                    setTimeout(() => {
                        var _a, _b;
                        (_b = (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
                    }, 150);
                }
            } },
            React.createElement("p", null, getTranslation(isAdminPanel
                ? 'selectClient'
                : 'selectPreviousProfile')),
            React.createElement(Icon, { icon: "chevronDown", className: "w-4 h-4 ml-auto" })),
        React.createElement(AnimatePresence, { mode: "wait" }, isOpen && (React.createElement(motion.div, Object.assign({ className: classNames('w-full 768:max-w-[330px]', zIndexes['PROFILE_SELECTOR_CONTENT']), ref: setPopperElement, style: styles.popper }, attributes.popper),
            React.createElement(motion.div, { className: "p-2 text-gray-900 bg-white rounded-md shadow-xl ", style: { transformOrigin: 'top center' }, initial: { opacity: 0, scale: 0.5 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.5 }, transition: { type: 'spring', damping: 20, stiffness: 250 } },
                React.createElement(TextInput, { LeftIcon: () => (React.createElement(Icon, { icon: "search", className: "w-6 h-6 ml-2 text-gray-500" })), containerClassName: "shadow-inner focus:ring-2 ring-primary-main transition-colors outline-none rounded", placeholder: `${getTranslation('search')}...`, ref: inputRef, value: filterValue, onChange: (e) => {
                        setFilterValue(e.target.value);
                    } }),
                React.createElement("div", { className: "overflow-y-auto max-h-72" }, filteredOptions.length > 0 ? (filteredOptions.map((option, index) => (React.createElement("div", { key: index, className: classNames('flex justify-between items-center hover:bg-blue-100 py-1 w-full cursor-pointer', {
                        'border-b': !isLastIndex(index, filteredOptions),
                    }), onClick: () => {
                        setIsOpen(false);
                        onSelect(option);
                        validateBillingDetails();
                    } },
                    React.createElement("div", { className: "flex flex-col pl-1" },
                        React.createElement("p", null, option.fullName),
                        option.email && React.createElement("p", null, option.email)),
                    !option.isMembershipCard && (React.createElement(Icon, { icon: "close", className: "w-8 h-8 cursor-pointer", onClick: (event) => {
                            event.stopPropagation();
                            onDelete(option);
                        } })))))) : (React.createElement("p", { className: "flex justify-center py-3 text-gray-900" },
                    getTranslation('noResults'),
                    ".")))))))));
};

import { getDisplayTime, Icon, IntervalStatuses, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { format, isBefore } from 'date-fns';
import React from 'react';
import { areIntervalsTheSame } from '../../../reservation-flow/helpers/are-intervals-the-same';
import { getReservableIntervalByIdFromService } from '../../../reservation-flow/helpers/get-reservable-interval-by-id-from-service';
import { getSelectedIntervalNumberOfPersons } from '../../../reservation-flow/helpers/get-selected-interval-number-of-persons';
import { motion } from 'framer-motion';
import { usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, } from '../../../reservation-flow/store';
export const ReservableInterval = ({ reservableInterval, afterIntervalVariants, beforeIntervalVariants, relevantEntity, isLastInterval, index, }) => {
    const { selectedBulkNumberOfPersons, setSelectedBulkNumberOfPersons } = useSelectedValuesSlice();
    const { selectedDate, previousSelectedDate } = usePeriodAndEntityStepSpecificSlice();
    const { selectedIntervals, setSelectedIntervals, selectedService, } = useSelectedValuesSlice();
    const getTranslation = useGetTranslation();
    const activeLanguage = useActiveLanguage();
    const currentListSelectedIntervals = selectedIntervals;
    const setCurrentListSelectedIntervals = setSelectedIntervals;
    let isIntervalSelected = false;
    if (currentListSelectedIntervals.some((selectedInterval) => {
        return (selectedDate &&
            areIntervalsTheSame(selectedInterval, Object.assign({ date: selectedDate }, reservableInterval.timeInterval)));
    })) {
        isIntervalSelected = true;
    }
    const displayTime = getDisplayTime({
        interval: reservableInterval.timeInterval,
        activeLanguage,
    });
    const key = `${selectedDate || previousSelectedDate
        ? selectedDate || previousSelectedDate
        : '-'}/${displayTime}-${reservableInterval.priceId}`;
    return (React.createElement(motion.div, { key: key, variants: selectedDate &&
            previousSelectedDate &&
            isBefore(selectedDate, previousSelectedDate)
            ? afterIntervalVariants
            : beforeIntervalVariants, initial: "initial", animate: "animate", exit: "exit", transition: { duration: 0.1, ease: 'easeIn' }, "data-test-id": `reservation-modal-button-${index}`, onClick: () => {
            if (isIntervalSelected) {
                const filteredSelectedIntervals = currentListSelectedIntervals.filter((selectedInterval) => {
                    if (areIntervalsTheSame(selectedInterval, Object.assign({ date: selectedDate }, reservableInterval.timeInterval))) {
                        return false;
                    }
                    return true;
                });
                setCurrentListSelectedIntervals(filteredSelectedIntervals);
            }
            else {
                const numberOfPersons = getSelectedIntervalNumberOfPersons({
                    syncGroupEntity: relevantEntity,
                    selectedBulkNumberOfPersons,
                    setSelectedBulkNumberOfPersons,
                    selectedService,
                    selectedDate,
                });
                setCurrentListSelectedIntervals([
                    ...currentListSelectedIntervals,
                    {
                        date: format(selectedDate, 'yyyy-MM-dd'),
                        selectedExtras: [],
                        reservableIntervalId: reservableInterval._id,
                        numberOfPersons,
                        reservableInterval: getReservableIntervalByIdFromService({
                            service: selectedService,
                            reservableIntervalId: reservableInterval._id,
                        }),
                    },
                ]);
            }
        }, className: classNames(`${key} reservable-interval flex flex-grow justify-between items-center bg-gray-50 rounded-md px-4 py-1.5 cursor-pointer shadow-md transition-all duration-200 select-none text-sm`, {
            'text-gray-900 bg-gray-200 pointer-events-none': reservableInterval.timeInterval.showAsClosed,
            'text-gray-900 768:hover:bg-primary-100 hover:text-gray-900 768:bg-gray-50': !isIntervalSelected &&
                reservableInterval.status === IntervalStatuses.AVAILABLE &&
                !reservableInterval.timeInterval.showAsClosed,
            'bg-primary-light': isIntervalSelected,
            'text-gray-900 bg-error-200 pointer-events-none': reservableInterval.status === IntervalStatuses.RESERVED,
            'mb-4': !isLastInterval,
        }) },
        React.createElement("div", { className: "flex text-gray-900 items-center" },
            React.createElement(Icon, { icon: reservableInterval.timeInterval.icon || 'clock', className: "flex-shrink-0 text-base mr-2" }),
            React.createElement("p", { className: "text-gray-900" }, displayTime)),
        reservableInterval.status === IntervalStatuses.RESERVED &&
            !reservableInterval.timeInterval.showAsClosed && (React.createElement("p", { className: `${key} font-semibold ml-2 text-error-main` }, reservableInterval.availableNumberOfPersons
            ? `${reservableInterval.availableNumberOfPersons} ${reservableInterval.availableNumberOfPersons > 1
                ? getTranslation('availablePlaces')
                : getTranslation('availablePlace')}`
            : getTranslation('reserved'))),
        reservableInterval.timeInterval.showAsClosed && (React.createElement("p", { className: `${key} font-semibold ml-2 text-gray-500` }, getTranslation('closed'))),
        reservableInterval.status === IntervalStatuses.AVAILABLE &&
            !reservableInterval.timeInterval.showAsClosed && (React.createElement("p", { className: `${key} font-semibold ml-2 text-gray-700` }, getTranslation('available')))));
};

import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createStaticPropertiesSlice = (set, _get) => ({
    staticProps: {
        apiClient: null,
        defaultLanguage: null,
        toastManager: null,
        isAdminPanel: false,
        isVerifyAvailabilityMode: false,
        onLeaveReservationFlowClick: undefined,
        useWindowFocus: undefined,
        pageUrl: null,
        rulesHtml: null,
        pricesSectionProps: null,
        schedulesSectionProps: null,
        TermsAndConditionsComponent: null,
        componentProps: null,
    },
    components: {
        error: null,
        loading: null,
        headerWrapper: null,
    },
    setStaticProps: ({ apiClient, defaultLanguage, toastManager, onLeaveReservationFlowClick, isAdminPanel, isVerifyAvailabilityMode, useWindowFocus, pageUrl, rulesHtml, pricesSectionProps, schedulesSectionProps, TermsAndConditionsComponent, componentProps, components: { error, loading, headerWrapper }, }, extraDevtoolsInfo) => set((state) => {
        state.static.staticProps = {
            defaultLanguage,
            apiClient,
            toastManager,
            onLeaveReservationFlowClick,
            isAdminPanel,
            isVerifyAvailabilityMode,
            useWindowFocus,
            componentProps,
            pageUrl,
            rulesHtml,
            pricesSectionProps,
            schedulesSectionProps,
            TermsAndConditionsComponent,
        };
        state.static.components = {
            error,
            loading,
            headerWrapper,
        };
    }, false, getDevtoolsLogMessage('setStaticProps', extraDevtoolsInfo)),
});

import React, { useEffect } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { ModalWrapper } from './modal-wrapper';
import { useFormattedDateLocale } from '../hooks/use-formatted-date-locale';
import * as Sentry from '@sentry/react';
import { useIsAdminPanel, useModalStatesDataSlice, useSelectedValuesSlice, useStepperStateSlice, } from '../store';
import { zIndexes, ReservationSteps, isLastIndex, Icon, useTranslationsStore, Button, SELECTED_COMPANY_NAME_LS_KEY, Modal, getDisplayTime, } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame } from '../helpers/are-intervals-the-same';
import { getRestrictionValidationErrors } from '../helpers/get-restriction-validation-errors';
export const ConflictingReservedIntervalsModal = () => {
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { setCurrentStep, currentStep, bottomSheetSnapTo } = useStepperStateSlice();
    const isAdminPanel = useIsAdminPanel();
    const { isConflictingSelectedIntervalsModalOpen, setIsConflictingSelectedIntervalsModalOpen, conflictingSelectedIntervals, } = useModalStatesDataSlice();
    const { selectedIntervals, selectedEntity, selectedService, removeSelectedIntervals, } = useSelectedValuesSlice();
    const locale = useFormattedDateLocale(activeLanguage);
    const areAllSelectedIntervalsIntersecting = selectedIntervals.every((selectedInterval) => conflictingSelectedIntervals.some((conflictingSelectedInterval) => areIntervalsTheSame(selectedInterval, conflictingSelectedInterval)));
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (isConflictingSelectedIntervalsModalOpen) {
            try {
                Sentry.captureMessage('ConflictingReservedIntervalsModal opened', {
                    level: 'info',
                    extra: {
                        selectedIntervals: selectedIntervals === null || selectedIntervals === void 0 ? void 0 : selectedIntervals.map((si) => {
                            var _a, _b, _c, _d;
                            return ({
                                date: si === null || si === void 0 ? void 0 : si.date,
                                startTime: (_b = (_a = si === null || si === void 0 ? void 0 : si.reservableInterval) === null || _a === void 0 ? void 0 : _a.timeInterval) === null || _b === void 0 ? void 0 : _b.startTime,
                                endTime: (_d = (_c = si === null || si === void 0 ? void 0 : si.reservableInterval) === null || _c === void 0 ? void 0 : _c.timeInterval) === null || _d === void 0 ? void 0 : _d.endTime,
                            });
                        }),
                        selectedEntity: (_d = (_c = (_b = (_a = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.uiProps) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.en,
                        selectedService: (_h = (_g = (_f = (_e = selectedService === null || selectedService === void 0 ? void 0 : selectedService.uiProps) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.en,
                        companyName: process.env.GATSBY_COMPANY_NAME ||
                            localStorage.getItem(SELECTED_COMPANY_NAME_LS_KEY) ||
                            'UNKNOWN',
                    },
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }, [isConflictingSelectedIntervalsModalOpen]);
    const restrictionErrors = getRestrictionValidationErrors({
        selectedIntervals,
        selectedEntity,
        selectedService,
        isAdmin: isAdminPanel,
    })
        .map((error) => error[activeLanguage])
        .join(' \n');
    return (React.createElement(Modal, { overlayClassName: zIndexes['CONFLICTING_INTERVALS_MODAL'], isOpen: isConflictingSelectedIntervalsModalOpen &&
            currentStep !== ReservationSteps.CONGRATULATIONS, onClose: () => { }, withCloseButton: false, modalClassName: "p-0", restoreFocus: false },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "text-white bg-warning-main" },
            React.createElement("div", { className: "p-4" },
                React.createElement("p", { className: "mb-2 text-lg font-semibold text-gray-900" }, getTranslation('problemWithIntervals')),
                React.createElement("p", { className: "mb-5 text-gray-900" }, conflictingSelectedIntervals.length === 1
                    ? getTranslation('followingDateGotReserved')
                    : getTranslation('followingDatesGotReserved')),
                conflictingSelectedIntervals.map((conflictingInterval, index) => {
                    const displayTime = getDisplayTime({
                        interval: conflictingInterval.reservableInterval.timeInterval,
                        activeLanguage,
                    });
                    return (React.createElement("div", { key: conflictingInterval.reservableIntervalId, className: classNames('flex justify-between items-center mb-3 rounded-md shadow-md py-2 px-4', { 'mb-5': isLastIndex(index, conflictingSelectedIntervals) }) },
                        React.createElement("p", { className: "text-lg font-semibold text-gray-900" }, format(new Date(conflictingInterval.date), 'd MMMM', {
                            locale,
                        })),
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(Icon, { icon: "clock", className: "flex-shrink-0 mr-1 text-gray-900 stroke-2" }),
                            React.createElement("p", { className: "text-gray-900" }, displayTime))));
                }),
                restrictionErrors.length > 0 && (React.createElement("div", null,
                    React.createElement("p", { className: "text-gray-900" }, getTranslation('youHaveToChooseAnotherIntervalBecause')),
                    React.createElement("p", { className: "mb-2 font-semibold text-gray-900" }, restrictionErrors))),
                !areAllSelectedIntervalsIntersecting && (React.createElement(Button, { onClick: () => {
                        removeSelectedIntervals(conflictingSelectedIntervals);
                        setIsConflictingSelectedIntervalsModalOpen(false);
                    }, color: "warning", size: "md", variant: "outline", className: "w-full mb-2 conflicting-reserved-intervals-modal-not-all-intervals-intersect-fitler-button" }, getTranslation('goOnWithTheRemainingIntervals'))),
                React.createElement(Button, { onClick: () => {
                        setCurrentStep(ReservationSteps.SELECT_PERIOD_AND_ENTITY);
                        setTimeout(() => {
                            removeSelectedIntervals(conflictingSelectedIntervals);
                            bottomSheetSnapTo === null || bottomSheetSnapTo === void 0 ? void 0 : bottomSheetSnapTo(0);
                        }, 300);
                        setIsConflictingSelectedIntervalsModalOpen(false);
                    }, color: "warning", size: "md", className: "w-full conflicting-reserved-intervals-modal-go-back-to-intervals-step" }, getTranslation('goBackToIntervalsStep'))))));
};

import { isPeriodExpiredForDate } from './is-period-expired';
export const getPromotionsToList = ({ promotions, }) => {
    const today = new Date();
    const promotionsToList = [];
    promotions
        .filter(({ period }) => !isPeriodExpiredForDate({ period, date: today }))
        .forEach(({ period, value: { promotions: periodPromotions } }) => {
        periodPromotions.forEach((periodPromotion) => {
            promotionsToList.push(Object.assign(Object.assign({}, periodPromotion), { startDate: period.startDate, endDate: period.endDate, excludeStartDate: period.excludeStartDate, excludeEndDate: period.excludeEndDate }));
        });
    });
    return promotionsToList.sort((a, b) => a.displayOrder - b.displayOrder);
};

const localeCharacterMapping = {
    ă: 'a',
    â: 'a',
    ț: 't',
    ș: 's',
    î: 'i',
    á: 'a',
    é: 'e',
    ö: 'o',
    ő: 'o',
    ó: 'o',
    ü: 'u',
    ű: 'u',
    ú: 'u',
};
export const replaceLocaleCharactersInText = (text) => {
    if (!text) {
        return '';
    }
    let result = '';
    const lowerCaseText = text.toLowerCase();
    for (const char of lowerCaseText) {
        const localCharacterAlternative = localeCharacterMapping[char];
        if (localCharacterAlternative) {
            result += localCharacterAlternative;
        }
        else {
            result += char;
        }
    }
    return result;
};

import React from 'react';
import classNames from 'classnames';
export const Slide = ({ width, height, children, onClick, index, highlightedIndex, isHighlightMode, isSsr = false, isHidden, }) => {
    const extraProps = { style: {} };
    if (!isSsr) {
        extraProps.style.width = width;
        if (isHighlightMode) {
            extraProps.style.height = height;
        }
    }
    return (React.createElement("div", Object.assign({ "aria-hidden": isHidden ? 'true' : 'false', role: onClick ? 'button' : undefined, tabIndex: onClick ? 0 : undefined, onClick: () => {
            if (onClick) {
                onClick(index);
            }
        }, onKeyDown: (event) => {
            if (onClick && (event.code === 'Space' || event.code === 'Enter')) {
                onClick(index);
            }
        }, className: classNames('slider-slide', {
            'slider-slide-without-modal': onClick === undefined,
            'slider-slide-highlight-active': index === highlightedIndex && isHighlightMode,
            'slider-slide-highlight-not-active': index !== highlightedIndex && isHighlightMode,
            'ag-visually-hidden': isHidden && isSsr,
        }) }, extraProps), children));
};

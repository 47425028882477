import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Label } from './label';
export const Switch = forwardRef(({ value, label, onChange, onBlur, containerClassName, isDisabled, notActiveClassName = 'switch-not-active-bg-default bg-gray-200 border border-gray-700', activeClassName = 'switch-active-bg-default bg-primary-main border border-gray-700', activeDotClassName = 'switch-dot-bg-default border border-gray-700 bg-gray-50', notActiveDotClassName = 'switch-dot-bg-default border border-gray-700', name, iosMode = true, size = 'normal', error, }, ref) => {
    return (React.createElement("div", { className: "switch-container" },
        label && React.createElement(Label, null, label),
        React.createElement("button", { type: "button", className: classNames({
                'switch-button-disabled ': isDisabled,
            }, {
                'switch-button ': !iosMode && size === 'normal',
            }, {
                'switch-button-lg ': !iosMode && size === 'lg',
            }, {
                'switch-button-xl ': !iosMode && size === 'xl',
            }, {
                'switch-button-ios ': iosMode && size === 'normal',
            }, {
                'switch-button-ios-lg ': iosMode && size === 'lg',
            }, {
                'switch-button-ios-xl ': iosMode && size === 'xl',
            }, 'keyboard-only-focus', containerClassName), disabled: isDisabled, onClick: () => {
                if (value !== undefined && onChange) {
                    onChange(!value);
                }
            }, onBlur: onBlur },
            React.createElement("input", { ref: ref, type: "checkbox", className: "ag-visually-hidden", id: name, name: name, checked: value, readOnly: true }),
            React.createElement("span", { className: classNames('switch-box', {
                    [`${notActiveClassName} switch-color-transition`]: !value,
                }, {
                    [`${activeClassName} switch-color-transition`]: value,
                }) }),
            React.createElement("span", { className: classNames('switch-dot', {
                    [`switch-active-dot ${activeDotClassName}`]: value && !iosMode && size === 'normal',
                }, {
                    [`switch-not-active-dot  ${notActiveDotClassName}`]: !value && !iosMode && size === 'normal',
                }, {
                    [`switch-active-dot-lg ${activeDotClassName}`]: value && !iosMode && size === 'lg',
                }, {
                    [`switch-not-active-dot-lg  ${notActiveDotClassName}`]: !value && !iosMode && size === 'lg',
                }, {
                    [`switch-active-dot-xl ${activeDotClassName}`]: value && !iosMode && size === 'xl',
                }, {
                    [`switch-not-active-dot-xl  ${notActiveDotClassName}`]: !value && !iosMode && size === 'xl',
                }, {
                    [`switch-active-dot-ios ${activeDotClassName}`]: value && iosMode && size === 'normal',
                }, {
                    [`switch-not-active-dot-ios  ${notActiveDotClassName}`]: !value && iosMode && size === 'normal',
                }, {
                    [`switch-active-dot-ios-lg ${activeDotClassName}`]: value && iosMode && size === 'lg',
                }, {
                    [`switch-not-active-dot-ios-lg  ${notActiveDotClassName}`]: !value && iosMode && size === 'lg',
                }, {
                    [`switch-active-dot-ios-xl ${activeDotClassName}`]: value && iosMode && size === 'xl',
                }, {
                    [`switch-not-active-dot-ios-xl  ${notActiveDotClassName}`]: !value && iosMode && size === 'xl',
                }) })),
        error && React.createElement("span", { className: "switch-error" }, error)));
});

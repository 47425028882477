import { useGetTranslation, Button, Icon, ReservationSteps, } from '@reservation-app/common-used-in-web';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
import { StepWrapper } from '../../layout/step-wrapper';
import { Summary } from '../../layout/summary';
import { useStaticPropsSlice, useRemoteMasterDataSlice, useIsVerifyAvailabilityMode, useStepperStateSlice, useIsDesktop, useSelectedValuesSlice, useIsAdminPanel, } from '../../store';
import classNames from 'classnames';
export const CongratulationsStep = () => {
    const { onLeaveReservationFlowClick } = useStaticPropsSlice();
    const { companyReservationConfig: { currency }, companyOfficialData, } = useRemoteMasterDataSlice();
    const getTranslation = useGetTranslation();
    const isVerifyAvailabilityMode = useIsVerifyAvailabilityMode();
    const queryClient = useQueryClient();
    const isDesktop = useIsDesktop();
    const { resetReservationFlow, handleSwitchToReservationFlowAfterAvailabilityCheck, price: { discountAmount, totalAmount, totalAmountBeforeDiscount }, } = useStepperStateSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const isAdminPanel = useIsAdminPanel();
    return (React.createElement(StepWrapper, { step: ReservationSteps.CONGRATULATIONS, shouldAlignCenterOnMobile: true },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement(Icon, { className: "flex-shrink-0 w-12 h-12 mb-2", icon: "partyPopper" }),
            isVerifyAvailabilityMode && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "w-full max-w-sm mb-3 text-lg font-semibold text-center text-gray-900" }, getTranslation('theSelectedIntervalsAreAvailable')),
                React.createElement("p", { className: "w-full max-w-sm mb-5 text-lg text-center" }, getTranslation('youCanReserveThesePeriodsInTheFollowingWays')))),
            !isVerifyAvailabilityMode && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "w-full max-w-sm mb-3 text-lg font-semibold text-center text-gray-900" }, selectedEntityType.withAdminApprovalRequired && !isAdminPanel
                    ? getTranslation('successfulBookingAwaitingAdminApproval')
                    : getTranslation('successfulBooking')),
                selectedEntityType.withAdminApprovalRequired && !isAdminPanel && (React.createElement("p", { className: "mb-3 text-lg font-semibold text-center text-gray-900" }, getTranslation('notValidUntilApproved'))),
                isDesktop && (React.createElement("p", { className: "w-full max-w-sm mb-5 text-lg text-center text-gray-900" }, getTranslation('checkBookingDetailsDesktop'))),
                !isDesktop && (React.createElement("p", { className: "w-full max-w-sm mb-5 text-lg text-center text-gray-900" }, getTranslation('checkBookingDetailsMobile'))))),
            !isVerifyAvailabilityMode && !isDesktop && (React.createElement("div", { className: "flex flex-col items-center mb-6 768:flex-row" },
                React.createElement(Button, { className: "mb-3 768:btn-lg 768:mb-0 760:mr-3", onClick: () => {
                        resetReservationFlow(queryClient);
                    } }, getTranslation('MakeNewReservation')),
                React.createElement(Button, { color: "neutral", variant: "outline", className: "768:btn-lg", onClick: () => {
                        onLeaveReservationFlowClick();
                    } }, getTranslation('goToHomePage')))),
            isVerifyAvailabilityMode && (React.createElement("div", { className: "flex flex-col items-center mb-6 space-y-4 900:flex-row 900:space-x-2 900:space-y-0" },
                !selectedEntityType.withMembershipCard && (React.createElement(Button, { className: "w-full mb-2 768:btn-lg 768:mb-0", onClick: () => handleSwitchToReservationFlowAfterAvailabilityCheck() }, getTranslation('reserveSelectedIntervals'))),
                React.createElement("a", { className: classNames('w-full btn btn-md 768:btn-lg', {
                        'btn-primary-outline': !selectedEntityType.withMembershipCard,
                        'btn-primary-filled': selectedEntityType.withMembershipCard,
                    }), href: `tel:${companyOfficialData === null || companyOfficialData === void 0 ? void 0 : companyOfficialData.phoneNumber}` },
                    getTranslation('call'),
                    " ", companyOfficialData === null || companyOfficialData === void 0 ? void 0 :
                    companyOfficialData.phoneNumber))),
            React.createElement("div", { className: "w-full max-w-sm mb-6 768:hidden" },
                React.createElement("div", { className: "w-full p-4 bg-white shadow-md rounded-t-md", "data-test-id": "summary-container-congratulations" },
                    React.createElement(Summary, null)),
                React.createElement("div", { className: "flex justify-between py-4 mb-4 text-white shadow-md bg-primary-main rounded-b-md" },
                    React.createElement("p", { className: "ml-5 font-semibold uppercase" }, getTranslation('totalPrice')),
                    React.createElement("div", { className: "flex mr-4" },
                        discountAmount > 0 && (React.createElement("p", { className: "mr-2 text-gray-400 line-through" }, getFormattedPrice(totalAmountBeforeDiscount, currency))),
                        React.createElement("p", { className: "font-semibold" }, getFormattedPrice(totalAmount, currency)))),
                isVerifyAvailabilityMode && (React.createElement("p", { className: "text-center" },
                    getTranslation('ifYouHaventDecidedYet'),
                    ' ',
                    React.createElement("span", { onClick: () => {
                            resetReservationFlow(queryClient);
                        }, className: "inline !underline font-semibold cursor-pointer" }, getTranslation('checkOtherIntervals')),
                    ' ',
                    getTranslation('or'),
                    ' ',
                    React.createElement("span", { onClick: () => {
                            onLeaveReservationFlowClick();
                        }, className: "inline !underline font-semibold cursor-pointer" }, getTranslation('goBackToHomePage')),
                    ' ',
                    getTranslation('forMoreInfo')))))));
};

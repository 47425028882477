import React from 'react';
import { useActiveLanguage, zIndexes, Modal, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice } from '../store';
export const HtmlContentModal = () => {
    const activeLanguage = useActiveLanguage();
    const { isHtmlContentModalOpen, setIsHtmlContentModalOpen, htmlContentModalText, } = useModalStatesDataSlice();
    const isContentString = htmlContentModalText &&
        (htmlContentModalText === null || htmlContentModalText === void 0 ? void 0 : htmlContentModalText[activeLanguage]) &&
        typeof htmlContentModalText[activeLanguage] === 'string';
    return (React.createElement(Modal, { overlayClassName: zIndexes['HTML_CONTENT_MODAL'], isOpen: isHtmlContentModalOpen, onClose: () => setIsHtmlContentModalOpen(false), modalClassName: "", restoreFocus: false, withScrollLock: false },
        React.createElement("div", { className: "overflow-auto max-h-[80vh] text-gray-900", dangerouslySetInnerHTML: isContentString
                ? {
                    __html: htmlContentModalText[activeLanguage],
                }
                : undefined }, !isContentString ? htmlContentModalText : null)));
};

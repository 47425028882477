import { useMemo } from 'react';
import { ro, hu } from 'date-fns/locale';
export const useFormattedDateLocale = (activeLanguage) => {
    const locale = useMemo(() => {
        if (activeLanguage === 'ro') {
            return ro;
        }
        if (activeLanguage === 'hu') {
            return hu;
        }
        return undefined;
    }, [activeLanguage]);
    return locale;
};

import React from 'react';
import { StepWrapper } from '../../layout/step-wrapper';
import { ServiceCard } from './service-card';
import FindOutMoreSection from '../../../components/find-out-more-section';
import { useFilteredByEntityTypeServices, useStaticPropsSlice, } from '../../store';
import { ReservationSteps } from '@reservation-app/common-used-in-web';
export const SelectServiceStep = () => {
    const filteredByEntityTypeServices = useFilteredByEntityTypeServices();
    const { isAdminPanel } = useStaticPropsSlice();
    return (React.createElement("div", { className: "flex w-full" },
        React.createElement(StepWrapper, { step: ReservationSteps.SELECT_SERVICE },
            filteredByEntityTypeServices.map((service) => (React.createElement(ServiceCard, { service: service, key: service._id }))),
            !isAdminPanel && React.createElement(FindOutMoreSection, null))));
};

import { getDayFromDate } from './get-day-from-date';
import { getEntityCapacity } from './get-entity-capacity';
import { getNumberOfPersonsOptionsForIntervals } from './get-number-of-persons-options-for-intervals';
import { getUniqueReservableIntervals } from './get-unique-reservable-intervals';
import { getTimelessDate, isLastIndex, TileStatuses, getPeriodPropertyValueForDate, getPeriodPropertiesForDate, } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame } from './are-intervals-the-same';
import { areIntervalsIntersecting } from './are-intervals-intersecting';
export const getStatusForCalendarDate = ({ currentDate, entities, selectedService, selectedIntervals, selectedNumberOfPersons, }) => {
    var _a;
    let status;
    let firstHalfStatus;
    let secondHalfStatus;
    const syncGroupEntity = entities[0];
    const showAsHalves = (syncGroupEntity === null || syncGroupEntity === void 0 ? void 0 : syncGroupEntity.showAsHalves)
        ? getPeriodPropertyValueForDate({
            date: currentDate,
            periodSensitiveProperty: syncGroupEntity.showAsHalves,
        })
        : false;
    const currentDay = getDayFromDate(currentDate);
    let numberOfClosedEntities = 0;
    for (let i = 0; i < entities.length; i++) {
        const currentEntity = entities[i];
        const relevantReservableIntervals = getPeriodPropertiesForDate({
            date: currentDate,
            periodSensitiveProperty: selectedService.reservableIntervals,
        }).filter((reservableInterval) => reservableInterval.value.days
            .map(({ value: day }) => day)
            .includes(currentDay));
        const uniqueRelevantReservableIntervals = getUniqueReservableIntervals({
            reservableIntervals: relevantReservableIntervals,
        });
        const entitySchedule = getPeriodPropertyValueForDate({
            date: currentDate,
            periodSensitiveProperty: currentEntity.schedule,
        });
        const entityDaySchedule = entitySchedule[currentDay];
        if (entityDaySchedule.isClosed === true) {
            numberOfClosedEntities += 1;
        }
        else {
            reservableIntervalsLoop: for (let j = 0; j < uniqueRelevantReservableIntervals.length; j++) {
                const currentReservableInterval = uniqueRelevantReservableIntervals[j].value;
                if ((selectedIntervals === null || selectedIntervals === void 0 ? void 0 : selectedIntervals.length) > 0 &&
                    selectedIntervals.some((selectedInterval) => areIntervalsTheSame(selectedInterval, {
                        date: currentDate,
                        startTime: currentReservableInterval.timeInterval.startTime,
                        endTime: currentReservableInterval.timeInterval.endTime,
                    }))) {
                    if (showAsHalves) {
                        if (currentReservableInterval.timeInterval.isFirstHalf) {
                            firstHalfStatus = TileStatuses.SELECTED;
                        }
                        if (currentReservableInterval.timeInterval.isSecondHalf) {
                            secondHalfStatus = TileStatuses.SELECTED;
                        }
                    }
                    else {
                        status = TileStatuses.SELECTED;
                    }
                }
                if (((_a = currentEntity.reservedIntervals) === null || _a === void 0 ? void 0 : _a.length) || showAsHalves) {
                    let areAnyIntervalsConflicting;
                    if (entities[i].withBulkNumberOfPersonsSelect) {
                        const conflictingReservedIntervals = currentEntity.reservedIntervals.filter((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval), Object.assign({ date: currentDate }, currentReservableInterval.timeInterval)));
                        let relevantSmallestRequiredNumberOfPersonsOption;
                        if (selectedNumberOfPersons) {
                            relevantSmallestRequiredNumberOfPersonsOption =
                                selectedNumberOfPersons;
                        }
                        else {
                            const relevantNumberOfPersonsSelectOptions = getNumberOfPersonsOptionsForIntervals({
                                selectedDate: getTimelessDate(currentDate),
                                numberOfPersonsSelectOptions: syncGroupEntity.numberOfPersonsSelectOptions,
                            });
                            relevantSmallestRequiredNumberOfPersonsOption =
                                relevantNumberOfPersonsSelectOptions[0];
                        }
                        const conflictingNumberOfPersons = conflictingReservedIntervals.reduce((result, reservedInterval) => {
                            return result + reservedInterval.numberOfPersons;
                        }, 0);
                        if (conflictingNumberOfPersons +
                            relevantSmallestRequiredNumberOfPersonsOption >
                            getEntityCapacity({ entity: syncGroupEntity, selectedIntervals })) {
                            areAnyIntervalsConflicting = true;
                        }
                        else {
                            areAnyIntervalsConflicting = false;
                        }
                    }
                    else {
                        areAnyIntervalsConflicting = currentEntity.reservedIntervals.some((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval), Object.assign({ date: currentDate }, currentReservableInterval.timeInterval)));
                    }
                    if (showAsHalves) {
                        if (currentReservableInterval.timeInterval.showAsClosed) {
                            if (currentReservableInterval.timeInterval.isFirstHalf) {
                                firstHalfStatus = TileStatuses.CLOSED;
                            }
                            if (currentReservableInterval.timeInterval.isSecondHalf) {
                                secondHalfStatus = TileStatuses.CLOSED;
                            }
                        }
                        else {
                            if (!areAnyIntervalsConflicting) {
                                if (currentReservableInterval.timeInterval.isFirstHalf &&
                                    firstHalfStatus !== TileStatuses.SELECTED) {
                                    firstHalfStatus = TileStatuses.AVAILABLE;
                                }
                                if (currentReservableInterval.timeInterval.isSecondHalf &&
                                    secondHalfStatus !== TileStatuses.SELECTED) {
                                    secondHalfStatus = TileStatuses.AVAILABLE;
                                }
                                if ((firstHalfStatus === TileStatuses.AVAILABLE ||
                                    firstHalfStatus === TileStatuses.SELECTED) &&
                                    (secondHalfStatus === TileStatuses.AVAILABLE ||
                                        secondHalfStatus === TileStatuses.SELECTED)) {
                                    break reservableIntervalsLoop;
                                }
                            }
                            else if (isLastIndex(i, entities) &&
                                isLastIndex(j, uniqueRelevantReservableIntervals)) {
                                if (currentReservableInterval.timeInterval.isFirstHalf &&
                                    firstHalfStatus !== TileStatuses.AVAILABLE &&
                                    firstHalfStatus !== TileStatuses.SELECTED &&
                                    firstHalfStatus !== TileStatuses.CLOSED) {
                                    firstHalfStatus = TileStatuses.RESERVED;
                                }
                                if (currentReservableInterval.timeInterval.isSecondHalf &&
                                    secondHalfStatus !== TileStatuses.AVAILABLE &&
                                    secondHalfStatus !== TileStatuses.SELECTED &&
                                    secondHalfStatus !== TileStatuses.CLOSED) {
                                    secondHalfStatus = TileStatuses.RESERVED;
                                }
                            }
                        }
                    }
                    else {
                        if (status !== TileStatuses.SELECTED) {
                            if (!areAnyIntervalsConflicting) {
                                status = TileStatuses.AVAILABLE;
                                return status;
                            }
                            else if (isLastIndex(i, entities) &&
                                isLastIndex(j, uniqueRelevantReservableIntervals)) {
                                status = TileStatuses.RESERVED;
                            }
                        }
                    }
                }
                else if (status !== TileStatuses.SELECTED) {
                    status = TileStatuses.AVAILABLE;
                    return status;
                }
                if (status === TileStatuses.AVAILABLE) {
                    return status;
                }
            }
        }
        if (showAsHalves) {
            if (!firstHalfStatus) {
                firstHalfStatus = TileStatuses.RESERVED;
            }
            if (!secondHalfStatus) {
                secondHalfStatus = TileStatuses.RESERVED;
            }
            if (firstHalfStatus === secondHalfStatus) {
                status = firstHalfStatus;
            }
            if (firstHalfStatus === TileStatuses.AVAILABLE &&
                secondHalfStatus === TileStatuses.CLOSED) {
                status = TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_CLOSED;
            }
            if (firstHalfStatus === TileStatuses.AVAILABLE &&
                secondHalfStatus === TileStatuses.RESERVED) {
                status = TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_RESERVED;
            }
            if (firstHalfStatus === TileStatuses.CLOSED &&
                secondHalfStatus === TileStatuses.AVAILABLE) {
                status = TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_AVAILABLE;
            }
            if (firstHalfStatus === TileStatuses.CLOSED &&
                secondHalfStatus === TileStatuses.RESERVED) {
                status = TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_RESERVED;
            }
            if (firstHalfStatus === TileStatuses.RESERVED &&
                secondHalfStatus === TileStatuses.AVAILABLE) {
                status = TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_AVAILABLE;
            }
            if (firstHalfStatus === TileStatuses.RESERVED &&
                secondHalfStatus === TileStatuses.CLOSED) {
                status = TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_CLOSED;
            }
            if (firstHalfStatus === TileStatuses.SELECTED &&
                secondHalfStatus === TileStatuses.CLOSED) {
                status = TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_CLOSED;
            }
            if (firstHalfStatus === TileStatuses.SELECTED &&
                secondHalfStatus === TileStatuses.AVAILABLE) {
                status = TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_AVAILABLE;
            }
            if (firstHalfStatus === TileStatuses.SELECTED &&
                secondHalfStatus === TileStatuses.RESERVED) {
                status = TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_RESERVED;
            }
            if (firstHalfStatus === TileStatuses.CLOSED &&
                secondHalfStatus === TileStatuses.SELECTED) {
                status = TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_SELECTED;
            }
            if (firstHalfStatus === TileStatuses.AVAILABLE &&
                secondHalfStatus === TileStatuses.SELECTED) {
                status = TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_SELECTED;
            }
            if (firstHalfStatus === TileStatuses.RESERVED &&
                secondHalfStatus === TileStatuses.SELECTED) {
                status = TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_SELECTED;
            }
        }
        else if (!status) {
            status = TileStatuses.RESERVED;
        }
    }
    if (numberOfClosedEntities === entities.length) {
        status = TileStatuses.CLOSED;
    }
    return status;
};

import React, { useMemo, useRef } from 'react';
import { format, isSameDay } from 'date-fns';
import classNames from 'classnames';
import { usePresence, AnimatePresence, motion, LayoutGroup, } from 'framer-motion';
import { useFormattedDateLocale } from '../hooks/use-formatted-date-locale';
import { useCompanyReservationConfig, useFilteredPromotions, useIsAdminPanel, useIsDesktop, useModalStatesDataSlice, useSelectedValuesSlice, useStepperStateSlice, } from '../store';
import { Icon, ReservationSteps, PromotionTypes, useTranslationsStore, useActiveLanguage, useGetTranslation, getTimelessDate, getPeriodPropertyValueForDate, getDisplayTime, } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame } from '../helpers/are-intervals-the-same';
import { getExtraPrice } from '../helpers/get-extra-price';
import { getFormattedPrice } from '../helpers/get-formatted-price';
import { getReservableIntervalPrice } from '../helpers/get-reservable-interval-price';
import { getRestrictionValidationErrors } from '../helpers/get-restriction-validation-errors';
const MergedIntervalsWrapper = ({ maxLength, children, shouldShowDeleteButtonAtThisStep, mergedByDateSelectedIntervals, onRemoveIntervalClick, index, shouldMergeDaysInSummary, isAnyPromotionApplied, }) => {
    const [isPresent, safeToRemove] = usePresence();
    const activeLanguage = useActiveLanguage();
    const { selectedIntervals } = useSelectedValuesSlice();
    const { currentStep } = useStepperStateSlice();
    const locale = useFormattedDateLocale(activeLanguage);
    const wrapperTransition = {
        type: 'spring',
        stiffness: 500,
        damping: 60,
        mass: 1,
    };
    const wrapperAnimations = {
        initial: 'out',
        animate: isPresent ? 'in' : 'out',
        variants: {
            in: {
                x: 0,
                opacity: 1,
                transition: Object.assign(Object.assign({}, wrapperTransition), { delay: 0.3 }),
            },
            out: {
                x: -100,
                opacity: 0,
                transition: Object.assign(Object.assign({}, wrapperTransition), { delay: 0.1 }),
            },
        },
        onAnimationComplete: () => {
            if (!isPresent) {
                safeToRemove();
            }
        },
    };
    return (React.createElement(motion.div, Object.assign({ layout: true, transition: { type: 'tween' } }, wrapperAnimations, { className: classNames('bg-primary-light rounded-lg shadow p-2 relative', {
            'mb-2': index !== maxLength - 1 || isAnyPromotionApplied,
        }) }),
        React.createElement(motion.div, { layout: true, className: "flex justify-between w-full font-semibold h-[32px]" },
            React.createElement(motion.div, { layout: "position", className: "flex items-center capitalize whitespace-nowrap" },
                React.createElement(Icon, { icon: "calendar", className: "flex-shrink-0 mr-2 text-xl" }),
                format(getTimelessDate(mergedByDateSelectedIntervals.date), 'MMMM dd (EEEE)', {
                    locale,
                })),
            shouldShowDeleteButtonAtThisStep &&
                !shouldMergeDaysInSummary &&
                (currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY ||
                    selectedIntervals.length > 1) && (React.createElement(Icon, { testId: `selected-interval-remove-button-${index}`, icon: "close", className: `remove-interval-icon-${index} w-8 h-8 text-gray-900 -mr-2 cursor-pointer`, onClick: () => onRemoveIntervalClick(mergedByDateSelectedIntervals.intervals[0]) }))),
        children));
};
const SelectedInterval = ({ selectedInterval, shouldShowDeleteButtonAtThisStep, mergedByDateSelectedIntervalsLength, currentMergedByDateSelectedIntervalsLength, index, onRemoveIntervalClick, isDesktop, }) => {
    var _a, _b, _c;
    const activeLanguage = useActiveLanguage();
    const { currency } = useCompanyReservationConfig();
    const { selectedEntity, selectedIntervals, selectedEntityType } = useSelectedValuesSlice();
    const { defaultDate, currentStep, price: { discountAmount }, } = useStepperStateSlice();
    let intervalPrice = getReservableIntervalPrice({
        selectedInterval: {
            numberOfPersons: selectedInterval.numberOfPersons,
            price: getPeriodPropertyValueForDate({
                periodSensitiveProperty: selectedInterval.reservableInterval.price.currentDatePeriods,
                date: defaultDate,
            }),
        },
    });
    let shouldShowTotal = false;
    if (mergedByDateSelectedIntervalsLength > 1) {
        shouldShowTotal = true;
        if (currentMergedByDateSelectedIntervalsLength > 1 &&
            index === currentMergedByDateSelectedIntervalsLength - 1) {
            shouldShowTotal = true;
        }
        else if (currentMergedByDateSelectedIntervalsLength === 1 &&
            ((_a = selectedInterval.selectedExtras) === null || _a === void 0 ? void 0 : _a.length)) {
            shouldShowTotal = true;
        }
        else {
            shouldShowTotal = false;
        }
    }
    (_b = selectedInterval.selectedExtras) === null || _b === void 0 ? void 0 : _b.forEach((selectedExtra) => {
        const extraPrice = getExtraPrice({
            selectedExtra,
            selectedInterval: {
                date: selectedInterval.date,
                numberOfPersons: selectedInterval.numberOfPersons,
                extras: selectedInterval.reservableInterval.extras,
            },
        });
        intervalPrice += extraPrice;
    });
    const displayTime = getDisplayTime({
        interval: selectedInterval.reservableInterval.timeInterval,
        activeLanguage,
    });
    const keyPrefix = `${format(new Date(selectedInterval.date), 'dd-MM-yyyy')}/${displayTime}`;
    const priceForInterval = getFormattedPrice(getReservableIntervalPrice({
        selectedInterval: {
            numberOfPersons: selectedInterval.numberOfPersons,
            price: getPeriodPropertyValueForDate({
                periodSensitiveProperty: selectedInterval.reservableInterval.price.currentDatePeriods,
                date: defaultDate,
            }),
        },
    }), currency);
    return (React.createElement(motion.div, { layout: true, className: classNames({
            'mb-4': currentMergedByDateSelectedIntervalsLength > 1,
        }) },
        React.createElement(motion.div, { layout: "position", className: "flex justify-between" },
            React.createElement("div", { className: "flex items-center h-[32px] text-gray-900" },
                React.createElement(Icon, { icon: "clock", className: "flex-shrink-0 mr-2 text-xl text-gray-900" }),
                React.createElement("p", { className: "font-semibold text-gray-900" }, displayTime)),
            selectedEntity &&
                (selectedIntervals.length > 1 ||
                    selectedInterval.selectedExtras.length > 0 ||
                    !!discountAmount) && (React.createElement(motion.div, { key: priceForInterval, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween' }, className: "flex items-center h-[32px] text-gray-900" }, priceForInterval)),
            shouldShowDeleteButtonAtThisStep &&
                (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldMergeDaysInSummary) &&
                (currentStep == ReservationSteps.SELECT_PERIOD_AND_ENTITY ||
                    selectedIntervals.length > 1) && (React.createElement(Icon, { icon: "close", className: "w-8 h-8 -mr-2 text-gray-900 cursor-pointer remove-interval-icon-should-merge-days-in-summary", onClick: () => onRemoveIntervalClick(selectedInterval) }))),
        React.createElement(AnimatePresence, { initial: isDesktop }, ((_c = selectedInterval.selectedExtras) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(motion.div, { layout: true, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween' }, key: `${keyPrefix}-extras-container`, className: "flex" },
            React.createElement(motion.div, { layout: "position", key: `${keyPrefix}--icon` },
                React.createElement("div", { className: "flex items-center h-[32px] mr-2" },
                    React.createElement(Icon, { icon: "circledPlus", className: "flex-shrink-0 text-xl text-gray-900" }))),
            React.createElement("div", { className: "flex flex-col w-full" },
                React.createElement(AnimatePresence, { initial: isDesktop }, selectedInterval.selectedExtras.map((selectedExtra) => {
                    const extraPrice = getExtraPrice({
                        selectedExtra,
                        selectedInterval: {
                            date: selectedInterval.date,
                            numberOfPersons: selectedInterval.numberOfPersons,
                            extras: selectedInterval.reservableInterval.extras,
                        },
                    });
                    const selectedExtraData = selectedInterval.reservableInterval.extras.find((e) => e._id === selectedExtra.extraId);
                    return (React.createElement(motion.div, { layout: "position", key: `${keyPrefix}-${selectedExtra.extraId}--extra`, className: "flex justify-between w-full h-[32px] items-center" },
                        React.createElement(motion.div, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, className: "flex text-gray-900" },
                            getPeriodPropertyValueForDate({
                                periodSensitiveProperty: selectedExtraData.uiProps.name,
                                date: defaultDate,
                            })[activeLanguage],
                            ' ',
                            React.createElement(motion.p, { key: `${keyPrefix}-${selectedExtra.extraId}-${selectedExtra.amount}-amount`, initial: {
                                    scale: 0.75,
                                    opacity: 0,
                                }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, className: "ml-2 font-semibold text-gray-900" },
                                "x",
                                selectedExtra.amount)),
                        React.createElement(motion.p, { key: `${keyPrefix}-${selectedExtra.extraId}-${extraPrice}-price`, initial: {
                                scale: 0.75,
                                opacity: 0,
                            }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, className: "ml-2 text-gray-900 whitespace-nowrap" }, getFormattedPrice(extraPrice, currency))));
                })))))),
        shouldShowTotal && (React.createElement("div", { className: "flex justify-end border-t border-gray-900" },
            React.createElement(motion.p, { key: `${keyPrefix}-${intervalPrice}-total-interval-price`, initial: {
                    scale: 0.75,
                    opacity: 0,
                }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, className: "text-xl font-bold text-primary-main" }, getFormattedPrice(intervalPrice, currency))))));
};
const SelectedIntervalsList = ({ mergedByDateSelectedIntervals, onRemoveIntervalClick, }) => {
    const { currentStep, price: { appliedPromotionId }, defaultDate, } = useStepperStateSlice();
    const filteredPromotions = useFilteredPromotions();
    const { selectedEntityType } = useSelectedValuesSlice();
    const activeLanguage = useActiveLanguage();
    const appliedPromotion = useMemo(() => {
        if (appliedPromotionId) {
            return filteredPromotions.find((promotion) => promotion._id === appliedPromotionId);
        }
        return undefined;
    }, [appliedPromotionId, filteredPromotions]);
    const appliedPromotionName = appliedPromotion
        ? getPeriodPropertyValueForDate({
            date: defaultDate,
            periodSensitiveProperty: appliedPromotion.uiProps.name,
        })[activeLanguage]
        : undefined;
    const isDesktop = useIsDesktop();
    const getTranslation = useGetTranslation();
    const { currency } = useCompanyReservationConfig();
    const shouldShowDeleteButtonAtThisStep = currentStep !== ReservationSteps.CONGRATULATIONS &&
        currentStep !== ReservationSteps.PAYMENT_DETAILS;
    const parentRef = useRef();
    const sortedMergedByDateSelectedIntervals = useMemo(() => {
        var _a;
        return (_a = [...mergedByDateSelectedIntervals]) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
            if (!(selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldMergeDaysInSummary)) {
                return (new Date(a.date).getTime() +
                    a.intervals[0].reservableInterval.timeInterval.startTime -
                    (new Date(b.date).getTime() +
                        b.intervals[0].reservableInterval.timeInterval.startTime));
            }
            else {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
        });
    }, [
        selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldMergeDaysInSummary,
        mergedByDateSelectedIntervals,
    ]);
    return (React.createElement(motion.div, { layout: true, ref: parentRef, className: "flex flex-col overflow-y-auto grow 768:max-h-72 768:grow-0" },
        React.createElement(LayoutGroup, null,
            sortedMergedByDateSelectedIntervals.map((msi, index) => {
                return (React.createElement(MergedIntervalsWrapper, { maxLength: mergedByDateSelectedIntervals.length, index: index, key: `${msi.date}-${msi.intervals[0].reservableInterval.timeInterval.startTime}`, shouldShowDeleteButtonAtThisStep: shouldShowDeleteButtonAtThisStep, mergedByDateSelectedIntervals: msi, onRemoveIntervalClick: onRemoveIntervalClick, shouldMergeDaysInSummary: selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldMergeDaysInSummary, isDesktop: isDesktop, isAnyPromotionApplied: Boolean(appliedPromotion) }, msi.intervals
                    .sort((a, b) => {
                    if (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldMergeDaysInSummary) {
                        return (a.reservableInterval.timeInterval.startTime -
                            b.reservableInterval.timeInterval.startTime);
                    }
                    else {
                        return undefined;
                    }
                })
                    .map((selectedInterval, index) => (React.createElement(SelectedInterval, { key: `${selectedInterval.date}--${selectedInterval.reservableInterval.timeInterval.endTime}`, mergedByDateSelectedIntervalsLength: mergedByDateSelectedIntervals.length, index: index, currentMergedByDateSelectedIntervalsLength: msi.intervals.length, selectedInterval: selectedInterval, shouldShowDeleteButtonAtThisStep: shouldShowDeleteButtonAtThisStep, onRemoveIntervalClick: onRemoveIntervalClick, isDesktop: isDesktop })))));
            }),
            appliedPromotion && (React.createElement(motion.div, { layout: true, className: "px-2 py-4 rounded-md shadow bg-success-50" },
                React.createElement("p", { className: "mb-2 font-semibold text-success-main text" },
                    getTranslation('activePromotion'),
                    ":"),
                React.createElement("div", { className: "flex justify-between " }, appliedPromotionName ? (React.createElement("p", { className: "font-semibold text" }, appliedPromotionName)) : (React.createElement("p", { className: "font-semibold uppercase text" },
                    appliedPromotion.type === PromotionTypes.FIXED
                        ? ` ${getFormattedPrice(appliedPromotion.value, currency)}`
                        : `${appliedPromotion.value}%`,
                    ' ',
                    getTranslation('discount')))))))));
};
export const Summary = ({ className }) => {
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { defaultDate, currentStep } = useStepperStateSlice();
    const isAdminPanel = useIsAdminPanel();
    const { selectedService, selectedEntity, selectedIntervals, removeSelectedIntervals, selectedBulkNumberOfPersons, selectedEntityType, } = useSelectedValuesSlice();
    const { shouldMergeDaysInSummary, shouldShowEntityInSummary, shouldShowServiceInSummary, shouldShowEntityImageInSummary, } = selectedEntityType || {};
    const calculateIntervalPrices = (riArray) => {
        let totalPrice = 0;
        riArray.forEach((selectedInterval) => {
            totalPrice += getReservableIntervalPrice({
                selectedInterval: {
                    numberOfPersons: selectedInterval.numberOfPersons,
                    price: getPeriodPropertyValueForDate({
                        periodSensitiveProperty: selectedInterval.reservableInterval.price.currentDatePeriods,
                        date: defaultDate,
                    }),
                },
            });
            if (selectedInterval.selectedExtras &&
                selectedInterval.selectedExtras.length > 0) {
                selectedInterval.selectedExtras.forEach((selectedExtra) => {
                    const extraPrice = getExtraPrice({
                        selectedExtra,
                        selectedInterval: {
                            date: selectedInterval.date,
                            numberOfPersons: selectedInterval.numberOfPersons,
                            extras: selectedInterval.reservableInterval.extras,
                        },
                    });
                    totalPrice += extraPrice;
                });
            }
        });
        return totalPrice;
    };
    const mergedByDateSelectedIntervals = useMemo(() => selectedIntervals.reduce((result, value) => {
        if (!shouldMergeDaysInSummary) {
            return result.concat({
                date: value.date,
                intervals: [value],
            });
        }
        const sameDayResult = result.findIndex((resultValue) => isSameDay(new Date(resultValue.date), new Date(value.date)));
        if (sameDayResult !== -1) {
            const resultCopy = JSON.parse(JSON.stringify(result));
            resultCopy[sameDayResult].intervals.push(value);
            const totalIntervalsPrice = calculateIntervalPrices(resultCopy[sameDayResult].intervals);
            resultCopy[sameDayResult].totalPrice = totalIntervalsPrice;
            return resultCopy;
        }
        else {
            return result.concat({
                date: value.date,
                intervals: [value],
            });
        }
    }, []), [selectedIntervals, shouldMergeDaysInSummary]);
    const isDesktop = useIsDesktop();
    const { setIsRestrictionErrorModalOpen, setRestrictionErrorModalMessage, setRestrictionErrorModalSelectedInterval, } = useModalStatesDataSlice();
    const onRemoveIntervalClick = (reservableIntervalToRemove) => {
        const filteredSelectedIntervals = selectedIntervals.filter((selectedInterval) => {
            if (areIntervalsTheSame(selectedInterval, reservableIntervalToRemove)) {
                return false;
            }
            return true;
        });
        const restrictionValidationError = getRestrictionValidationErrors({
            selectedIntervals: filteredSelectedIntervals,
            selectedEntity,
            selectedService,
            isAdmin: isAdminPanel,
        });
        if (restrictionValidationError.length > 0 &&
            currentStep !== ReservationSteps.SELECT_PERIOD_AND_ENTITY &&
            currentStep !== ReservationSteps.SELECT_PERIOD_AND_SERVICE) {
            setIsRestrictionErrorModalOpen(true);
            setRestrictionErrorModalMessage(restrictionValidationError
                .map((error) => error[activeLanguage])
                .join(' \n'));
            setRestrictionErrorModalSelectedInterval(reservableIntervalToRemove);
        }
        else {
            removeSelectedIntervals([reservableIntervalToRemove]);
        }
    };
    const firstImage = selectedEntity && selectedEntity.uiProps.images[0];
    const selectedEntityName = selectedEntity &&
        getPeriodPropertyValueForDate({
            periodSensitiveProperty: selectedEntity.uiProps.name,
            date: defaultDate,
            selectedServiceId: selectedService._id,
        })[activeLanguage];
    const selectedServiceName = selectedService &&
        currentStep !== ReservationSteps.SELECT_ENTITY_TYPE &&
        getPeriodPropertyValueForDate({
            periodSensitiveProperty: selectedService.uiProps.name,
            date: defaultDate,
        })[activeLanguage];
    return (React.createElement("div", { className: classNames('flex flex-col w-full 768:pb-0', {
            'pb-[100px]': currentStep !== ReservationSteps.CONGRATULATIONS,
        }, className) },
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { layout: true, className: "flex items-center pb-2 mb-3 border-b-2" },
                React.createElement("p", { className: "text-lg font-semibold text-gray-600" }, getTranslation('summary'))),
            ((selectedEntity && shouldShowEntityInSummary) ||
                (selectedServiceName && shouldShowServiceInSummary)) && (React.createElement(motion.div, { layout: true, initial: isDesktop ? { opacity: 0 } : false, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween', delay: 0.3 }, className: classNames('flex mb-2') },
                selectedEntity &&
                    (firstImage === null || firstImage === void 0 ? void 0 : firstImage.url) &&
                    shouldShowEntityInSummary &&
                    shouldShowEntityImageInSummary && (React.createElement(motion.img, { initial: isDesktop ? { scale: 0.75, opacity: 0 } : false, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, transition: { type: 'tween' }, src: firstImage.url, key: firstImage.url, alt: "", className: "w-20 h-20 mr-3 rounded-md" })),
                React.createElement("div", { className: "flex flex-col w-full" },
                    selectedEntity && shouldShowEntityInSummary && (React.createElement(motion.p, { key: selectedEntityName, layout: "position", className: "text-xl font-semibold text-gray-800", initial: isDesktop ? { opacity: 0 } : false, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween' } }, selectedEntityName)),
                    selectedServiceName && shouldShowServiceInSummary && (React.createElement(motion.p, { key: selectedServiceName, className: "font-semibold text-gray-800", layout: "position", initial: isDesktop ? { opacity: 0 } : false, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween' } }, selectedServiceName)),
                    selectedEntity &&
                        selectedEntity.withBulkNumberOfPersonsSelect &&
                        selectedBulkNumberOfPersons && (React.createElement(motion.p, { key: selectedBulkNumberOfPersons, className: "font-semibold text-gray-800", initial: isDesktop ? { opacity: 0 } : false, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween' } }, `${selectedBulkNumberOfPersons} ${selectedBulkNumberOfPersons > 1
                        ? selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.uiProps.translations.summaryPersons[activeLanguage]
                        : selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.uiProps.translations.summaryPerson[activeLanguage]}`))))),
            selectedIntervals.length > 0 ? (React.createElement(SelectedIntervalsList, { mergedByDateSelectedIntervals: mergedByDateSelectedIntervals, onRemoveIntervalClick: onRemoveIntervalClick })) : (React.createElement(motion.p, { className: classNames('text-gray-500 font-semibold'), layout: "position" }, getTranslation('noSelectedIntervals'))))));
};

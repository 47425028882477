import React from 'react';
import { Icon, ShortcutStatuses, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { addDays, format, isBefore, startOfWeek } from 'date-fns';
import { areIntervalsTheSame } from '../../../reservation-flow/helpers/are-intervals-the-same';
import { getReservableIntervalByIdFromService } from '../../../reservation-flow/helpers/get-reservable-interval-by-id-from-service';
import { getSelectedIntervalNumberOfPersons } from '../../../reservation-flow/helpers/get-selected-interval-number-of-persons';
import { motion } from 'framer-motion';
import { usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, } from '../../../reservation-flow/store';
import { dayToJsDayIndexMap } from '../../../reservation-flow/helpers/day-to-js-day-index';
export const ReservableShortcut = ({ reservableShortcut, relevantEntity, isLastReservableShortcut, afterIntervalVariants, beforeIntervalVariants, index, }) => {
    var _a;
    const { selectedBulkNumberOfPersons, setSelectedBulkNumberOfPersons } = useSelectedValuesSlice();
    const { selectedDate, previousSelectedDate } = usePeriodAndEntityStepSpecificSlice();
    const { selectedIntervals, setSelectedIntervals, selectedService, } = useSelectedValuesSlice();
    const getTranslation = useGetTranslation();
    const activeLanguage = useActiveLanguage();
    const currentListSelectedIntervals = selectedIntervals;
    const setCurrentListSelectedIntervals = setSelectedIntervals;
    const relevantWeek = startOfWeek(selectedDate);
    const isShortcutSelected = reservableShortcut.reservableIntervalsIds.every((reservableIntervalId) => {
        const relevantIntervalFromService = getReservableIntervalByIdFromService({
            service: selectedService,
            reservableIntervalId,
        });
        return reservableShortcut.days.every((day) => currentListSelectedIntervals.some((selectedInterval) => areIntervalsTheSame(selectedInterval, Object.assign({ date: format(addDays(relevantWeek, day.isNextWeek
                ? dayToJsDayIndexMap[day.value] + 7
                : dayToJsDayIndexMap[day.value]), 'yyyy-MM-dd') }, relevantIntervalFromService.timeInterval))));
    });
    return (React.createElement(motion.div, { key: `${relevantWeek.toString()}-${reservableShortcut._id}`, variants: selectedDate &&
            previousSelectedDate &&
            isBefore(selectedDate, previousSelectedDate)
            ? afterIntervalVariants
            : beforeIntervalVariants, initial: "initial", animate: "animate", exit: "exit", transition: { duration: 0.1, ease: 'easeIn' }, "data-test-id": `reservation-modal-shortcut-button-${index}`, onClick: () => {
            if (isShortcutSelected) {
                const intervalsToFilterOut = [];
                reservableShortcut.reservableIntervalsIds.forEach((shortcutIntervalId) => {
                    const relevantIntervalFromService = getReservableIntervalByIdFromService({
                        service: selectedService,
                        reservableIntervalId: shortcutIntervalId,
                    });
                    reservableShortcut.days.forEach((day) => {
                        intervalsToFilterOut.push(Object.assign({ date: format(addDays(relevantWeek, day.isNextWeek
                                ? dayToJsDayIndexMap[day.value] + 7
                                : dayToJsDayIndexMap[day.value]), 'yyyy-MM-dd') }, relevantIntervalFromService.timeInterval));
                    });
                });
                const filteredSelectedIntervals = currentListSelectedIntervals.filter((selectedInterval) => {
                    const res = intervalsToFilterOut.some((shortcutInterval) => {
                        return areIntervalsTheSame(selectedInterval, shortcutInterval);
                    });
                    return !res;
                });
                setCurrentListSelectedIntervals(filteredSelectedIntervals);
            }
            else {
                const numberOfPersons = getSelectedIntervalNumberOfPersons({
                    syncGroupEntity: relevantEntity,
                    selectedBulkNumberOfPersons,
                    setSelectedBulkNumberOfPersons,
                    selectedService,
                    selectedDate,
                });
                const relevantWeek = startOfWeek(selectedDate);
                const relevantShortcutIntervalsFromService = [];
                reservableShortcut.days.forEach((day) => {
                    reservableShortcut.reservableIntervalsIds.forEach((reservableIntervalId) => {
                        relevantShortcutIntervalsFromService.push({
                            date: format(addDays(relevantWeek, day.isNextWeek
                                ? dayToJsDayIndexMap[day.value] + 7
                                : dayToJsDayIndexMap[day.value]), 'yyyy-MM-dd'),
                            selectedExtras: [],
                            reservableIntervalId,
                            numberOfPersons,
                            reservableInterval: getReservableIntervalByIdFromService({
                                service: selectedService,
                                reservableIntervalId,
                            }),
                        });
                    });
                });
                const newIntervals = relevantShortcutIntervalsFromService.filter((shortcutInterval) => {
                    const foundInterval = currentListSelectedIntervals.some((selectedInterval) => areIntervalsTheSame(selectedInterval, shortcutInterval));
                    return !foundInterval;
                });
                setCurrentListSelectedIntervals([
                    ...currentListSelectedIntervals,
                    ...newIntervals,
                ]);
            }
        }, className: classNames(`reservable-shortuct-${reservableShortcut._id} flex flex-col flex-grow justify-between items-center bg-gray-50 rounded-md px-4 py-1.5 cursor-pointer shadow-md transition-all duration-200 select-none text-sm`, {
            'text-gray-900 bg-gray-200 pointer-events-none': reservableShortcut.status === ShortcutStatuses.PARTIALLY_CLOSED ||
                reservableShortcut.status === ShortcutStatuses.CLOSED,
            'text-gray-900 768:hover:bg-primary-100 hover:text-gray-900 768:bg-gray-50': !isShortcutSelected &&
                reservableShortcut.status === ShortcutStatuses.AVAILABLE,
            'bg-primary-light': isShortcutSelected,
            'text-gray-900 bg-error-200 pointer-events-none': reservableShortcut.status === ShortcutStatuses.RESERVED ||
                reservableShortcut.status === ShortcutStatuses.PARTIALLY_RESERVED,
            'mb-4': !isLastReservableShortcut,
        }) },
        ((_a = reservableShortcut.shortcut.label) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("p", { className: "text-gray-900 font-semibold w-full" }, reservableShortcut.shortcut.label[activeLanguage])),
        React.createElement("div", { className: "flex flex-grow justify-between items-center w-full" },
            React.createElement("div", { className: "flex text-gray-900 items-center" },
                React.createElement(Icon, { icon: reservableShortcut.shortcut.icon || 'clock', className: "flex-shrink-0 text-base mr-2" }),
                React.createElement("p", { className: "text-gray-900" }, reservableShortcut.shortcut.displayStartEndTime[activeLanguage])),
            (reservableShortcut.status === ShortcutStatuses.RESERVED ||
                reservableShortcut.status ===
                    ShortcutStatuses.PARTIALLY_RESERVED) && (React.createElement("p", { className: `${reservableShortcut._id} font-semibold ml-2 text-error-main text-right` }, reservableShortcut.availableNumberOfPersons
                ? `${reservableShortcut.availableNumberOfPersons} ${reservableShortcut.availableNumberOfPersons > 1
                    ? getTranslation('availablePlaces')
                    : getTranslation('availablePlace')}`
                : getTranslation(reservableShortcut.status))),
            (reservableShortcut.status === ShortcutStatuses.PARTIALLY_CLOSED ||
                reservableShortcut.status === ShortcutStatuses.CLOSED) && (React.createElement("p", { className: `${reservableShortcut._id} font-semibold ml-2 text-gray-500 text-right` }, getTranslation(reservableShortcut.status))),
            reservableShortcut.status === ShortcutStatuses.AVAILABLE && (React.createElement("p", { className: `${reservableShortcut._id} font-semibold ml-2 text-gray-700` }, getTranslation('available'))))));
};

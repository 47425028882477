import { getTimelessDate } from '@reservation-app/common-used-in-web';
import { isSameDay } from 'date-fns';
export const areTimeIntervalsTheSame = (interval1, interval2) => {
    const interval1StartTime = interval1
        .reservableInterval
        ? interval1.reservableInterval
            .timeInterval.startTime
        : interval1.startTime;
    const interval1EndTime = interval1
        .reservableInterval
        ? interval1.reservableInterval
            .timeInterval.endTime
        : interval1.endTime;
    const interval2StartTime = interval2
        .reservableInterval
        ? interval2.reservableInterval
            .timeInterval.startTime
        : interval2.startTime;
    const interval2EndTime = interval2
        .reservableInterval
        ? interval2.reservableInterval
            .timeInterval.endTime
        : interval2.endTime;
    if (interval1StartTime === interval2StartTime &&
        interval1EndTime === interval2EndTime) {
        return true;
    }
    return false;
};
export const areIntervalsTheSame = (interval1, interval2) => {
    if (areTimeIntervalsTheSame(interval1, interval2) &&
        isSameDay(getTimelessDate(interval1.date), getTimelessDate(interval2.date))) {
        return true;
    }
    return false;
};

import { Days } from '@reservation-app/common-used-in-web';
export const dayToJsDayIndexMap = {
    [Days.MONDAY]: 1,
    [Days.TUESDAY]: 2,
    [Days.WEDNESDAY]: 3,
    [Days.THURSDAY]: 4,
    [Days.FRIDAY]: 5,
    [Days.SATURDAY]: 6,
    [Days.SUNDAY]: 0,
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from '../modal';
import { useDebouncedEventListener } from '../hooks';
import classNames from 'classnames';
import { Arrow } from './arrow';
import { Slide } from './slide';
import { ReactCarouselDots } from './react-dots';
export const Slider = ({ mainContainerClassName, slidesContainerClassName, leftArrowClassName, rightArrowClassName, children, itemsToShow = 1, itemsToScroll = 1, breakpoints, withDots = true, maxDotsToShow = 5, dotsContainerClassName, onSlideClick, startAtIndex = 0, activeIndex, isHighlightMode, onChange, LeftArrowComponent, RightArrowComponent, withArrows = true, arrowPosition = 'slider', autoPlay = false, autoPlayInterval = 5000, }) => {
    const defaultBpConfig = {
        mainContainerClassName,
        slidesContainerClassName,
        leftArrowClassName,
        rightArrowClassName,
        itemsToScroll,
        itemsToShow,
        isHighlightMode,
        onChange,
        onSlideClick,
        withArrows,
        arrowPosition,
        LeftArrowComponent,
        RightArrowComponent,
        withDots,
        maxDotsToShow,
        dotsContainerClassName,
        startAtIndex,
    };
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [breakpointConfig, setBreakpointConfig] = useState(defaultBpConfig);
    const [internalActiveDot, setInternalActiveDot] = useState(startAtIndex);
    const [internalActiveIndex, setInternalActiveIndex] = useState(startAtIndex);
    const [translate, setTranslate] = useState(0);
    const [transition, setTransition] = useState(0);
    const [intervalValue, setIntervalValue] = useState();
    const [isTouched, setIsTouched] = useState(false);
    const mainContainerRef = useRef(null);
    const slidesContainerRef = useRef(null);
    const activeDot = useMemo(() => (activeIndex !== undefined ? activeIndex : internalActiveDot), [activeIndex, internalActiveDot]);
    const goToPrevSlide = () => {
        setActiveDot((prev) => {
            if (prev === 0) {
                return dotsNum - 1;
            }
            return activeDot - 1;
        });
        clearInterval(intervalValue);
    };
    const goToNextSlide = () => {
        setActiveDot((prev) => {
            if (prev === dotsNum - 1) {
                return 0;
            }
            return activeDot + 1;
        });
        clearInterval(intervalValue);
    };
    const setActiveDot = useMemo(() => defaultBpConfig.onChange !== undefined
        ? defaultBpConfig.onChange
        : setInternalActiveDot, [defaultBpConfig.onChange, setInternalActiveDot]);
    const isSsr = typeof window === 'undefined' || width === 0;
    const setDimensions = () => {
        const screenWidth = isSsr ? window.innerWidth : 0;
        let bpConfig = defaultBpConfig;
        if (breakpoints) {
            const currentBp = [...breakpoints]
                .sort((a, b) => a.minWidth - b.minWidth)
                .find((bp) => screenWidth > bp.minWidth);
            if (currentBp) {
                bpConfig = Object.assign(Object.assign({}, defaultBpConfig), currentBp.config);
            }
            setBreakpointConfig(bpConfig);
        }
        if ((!bpConfig.withArrows || bpConfig.isHighlightMode) &&
            mainContainerRef.current) {
            setWidth(mainContainerRef.current.getBoundingClientRect().width);
            setHeight(mainContainerRef.current.getBoundingClientRect().height);
        }
        else if (bpConfig.withArrows && slidesContainerRef.current) {
            setWidth(slidesContainerRef.current.getBoundingClientRect().width);
            setHeight(slidesContainerRef.current.getBoundingClientRect().height);
        }
    };
    useDebouncedEventListener('resize', setDimensions, 300);
    useEffect(() => {
        setTimeout(() => {
            setDimensions();
        }, 300);
    }, []);
    const consistentChildren = React.Children.map(children, (child, index) => {
        let isHidden = false;
        if (!breakpointConfig.isHighlightMode &&
            !(index >= internalActiveIndex &&
                index <= internalActiveIndex + itemsToShow - 1)) {
            isHidden = true;
        }
        else if (breakpointConfig.isHighlightMode &&
            !(index >=
                Math.ceil(internalActiveIndex - 1 + breakpointConfig.itemsToShow / 2) -
                    1 -
                    Math.floor(breakpointConfig.itemsToShow / 2) &&
                index <=
                    Math.ceil(internalActiveIndex - 1 + breakpointConfig.itemsToShow / 2) -
                        1 +
                        Math.floor(breakpointConfig.itemsToShow / 2))) {
            isHidden = true;
        }
        return (React.createElement(Slide, { key: index + 1, width: !isSsr ? width : undefined, height: !isSsr ? height : undefined, isSsr: isSsr, isHidden: isHidden, onClick: breakpointConfig.onSlideClick || undefined, index: index, highlightedIndex: Math.ceil(internalActiveIndex - 1 + breakpointConfig.itemsToShow / 2) - 1, isHighlightMode: breakpointConfig.isHighlightMode }, child));
    }) || [];
    const finalChildren = breakpointConfig.isHighlightMode
        ? [
            React.createElement(Slide, { width: !isSsr ? width : undefined, height: !isSsr ? height : undefined, isHidden: true, index: 0, key: "final-children-first" },
                React.createElement("div", null)),
            ...consistentChildren,
            React.createElement(Slide, { width: !isSsr ? width : undefined, height: !isSsr ? height : undefined, isHidden: true, index: children.length + 1, key: "final-children-last" },
                React.createElement("div", null)),
        ]
        : consistentChildren;
    const numberOfSlides = finalChildren.length;
    useEffect(() => {
        if (!isTouched && autoPlay && numberOfSlides > 1) {
            const interval = setInterval(() => {
                goToNextSlide();
            }, autoPlayInterval);
            setIntervalValue(interval);
            return () => {
                clearInterval(interval);
            };
        }
        else {
            return undefined;
        }
    }, [activeDot, isTouched]);
    useEffect(() => {
        setInternalActiveIndex(activeDot * breakpointConfig.itemsToScroll);
        setTranslate(activeDot *
            ((width / breakpointConfig.itemsToShow) *
                breakpointConfig.itemsToScroll));
        setTransition(0);
    }, [
        width,
        breakpointConfig.itemsToScroll,
        breakpointConfig.itemsToShow,
        setActiveDot,
        activeDot,
    ]);
    const dotsNum = Math.ceil((numberOfSlides - breakpointConfig.itemsToShow) /
        breakpointConfig.itemsToScroll) + 1;
    const onDotClick = (index) => {
        setActiveDot(index);
        setIsTouched(true);
    };
    useEffect(() => {
        if (activeDot + 1 === dotsNum) {
            setTranslate(breakpointConfig.itemsToScroll === 1 ||
                (numberOfSlides - breakpointConfig.itemsToShow) %
                    breakpointConfig.itemsToScroll ===
                    0
                ? activeDot *
                    ((width / breakpointConfig.itemsToShow) *
                        breakpointConfig.itemsToScroll)
                : activeDot *
                    ((width / breakpointConfig.itemsToShow) *
                        breakpointConfig.itemsToScroll) -
                    (breakpointConfig.itemsToScroll -
                        ((numberOfSlides -
                            (breakpointConfig.itemsToShow -
                                breakpointConfig.itemsToScroll)) %
                            breakpointConfig.itemsToScroll)) *
                        (width / breakpointConfig.itemsToShow));
            setTransition(0.45);
            setInternalActiveIndex(activeDot * breakpointConfig.itemsToScroll);
        }
        else {
            setTransition(0.45);
            setTranslate(activeDot *
                ((width / breakpointConfig.itemsToShow) *
                    breakpointConfig.itemsToScroll));
            setInternalActiveIndex(activeDot * breakpointConfig.itemsToScroll);
        }
    }, [activeDot, breakpointConfig.itemsToShow, breakpointConfig.itemsToScroll]);
    return (React.createElement("div", { className: classNames('slider-main-container', breakpointConfig.mainContainerClassName), ref: mainContainerRef },
        React.createElement("div", { className: classNames('slider-carousel-and-arrow-container', {
                relative: breakpointConfig.isHighlightMode,
            }) },
            breakpointConfig.withArrows &&
                breakpointConfig.arrowPosition === 'slider' && (React.createElement("div", { className: classNames({
                    'slider-highlight-arrow-container': breakpointConfig.isHighlightMode,
                }), style: {
                    left: !isSsr ? (width / breakpointConfig.itemsToShow) * 0.7 : 0,
                } }, breakpointConfig.LeftArrowComponent ? (breakpointConfig.LeftArrowComponent({ goToPrevSlide })) : (React.createElement(Arrow, { direction: "left", onClick: () => {
                    goToPrevSlide();
                    setIsTouched(true);
                }, className: breakpointConfig.leftArrowClassName })))),
            React.createElement("div", { className: classNames('slider-carousel', breakpointConfig.slidesContainerClassName), style: !isSsr
                    ? {
                        width: width * 2,
                    }
                    : undefined, ref: slidesContainerRef },
                React.createElement("div", { className: "slider-content", style: !isSsr
                        ? {
                            transform: `translateX(-${translate}px)`,
                            transition: `transform ease-out ${transition}s`,
                            width: `${(width * numberOfSlides) / breakpointConfig.itemsToShow}px`,
                        }
                        : undefined }, finalChildren)),
            breakpointConfig.withArrows &&
                breakpointConfig.arrowPosition === 'slider' && (React.createElement("div", { className: classNames('', {
                    'slider-highlight-arrow-container': breakpointConfig.isHighlightMode,
                }), style: {
                    right: !isSsr
                        ? (width / breakpointConfig.itemsToShow) * 0.7
                        : undefined,
                } }, breakpointConfig.RightArrowComponent ? (breakpointConfig.RightArrowComponent({ goToNextSlide })) : (React.createElement(Arrow, { direction: "right", onClick: () => {
                    goToNextSlide();
                    setIsTouched(true);
                }, className: breakpointConfig.rightArrowClassName }))))),
        breakpointConfig.withDots && (React.createElement("div", { className: "relative flex items-center justify-center slider-dots-container" },
            breakpointConfig.withArrows &&
                breakpointConfig.arrowPosition === 'dots' && (React.createElement("div", { className: classNames('mr-2', {
                    'slider-highlight-arrow-container': breakpointConfig.isHighlightMode,
                }), style: {
                    left: !isSsr
                        ? (width / breakpointConfig.itemsToShow) * 0.7
                        : 0,
                } }, breakpointConfig.LeftArrowComponent ? (breakpointConfig.LeftArrowComponent({ goToPrevSlide })) : (React.createElement(Arrow, { direction: "left", onClick: () => {
                    goToPrevSlide();
                    setIsTouched(true);
                }, className: breakpointConfig.leftArrowClassName })))),
            React.createElement("div", { className: breakpointConfig.dotsContainerClassName },
                React.createElement(ReactCarouselDots, { onClick: (i) => onDotClick(i), length: dotsNum, active: activeDot, size: 16, visible: breakpointConfig.maxDotsToShow })),
            breakpointConfig.withArrows &&
                breakpointConfig.arrowPosition === 'dots' && (React.createElement("div", { className: classNames('ml-2', {
                    'slider-highlight-arrow-container': breakpointConfig.isHighlightMode,
                }), style: {
                    right: !isSsr
                        ? (width / breakpointConfig.itemsToShow) * 0.7
                        : undefined,
                } }, breakpointConfig.RightArrowComponent ? (breakpointConfig.RightArrowComponent({ goToNextSlide })) : (React.createElement(Arrow, { direction: "right", onClick: () => {
                    goToNextSlide();
                    setIsTouched(true);
                }, className: breakpointConfig.rightArrowClassName }))))))));
};
export const SliderWithModal = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clickedSlide, setClickedSlide] = useState(0);
    const handleSlideClick = (index) => {
        setIsModalOpen(true);
        setClickedSlide(index);
    };
    return (React.createElement("div", { className: "slider-with-modal" },
        React.createElement(Modal, { modalClassName: "bg-transparent w-full p-0", isOpen: isModalOpen, onClose: () => setIsModalOpen(false) },
            React.createElement(Slider, Object.assign({}, props, { mainContainerClassName: "h-[80vh]", isHighlightMode: false, itemsToShow: 1, itemsToScroll: 1, startAtIndex: clickedSlide, slidesContainerClassName: "mx-2", dotsContainerClassName: "p-2 bg-gray-800 rounded-md" }))),
        React.createElement(Slider, Object.assign({}, props, { onSlideClick: handleSlideClick }))));
};

export function isDisabled(element) {
    return (Boolean(element.getAttribute('disabled')) === true ||
        Boolean(element.getAttribute('aria-disabled')) === true);
}
export function isHTMLElement(element) {
    return element instanceof HTMLElement;
}
export function isHidden(element) {
    if (element.parentElement && isHidden(element.parentElement))
        return true;
    return element.hidden;
}
export function isFocusable(element) {
    if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
        return false;
    }
}

import { isDateInPeriod } from './is-date-in-period';
export const getPeriodPropertiesForDate = ({ periodSensitiveProperty, date, }) => {
    const propertiesForPeriod = [];
    periodSensitiveProperty.forEach((periodProperty) => {
        if (isDateInPeriod({ date, period: periodProperty.period })) {
            propertiesForPeriod.push(periodProperty);
        }
    });
    return propertiesForPeriod;
};
export const getPeriodPropertyValuesForDate = ({ periodSensitiveProperty, date, }) => {
    return getPeriodPropertiesForDate({ periodSensitiveProperty, date }).map((configForPeriod) => configForPeriod.value);
};

import React from 'react';
import classNames from 'classnames';
import { FadeInAnimation } from '@reservation-app/common-used-in-web';

export interface SectionWrapperProps {
  headingText?: string;
  subHeadingText?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  textWrapperClassName?: string;
  tag?: string;
  id?: string;
}

export const sectionBasicWrapperClassNames =
  'flex justify-center items-center w-full';

export const sectionBasicContentClassNames =
  'flex flex-col items-center justify-center pt-6 pb-8 768:pt-8 768:pb-10 1024:pt-10 1024:pb-12 custom-container mx-auto scroll-mt-[50px]';

export const SectionBasicWrapper: React.FC<SectionWrapperProps> = ({
  children,
  headingText,
  subHeadingText,
  wrapperClassName,
  contentClassName,
  textWrapperClassName,
  tag,
  id,
}) => {
  const content = (
    <div
      id={id}
      className={classNames(sectionBasicContentClassNames, contentClassName)}
    >
      {headingText && (
        <FadeInAnimation
          className={classNames(
            'flex flex-col mb-8 768:mb-10 1024:mb-12',
            textWrapperClassName,
          )}
        >
          {headingText && (
            <h2 className="text-3xl 768:text-4xl 1024:text-4xl font-semibold text-gray-900 mb-1 text-center">
              {headingText}
            </h2>
          )}
          {subHeadingText && (
            <h3 className="text-lg font-normal text-gray-600 text-center">
              {subHeadingText}
            </h3>
          )}
        </FadeInAnimation>
      )}
      {children}
    </div>
  );

  if (tag === 'footer') {
    return (
      <footer
        className={classNames(sectionBasicWrapperClassNames, wrapperClassName)}
      >
        {content}
      </footer>
    );
  }

  return (
    <section
      className={classNames(sectionBasicWrapperClassNames, wrapperClassName)}
    >
      {content}
    </section>
  );
};

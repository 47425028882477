import { isLastIndex, Switch, useTranslationsStore, minutesToHoursMap, getDisplayTime, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { CounterInput } from '../../../components';
import { areIntervalsTheSame } from '../../helpers/are-intervals-the-same';
import { getExtraAvailableCapacityForInterval } from '../../helpers/get-extra-available-capacity-for-interval';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
import { useFormattedDateLocale } from '../../hooks/use-formatted-date-locale';
import { useCompanyReservationConfig, useExtrasStepSpecificSlice, useRemoteMasterDataSlice, useSelectedValuesSlice, } from '../../store';
export const ExtraInterval = ({ extra, index, extraReservableInterval, }) => {
    var _a;
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { currency } = useCompanyReservationConfig();
    const locale = useFormattedDateLocale(activeLanguage);
    const { selectedIntervals } = useSelectedValuesSlice();
    const { entities } = useRemoteMasterDataSlice();
    const { handleExtraIntervalToggle, handleExtraIntervalCounterChange } = useExtrasStepSpecificSlice();
    let isSelected = false;
    let currentAmount = 0;
    const availableCapacity = getExtraAvailableCapacityForInterval({
        entities,
        extra,
        extraReservableInterval,
    });
    const relevantResevedInterval = selectedIntervals.find((si) => {
        return areIntervalsTheSame(Object.assign({ date: si.date }, si.reservableInterval.timeInterval), extraReservableInterval);
    });
    const relevantSelectedExtra = relevantResevedInterval === null || relevantResevedInterval === void 0 ? void 0 : relevantResevedInterval.selectedExtras.find((se) => se.extraId === extra._id);
    if (relevantSelectedExtra) {
        isSelected = true;
        currentAmount = relevantSelectedExtra.amount;
    }
    const displayTime = getDisplayTime({
        interval: extraReservableInterval,
        activeLanguage,
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: classNames('flex flex-col py-4', {
                'border-b border-gray-300': !isLastIndex(index, extra.intervals),
            }), "data-test-id": `${format(new Date(extraReservableInterval.date), 'dd-MM-yyyy')}/${minutesToHoursMap[extraReservableInterval.startTime]}-${minutesToHoursMap[extraReservableInterval.endTime]}` },
            React.createElement("div", { className: "flex justify-between items-center w-full" },
                React.createElement("div", { className: "flex flex-col justify-between" },
                    React.createElement("p", { className: "font-semibold text-gray-900" }, displayTime),
                    React.createElement("p", { className: "text-gray-800" }, format(new Date(extraReservableInterval.date), 'd EEEE, yyyy', {
                        locale,
                    }))),
                React.createElement("div", { className: "flex flex-col justify-center items-end" },
                    extra.withUnitCounter ? (React.createElement(CounterInput, { containerClassName: `extra-interval-counter-input ${extra.internalName} ${minutesToHoursMap[extraReservableInterval.startTime]}-${minutesToHoursMap[extraReservableInterval.endTime]}`, value: currentAmount, onChange: (num) => {
                            handleExtraIntervalCounterChange(extra, extraReservableInterval, isSelected, Number(num), availableCapacity);
                        } })) : (React.createElement(Switch, { size: "lg", value: isSelected, onChange: () => {
                            handleExtraIntervalToggle(extra, extraReservableInterval, isSelected, availableCapacity);
                        }, containerClassName: `extra-interval-switch ${extra.internalName} ${minutesToHoursMap[extraReservableInterval.startTime]}-${minutesToHoursMap[extraReservableInterval.endTime]} mb-1` })),
                    React.createElement("p", { className: "font-semibold text-gray-900" },
                        getFormattedPrice(extraReservableInterval.priceInfo.value.toString(), currency),
                        ' ', (_a = extraReservableInterval.priceInfo.unitName) === null || _a === void 0 ? void 0 :
                        _a[activeLanguage]))),
            extra.withCapacity && (React.createElement("p", { className: classNames({
                    'text-error-500': availableCapacity === 0,
                }) },
                getTranslation('available'),
                ": ",
                availableCapacity)))));
};

import React from 'react';
import { Modal, PricesSection, useTranslationsStore, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice, useStaticPropsSlice, } from '../../reservation-flow/store';
export const PricesSectionModal = () => {
    const { getTranslation } = useTranslationsStore();
    const { pricesSectionProps } = useStaticPropsSlice();
    const { isPricesSectionModalOpen, setIsPricesSectionModalOpen } = useModalStatesDataSlice();
    return (React.createElement(Modal, { isOpen: isPricesSectionModalOpen, onClose: () => setIsPricesSectionModalOpen(false), modalClassName: "flex flex-col items-center overflow-y-auto overflow-x-auto max-h-full", overlayClassName: "", withScrollLock: false },
        React.createElement("p", { className: "mb-6 text-3xl font-semibold text-gray-900" }, getTranslation('prices')),
        React.createElement(PricesSection, Object.assign({}, pricesSectionProps))));
};

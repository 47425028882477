import React, { useMemo } from 'react';
import { TextInput } from '../../../components';
import { useStaticPropsSlice, useStepperStateSlice } from '../../store';
import { StepWrapper } from '../../layout';
import { Languages, ReservationSteps, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
export const PasswordStep = () => {
    const { reservationPassword, setReservationPassword, reservationPasswordError, setReservationPasswordError, } = useStepperStateSlice();
    const getTranslation = useGetTranslation();
    const activeLanguage = useActiveLanguage();
    const { defaultLanguage } = useStaticPropsSlice();
    const verifyAvailabilityPageRoute = useMemo(() => {
        if (activeLanguage === Languages.EN) {
            return 'find-available-period';
        }
        if (activeLanguage === Languages.RO) {
            return 'cautare-perioada-libera';
        }
        if (activeLanguage === Languages.HU) {
            return 'szabad-idopont-kereses';
        }
        return 'find-available-period';
    }, [activeLanguage]);
    const contactSectionHomePageRoute = useMemo(() => {
        if (activeLanguage === Languages.HU) {
            return '#elerhetoseg';
        }
        if (activeLanguage === Languages.RO) {
            return '#contact';
        }
        if (activeLanguage === Languages.EN) {
            return '#contact';
        }
        return '#contact';
    }, [activeLanguage]);
    return (React.createElement(StepWrapper, { step: ReservationSteps.PASSWORD },
        React.createElement("p", { className: "max-w-sm mb-5 -mt-3 text-gray-900" }, getTranslation('passwordExplanation')),
        React.createElement(TextInput, { containerClassName: "mb-2 max-w-sm", placeholder: getTranslation('enterPasswordHere'), type: "password", value: reservationPassword, onChange: (event) => {
                setReservationPassword(event.target.value);
                if (event.target.value) {
                    setReservationPasswordError('');
                }
            } }),
        reservationPasswordError && (React.createElement("p", { className: "max-w-sm text-sm text-error-main" }, reservationPasswordError)),
        React.createElement("div", { className: "flex flex-col mt-2 text-center 768:text-left" },
            React.createElement("p", { className: "mb-2" }, getTranslation('dontHaveAPassword?')),
            React.createElement("a", { className: "!underline section-link", href: `${activeLanguage === defaultLanguage ? '' : `/${activeLanguage}`}/${contactSectionHomePageRoute}` },
                ' ',
                getTranslation('contactTheAdmins')),
            React.createElement("div", { className: "flex flex-col 768:flex-row" },
                React.createElement("p", null, getTranslation('or')),
                React.createElement("a", { className: "!underline section-link 768:ml-2", href: `${activeLanguage === defaultLanguage ? '' : `/${activeLanguage}`}/${verifyAvailabilityPageRoute}` },
                    ' ',
                    getTranslation('checkAvailabilityWithoutReserving'))))));
};

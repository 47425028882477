import React from 'react';
import { ModalWrapper } from './modal-wrapper';
import { Button, useGetTranslation, zIndexes, Modal, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice } from '../store';
export const WarningTextModal = () => {
    const getTranslation = useGetTranslation();
    const { isWarningTextModalOpen, setIsWarningTextModalOpen, warningTextModalText, } = useModalStatesDataSlice();
    const onModalClose = () => {
        setIsWarningTextModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['TEXT_MODAL'], isOpen: isWarningTextModalOpen, withCloseButton: false, onClose: onModalClose, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "bg-warning-main text-white", onClose: onModalClose },
            React.createElement("div", { className: "p-4", "data-test-id": "warning-text-modal" },
                React.createElement("p", { className: "mb-4 text-gray-900" }, warningTextModalText),
                React.createElement(Button, { onClick: onModalClose, color: "warning", size: "md", className: "text-modal-continue-button w-full" }, getTranslation('close'))))));
};

import { useEffect } from 'react';
import { useIsNextButtonDisabled, useStepperStateSlice } from '../store';
const WAS_NEXT_BUTTON_WIGGLED_LS_KEY = 'wasNextButtonWiggled';
export const useWiggleNextStepButton = () => {
    const { setShouldAnimateNextButton, shouldAnimateNextButton } = useStepperStateSlice();
    const isNextButtonDisabled = useIsNextButtonDisabled();
    useEffect(() => {
        (() => {
            if (!isNextButtonDisabled) {
                const wasButtonWiggled = localStorage.getItem(WAS_NEXT_BUTTON_WIGGLED_LS_KEY);
                if (!wasButtonWiggled) {
                    setShouldAnimateNextButton(true);
                    setTimeout(() => {
                        localStorage.setItem(WAS_NEXT_BUTTON_WIGGLED_LS_KEY, 'true');
                        setShouldAnimateNextButton(false);
                    }, 1600);
                }
            }
        })();
    }, [isNextButtonDisabled]);
    return shouldAnimateNextButton
        ? {
            initial: { opacity: 0 },
            exit: { opacity: 0 },
            animate: {
                opacity: 1,
                rotate: [0, 5, -5, 5, -5, 0],
                transition: {
                    type: 'spring',
                    duration: 1,
                    delay: 0.5,
                },
            },
        }
        : {
            initial: { opacity: 0, rotate: 0 },
            exit: { opacity: 0, rotate: 0 },
            animate: {
                rotate: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    delay: 0.5,
                },
            },
        };
};

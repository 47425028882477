var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isSameDay } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { globalApiClient, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { useRemoteMasterDataSlice, useModalStatesDataSlice, useStepperStateSlice, } from '../store';
import { RESERVATION_FLOW_COMPANY_BUILD_INFO_QUERY_KEY } from '../../query-keys';
export const useForceRefreshCheck = () => {
    const { companyBuildInfo } = useRemoteMasterDataSlice();
    const { setForceRefreshModalText, setIsForceRefreshModalOpen } = useModalStatesDataSlice();
    const { isFocused, defaultDate } = useStepperStateSlice();
    const getTranslation = useGetTranslation();
    useQuery(RESERVATION_FLOW_COMPANY_BUILD_INFO_QUERY_KEY, () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield globalApiClient.get('companies/build-status');
        return response.data;
    }), {
        keepPreviousData: true,
        refetchOnMount: 'always',
        refetchOnWindowFocus: 'always',
        enabled: companyBuildInfo ? true : false,
        onSuccess: (response) => {
            const { isBuilding, lastBuildFinishedAt } = response || {};
            if (isFocused) {
                if (!isSameDay(Date.now(), defaultDate)) {
                    setForceRefreshModalText({
                        header: getTranslation('refreshNeeded'),
                        description: getTranslation('aDayHasPassedSinceLastUse'),
                        callToAction: getTranslation('refreshToMakeTheAppWork'),
                    });
                    setIsForceRefreshModalOpen(true);
                    return;
                }
                if (companyBuildInfo) {
                    const buildTimeDifference = (new Date(lastBuildFinishedAt).getTime() -
                        new Date(companyBuildInfo.lastBuildFinishedAt).getTime()) /
                        1000;
                    if (buildTimeDifference > 60) {
                        setForceRefreshModalText({
                            header: getTranslation('anUpdateHasBeenMade'),
                            description: getTranslation('lastUpdateMightAffectTheData'),
                            callToAction: getTranslation('pleaseRefreshTheApp'),
                        });
                        setIsForceRefreshModalOpen(true);
                    }
                }
            }
            if (isBuilding) {
                setForceRefreshModalText({
                    header: getTranslation('anUpdateIsInProgress'),
                    description: getTranslation('refreshToMakeTheAppWork'),
                    callToAction: getTranslation('refreshAppAndComeBackLater'),
                });
                setIsForceRefreshModalOpen(true);
            }
        },
        refetchInterval: isFocused ? 13777 : false,
        notifyOnChangeProps: ['data'],
    });
};

const DateInputBaseClasses = (theme) => ({
    '.date-input-text-input-container': {
        width: theme('spacing.96'),
    },
    '.date-input-calendar': {
        width: theme('spacing.56'),
    },
    '.date-input-caret-disable': {
        caretColor: 'transparent',
    },
    '.date-input-input': {
        cursor: 'pointer',
    },
});
module.exports = DateInputBaseClasses;

import { isAfter, isSameDay, isWithinInterval, isBefore, getWeek, } from 'date-fns';
import { getTimelessDate } from './get-timeless-date';
import { CronPatterns } from '../';
export const isDateInPeriod = ({ period, date }) => {
    const isDateExcluded = () => {
        if (period.excludeStartDate && period.excludeEndDate) {
            const isDateWithinExcludeInterval = isWithinInterval(getTimelessDate(date), {
                start: getTimelessDate(period.excludeStartDate),
                end: getTimelessDate(period.excludeEndDate),
            });
            return isDateWithinExcludeInterval;
        }
        if (period.excludeStartDate && !period.excludeEndDate) {
            const isDateWithinExcludeInterval = isAfter(getTimelessDate(date), getTimelessDate(period.excludeStartDate)) ||
                isSameDay(getTimelessDate(date), getTimelessDate(period.excludeStartDate));
            return isDateWithinExcludeInterval;
        }
        return false;
    };
    const isDateInCronPattern = () => {
        const weekNumber = getWeek(new Date(date), {
            weekStartsOn: 1,
        });
        switch (period.cronPattern) {
            case CronPatterns.EVERY_WEEK:
                return true;
            case CronPatterns.EVERY_ODD_WEEK:
                if (weekNumber % 2 !== 0) {
                    return true;
                }
                return false;
            case CronPatterns.EVERY_EVEN_WEEK:
                if (weekNumber % 2 === 0) {
                    return true;
                }
                return false;
            default:
                return true;
        }
    };
    if (isDateExcluded()) {
        return false;
    }
    if (!isDateInCronPattern()) {
        return false;
    }
    if (period.startDate && period.endDate) {
        const isDateIncluded = isWithinInterval(getTimelessDate(date), {
            start: getTimelessDate(period.startDate),
            end: getTimelessDate(period.endDate),
        });
        return isDateIncluded;
    }
    if (period.startDate && !period.endDate) {
        const isDateWithinInterval = isAfter(getTimelessDate(date), getTimelessDate(period.startDate)) ||
            isSameDay(getTimelessDate(period.startDate), getTimelessDate(date));
        return isDateWithinInterval;
    }
    if (!period.startDate && period.endDate) {
        const isDateWithinInterval = isBefore(getTimelessDate(date), getTimelessDate(period.endDate)) ||
            isSameDay(getTimelessDate(period.endDate), getTimelessDate(date));
        return isDateWithinInterval;
    }
    if (!period.startDate && !period.endDate) {
        return true;
    }
    return false;
};

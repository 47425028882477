import React from 'react';
import { SelectEntity } from '../select-period-and-entity/select-entity';
import { useValidateRestrictions } from '../../hooks/use-validate-restrictions';
import { useSyncCachedValuesOfEntitiesSwitches } from '../../hooks/use-sync-cached-values-of-entities-switches';
import { useSyncNumberOfPersons } from '../../hooks/use-sync-number-of-persons';
import { isStepBeforeAnotherStep, ReservationSteps, } from '@reservation-app/common-used-in-web';
import { useSelectedValuesSlice, useStepperStateSlice } from '../../store';
import { BulkNumberOfPersonsSelector } from '../select-period-and-entity/bulk-number-of-persons-selector';
import { PromotionsList } from '../select-period-and-entity/promotions-list';
export const SelectEntityStep = () => {
    const { steps } = useStepperStateSlice();
    const { selectedEntity } = useSelectedValuesSlice();
    const isSelectPeriodStepBeforeSelectEntityStep = isStepBeforeAnotherStep({
        steps,
        stepToCheckIfBehind: ReservationSteps.SELECT_PERIOD,
        stepToCheckIfAhead: ReservationSteps.SELECT_ENTITY,
    });
    useValidateRestrictions({
        isDisabled: !isSelectPeriodStepBeforeSelectEntityStep,
    });
    useSyncCachedValuesOfEntitiesSwitches();
    useSyncNumberOfPersons();
    return (React.createElement("div", null,
        React.createElement(SelectEntity, { step: ReservationSteps.SELECT_ENTITY }),
        ";",
        selectedEntity && selectedEntity.withBulkNumberOfPersonsSelect && (React.createElement(BulkNumberOfPersonsSelector, { step: ReservationSteps.SELECT_ENTITY })),
        React.createElement(PromotionsList, null)));
};

import React from 'react';
import { Pricetag } from './icons/pricetag';
import { Timetable } from './icons/timetable';
import { Fishing } from './icons/fishing';
import { Lodge } from './icons/lodge';
import { Fish } from './icons/fish';
import { Close } from './icons/close';
import { Ribbon } from './icons/ribbon';
import { Trophy } from './icons/trophy';
import { ChevronLeft } from './icons/chevron-left';
import { ChevronRight } from './icons/chevron-right';
import { ChevronUp } from './icons/chevron-up';
import { ChevronDown } from './icons/chevron-down';
import { ChevronLeft2 } from './icons/chevron-left-2';
import { ChevronRight2 } from './icons/chevron-right-2';
import { Moon } from './icons/moon';
import { Sun } from './icons/sun';
import { SunRise } from './icons/sun-rise';
import { Clock } from './icons/clock';
import { CloseWithCircle } from './icons/close-with-circle';
import { DoubleFish } from './icons/double-fish';
import { Warning } from './icons/warning';
import { Phone } from './icons/phone';
import { Telephone } from './icons/telephone';
import { CircledPlus } from './icons/circled-plus';
import { Calendar } from './icons/calendar';
import { Filter } from './icons/filter';
import { Home } from './icons/home';
import { ReservedIntervals } from './icons/reserved-intervals';
import { EntityTypes } from './icons/entity-types';
import { Eye } from './icons/eye';
import { Entities } from './icons/entities';
import { Service } from './icons/service';
import { PeriodConfigs } from './icons/period-configs';
import { Extras } from './icons/extras';
import { Pages } from './icons/pages';
import { Sections } from './icons/sections';
import { Companies } from './icons/companies';
import { Promotions } from './icons/promotions';
import { Restrictions } from './icons/restrictions';
import { LocalTranslations } from './icons/local-translations';
import { GlobalTranslations } from './icons/global-translations';
import { Backups } from './icons/backups';
import { Hamburger } from './icons/hamburger';
import { Report } from './icons/report';
import { Download } from './icons/download';
import { ErrorWithCircle } from './icons/error-with-circle';
import { Schedule } from './icons/schedule';
import { Period } from './icons/period';
import { ReservableInterval } from './icons/reservable-interval';
import { ReservableShortcut } from './icons/reservable-shortcut';
import { InfoWithCircle } from './icons/info-with-circle';
import { DownArrow } from './icons/down-arrow';
import { Email } from './icons/email';
import { EmailEnvelope } from './icons/email-envelope';
import { Social } from './icons/social';
import { Location } from './icons/location';
import { User } from './icons/user';
import { Facebook } from './icons/facebook';
import { Instagram } from './icons/instagram';
import { Piers } from './icons/piers';
import { PierWithLodges } from './icons/pier-with-lodges';
import { RightArrow } from './icons/right-arrow';
import { LeftArrow } from './icons/left-arrow';
import { Check } from './icons/check';
import { TimeInterval } from './icons/time-interval';
import { Dollar } from './icons/dollar';
import { Image } from './icons/image';
import { Orders } from './icons/orders';
import { PierWithFish } from './icons/pier-with-fish';
import { PierWithFishes } from './icons/pier-with-fishes';
import { Waves } from './icons/waves';
import { GridFill } from './icons/grid-fill';
import { ListFill } from './icons/list-fill';
import { SoccerBall } from './icons/soccer-ball';
import { SoccerField } from './icons/soccer-field';
import { TennisBall } from './icons/tennis-ball';
import { TennisRacket } from './icons/tennis-racket';
import { TennisField } from './icons/tennis-field';
import { TennisField2 } from './icons/tennis-field-2';
import { BasketBall } from './icons/basketball';
import { BasketBallField } from './icons/basketball-field';
import { SwimmingPool1 } from './icons/swimming-pool-1';
import { SwimmingPool2 } from './icons/swimming-pool-2';
import { SwimmingPool3 } from './icons/swimming-pool-3';
import { FootballTennis } from './icons/football-tennis';
import { TankTop } from './icons/tank-top';
import { Shirt } from './icons/shirt';
import { Cross } from './icons/cross';
import { Sunbed } from './icons/sunbed';
import { SunbedWithUmbrella } from './icons/sunbed-with-umbrella';
import { Search } from './icons/search';
import { Notifications } from './icons/notifications';
import { Plus } from './icons/plus';
import { HungarianFlag } from './icons/hungarian-flag';
import { EnglishFlag } from './icons/english-flag';
import { RomanianFlag } from './icons/romanian-flag';
import { Star } from './icons/star';
import { WavingHand } from './icons/waving-hand';
import { PartyPopper } from './icons/party-popper';
import { Question } from './icons/question';
import { Area } from './icons/area';
export const iconKeys = [
    'question',
    'pricetag',
    'timetable',
    'fishing',
    'lodge',
    'fish',
    'chevronLeft',
    'chevronRight',
    'chevronUp',
    'chevronDown',
    'chevronLeft-2',
    'chevronRight-2',
    'moon',
    'sun',
    'sunRise',
    'close',
    'clock',
    'closeWithCircle',
    'warning',
    'check',
    'phone',
    'eye',
    'telephone',
    'calendar',
    'filter',
    'circledPlus',
    'home',
    'reservedIntervals',
    'entityTypes',
    'entities',
    'service',
    'search',
    'periodConfigs',
    'extras',
    'pages',
    'sections',
    'companies',
    'promotions',
    'restrictions',
    'localTranslations',
    'globalTranslations',
    'backups',
    'report',
    'download',
    'errorWithCircle',
    'schedule',
    'period',
    'reservableInterval',
    'doubleFish',
    'star',
    'infoWithCircle',
    'downArrow',
    'hamburger',
    'email',
    'emailEnvelope',
    'social',
    'location',
    'facebook',
    'instagram',
    'piers',
    'pierWithLodges',
    'rightArrow',
    'leftArrow',
    'timeInterval',
    'dollar',
    'image',
    'orders',
    'pierWithFish',
    'pierWithFishes',
    'user',
    'waves',
    'area',
    'gridFill',
    'listFill',
    'soccerBall',
    'soccerField',
    'tennisBall',
    'tennisRacket',
    'tennisField',
    'tennisField2',
    'basketBall',
    'basketBallBasket',
    'swimmingPool1',
    'swimmingPool2',
    'swimmingPool3',
    'footballTennis',
    'tankTop',
    'shirt',
    'cross',
    'sunbed',
    'sunbedWithUmbrella',
    'notifications',
    'plus',
    'wavingHand',
    'partyPopper',
    'ribbon',
    'trophy',
    'ro',
    'hu',
    'en',
    'reservableShortcut',
];
export const Icon = ({ icon, className, onClick, testId, containerClassName, }) => {
    switch (icon) {
        case 'question':
            return React.createElement(Question, { className: className, onClick: onClick });
        case 'cross':
            return React.createElement(Cross, { className: className, onClick: onClick });
        case 'pricetag':
            return React.createElement(Pricetag, { className: className, onClick: onClick });
        case 'trophy':
            return React.createElement(Trophy, { className: className, onClick: onClick });
        case 'ribbon':
            return React.createElement(Ribbon, { className: className, onClick: onClick });
        case 'timetable':
            return React.createElement(Timetable, { className: className, onClick: onClick });
        case 'fishing':
            return React.createElement(Fishing, { className: className, onClick: onClick });
        case 'lodge':
            return React.createElement(Lodge, { className: className, onClick: onClick });
        case 'fish':
            return React.createElement(Fish, { className: className, onClick: onClick });
        case 'close':
            return React.createElement(Close, { className: className, onClick: onClick, testId: testId });
        case 'chevronLeft':
            return React.createElement(ChevronLeft, { className: className, onClick: onClick });
        case 'chevronRight':
            return React.createElement(ChevronRight, { className: className, onClick: onClick });
        case 'chevronUp':
            return React.createElement(ChevronUp, { className: className, onClick: onClick });
        case 'chevronDown':
            return React.createElement(ChevronDown, { className: className, onClick: onClick });
        case 'chevronLeft-2':
            return React.createElement(ChevronLeft2, { className: className, onClick: onClick });
        case 'chevronRight-2':
            return React.createElement(ChevronRight2, { className: className, onClick: onClick });
        case 'moon':
            return React.createElement(Moon, { className: className, onClick: onClick });
        case 'star':
            return React.createElement(Star, { className: className, onClick: onClick });
        case 'sun':
            return React.createElement(Sun, { className: className, onClick: onClick });
        case 'sunRise':
            return React.createElement(SunRise, { className: className, onClick: onClick });
        case 'clock':
            return React.createElement(Clock, { className: className, onClick: onClick });
        case 'closeWithCircle':
            return React.createElement(CloseWithCircle, { className: className, onClick: onClick });
        case 'area':
            return React.createElement(Area, { className: className, onClick: onClick });
        case 'warning':
            return React.createElement(Warning, { className: className, onClick: onClick });
        case 'check':
            return React.createElement(Check, { className: className, onClick: onClick });
        case 'phone':
            return React.createElement(Phone, { className: className, onClick: onClick });
        case 'telephone':
            return React.createElement(Telephone, { className: className, onClick: onClick });
        case 'circledPlus':
            return React.createElement(CircledPlus, { className: className, onClick: onClick });
        case 'calendar':
            return React.createElement(Calendar, { className: className, onClick: onClick });
        case 'filter':
            return React.createElement(Filter, { className: className, onClick: onClick });
        case 'home':
            return React.createElement(Home, { className: className, onClick: onClick });
        case 'doubleFish':
            return React.createElement(DoubleFish, { className: className, onClick: onClick });
        case 'reservedIntervals':
            return React.createElement(ReservedIntervals, { className: className, onClick: onClick });
        case 'entityTypes':
            return React.createElement(EntityTypes, { className: className, onClick: onClick });
        case 'entities':
            return React.createElement(Entities, { className: className, onClick: onClick });
        case 'service':
            return React.createElement(Service, { className: className, onClick: onClick });
        case 'periodConfigs':
            return React.createElement(PeriodConfigs, { className: className, onClick: onClick });
        case 'extras':
            return React.createElement(Extras, { className: className, onClick: onClick });
        case 'pages':
            return React.createElement(Pages, { className: className, onClick: onClick });
        case 'sections':
            return React.createElement(Sections, { className: className, onClick: onClick });
        case 'companies':
            return React.createElement(Companies, { className: className, onClick: onClick });
        case 'promotions':
            return React.createElement(Promotions, { className: className, onClick: onClick });
        case 'restrictions':
            return React.createElement(Restrictions, { className: className, onClick: onClick });
        case 'localTranslations':
            return React.createElement(LocalTranslations, { className: className, onClick: onClick });
        case 'globalTranslations':
            return React.createElement(GlobalTranslations, { className: className, onClick: onClick });
        case 'backups':
            return React.createElement(Backups, { className: className, onClick: onClick });
        case 'hamburger':
            return React.createElement(Hamburger, { className: className, onClick: onClick });
        case 'report':
            return React.createElement(Report, { className: className, onClick: onClick });
        case 'download':
            return React.createElement(Download, { className: className, onClick: onClick });
        case 'errorWithCircle':
            return React.createElement(ErrorWithCircle, { className: className, onClick: onClick });
        case 'schedule':
            return React.createElement(Schedule, { className: className, onClick: onClick });
        case 'period':
            return React.createElement(Period, { className: className, onClick: onClick });
        case 'reservableInterval':
            return React.createElement(ReservableInterval, { className: className, onClick: onClick });
        case 'infoWithCircle':
            return React.createElement(InfoWithCircle, { className: className, onClick: onClick });
        case 'downArrow':
            return React.createElement(DownArrow, { className: className, onClick: onClick });
        case 'email':
            return React.createElement(Email, { className: className, onClick: onClick });
        case 'emailEnvelope':
            return React.createElement(EmailEnvelope, { className: className, onClick: onClick });
        case 'social':
            return React.createElement(Social, { className: className, onClick: onClick });
        case 'location':
            return React.createElement(Location, { className: className, onClick: onClick });
        case 'user':
            return React.createElement(User, { className: className, onClick: onClick });
        case 'facebook':
            return React.createElement(Facebook, { className: className, onClick: onClick });
        case 'instagram':
            return React.createElement(Instagram, { className: className, onClick: onClick });
        case 'piers':
            return React.createElement(Piers, { className: className, onClick: onClick });
        case 'pierWithLodges':
            return React.createElement(PierWithLodges, { className: className, onClick: onClick });
        case 'rightArrow':
            return React.createElement(RightArrow, { className: className, onClick: onClick });
        case 'leftArrow':
            return React.createElement(LeftArrow, { className: className, onClick: onClick });
        case 'timeInterval':
            return React.createElement(TimeInterval, { className: className, onClick: onClick });
        case 'dollar':
            return React.createElement(Dollar, { className: className, onClick: onClick });
        case 'image':
            return React.createElement(Image, { className: className, onClick: onClick });
        case 'waves':
            return React.createElement(Waves, { className: className, onClick: onClick });
        case 'pierWithFish':
            return (React.createElement(PierWithFish, { containerClassName: containerClassName, className: className, onClick: onClick }));
        case 'pierWithFishes':
            return (React.createElement(PierWithFishes, { containerClassName: containerClassName, className: className, onClick: onClick }));
        case 'orders':
            return React.createElement(Orders, { className: className, onClick: onClick });
        case 'gridFill':
            return React.createElement(GridFill, { className: className, onClick: onClick });
        case 'listFill':
            return React.createElement(ListFill, { className: className, onClick: onClick });
        case 'soccerBall':
            return React.createElement(SoccerBall, { className: className, onClick: onClick });
        case 'soccerField':
            return React.createElement(SoccerField, { className: className, onClick: onClick });
        case 'tennisBall':
            return React.createElement(TennisBall, { className: className, onClick: onClick });
        case 'tennisRacket':
            return React.createElement(TennisRacket, { className: className, onClick: onClick });
        case 'tennisField':
            return React.createElement(TennisField, { className: className, onClick: onClick });
        case 'tennisField2':
            return React.createElement(TennisField2, { className: className, onClick: onClick });
        case 'basketBall':
            return React.createElement(BasketBall, { className: className, onClick: onClick });
        case 'basketBallBasket':
            return React.createElement(BasketBallField, { className: className, onClick: onClick });
        case 'swimmingPool1':
            return React.createElement(SwimmingPool1, { className: className, onClick: onClick });
        case 'swimmingPool2':
            return React.createElement(SwimmingPool2, { className: className, onClick: onClick });
        case 'swimmingPool3':
            return React.createElement(SwimmingPool3, { className: className, onClick: onClick });
        case 'footballTennis':
            return React.createElement(FootballTennis, { className: className, onClick: onClick });
        case 'tankTop':
            return React.createElement(TankTop, { className: className, onClick: onClick });
        case 'shirt':
            return React.createElement(Shirt, { className: className, onClick: onClick });
        case 'eye':
            return React.createElement(Eye, { className: className, onClick: onClick });
        case 'sunbed':
            return React.createElement(Sunbed, { className: className, onClick: onClick });
        case 'sunbedWithUmbrella':
            return React.createElement(SunbedWithUmbrella, { className: className, onClick: onClick });
        case 'search':
            return React.createElement(Search, { className: className, onClick: onClick });
        case 'notifications':
            return React.createElement(Notifications, { className: className, onClick: onClick });
        case 'plus':
            return React.createElement(Plus, { className: className, onClick: onClick });
        case 'wavingHand':
            return React.createElement(WavingHand, { className: className, onClick: onClick });
        case 'partyPopper':
            return React.createElement(PartyPopper, { className: className, onClick: onClick });
        case 'en':
            return React.createElement(EnglishFlag, { className: className, onClick: onClick });
        case 'ro':
            return React.createElement(RomanianFlag, { className: className, onClick: onClick });
        case 'hu':
            return React.createElement(HungarianFlag, { className: className, onClick: onClick });
        case 'reservableShortcut':
            return React.createElement(ReservableShortcut, { className: className, onClick: onClick });
        default:
            return null;
    }
};

import React from 'react';
export const RomanianFlag = ({ className, onClick }) => {
    return (React.createElement("svg", { onClick: onClick, className: className, version: "1.1", id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 475.6 475.6", enableBackground: "new 0 0 475.6 475.6", xmlSpace: "preserve" },
        React.createElement("path", { fill: "#DF1E26", d: "M317.1,13.6l-27.8,76.9c25.6,84.5,25.6,210,0,294.5l27.8,76.9c92.3-32.7,158.5-120.7,158.5-224.2\r\n      C475.6,134.3,409.4,46.3,317.1,13.6z" }),
        React.createElement("g", null,
            React.createElement("path", { fill: "#E1B623", d: "M317.1,90.5V13.6C292.3,4.8,265.6,0,237.8,0C274.5,0,223.6,124.3,317.1,90.5z" }),
            React.createElement("path", { fill: "#E1B623", d: "M237.8,475.6c27.8,0,54.5-4.8,79.3-13.6v-83.9C224.6,368.8,237.8,475.6,237.8,475.6z" })),
        React.createElement("path", { fill: "#1F429A", d: "M202.1,2.7c-14.7,2.2-29,5.8-42.7,10.6C66.6,45.7,0,133.9,0,237.8s66.6,192.1,159.4,224.5\r\n      c2.6,0.9,5.3,1.8,8,2.6c22.2,6.9,53.7-169.2,60.3-224.7C234.8,180.9,213.8,0.9,202.1,2.7z" }),
        React.createElement("path", { fill: "#FFD72E", d: "M317.1,385.1V90.5C300.9,37.1,274.5,0,237.8,0c-27.5,0-53.8,4.7-78.3,13.3v449\r\n      c24.5,8.6,50.9,13.3,78.3,13.3C274.5,475.6,300.9,438.5,317.1,385.1z" }),
        React.createElement("path", { fill: "#ED2524", d: "M317.1,90.5V385C342.7,300.5,342.7,175.1,317.1,90.5z" })));
};

export const getServerErrorMessage = (error, getTranslation) => {
    var _a;
    if (typeof error === 'string') {
        return error;
    }
    const genericServerErrorMessage = getTranslation('genericServerErrorMessage', {}, {
        ro: 'A apărut o eroare pe server. Încercă din nou mai târziu.',
        hu: 'Hiba történt a szerveren. Próbálkozz ujra később.',
        en: 'An error occurred on the server. Try again later.',
    });
    if (!error) {
        return genericServerErrorMessage;
    }
    if (error.code === 'ERR_NETWORK') {
        return getTranslation('genericNetworkErrorMessage', {}, {
            ro: 'Momentan serverul nu este disponibil. Încearcă din nou mai târziu.',
            hu: 'A szerver jelenleg nem elérhető. Próbálkozz ujra később.',
            en: 'The server is unreachable at the moment. Try again later.',
        });
    }
    if (error.response) {
        return (((_a = error.response.data) === null || _a === void 0 ? void 0 : _a.message) ||
            genericServerErrorMessage);
    }
    if (error.message) {
        return error.message || genericServerErrorMessage;
    }
    return genericServerErrorMessage;
};

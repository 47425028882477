import { format } from 'date-fns';
export const CONTACT_FORMS_QUERY_KEY = ['contact-forms'];
export const CONTACT_FORMS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'contact-forms-cms-ui-properties',
];
export const RESERVATION_FLOW_DATA_QUERY_KEY = ['reservation-flow/data'];
export const RESERVATION_FLOW_RESERVED_INTERVALS_QUERY_KEY = [
    'reservation-flow/reserved-intervals',
];
export const RESERVATION_FLOW_SYSTEM_TIME_QUERY_KEY = [
    'reservation-flow/system-time',
];
export const RESERVATION_FLOW_COMPANY_BUILD_INFO_QUERY_KEY = [
    'reservation-flow/company-build-info',
];
export const ALERTS_QUERY_KEY = ['alerts'];
export const ALERTS_CMS_UI_PROPERTIES_QUERY_KEY = ['alerts-cms-ui-properties'];
export const getCompanyAlertsQueryKey = (companyName) => [
    ...ALERTS_QUERY_KEY,
    companyName,
];
export const BACKUPS_QUERY_KEY = ['backups'];
export const BACKUPS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'backups-cms-ui-properties',
];
export const getCompanyBackupsQueryKey = (companyName) => [
    ...BACKUPS_QUERY_KEY,
    companyName,
];
export const COMPANIES_QUERY_KEY = ['companies'];
export const COMPANIES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'companies-cms-ui-properties',
];
export const CONSENTS_QUERY_KEY = ['consents'];
export const CONSENTS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'consents-cms-ui-properties',
];
export const getCompanyConsentsQueryKey = (companyName) => [
    ...CONSENTS_QUERY_KEY,
    companyName,
];
export const ENTITIES_QUERY_KEY = ['entities'];
export const ENTITIES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'entities-cms-ui-properties',
];
export const getCompanyEntitiesQueryKey = (companyName) => [
    ...ENTITIES_QUERY_KEY,
    companyName,
];
export const ENTITY_TYPES_QUERY_KEY = ['entity-types'];
export const ENTITY_TYPES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'entity-types-cms-ui-properties',
];
export const getCompanyEntityTypesQueryKey = (companyName) => [
    ...ENTITY_TYPES_QUERY_KEY,
    companyName,
];
export const EXTRAS_QUERY_KEY = ['extras'];
export const EXTRAS_CMS_UI_PROPERTIES_QUERY_KEY = ['extras-cms-ui-properties'];
export const getCompanyExtrasQueryKey = (companyName) => [
    ...EXTRAS_QUERY_KEY,
    companyName,
];
export const PUBLIC_MEMBERSHIP_CARDS_QUERY_KEY = ['public-membership-cards'];
export const getCompanyPublicMembershipCardsQueryKey = (companyName) => [...PUBLIC_MEMBERSHIP_CARDS_QUERY_KEY, companyName];
export const PUBLIC_MEMBERSHIP_CARD_QUERY_KEY = ['public-membership-card'];
export const getCompanyPublicMembershipCardQueryKey = (companyName, id) => [...PUBLIC_MEMBERSHIP_CARDS_QUERY_KEY, companyName, id];
export const MEMBERSHIP_CARDS_QUERY_KEY = ['membership-cards'];
export const MEMBERSHIP_CARDS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'membership-cards-cms-ui-properties',
];
export const getCompanyMembershipCardsQueryKey = (companyName) => [
    ...MEMBERSHIP_CARDS_QUERY_KEY,
    companyName,
];
export const GLOBAL_TRANSLATIONS_QUERY_KEY = ['global-translations'];
export const GLOBAL_TRANSLATIONS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'global-translations-cms-ui-properties',
];
export const getCompanyGlobalTranslationsQueryKey = (companyName) => [
    ...GLOBAL_TRANSLATIONS_QUERY_KEY,
    companyName,
];
export const LOCAL_TRANSLATIONS_QUERY_KEY = ['local-translations'];
export const LOCAL_TRANSLATIONS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'local-translations-cms-ui-properties',
];
export const getCompanyLocalEntitiesQueryKey = (companyName) => [
    ...LOCAL_TRANSLATIONS_QUERY_KEY,
    companyName,
];
export const IMAGE_METADATAS_QUERY_KEY = ['image-metadatas'];
export const IMAGE_METADATAS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'image-metadatas-cms-ui-properties',
];
export const getCompanyImageMetadatasQueryKey = (companyName) => [
    ...IMAGE_METADATAS_QUERY_KEY,
    companyName,
];
export const PAGES_QUERY_KEY = ['pages'];
export const PAGES_CMS_UI_PROPERTIES_QUERY_KEY = ['pages-cms-ui-properties'];
export const getCompanyPagesQueryKey = (companyName) => [
    ...PAGES_QUERY_KEY,
    companyName,
];
export const PERIODS_QUERY_KEY = ['periods'];
export const PERIODS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'periods-cms-ui-properties',
];
export const getCompanyPeriodsQueryKey = (companyName) => [
    ...PERIODS_QUERY_KEY,
    companyName,
];
export const PROMOTIONS_QUERY_KEY = ['promotions'];
export const PROMOTIONS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'promotions-cms-ui-properties',
];
export const getCompanyPromotionsQueryKey = (companyName) => [
    ...PROMOTIONS_QUERY_KEY,
    companyName,
];
export const RESERVED_INTERVAL_PRICES_QUERY_KEY = ['reserved-interval-prices'];
export const RESERVED_INTERVAL_PRICES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'reserved-interval-prices-cms-ui-properties',
];
export const getCompanyReservedIntervalPricesQueryKey = (companyName) => [...RESERVED_INTERVAL_PRICES_QUERY_KEY, companyName];
export const RESTRICTIONS_QUERY_KEY = ['restrictions'];
export const RESTRICTIONS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'restrictions-cms-ui-properties',
];
export const getCompanyRestrictionsQueryKey = (companyName) => [
    ...RESTRICTIONS_QUERY_KEY,
    companyName,
];
export const SCHEDULES_QUERY_KEY = ['schedules'];
export const SCHEDULES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'schedules-cms-ui-properties',
];
export const getCompanySchedulesQueryKey = (companyName) => [
    ...SCHEDULES_QUERY_KEY,
    companyName,
];
export const SECTIONS_QUERY_KEY = ['sections'];
export const SECTIONS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'sections-cms-ui-properties',
];
export const getCompanySectionsQueryKey = (companyName) => [
    ...SECTIONS_QUERY_KEY,
    companyName,
];
export const SERVICES_QUERY_KEY = ['services'];
export const SERVICES_CMS_UI_PROPERTIES_QUERY_KEY = [
    'services-cms-ui-properties',
];
export const getCompanyServicesQueryKey = (companyName) => [
    ...SERVICES_QUERY_KEY,
    companyName,
];
export const TIME_INTERVALS_QUERY_KEY = ['time-intervals'];
export const TIME_INTERVALS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'time-intervals-cms-ui-properties',
];
export const getCompanyTimeIntervalsQueryKey = (companyName) => [
    ...TIME_INTERVALS_QUERY_KEY,
    companyName,
];
export const CONTACT_INFOS_QUERY_KEY = ['contact-infos'];
export const CONTACT_INFOS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'contact-infos-cms-ui-properties',
];
export const getCompanyContactInfosQueryKey = (companyName) => [
    ...CONTACT_INFOS_QUERY_KEY,
    companyName,
];
export const USER_QUERY_KEY = ['user'];
export const USERS_QUERY_KEY = ['users'];
export const USERS_CMS_UI_PROPERTIES_QUERY_KEY = ['users-cms-ui-properties'];
export const getCompanyUsersQueryKey = (companyName) => [
    ...USERS_QUERY_KEY,
    companyName,
];
export const MERGED_TRANSLATIONS_KEY = ['mergedTranslations'];
export const getCompanyMergedTranslationsQueryKey = (companyName) => [
    ...MERGED_TRANSLATIONS_KEY,
    companyName,
];
export const SECTION_TYPES_QUERY_KEY = ['sectionTypes'];
export const getCompanySectionTypesQueryKey = (companyName, userId) => [...SECTION_TYPES_QUERY_KEY, companyName, userId];
export const ORDER_QUERY_KEY = ['order'];
export const getOrderQueryKey = (orderId) => [
    ...ORDER_QUERY_KEY,
    orderId,
];
export const ORDERS_QUERY_KEY = ['orders'];
export const getOrdersQueryKey = (filters) => {
    return [
        ...ORDERS_QUERY_KEY,
        filters.page,
        filters.limit,
        filters.appliedName,
        filters.appliedEmail,
        filters.appliedHistoricEntityId,
        filters.appliedCreatedAtFrom,
        filters.appliedCreatedAtTo,
        filters.appliedCreatedAtDate,
        filters.appliedReservedIntervalsFrom,
        filters.appliedReservedIntervalsTo,
        filters.appliedReservedIntervalsDate,
        filters.selectedCompanyName,
        filters.ids,
    ];
};
export const ALL_PENDING_ORDERS_QUERY_KEY = ['all-pending-orders'];
export const getAllPendingOrdersQueryKey = (companyName) => [
    ...ALL_PENDING_ORDERS_QUERY_KEY,
    companyName,
];
export const RESERVED_INTERVALS_TODAY_QUERY_KEY = ['reserved-intervals-today'];
export const getReservedIntervalsTodayQueryKey = (filters) => [
    ...RESERVED_INTERVALS_TODAY_QUERY_KEY,
    filters.page,
    filters.limit,
    filters.date,
    filters.selectedCompanyName,
];
export const RESERVED_INTERVALS_PROFIT_FOR_MONTH_QUERY_KEY = [
    'reserved-intervals-profit-for-month',
];
export const getReservedIntervalsProfitForMonthQueryKey = (year, companyName) => [...RESERVED_INTERVALS_PROFIT_FOR_MONTH_QUERY_KEY, year, companyName];
export const RESERVED_INTERVALS_QUERY_KEY = ['reserved-intervals'];
export const getReservedIntervalsQueryKey = (filters) => [
    ...RESERVED_INTERVALS_QUERY_KEY,
    filters.page,
    filters.limit,
    filters.appliedName,
    filters.appliedEmail,
    filters.appliedEntityId,
    filters.appliedFrom,
    filters.appliedTo,
    filters.appliedDate,
    filters.selectedCompanyName,
];
export const RESERVED_INTERVAL_QUERY_KEY = ['reserved-interval'];
export const getReservedIntervalQueryKey = (reservedIntervalId) => [
    ...RESERVED_INTERVAL_QUERY_KEY,
    reservedIntervalId,
];
export const REPORT_FOR_YEAR_QUERY_KEY = ['report-for-year-query-key'];
export const getReportForYearQueryKey = (year, companyName) => [...REPORT_FOR_YEAR_QUERY_KEY, year, companyName];
export const getCreateEditPageQueryKey = (apiPath, companyName, id) => [apiPath, companyName, id];
export const getListingPageQueryKey = (apiPath, companyName, filters) => [
    apiPath,
    companyName,
    filters.page,
    filters.limit,
    filters.filterAppliedValues,
];
export const ADMIN_CALENDAR_ENTITIES_QUERY_KEY = ['admin-calendar-entities'];
export const getAdminCalendarEntitiesQueryKey = (companyName) => [
    ...ADMIN_CALENDAR_ENTITIES_QUERY_KEY,
    companyName,
];
export const ADMIN_CALENDAR_RESERVED_INTERVALS_QUERY_KEY = [
    'admin-calendar-reserved-intervals',
];
export const getAdminCalendarReservedIntervalsQueryKey = (selectedCompanyName, datesToShow) => [
    ...ADMIN_CALENDAR_RESERVED_INTERVALS_QUERY_KEY,
    selectedCompanyName,
    (datesToShow === null || datesToShow === void 0 ? void 0 : datesToShow[0]) ? format(datesToShow[0], 'yyyy-MM-dd') : null,
    (datesToShow === null || datesToShow === void 0 ? void 0 : datesToShow[datesToShow.length - 1])
        ? format(datesToShow[datesToShow.length - 1], 'yyyy-MM-dd')
        : null,
];
export const NOTIFICATIONS_QUERY_KEY = ['notifications'];
export const getCompanyNotificationsQueryKey = (companyName) => [
    ...NOTIFICATIONS_QUERY_KEY,
    companyName,
];
export const RESERVABLE_SHORTCUTS_QUERY_KEY = ['reservable-shortcuts'];
export const RESERVABLE_SHORTCUTS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'reservable-shortcuts-cms-ui-properties',
];
export const getCompanyReservableShortcutsQueryKey = (companyName) => [
    ...RESERVABLE_SHORTCUTS_QUERY_KEY,
    companyName,
];
export const RESERVABLE_INTERVALS_QUERY_KEY = ['reservable-intervals'];
export const RESERVABLE_INTERVALS_CMS_UI_PROPERTIES_QUERY_KEY = [
    'reservable-intervals-cms-ui-properties',
];
export const getCompanyReservableIntervalsQueryKey = (companyName) => [
    ...RESERVABLE_INTERVALS_QUERY_KEY,
    companyName,
];
export const invalidateOrdersAndReservedIntervalsQueries = (queryClient) => {
    queryClient.invalidateQueries(ORDER_QUERY_KEY);
    queryClient.invalidateQueries(ORDERS_QUERY_KEY);
    queryClient.invalidateQueries(ALL_PENDING_ORDERS_QUERY_KEY);
    queryClient.invalidateQueries(RESERVED_INTERVALS_TODAY_QUERY_KEY);
    queryClient.invalidateQueries(RESERVED_INTERVALS_PROFIT_FOR_MONTH_QUERY_KEY);
    queryClient.invalidateQueries(RESERVED_INTERVALS_QUERY_KEY);
    queryClient.invalidateQueries(RESERVED_INTERVAL_QUERY_KEY);
    queryClient.invalidateQueries(ADMIN_CALENDAR_RESERVED_INTERVALS_QUERY_KEY);
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY);
};

import React, { useMemo } from 'react';
import Basic from './basic';
import Table from './table';
import Cards from './cards';
import { FadeInAnimation } from '../fade-in-animation';
import { PricesSectionStyles } from '../../../types/enums';
import { Alert, AlertTypes } from '../../../';
export const PricesSection = ({ section: { items, backgroundColor, style, textBelowHtml, textAboveHtml, metadata, }, activeLanguage, promotions, alerts, shouldDisableAnimation, }) => {
    let content;
    switch (style) {
        case PricesSectionStyles.TABLE:
            content = (React.createElement(Table, { items: items, activeLanguage: activeLanguage, promotions: promotions, shouldDisableAnimation: shouldDisableAnimation }));
            break;
        case PricesSectionStyles.CARDS:
            content = (React.createElement(Cards, { items: items, activeLanguage: activeLanguage, shouldDisableAnimation: shouldDisableAnimation }));
            break;
        default:
            content = (React.createElement(Basic, { items: items, activeLanguage: activeLanguage, shouldDisableAnimation: shouldDisableAnimation }));
            break;
    }
    const sectionMetadata = useMemo(() => metadata
        ? Object.assign({}, JSON.parse(metadata)) : {}, []);
    return (React.createElement(React.Fragment, null,
        sectionMetadata.alertPosition === 'top' && (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation },
            React.createElement(Alert, { alertType: AlertTypes.PRICES, className: "mb-10", alerts: alerts, isReservationFlow: false, iconClassName: backgroundColor }))),
        (textAboveHtml === null || textAboveHtml === void 0 ? void 0 : textAboveHtml[activeLanguage]) && (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation },
            React.createElement("div", { className: "mb-10 max-w-md text-center 768:max-w-lg text-gray-900", dangerouslySetInnerHTML: {
                    __html: textAboveHtml[activeLanguage],
                } }))),
        content,
        (textBelowHtml === null || textBelowHtml === void 0 ? void 0 : textBelowHtml[activeLanguage]) && (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation },
            React.createElement("div", { className: "mt-10 max-w-md text-center 768:max-w-lg text-gray-900", dangerouslySetInnerHTML: {
                    __html: textBelowHtml[activeLanguage],
                } }))),
        sectionMetadata.alertPosition === 'bottom' && (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation },
            React.createElement(Alert, { alertType: AlertTypes.PRICES, className: "mt-10", alerts: alerts, isReservationFlow: false, iconClassName: backgroundColor })))));
};

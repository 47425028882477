import { useState, useEffect } from 'react';
export const useIsWindowFocused = () => {
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        setFocused(document.hasFocus());
        const onFocus = () => setFocused(true);
        const onBlur = () => setFocused(false);
        if (typeof window !== 'undefined') {
            window.addEventListener('focus', onFocus);
            window.addEventListener('blur', onBlur);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('focus', onFocus);
                window.removeEventListener('blur', onBlur);
            }
        };
    }, []);
    return focused;
};

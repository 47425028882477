const CommonBaseClasses = (theme) => ({
    '.ag-visually-hidden': {
        borderWidth: theme('borderWidth.0'),
        clip: 'rect(0px, 0px, 0px, 0px)',
        height: theme('spacing.px'),
        width: theme('spacing.px'),
        margin: `-${theme('spacing.px')}`,
        padding: theme('spacing.0'),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'absolute',
    },
});
module.exports = CommonBaseClasses;

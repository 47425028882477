import { isLastIndex, jsDayIndexToDayMap, } from '@reservation-app/common-used-in-web';
import { isSameDay, addDays } from 'date-fns';
import { getIntervalLengthInMinutes } from './get-interval-length-in-minutes';
export const getSelectedIntervalsMinutesInfo = ({ selectedIntervals, days, }) => {
    let totalNumberOfMinutes = 0;
    let globalMaxNumberOfContinuousMinutes = 0;
    let localMaxNumberOfContinuousMinutes = 0;
    const consecutiveIntervalsGroups = [];
    const orderedFilteredSelectedIntervals = JSON.parse(JSON.stringify(selectedIntervals))
        .filter((selectedInterval) => {
        if (!days) {
            return true;
        }
        return days.some((dayToCheck) => {
            return (dayToCheck.day ===
                jsDayIndexToDayMap[new Date(selectedInterval.date).getDay()] &&
                (!dayToCheck.afterMinutes ||
                    selectedInterval.reservableInterval.timeInterval.startTime >=
                        dayToCheck.afterMinutes) &&
                (!dayToCheck.beforeMinutes ||
                    selectedInterval.reservableInterval.timeInterval.startTime <=
                        dayToCheck.beforeMinutes));
        });
    })
        .sort((firstSelectedInterval, secondSelectedInterval) => {
        const areDatesTheSame = isSameDay(new Date(firstSelectedInterval.date), new Date(secondSelectedInterval.date));
        if (areDatesTheSame) {
            return (firstSelectedInterval.reservableInterval.timeInterval.startTime -
                secondSelectedInterval.reservableInterval.timeInterval.startTime);
        }
        return (new Date(firstSelectedInterval.date).getTime() -
            new Date(secondSelectedInterval.date).getTime());
    });
    let currentConsecutiveGroupIndex = 0;
    orderedFilteredSelectedIntervals.forEach((selectedInterval, index) => {
        const isLast = isLastIndex(index, orderedFilteredSelectedIntervals);
        const intervalLenghtInMinutes = getIntervalLengthInMinutes({
            interval: {
                startTime: selectedInterval.reservableInterval.timeInterval.startTime,
                endTime: selectedInterval.reservableInterval.timeInterval.endTime,
            },
        });
        const selectedIntervalDay = selectedInterval.reservableInterval.days.find((day) => day.value ===
            jsDayIndexToDayMap[new Date(selectedInterval.date).getDay()]);
        const isTheNextContinousIntervalSelected = selectedIntervalDay.isContinousAfter
            ? orderedFilteredSelectedIntervals.some((orderedSelectedInterval) => {
                const areDatesCompliant = selectedInterval.reservableInterval
                    .timeInterval.continuityProps.isNextOneDayAfterCurrent
                    ? isSameDay(addDays(new Date(selectedInterval.date), 1), new Date(orderedSelectedInterval.date))
                    : isSameDay(new Date(selectedInterval.date), new Date(orderedSelectedInterval.date));
                return (areDatesCompliant &&
                    selectedInterval.reservableInterval.timeInterval.continuityProps
                        .nextStartTime ===
                        orderedSelectedInterval.reservableInterval.timeInterval
                            .startTime);
            })
            : false;
        if (consecutiveIntervalsGroups[currentConsecutiveGroupIndex]) {
            consecutiveIntervalsGroups[currentConsecutiveGroupIndex].numberOfMinutes += intervalLenghtInMinutes;
            consecutiveIntervalsGroups[currentConsecutiveGroupIndex].selectedIntervals.push(selectedInterval);
        }
        else {
            consecutiveIntervalsGroups.push({
                selectedIntervals: [selectedInterval],
                numberOfMinutes: intervalLenghtInMinutes,
            });
        }
        totalNumberOfMinutes += intervalLenghtInMinutes;
        localMaxNumberOfContinuousMinutes += intervalLenghtInMinutes;
        if (localMaxNumberOfContinuousMinutes > globalMaxNumberOfContinuousMinutes) {
            globalMaxNumberOfContinuousMinutes = localMaxNumberOfContinuousMinutes;
        }
        if (!isTheNextContinousIntervalSelected || isLast) {
            localMaxNumberOfContinuousMinutes = 0;
            currentConsecutiveGroupIndex += 1;
        }
    });
    return {
        consecutiveIntervalsGroups,
        maxNumberOfContinuousMinutes: globalMaxNumberOfContinuousMinutes,
        numberOfMinutes: totalNumberOfMinutes,
    };
};

import { areIntervalsTheSame } from '../../helpers/are-intervals-the-same';
import { getExtraAvailableCapacityForInterval } from '../../helpers/get-extra-available-capacity-for-interval';
import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createExtrasStepSpecificPropertiesSlice = (set, _get) => ({
    handleActivateExtra: (extra) => {
        set((state) => {
            for (let i = 0; i < state.selectedValues.selectedIntervals.length; i++) {
                const selectedInterval = state.selectedValues.selectedIntervals[i];
                const extraReservableInterval = extra.intervals.find((extraInterval) => areIntervalsTheSame(extraInterval, selectedInterval));
                if (extraReservableInterval) {
                    if (extra.withCapacity) {
                        const availableCapacity = getExtraAvailableCapacityForInterval({
                            entities: state.remoteMasterData.entities,
                            extra,
                            extraReservableInterval,
                        });
                        if (availableCapacity > 0) {
                            state.selectedValues.selectedIntervals[i].selectedExtras.push({
                                extraId: extra._id,
                                amount: 1,
                            });
                        }
                    }
                    else {
                        state.selectedValues.selectedIntervals[i].selectedExtras.push({
                            extraId: extra._id,
                            amount: 1,
                        });
                    }
                }
            }
        });
    },
    handleDeactivateExtra: (extra) => {
        set((state) => {
            for (let i = 0; i < state.selectedValues.selectedIntervals.length; i++) {
                if (extra.intervals.some((extraInterval) => {
                    const selectedInterval = state.selectedValues.selectedIntervals[i];
                    return areIntervalsTheSame(extraInterval, selectedInterval);
                })) {
                    const filteredExtras = state.selectedValues.selectedIntervals[i].selectedExtras.filter((se) => se.extraId !== extra._id);
                    state.selectedValues.selectedIntervals[i].selectedExtras =
                        filteredExtras;
                }
            }
        });
    },
    handleExtrasCountChange: (extra, action) => {
        set((state) => {
            state.selectedValues.selectedIntervals.forEach((selectedInterval) => {
                const foundSelectedExtra = selectedInterval.selectedExtras.find((se) => se.extraId === extra._id);
                const extraReservableInterval = extra.intervals.find((extraInterval) => areIntervalsTheSame(extraInterval, selectedInterval));
                const availableCapacity = getExtraAvailableCapacityForInterval({
                    entities: state.remoteMasterData.entities,
                    extra,
                    extraReservableInterval,
                });
                if (action === 'increment') {
                    if (!foundSelectedExtra) {
                        if (extra.withCapacity) {
                            if (availableCapacity > 0) {
                                selectedInterval.selectedExtras.push({
                                    extraId: extra._id,
                                    amount: 1,
                                });
                            }
                        }
                        else {
                            selectedInterval.selectedExtras.push({
                                extraId: extra._id,
                                amount: 1,
                            });
                        }
                    }
                    else {
                        if (foundSelectedExtra.amount < extra.maxUnits) {
                            if (extra.withCapacity) {
                                if (foundSelectedExtra.amount < availableCapacity) {
                                    foundSelectedExtra.amount++;
                                }
                            }
                            else {
                                foundSelectedExtra.amount++;
                            }
                        }
                    }
                }
                if (action === 'decrement') {
                    if (foundSelectedExtra && foundSelectedExtra.amount === 1) {
                        const toBeDeletedIndex = selectedInterval.selectedExtras.findIndex((selectedExtra) => selectedExtra.extraId === extra._id);
                        if (toBeDeletedIndex > -1) {
                            selectedInterval.selectedExtras.splice(toBeDeletedIndex, 1);
                        }
                    }
                    else if (foundSelectedExtra && foundSelectedExtra.amount > 1) {
                        foundSelectedExtra.amount--;
                    }
                }
            });
        });
    },
    handleExtraIntervalToggle: (extra, reservableInterval, isSelected, availableCapacity) => {
        set((state) => {
            const relevantResevedInterval = state.selectedValues.selectedIntervals.find((selectedInterval) => {
                return areIntervalsTheSame(selectedInterval, reservableInterval);
            });
            if (!relevantResevedInterval.selectedExtras) {
                relevantResevedInterval.selectedExtras = [];
            }
            if (!isSelected && (!extra.withCapacity || availableCapacity > 0)) {
                relevantResevedInterval.selectedExtras.push({
                    extraId: extra._id,
                    amount: 1,
                });
            }
            if (isSelected) {
                const toBeDeletedIndex = relevantResevedInterval.selectedExtras.findIndex((selectedExtra) => selectedExtra.extraId === extra._id);
                if (toBeDeletedIndex > -1) {
                    relevantResevedInterval.selectedExtras.splice(toBeDeletedIndex, 1);
                }
            }
        });
    },
    handleExtraIntervalCounterChange: (extra, reservableInterval, isSelected, resultNumber, availableCapacity) => {
        set((state) => {
            const relevantResevedInterval = state.selectedValues.selectedIntervals.find((selectedInterval) => {
                return areIntervalsTheSame(selectedInterval, reservableInterval);
            });
            if (!isSelected && resultNumber < 1) {
                return;
            }
            if (!relevantResevedInterval.selectedExtras) {
                relevantResevedInterval.selectedExtras = [];
            }
            if (!isSelected && resultNumber > 0 && resultNumber <= extra.maxUnits) {
                if (extra.withCapacity) {
                    if (resultNumber <= availableCapacity) {
                        relevantResevedInterval.selectedExtras.push({
                            extraId: extra._id,
                            amount: resultNumber,
                        });
                    }
                }
                else {
                    relevantResevedInterval.selectedExtras.push({
                        extraId: extra._id,
                        amount: resultNumber,
                    });
                }
            }
            if (isSelected && resultNumber < 1) {
                const toBeDeletedIndex = relevantResevedInterval.selectedExtras.findIndex((selectedExtra) => selectedExtra.extraId === extra._id);
                if (toBeDeletedIndex > -1) {
                    relevantResevedInterval.selectedExtras.splice(toBeDeletedIndex, 1);
                }
            }
            const relevantSelectedExtra = relevantResevedInterval === null || relevantResevedInterval === void 0 ? void 0 : relevantResevedInterval.selectedExtras.find((se) => se.extraId === extra._id);
            if (isSelected && resultNumber >= 1 && resultNumber <= extra.maxUnits) {
                if (extra.withCapacity) {
                    if (resultNumber <= availableCapacity) {
                        relevantSelectedExtra.amount = resultNumber;
                    }
                }
                else {
                    relevantSelectedExtra.amount = resultNumber;
                }
            }
        });
    },
    openExtras: {},
    setOpenExtras: (openExtras, extraDevtoolsInfo) => set((state) => {
        state.extrasStepSpecific.openExtras = openExtras;
    }, false, getDevtoolsLogMessage('setOpenExtras', extraDevtoolsInfo)),
    selectedExtras: [],
    setSelectedExtras: (selectedExtras, extraDevtoolsInfo) => set((state) => {
        state.extrasStepSpecific.selectedExtras = selectedExtras;
    }, false, getDevtoolsLogMessage('setSelectedExtras', extraDevtoolsInfo)),
});

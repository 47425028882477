import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
export const Tooltip = ({ delay = 0, children, content, direction = 'top', contentClassName, wrapperClassName, arrowClassName, onOpen, onClose, trigger = 'click', }) => {
    let timeout;
    const child = React.Children.only(children);
    const [active, setActive] = useState(false);
    const showTip = () => {
        timeout = setTimeout(() => {
            onOpen === null || onOpen === void 0 ? void 0 : onOpen();
            setActive(true);
        }, delay);
    };
    const toggleTip = () => {
        timeout = setTimeout(() => {
            onOpen === null || onOpen === void 0 ? void 0 : onOpen();
            setActive((prev) => {
                if (!prev === true) {
                    onOpen === null || onOpen === void 0 ? void 0 : onOpen();
                }
                return !prev;
            });
        }, delay);
    };
    const hideTip = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        clearTimeout(timeout);
        setActive(false);
    };
    const triggerElement = React.cloneElement(child, Object.assign(Object.assign({}, child.props), { onMouseEnter: trigger === 'hover' ? showTip : undefined, onMouseLeave: trigger === 'hover' ? hideTip : undefined, onFocus: trigger === 'hover' ? toggleTip : undefined, onBlur: trigger !== 'none' ? hideTip : undefined, onClick: trigger === 'click' ? toggleTip : undefined }));
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: direction,
        modifiers: [
            {
                name: 'offset',
                enabled: true,
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "tooltip-wrapper", ref: setReferenceElement }, triggerElement),
        ReactDOM.createPortal(React.createElement(AnimatePresence, { mode: "wait" }, active && (React.createElement(motion.div, Object.assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, ref: setPopperElement, style: styles.popper }, attributes.popper, { className: classNames('popper-arrow-conainer max-w-sm', wrapperClassName), "data-popper-placement": direction }),
            React.createElement("div", { className: classNames('tooltip-content', contentClassName) }, content),
            React.createElement("div", { className: classNames('', arrowClassName), style: styles.arrow, "data-popper-arrow": true, id: "arrow" })))), document.body)));
};

import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import { EntityCard, ENTITY_CARD_SPACE_BETWEEN, ENTITY_CARD_WIDTH, } from './entity-card';
import { throttle, EntityStatuses, Icon, useTranslationsStore, Button, Modal, Slider, getPeriodPropertyValueForDate, isLastIndex, } from '@reservation-app/common-used-in-web';
import { AnimatePresence, motion } from 'framer-motion';
import { useStepperStateSlice, useSelectedValuesSlice, usePeriodAndEntityStepSpecificSlice, useEntitiesWithStatus, } from '../../store';
export const ENTITY_LIST_SCROLL_UNIT = 250;
export const SelectEntityList = () => {
    var _a, _b, _c;
    const { activeLanguage, getTranslation } = useTranslationsStore();
    const { defaultDate } = useStepperStateSlice();
    const { selectedEntityType, selectedEntity } = useSelectedValuesSlice();
    const entitiesWithStatus = useEntitiesWithStatus();
    const { isBirdEyeModalOpen, setIsBirdEyeModalOpen, showAvailableEntitiesOnly, isEntityGalleryModalOpen, setIsEntityGalleryModalOpen, entityGalleryModalSelectedEntity, lastEntitySelectedAt, } = usePeriodAndEntityStepSpecificSlice();
    const [areArrowsDisabled, setAreArrowsDisabled] = useState(false);
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
    const [isRightArrowVisible, setIsRightArrowVisible] = useState(false);
    const [galleryActiveIndex, setGalleryActiveIndex] = useState(0);
    const entityListRef = useRef(null);
    const handleScrollScrollArrowClick = (direction) => {
        const entityList = entityListRef.current;
        const entityListScrollPosition = entityListRef.current.scrollLeft;
        if (direction === 'right') {
            entityList.scrollTo({
                top: 0,
                left: entityListScrollPosition + ENTITY_LIST_SCROLL_UNIT,
                behavior: 'smooth',
            });
        }
        if (direction === 'left') {
            entityList.scrollTo({
                top: 0,
                left: entityListScrollPosition - ENTITY_LIST_SCROLL_UNIT,
                behavior: 'smooth',
            });
        }
    };
    const handleEntityListScroll = useCallback(throttle(() => {
        var _a, _b, _c, _d;
        if (((_a = entityListRef === null || entityListRef === void 0 ? void 0 : entityListRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) === 0) {
            setIsLeftArrowVisible(false);
        }
        else if (isLeftArrowVisible === false) {
            setIsLeftArrowVisible(true);
        }
        if (Math.ceil((_b = entityListRef === null || entityListRef === void 0 ? void 0 : entityListRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) ===
            ((_c = entityListRef === null || entityListRef === void 0 ? void 0 : entityListRef.current) === null || _c === void 0 ? void 0 : _c.scrollWidth) -
                ((_d = entityListRef === null || entityListRef === void 0 ? void 0 : entityListRef.current) === null || _d === void 0 ? void 0 : _d.clientWidth)) {
            setIsRightArrowVisible(false);
        }
        else if (isRightArrowVisible === false) {
            setIsRightArrowVisible(true);
        }
    }, 100), [isLeftArrowVisible, isRightArrowVisible]);
    const entitiesToList = useMemo(() => entitiesWithStatus
        .filter((entity) => {
        if (!showAvailableEntitiesOnly) {
            return true;
        }
        return entity.status
            ? entity.status === EntityStatuses.AVAILABLE
            : true;
    })
        .sort((a, b) => a.displayOrder - b.displayOrder), [entitiesWithStatus, showAvailableEntitiesOnly]);
    useEffect(() => {
        if (selectedEntity &&
            lastEntitySelectedAt === 'interactive-birdeye-image') {
            const entityList = entityListRef.current;
            const entityPosition = entitiesToList.findIndex((entity) => entity._id === selectedEntity._id);
            const positionToScrollTo = entityPosition * (ENTITY_CARD_WIDTH + ENTITY_CARD_SPACE_BETWEEN);
            entityList.scrollTo({
                top: 0,
                left: positionToScrollTo,
                behavior: 'smooth',
            });
        }
    }, [selectedEntity]);
    useEffect(() => {
        if (entityListRef.current &&
            entityListRef.current.scrollWidth > entityListRef.current.clientWidth &&
            entitiesToList.length > 0) {
            setIsRightArrowVisible(true);
        }
    }, [entitiesToList]);
    const birdEyeImageValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: selectedEntityType.uiProps.birdEyeImage,
        date: defaultDate,
    });
    const galleryImages = useMemo(() => {
        var _a;
        return ((_a = entityGalleryModalSelectedEntity === null || entityGalleryModalSelectedEntity === void 0 ? void 0 : entityGalleryModalSelectedEntity.uiProps) === null || _a === void 0 ? void 0 : _a.images)
            ? [...entityGalleryModalSelectedEntity.uiProps.images]
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((image) => (React.createElement("img", { src: image.url, className: "w-full", key: image._id })))
            : [];
    }, [entityGalleryModalSelectedEntity]);
    if (entitiesWithStatus.length === 1) {
        return null;
    }
    return (React.createElement("div", { className: "flex flex-col items-start" },
        React.createElement("div", { ref: entityListRef, onScroll: handleEntityListScroll, className: "max-w-full overflow-x-auto" },
            React.createElement("div", { className: classNames({
                    'flex space-x-4 p-2 -ml-2 !pt-0': entitiesToList.length > 0,
                    'h-[391px] flex justify-center items-center w-full mt-2 text-gray-900': !(entitiesToList.length > 0),
                }) }, entitiesToList.length > 0
                ? entitiesToList.map((entity, index) => (React.createElement(EntityCard, { key: entity._id, entity: entity, testId: entity._id, isLast: isLastIndex(index, entitiesToList) })))
                : getTranslation('noAvailableEntitiesToShow', {
                    entities: (_a = selectedEntityType.uiProps.translations.entities) === null || _a === void 0 ? void 0 : _a[activeLanguage],
                }))),
        React.createElement("div", { className: "relative w-full h-0" },
            React.createElement(AnimatePresence, null, isLeftArrowVisible && (React.createElement(motion.div, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween', duration: 0.2 }, className: "absolute top-[-215px] left-[-10px] 640:top-[-220px] 640:left-[-23px] flex z-10 justify-center items-center cursor-pointer p-1.5 640:p-2 bg-gray-200 bg-opacity-80 text-gray-700 rounded-full", onClick: () => {
                    if (!areArrowsDisabled) {
                        setAreArrowsDisabled(true);
                        handleScrollScrollArrowClick('left');
                        setTimeout(() => setAreArrowsDisabled(false), 200);
                    }
                } },
                React.createElement(Icon, { icon: "chevronLeft", className: "w-6 h-6 640:w-7 640:h-7" })))),
            React.createElement(AnimatePresence, null, isRightArrowVisible && (React.createElement(motion.div, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { type: 'tween', duration: 0.2 }, className: "absolute top-[-215px] right-[-10px] 640:top-[-220px] 640:right-[-23px] flex z-10 justify-center items-center cursor-pointer p-1.5 640:p-2 bg-gray-200 text-gray-700 bg-opacity-80 rounded-full", onClick: () => {
                    if (!areArrowsDisabled) {
                        setAreArrowsDisabled(true);
                        handleScrollScrollArrowClick('right');
                        setTimeout(() => setAreArrowsDisabled(false), 200);
                    }
                } },
                React.createElement(Icon, { icon: "chevronRight", className: "w-6 h-6 640:w-7 640:h-7" }))))),
        React.createElement(Modal, { modalClassName: "p-0", overlayClassName: "p-0 768:p-12", autoFocus: false, restoreFocus: false, isOpen: isBirdEyeModalOpen, onClose: () => setIsBirdEyeModalOpen(false) },
            React.createElement("img", { className: "768:rounded-lg", src: birdEyeImageValue === null || birdEyeImageValue === void 0 ? void 0 : birdEyeImageValue.url, alt: "" })),
        React.createElement(Modal, { modalClassName: "p-0 bg-transparent w-full", overlayClassName: "p-0", isOpen: isEntityGalleryModalOpen, onClose: () => {
                setIsEntityGalleryModalOpen(false);
                setTimeout(() => {
                    setGalleryActiveIndex(0);
                }, 200);
            }, restoreFocus: false, autoFocus: false, overlayChildren: (entityGalleryModalSelectedEntity === null || entityGalleryModalSelectedEntity === void 0 ? void 0 : entityGalleryModalSelectedEntity.uiProps.birdEyeImageIndex) !==
                undefined &&
                !((_c = (_b = selectedEntityType.interactiveBirdEyeViewSvg) === null || _b === void 0 ? void 0 : _b.url) === null || _c === void 0 ? void 0 : _c[activeLanguage]) ? (React.createElement(Button, { size: "sm", className: "fixed top-7 left-9", onClick: (event) => {
                    event.stopPropagation();
                    setGalleryActiveIndex(entityGalleryModalSelectedEntity.uiProps.birdEyeImageIndex <=
                        galleryImages.length
                        ? (entityGalleryModalSelectedEntity === null || entityGalleryModalSelectedEntity === void 0 ? void 0 : entityGalleryModalSelectedEntity.uiProps.birdEyeImageIndex) - 1
                        : galleryImages.length - 1);
                } }, getTranslation('goToBirdEyeImage'))) : undefined },
            React.createElement(Slider, { itemsToScroll: 1, itemsToShow: 1, mainContainerClassName: "w-full 768:max-w-5xl mx-auto", slidesContainerClassName: "768:rounded-lg", withArrows: true, arrowPosition: "dots", onChange: (newIndex) => setGalleryActiveIndex(newIndex), activeIndex: galleryActiveIndex, breakpoints: [
                    {
                        minWidth: 768,
                        config: {
                            itemsToScroll: 1,
                            itemsToShow: 1,
                            withArrows: true,
                            arrowPosition: 'slider',
                            leftArrowClassName: 'mr-2',
                            rightArrowClassName: 'ml-2',
                            onChange: (newIndex) => setGalleryActiveIndex(newIndex),
                        },
                    },
                ] }, galleryImages))));
};

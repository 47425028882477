import React from 'react';
import classNames from 'classnames';
import { FadeInAnimation } from '../fade-in-animation';
import { isLastIndex, Icon } from '../../../';
const TableCardsSection = ({ items, activeLanguage, shouldDisableAnimation, }) => {
    const sortedItems = [...items].sort((a, b) => {
        return a.displayOrder - b.displayOrder;
    });
    return (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "flex flex-col items-center" }, sortedItems.map((item, itemIndex) => {
        var _a;
        return (React.createElement("div", { key: itemIndex, className: classNames('flex flex-col items-center', {
                'mb-8': !isLastIndex(itemIndex, items),
            }) },
            React.createElement("div", { className: classNames('flex flex-col w-full') },
                ((_a = item.text) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("h2", { className: "flex w-full items-center justify-center text-xl text-gray-700 font-semibold mb-6 pb-2 border-b-2 border-gray-300 max-w-xl self-center text-center" },
                    item.icon && (React.createElement(Icon, { icon: item.icon, className: "mr-2" })),
                    item.text[activeLanguage])),
                React.createElement("div", { className: "flex flex-wrap justify-center items-stretch" }, item.cards.map((card, cardIndex) => {
                    var _a, _b;
                    return (React.createElement("div", { key: `${itemIndex}-${cardIndex}`, className: "rounded shadow-lg bg-white p-4 flex flex-col items-center max-w-md mx-3 mb-6" },
                        React.createElement("h3", { className: "flex flex-col items-center text-gray-900 font-semibold mb-5 text-lg text-center" },
                            card.icon && (React.createElement(Icon, { icon: card.icon, className: "mb-1" })),
                            card.headingText[activeLanguage]),
                        ((_a = card.descriptionHtml) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("div", { className: "text-center text-gray-900 mb-auto", dangerouslySetInnerHTML: {
                                __html: card.descriptionHtml[activeLanguage],
                            } })),
                        ((_b = card.price) === null || _b === void 0 ? void 0 : _b[activeLanguage]) && (React.createElement("p", { className: "bg-primary-main text-white font-semibold mt-4 rounded px-1 text-center" }, card.price[activeLanguage]))));
                })))));
    })));
};
export default TableCardsSection;

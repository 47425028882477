import React from 'react';
import { StepWrapper } from '../../layout/step-wrapper';
import { AnimatePresence, motion } from 'framer-motion';
import { Calendar } from './calendar';
import { ReservableIntervals } from './reservable-intervals';
import { ReservationSteps, AlertTypes, Alert, ReservationSubSteps, } from '@reservation-app/common-used-in-web';
import { useSelectedValuesSlice, useStepperStateSlice, usePeriodAndEntityStepSpecificSlice, useRemoteMasterDataSlice, } from '../../store';
export const SelectPeriod = ({ step }) => {
    const { alerts } = useRemoteMasterDataSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const { currentStep } = useStepperStateSlice();
    const { isReservableIntervalsListShowing } = usePeriodAndEntityStepSpecificSlice();
    const isSelectPeriodAndEntityStep = currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY;
    const DESKTOP_INTERVALS_ANIMATION_DURATION_IN_MS = 300;
    return (React.createElement(StepWrapper, { withAvailabilityInfoTooltip: true, wrapperClassName: isSelectPeriodAndEntityStep ? '!pb-0' : '', step: step, subStep: ReservationSubSteps.SELECT_PERIOD },
        React.createElement("div", { className: "flex flex-col 1280:flex-row" },
            React.createElement(Calendar, { className: "rounded-lg shadow-lg max-w-sm overflow-hidden 1280:flex-grow\npt-1 pb-2 px-2 1280:pt-2 1280:pb-4 1280:px-4 1280:w-1/2" }),
            React.createElement("div", { className: "hidden 1280:flex w-5 h-5" }),
            React.createElement(AnimatePresence, null, isReservableIntervalsListShowing && (React.createElement(motion.div, { className: "flex w-full max-w-sm 1280:flex-grow 1280:w-1/2 1280:mt-0 mt-5", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: {
                    type: 'tween',
                    duration: DESKTOP_INTERVALS_ANIMATION_DURATION_IN_MS / 1000,
                } },
                React.createElement(ReservableIntervals, null))))),
        React.createElement(Alert, { alerts: alerts, isReservationFlow: true, selectedEntityType: selectedEntityType, alertType: AlertTypes.PRICES, className: "mt-6 640:!max-w-sm", iconClassName: "bg-gray-50" }),
        React.createElement(Alert, { alerts: alerts, isReservationFlow: true, selectedEntityType: selectedEntityType, alertType: AlertTypes.SCHEDULE, className: "mt-6 640:!max-w-sm", iconClassName: "bg-gray-50" })));
};

import { useEffect } from 'react';
import { useWebSpecificSlice } from '../store';
export const useIsDesktop = () => {
    const { isDesktop, setIsDesktop } = useWebSpecificSlice();
    useEffect(() => {
        const width = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (width > 768) {
            setIsDesktop(true);
        }
        else {
            setIsDesktop(false);
        }
    }, []);
    const onWindowResize = () => {
        if (!isDesktop && window.innerWidth > 768) {
            setIsDesktop(true);
        }
        else if (isDesktop && window.innerWidth <= 768) {
            setIsDesktop(false);
        }
    };
    if (typeof window !== 'undefined') {
        window.onresize = onWindowResize;
    }
};

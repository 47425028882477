var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useBillingDetailsStepSpecificSlice, BILLING_DETAILS_LS_KEY, useStaticPropsSlice, } from '../store';
export const useBillingDetailsCache = () => {
    const { setCachedBillingDetails, replaceBillingDetails } = useBillingDetailsStepSpecificSlice();
    const { isAdminPanel } = useStaticPropsSlice();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!isAdminPanel) {
                const cachedBillingDetails = localStorage.getItem(BILLING_DETAILS_LS_KEY);
                if (cachedBillingDetails) {
                    const parsedCachedBillingDetails = JSON.parse(cachedBillingDetails);
                    const cachedBillingDetailsArray = Object.values(parsedCachedBillingDetails);
                    cachedBillingDetailsArray.sort((a, b) => b.updatedAt - a.updatedAt);
                    setCachedBillingDetails(cachedBillingDetailsArray);
                    if (typeof cachedBillingDetailsArray[0] === 'object') {
                        replaceBillingDetails(cachedBillingDetailsArray[0]);
                    }
                }
                else {
                    setCachedBillingDetails([]);
                }
            }
        }))();
    }, []);
};

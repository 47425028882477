import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createPaymentDetailsStepSpecificPropertiesSlice = (set, _get) => ({
    paymentIntentClientSecret: '',
    setPaymentIntentClientSecret: (paymentIntentClientSecret, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.paymentIntentClientSecret =
            paymentIntentClientSecret;
    }, false, getDevtoolsLogMessage('setPaymentIntentClientSecret', extraDevtoolsInfo)),
    stripeInstance: null,
    setStripeInstance: (stripeInstance, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.stripeInstance = stripeInstance;
    }, false, getDevtoolsLogMessage('setStripeInstance', extraDevtoolsInfo)),
    stripeClient: null,
    setStripeClient: (stripeClient, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.stripeClient = stripeClient;
    }, false, getDevtoolsLogMessage('setStripeClient', extraDevtoolsInfo)),
    stripeElements: null,
    setStripeElements: (stripeElements, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.stripeElements = stripeElements;
    }, false, getDevtoolsLogMessage('setStripeElements', extraDevtoolsInfo)),
    stripeCardNumberElement: null,
    setStripeCardNumberElement: (stripeCardNumberElement, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.stripeCardNumberElement =
            stripeCardNumberElement;
    }, false, getDevtoolsLogMessage('setStripeCardNumberElement', extraDevtoolsInfo)),
    handlePayment: null,
    setHandlePayment: (handlePayment, extraDevtoolsInfo) => set((state) => {
        state.paymentDetailsStepSpecific.handlePayment = handlePayment;
    }, false, getDevtoolsLogMessage('setHandlePayment', extraDevtoolsInfo)),
});

import { useRunOnFirstRender } from '@reservation-app/common-used-in-web';
import { useStaticSlice } from '../store';
export const useSetStaticProps = (staticProps) => {
    const { setStaticProps } = useStaticSlice();
    useRunOnFirstRender(() => {
        setStaticProps({
            apiClient: staticProps.apiClient,
            defaultLanguage: staticProps.defaultLanguage,
            toastManager: staticProps.toastManager,
            isAdminPanel: staticProps.isAdminPanel,
            isVerifyAvailabilityMode: staticProps.isVerifyAvailabilityMode,
            onLeaveReservationFlowClick: staticProps.onLeaveReservationFlowClick,
            useWindowFocus: staticProps.useWindowFocus,
            pageUrl: staticProps.pageUrl,
            rulesHtml: staticProps.rulesHtml,
            pricesSectionProps: staticProps.pricesSectionProps,
            schedulesSectionProps: staticProps.schedulesSectionProps,
            TermsAndConditionsComponent: staticProps.TermsAndConditionsComponent,
            components: {
                error: staticProps.components.error,
                loading: staticProps.components.loading,
                headerWrapper: staticProps.components.headerWrapper,
            },
            componentProps: staticProps.componentProps,
        });
    });
};

import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createPeriodAndEntityStepSpecificPropertiesSlice = (set, _get) => ({
    previousSelectedDate: undefined,
    setPreviousSelectedDate: (previousSelectedDate, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.previousSelectedDate =
            previousSelectedDate;
    }, false, getDevtoolsLogMessage('setPreviousSelectedDate', extraDevtoolsInfo)),
    selectedDate: undefined,
    setSelectedDate: (selectedDate, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.previousSelectedDate =
            state.periodAndEntityStepSpecific.selectedDate;
        state.periodAndEntityStepSpecific.selectedDate = selectedDate;
    }, false, getDevtoolsLogMessage('setSelectedDate', extraDevtoolsInfo)),
    numberOfPersonsOptions: [],
    setNumberOfPersonsOptions: (numberOfPersonsOptions, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.numberOfPersonsOptions =
            numberOfPersonsOptions;
    }, false, getDevtoolsLogMessage('setNumberOfPersonsOptions', extraDevtoolsInfo)),
    isReservableIntervalsListShowing: false,
    setIsReservableIntervalsListShowing: (isReservableIntervalsListShowing, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.isReservableIntervalsListShowing =
            isReservableIntervalsListShowing;
    }, false, getDevtoolsLogMessage('setIsReservableIntervalsListShowing', extraDevtoolsInfo)),
    isBirdEyeModalOpen: false,
    setIsBirdEyeModalOpen: (isBirdEyeModalOpen, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.isBirdEyeModalOpen =
            isBirdEyeModalOpen;
    }, false, getDevtoolsLogMessage('setIsBirdEyeModalOpen', extraDevtoolsInfo)),
    showAvailableEntitiesOnly: false,
    setShowAvailableEntitiesOnly: (showAvailableEntitiesOnly, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.showAvailableEntitiesOnly =
            showAvailableEntitiesOnly;
    }, false, getDevtoolsLogMessage('setShowAvailableEntitiesOnly', extraDevtoolsInfo)),
    shouldShowPriceInModalIfThereIsNoEntitySelected: false,
    setShouldShowPriceInModalIfThereIsNoEntitySelected: (shouldShowPriceInModalIfThereIsNoEntitySelected, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.shouldShowPriceInModalIfThereIsNoEntitySelected =
            shouldShowPriceInModalIfThereIsNoEntitySelected;
    }, false, getDevtoolsLogMessage('setShouldShowPriceInModalIfThereIsNoEntitySelected', extraDevtoolsInfo)),
    handleShowOnlyAvailableEntitiesChange: (value) => set((state) => {
        state.periodAndEntityStepSpecific.showAvailableEntitiesOnly = value;
        localStorage.setItem(`showOnlyAvailableEntities-${state.selectedValues.selectedEntityType._id}`, `${value}`);
    }),
    showAvailableIntervalsOnly: false,
    setShowAvailableInvervalsOnly: (showAvailableIntervalsOnly, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.showAvailableIntervalsOnly =
            showAvailableIntervalsOnly;
    }, false, getDevtoolsLogMessage('setShowAvailableInvervalsOnly', extraDevtoolsInfo)),
    handleShowOnlyAvailableIntervalsChange: (value) => set((state) => {
        state.periodAndEntityStepSpecific.showAvailableIntervalsOnly = value;
        localStorage.setItem(`showOnlyAvailableIntervals-${state.selectedValues.selectedEntityType._id}`, `${value}`);
    }),
    isEntityGalleryModalOpen: false,
    setIsEntityGalleryModalOpen: (isEntityGalleryModalOpen, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.isEntityGalleryModalOpen =
            isEntityGalleryModalOpen;
    }, false, getDevtoolsLogMessage('setIsBirdEyeModalOpen', extraDevtoolsInfo)),
    entityGalleryModalSelectedEntity: null,
    setEntityGalleryModalSelectedEntity: (entityGalleryModalSelectedEntity, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.entityGalleryModalSelectedEntity =
            entityGalleryModalSelectedEntity;
    }, false, getDevtoolsLogMessage('setIsBirdEyeModalOpen', extraDevtoolsInfo)),
    shouldAnimateBottomDrawerHandle: false,
    setShouldAnimateBottomDrawerHandle: (shouldAnimateBottomDrawerHandle, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.shouldAnimateBottomDrawerHandle =
            shouldAnimateBottomDrawerHandle;
    }, false, getDevtoolsLogMessage('setShouldAnimateBottomDrawerHandle', extraDevtoolsInfo)),
    shouldAnimateTootlipIcon: false,
    setShouldAnimateTootlipIcon: (shouldAnimateTootlipIcon, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.shouldAnimateTootlipIcon =
            shouldAnimateTootlipIcon;
    }, false, getDevtoolsLogMessage('setShouldAnimateTootlipIcon', extraDevtoolsInfo)),
    lastEntitySelectedAt: null,
    setLastEntitySelectedAt: (lastEntitySelectedAt, extraDevtoolsInfo) => set((state) => {
        state.periodAndEntityStepSpecific.lastEntitySelectedAt =
            lastEntitySelectedAt;
    }, false, getDevtoolsLogMessage('setLastEntitySelectedAt', extraDevtoolsInfo)),
});

import { isDevEnv } from '@reservation-app/common-used-in-web';
export const getDevtoolsLogMessage = (actionName, extraDevtoolsInfo) => {
    if (!isDevEnv) {
        return actionName;
    }
    let appendToActionName = '';
    if (extraDevtoolsInfo !== undefined) {
        if (extraDevtoolsInfo === null) {
            appendToActionName = 'null';
        }
        if (typeof extraDevtoolsInfo === 'string' ||
            typeof extraDevtoolsInfo === 'number') {
            appendToActionName = String(extraDevtoolsInfo);
        }
        if (typeof extraDevtoolsInfo === 'object') {
            appendToActionName = JSON.stringify(extraDevtoolsInfo);
        }
    }
    return `${actionName}${appendToActionName ? ' | ' : ''}${appendToActionName}`;
};

import React, { useEffect } from 'react';
import { ModalWrapper } from './modal-wrapper';
import * as Sentry from '@sentry/react';
import { zIndexes, ReservationSteps, Button, SELECTED_COMPANY_NAME_LS_KEY, useGetTranslation, Modal, } from '@reservation-app/common-used-in-web';
import { useStepperStateSlice, useSelectedValuesSlice, useModalStatesDataSlice, } from '../store';
export const RestrictionErrorModal = () => {
    const getTranslation = useGetTranslation();
    const { setCurrentStep, bottomSheetSnapTo } = useStepperStateSlice();
    const { selectedIntervals, selectedEntity, selectedService, removeSelectedIntervals, } = useSelectedValuesSlice();
    const { isRestrictionErrorModalOpen, setIsRestrictionErrorModalOpen, restrictionErrorModalMessage, restrictionErrorModalSelectedInterval, } = useModalStatesDataSlice();
    useEffect(() => {
        if (isRestrictionErrorModalOpen) {
            try {
                Sentry.captureMessage('RestrictionErrorModal opened', {
                    level: 'info',
                    extra: {
                        selectedIntervals: JSON.stringify(selectedIntervals, null, 2),
                        selectedEntity: JSON.stringify(selectedEntity, null, 2),
                        selectedService: JSON.stringify(selectedService, null, 2),
                        companyName: process.env.GATSBY_COMPANY_NAME ||
                            localStorage.getItem(SELECTED_COMPANY_NAME_LS_KEY) ||
                            'UNKNOWN',
                    },
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }, [isRestrictionErrorModalOpen]);
    const onModalClose = () => {
        setIsRestrictionErrorModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['RESTRICTION_ERROR_MODAL'], isOpen: isRestrictionErrorModalOpen, onClose: onModalClose, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "text-white bg-warning-main", onClose: onModalClose },
            React.createElement("div", { "data-test-id": "restriction-error-modal", className: "p-4" },
                React.createElement("p", { className: "mb-5 font-semibold text-gray-900" }, getTranslation('cannotRemoveIntervalWithoutGoingBack')),
                React.createElement("p", { className: "mb-5 text-gray-900" }, restrictionErrorModalMessage),
                React.createElement("div", { className: "flex justify-end" },
                    React.createElement(Button, { testId: "restriction-error-modal-back-to-intervals-button", onClick: () => {
                            setIsRestrictionErrorModalOpen(false);
                            setCurrentStep(ReservationSteps.SELECT_PERIOD_AND_ENTITY);
                            setTimeout(() => {
                                removeSelectedIntervals([
                                    restrictionErrorModalSelectedInterval,
                                ]);
                                bottomSheetSnapTo === null || bottomSheetSnapTo === void 0 ? void 0 : bottomSheetSnapTo(0);
                            }, 300);
                        }, color: "warning", size: "md", className: "w-full restriction-error-modal-go-back-to-intervals-step-button" }, getTranslation('goBackToIntervalsStep')))))));
};

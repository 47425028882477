export const throttle = (callBack, delay = 1000) => {
    let shouldWait = false;
    let waitingArgs;
    const timeoutFn = () => {
        if (waitingArgs === null) {
            shouldWait = false;
        }
        else {
            if (waitingArgs) {
                callBack(...waitingArgs);
            }
            else {
                callBack();
            }
            waitingArgs = null;
            setTimeout(timeoutFn, delay);
        }
    };
    return (...args) => {
        if (shouldWait) {
            waitingArgs = args;
            return;
        }
        callBack(...args);
        shouldWait = true;
        setTimeout(timeoutFn, delay);
    };
};

import { getSelectedIntervalsMinutesInfo } from './get-selected-intervals-minutes-info';
import { getReservableIntervalPrice } from './get-reservable-interval-price';
import { getExtraPrice } from './get-extra-price';
import { getTimelessDate, isDateInPeriod, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { getReservableIntervalByIdFromService } from './get-reservable-interval-by-id-from-service';
const getPeriodHash = (period) => {
    return `${period.startDate ? new Date(period.startDate).toISOString() : undefined}--${period.endDate ? new Date(period.endDate).toISOString() : undefined}--${period.excludeStartDate
        ? new Date(period.excludeStartDate).toISOString()
        : undefined}--${period.excludeEndDate
        ? new Date(period.excludeEndDate).toISOString()
        : undefined}`;
};
export const getTotalPriceInfo = ({ order, allServices, }) => {
    const { selectedServiceId, selectedEntityId } = order;
    const selectedService = allServices.find((service) => service._id.toString() === selectedServiceId);
    const selectedIntervals = order.selectedIntervals.map((selectedInterval) => (Object.assign(Object.assign({}, selectedInterval), { reservableInterval: getReservableIntervalByIdFromService({
            service: selectedService,
            reservableIntervalId: selectedInterval.reservableIntervalId,
        }) })));
    const promotionPeriodsMetadata = selectedService.promotions.reduce((result, { period, value }) => {
        if (value.entityIds.includes(selectedEntityId)) {
            result[getPeriodHash(period)] = result[getPeriodHash(period)]
                ? Object.assign(Object.assign({}, result[getPeriodHash(period)]), { promotions: result[getPeriodHash(period)].promotions.concat(value.promotions) }) : {
                period,
                promotions: value.promotions,
                selectedIntervals: [],
            };
        }
        return result;
    }, {});
    let totalAmount = 0;
    selectedIntervals.forEach((selectedInterval) => {
        const priceOfCurrentInterval = getReservableIntervalPrice({
            selectedInterval: {
                price: getPeriodPropertyValueForDate({
                    periodSensitiveProperty: selectedInterval.reservableInterval.price.currentDatePeriods,
                    date: getTimelessDate(new Date()),
                }),
                numberOfPersons: selectedInterval.numberOfPersons,
            },
        });
        let priceOfExtras = 0;
        selectedInterval.selectedExtras.forEach((selectedExtra) => {
            const priceOfCurrentExtra = getExtraPrice({
                selectedInterval: {
                    numberOfPersons: selectedInterval.numberOfPersons,
                    date: selectedInterval.date,
                    extras: selectedInterval.reservableInterval.extras,
                },
                selectedExtra,
            });
            priceOfExtras += priceOfCurrentExtra;
        });
        totalAmount += priceOfCurrentInterval + priceOfExtras;
        Object.values(promotionPeriodsMetadata).forEach((promotionPeriodMetadata) => {
            if (isDateInPeriod({
                date: selectedInterval.date,
                period: promotionPeriodMetadata.period,
            })) {
                promotionPeriodMetadata.selectedIntervals.push(selectedInterval);
            }
        });
    });
    let biggestAppliedPromotion;
    let maxDiscountAmount = 0;
    Object.values(promotionPeriodsMetadata).forEach((promotionPeriodMetadata) => {
        promotionPeriodMetadata.promotions.forEach((promotion) => {
            const { maxNumberOfContinuousMinutes, numberOfMinutes } = getSelectedIntervalsMinutesInfo({
                selectedIntervals: promotionPeriodMetadata.selectedIntervals,
                days: promotion.days.map((day) => ({
                    day,
                    afterMinutes: undefined,
                    beforeMinutes: undefined,
                })),
            });
            const totalMinutes = promotion.mustBeConsecutive
                ? maxNumberOfContinuousMinutes
                : numberOfMinutes;
            if (totalMinutes >= promotion.threshold) {
                const currentPromotionDiscountValue = promotion.value *
                    (promotion.shouldScaleWithNrOfPersons
                        ? promotionPeriodMetadata.selectedIntervals[0].numberOfPersons
                        : 1);
                if (currentPromotionDiscountValue > maxDiscountAmount) {
                    maxDiscountAmount = currentPromotionDiscountValue;
                    biggestAppliedPromotion = promotion;
                }
            }
        });
    });
    const totalAmountBeforeDiscount = totalAmount;
    totalAmount -= maxDiscountAmount;
    return {
        totalAmountBeforeDiscount,
        totalAmount,
        discountAmount: maxDiscountAmount,
        appliedPromotion: biggestAppliedPromotion || null,
        appliedPromotionId: (biggestAppliedPromotion === null || biggestAppliedPromotion === void 0 ? void 0 : biggestAppliedPromotion._id.toString()) || '',
    };
};

import { getTimelessDate, } from '@reservation-app/common-used-in-web';
import { areIntervalsIntersecting } from './are-intervals-intersecting';
import { getEntityCapacity } from './get-entity-capacity';
export const getConflictingIntervals = ({ selectedIntervals, selectedEntity, reservedIntervals, }) => {
    const conflictingSelectedIntervals = [];
    selectedIntervals.forEach((selectedInterval) => {
        if (!selectedEntity.withBulkNumberOfPersonsSelect &&
            (reservedIntervals || []).some((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: getTimelessDate(reservedInterval.date) }, reservedInterval.historicReservableInterval.timeInterval), Object.assign({ date: selectedInterval.date }, selectedInterval.reservableInterval.timeInterval)) && reservedInterval.entityId === selectedEntity._id)) {
            conflictingSelectedIntervals.push(selectedInterval);
        }
        if (selectedEntity.withBulkNumberOfPersonsSelect &&
            selectedInterval.numberOfPersons) {
            const conflictingReservedIntervals = (reservedIntervals || []).filter((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: getTimelessDate(reservedInterval.date) }, reservedInterval.historicReservableInterval.timeInterval), Object.assign({ date: selectedInterval.date }, selectedInterval.reservableInterval.timeInterval)) && reservedInterval.entityId === selectedEntity._id) || [];
            const conflictingNumberOfPersons = conflictingReservedIntervals.reduce((result, reservedInterval) => {
                return result + reservedInterval.numberOfPersons;
            }, 0);
            if (conflictingNumberOfPersons + selectedInterval.numberOfPersons >
                getEntityCapacity({
                    entity: selectedEntity,
                    selectedIntervals,
                })) {
                conflictingSelectedIntervals.push(selectedInterval);
            }
        }
    });
    return conflictingSelectedIntervals;
};

import React from 'react';
import { Icon, useTranslationsStore, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice } from '../reservation-flow/store';
const FindOutMoreSection = () => {
    const { getTranslation } = useTranslationsStore();
    const { setIsPricesSectionModalOpen, setIsSchedulesSectionModalOpen } = useModalStatesDataSlice();
    const findOutMoreCards = [
        {
            title: getTranslation('prices'),
            description: getTranslation('findOutMoreAboutPrices'),
            onClick: () => setIsPricesSectionModalOpen(true),
        },
        {
            title: getTranslation('timetable'),
            description: getTranslation('findOutMoreAboutSchedule'),
            onClick: () => setIsSchedulesSectionModalOpen(true),
        },
    ];
    return (React.createElement("div", { className: "bg-gray-50 w-full h-full" },
        React.createElement("div", { className: "block w-full h-0.5 bg-gray-300 mb-5 rounded-full-left-side mt-5" }),
        React.createElement("div", { className: " mt-10flex flex-col max-w-sm" },
            React.createElement("div", { className: "text-xl font-bold text-gray-900 mb-5" }, getTranslation('findOutMore')),
            findOutMoreCards.map(({ title, description, onClick }) => (React.createElement("button", { key: title, className: "bg-white rounded-md shadow-md p-4 mb-5 hover:cursor-pointer hover:shadow-lg transition-all duration-300", onClick: onClick },
                React.createElement("div", { className: "flex items-center text-gray-900 font-bold text-lg mb-2" },
                    React.createElement("p", { className: "mr-2" }, title),
                    React.createElement(Icon, { icon: "rightArrow", className: "w-5 h-5 stroke-1" })),
                React.createElement("p", { className: "text-left" }, description)))))));
};
export default FindOutMoreSection;

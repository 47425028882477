import { useEffect, useRef } from 'react';
export const useRunOnFirstRender = (beforeFirstRenderFn) => {
    const isBeforeFirstRender = useRef(true);
    if (isBeforeFirstRender.current) {
        beforeFirstRenderFn();
    }
    useEffect(() => {
        if (isBeforeFirstRender.current) {
            isBeforeFirstRender.current = false;
        }
    }, []);
};

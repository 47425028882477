import React from 'react';
import { ModalWrapper } from './modal-wrapper';
import { Button, useGetTranslation, zIndexes, Modal, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice } from '../store';
export const ServerSideErrorModal = () => {
    const getTranslation = useGetTranslation();
    const { isServerSideErrorModalOpen, setIsServerSideErrorModalOpen, serverSideError, } = useModalStatesDataSlice();
    const onModalClose = () => {
        setIsServerSideErrorModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['SERVER_SIDE_ERROR_MODAL'], isOpen: isServerSideErrorModalOpen, withCloseButton: false, onClose: onModalClose, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "text-white bg-error-main", onClose: onModalClose },
            React.createElement("div", { className: "p-4", "data-test-id": "force-refresh-modal" },
                React.createElement("p", { className: "mb-4 text-gray-900" }, serverSideError),
                React.createElement(Button, { onClick: onModalClose, size: "md", className: "w-full force-refresh-modal-reload-page-button btn-danger-outline" }, getTranslation('close'))))));
};

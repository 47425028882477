import React from 'react';
import { useIsDesktop } from './hooks/use-is-desktop';
import { ConfirmLeaveModal } from './modals/confirm-leave-modal';
import { ConflictingReservedIntervalsModal } from './modals/conflicting-reserved-intervals-modal';
import { ForceRefreshModal } from './modals/force-refresh-modal';
import { RestrictionErrorModal } from './modals/restriction-error-modal';
import { useBillingDetailsCache } from './hooks/use-billing-details-cache';
import { useCalculatePrice } from './hooks/use-calculate-price';
import { CurrentStep } from './layout/current-step';
import { useSetStaticProps } from './hooks/use-set-static-props';
import { useRequestAndSetRemoteData } from './hooks/use-request-and-set-remote-data';
import { ContentWrapper } from './layout/content-wrapper';
import { useIsWindowFocused } from './hooks/use-is-window-focused';
import { useForceRefreshCheck } from './hooks/use-force-refresh-check';
import { useLoadStripe } from './hooks/use-load-stripe';
import { useSystemTimeCheck } from './hooks/use-system-time-check';
import { useCleanUpOnUnmount } from './hooks/use-clean-up-on-unmount';
import { HtmlContentModal } from './modals/html-content-modal';
import { ServerSideErrorModal } from './modals/server-side-error-modal';
import { EntityAlreadyReservedSelectedIntervalsModal } from './modals/entity-already-reserved-selected-intervals-modal';
import { WarningTextModal } from './modals/warning-text-modal';
import { useGetTranslation, getServerErrorMessage, } from '@reservation-app/common-used-in-web';
import { PasswordModal } from './modals/password-modal';
import { SchedulesSectionModal } from './modals/schedules-section-modal';
import { PricesSectionModal } from './modals/prices-section-modal';
import { OnboardingModal } from './modals/onboarding-modal';
import { useModalStatesDataSlice } from './store';
export const ReservationFlow = ({ staticProps }) => {
    useCleanUpOnUnmount();
    useSetStaticProps(staticProps);
    const { isLoadingRemoteData, serverFetchError } = useRequestAndSetRemoteData(staticProps.apiClient);
    useSystemTimeCheck();
    useCalculatePrice();
    useIsDesktop();
    useIsWindowFocused();
    useForceRefreshCheck();
    useBillingDetailsCache();
    useLoadStripe();
    const getTranslation = useGetTranslation();
    const { isForceRefreshModalOpen } = useModalStatesDataSlice();
    if (serverFetchError) {
        return (React.createElement(staticProps.components.error, { errorMessage: getServerErrorMessage(serverFetchError, getTranslation) }));
    }
    if (isLoadingRemoteData) {
        return React.createElement(staticProps.components.loading, null);
    }
    return (React.createElement(ContentWrapper, null,
        React.createElement(CurrentStep, null),
        React.createElement(ForceRefreshModal, null),
        !isForceRefreshModalOpen && (React.createElement(React.Fragment, null,
            React.createElement(SchedulesSectionModal, null),
            React.createElement(PricesSectionModal, null),
            React.createElement(ConfirmLeaveModal, null),
            React.createElement(ConflictingReservedIntervalsModal, null),
            React.createElement(EntityAlreadyReservedSelectedIntervalsModal, null),
            React.createElement(HtmlContentModal, null),
            React.createElement(RestrictionErrorModal, null),
            React.createElement(ServerSideErrorModal, null),
            React.createElement(WarningTextModal, null),
            React.createElement(PasswordModal, null),
            React.createElement(OnboardingModal, null)))));
};

import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { zIndexes } from '../../tailwind.config';
export const useDrawerDimensions = (ref) => {
    const dimensions = useRef({ width: 0, height: 0 });
    useEffect(() => {
        dimensions.current.width = ref.current.offsetWidth;
        dimensions.current.height = ref.current.offsetHeight;
    }, []);
    return dimensions.current;
};
export const Path = ({ variants, transition, d, }) => (React.createElement(motion.path, { fill: "transparent", strokeWidth: "3", stroke: "currentColor", strokeLinecap: "round", variants: variants, d: d, transition: transition }));
export const MenuToggle = ({ toggle, className, svgClassName, }) => (React.createElement(motion.button, { className: classNames('flex justify-center items-center outline-none border-none select-none cursor-pointer 4 w-12 h-12 rounded-[50%] bg-transparent text-black', zIndexes['HAMBURGER'], className), onClick: toggle },
    React.createElement("svg", { width: "23", height: "23", viewBox: "0 0 23 23", className: svgClassName },
        React.createElement(Path, { variants: {
                closed: { d: 'M 2 2.5 L 20 2.5' },
                open: { d: 'M 3 16.5 L 17 2.5' },
            } }),
        React.createElement(Path, { d: "M 2 9.423 L 20 9.423", variants: {
                closed: { opacity: 1 },
                open: { opacity: 0 },
            }, transition: { duration: 0.1 } }),
        React.createElement(Path, { variants: {
                closed: { d: 'M 2 16.346 L 20 16.346' },
                open: { d: 'M 3 2.5 L 17 16.346' },
            } }))));
const backdropVarians = {
    visible: { opacity: 1 },
    hidden: { opacity: 0, transition: { delay: 0.5 } },
};
export const Backdrop = ({ isOpen, variants, handleClose, }) => {
    return (React.createElement(motion.div, { className: classNames('drawer-container bg-black bg-opacity-40', { 'pointer-events-none': !isOpen }, zIndexes['DRAWER_BACKDROP']), initial: false, animate: isOpen ? 'visible' : 'hidden', variants: variants ? variants : backdropVarians, onClick: (event) => {
            event.stopPropagation();
            if (handleClose) {
                handleClose();
            }
        } }));
};
export const drawerNavigationVariants = {
    open: {
        overflowY: 'auto',
    },
    closed: {
        overflowY: 'hidden',
        transition: { delay: 0.5 },
    },
};
export const drawerWrapperDivVariants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};
export const drawerMenuItemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};
export const drawerSidebarVariants = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(30px at 350px 0px)',
        transition: {
            delay: 0.5,
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
    },
};
export const DrawerContentWrapper = ({ isOpen, children, }) => {
    const ref = useRef(null);
    const { height } = useDrawerDimensions(ref);
    return (React.createElement(motion.nav, { className: classNames('absolute top-0 right-0 bottom-0 w-80', {
            'pointer-events-none': !isOpen,
        }, zIndexes['DRAWER_CONTENT']), initial: false, animate: isOpen ? 'open' : 'closed', custom: height, ref: ref },
        React.createElement(motion.div, { className: "absolute top-0 right-0 bottom-0 w-80 bg-white h-screen shadow-2xl", variants: drawerSidebarVariants }),
        React.createElement(motion.ul, { variants: drawerNavigationVariants, className: classNames('m-0 p-6 absolute top-14 w-full h-screen', {
                'pointer-events-none': !isOpen,
            }) }, children)));
};

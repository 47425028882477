import { useEffect } from 'react';
import { getNumberOfPersonsOptionsWithStatus } from '../helpers/get-number-of-persons-options-status';
import { useSelectedValuesSlice, usePeriodAndEntityStepSpecificSlice, } from '../store';
export const useSyncNumberOfPersons = () => {
    const { selectedIntervals, selectedEntity, selectedBulkNumberOfPersons, setSelectedBulkNumberOfPersons, } = useSelectedValuesSlice();
    const { selectedDate, setNumberOfPersonsOptions } = usePeriodAndEntityStepSpecificSlice();
    useEffect(() => {
        if (selectedEntity && selectedEntity.withBulkNumberOfPersonsSelect) {
            const numberOfPersonsOptionsWithStatus = getNumberOfPersonsOptionsWithStatus({
                selectedEntity,
                selectedIntervals,
                selectedDate,
            });
            if (selectedBulkNumberOfPersons &&
                selectedBulkNumberOfPersons >
                    numberOfPersonsOptionsWithStatus[numberOfPersonsOptionsWithStatus.length - 1].value) {
                setSelectedBulkNumberOfPersons(undefined);
            }
            setNumberOfPersonsOptions(numberOfPersonsOptionsWithStatus);
        }
    }, [selectedEntity, selectedIntervals, selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime()]);
};

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { useComponentPropsSlice, useComponentsSlice, useIsAdminPanel, useIsCompletionStep, useIsDesktop, useIsNextButtonDisabled, useIsPreviousButtonDisabled, useIsVerifyAvailabilityMode, useModalStatesDataSlice, usePaymentDetailsStepSpecificSlice, useRemoteMasterDataSlice, useShouldWarnOnLeave, useStaticPropsSlice, useStepperStateSlice, } from '../store';
import { ReservationSteps, Icon, AppGarageLogoSmall, Button, Spinner, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { motion } from 'framer-motion';
import { useWiggleNextStepButton } from '../hooks/use-wiggle-next-step-button';
export const Header = () => {
    var _a;
    const isDesktop = useIsDesktop();
    const isAdminPanel = useIsAdminPanel();
    const isVerifyAvailabilityMode = useIsVerifyAvailabilityMode();
    const { companyOfficialData } = useRemoteMasterDataSlice();
    const { headerWrapper: HeaderWrapper } = useComponentsSlice();
    const { headerWrapper: headerWrapperProps } = useComponentPropsSlice();
    const getTranslation = useGetTranslation();
    const { validationError, validationWarning, resetReservationFlow } = useStepperStateSlice();
    const { onLeaveReservationFlowClick } = useStaticPropsSlice();
    const { isNextStepLoading, currentStep, handleGoToNextStepFromSelectEntityType, handleGoToNextStepFromSelectService, handleGoToNextStepFromSelectPeriodAndEntity, handleGoToNextStepFromExtras, handleGoToNextStepFromBillingDetails, handleGoToNextStepFromPassword, handleGoToNextStepFromPayment, handleGoToNextStepFromCompleteVerification, handleGoToPreviousStep, setIsPreviousButtonSpamBlockEnabled, } = useStepperStateSlice();
    const { setIsCancelReservationModalOpen, navigateToOnConfirm, setNavigateToOnConfirm, } = useModalStatesDataSlice();
    const queryClient = useQueryClient();
    usePaymentDetailsStepSpecificSlice();
    const isCompletionStep = useIsCompletionStep();
    const isPreviousButtonDisabled = useIsPreviousButtonDisabled();
    const isNextButtonDisabled = useIsNextButtonDisabled();
    const isCongratulationsStep = currentStep === ReservationSteps.CONGRATULATIONS;
    const shouldWarnOnLeave = useShouldWarnOnLeave();
    const nextButtonVariants = useWiggleNextStepButton();
    const nextButtonText = useMemo(() => {
        if (isCompletionStep && !isVerifyAvailabilityMode) {
            return getTranslation('complete');
        }
        if (isCompletionStep && isVerifyAvailabilityMode) {
            return getTranslation('verify');
        }
        if (isCongratulationsStep && !isVerifyAvailabilityMode) {
            return getTranslation('MakeNewReservation');
        }
        if (isCongratulationsStep && isVerifyAvailabilityMode) {
            return getTranslation('verifyNewPeriods');
        }
        return getTranslation('next');
    }, [isCompletionStep, isVerifyAvailabilityMode]);
    return (React.createElement(HeaderWrapper, Object.assign({}, headerWrapperProps, { shouldWarnOnLeave: shouldWarnOnLeave, onLeaveReservationFlowClick: onLeaveReservationFlowClick, setIsCancelReservationModalOpen: setIsCancelReservationModalOpen, setNavigateToOnConfirm: setNavigateToOnConfirm, className: classNames({
            'bg-error-200': validationError && !isDesktop,
            'bg-white': !(validationError && !isDesktop),
        }) }),
        React.createElement("div", { className: "flex items-center" },
            (validationWarning || validationError) && !isDesktop ? (React.createElement(Icon, { icon: "errorWithCircle", className: classNames('w-8 h-8 transition-colors mr-2', {
                    'text-error-main': validationError,
                    'text-warning-main': !validationError,
                }) })) : (React.createElement("div", { className: "flex items-center justify-center" }, isAdminPanel ? (React.createElement(AppGarageLogoSmall, { className: "w-12 h-12 cursor-pointer app-garage-logo-small 768:mr-3", onClick: () => {
                    if (shouldWarnOnLeave) {
                        setIsCancelReservationModalOpen(true);
                    }
                    else {
                        setNavigateToOnConfirm('/');
                        onLeaveReservationFlowClick(navigateToOnConfirm);
                    }
                } })) : (React.createElement("img", { src: (_a = companyOfficialData === null || companyOfficialData === void 0 ? void 0 : companyOfficialData.image) === null || _a === void 0 ? void 0 : _a.url, alt: "company-logo", className: "company-logo 768:mr-3 cursor-pointer min-w-[42px] max-w-[42px]", onClick: () => {
                    if (shouldWarnOnLeave) {
                        setIsCancelReservationModalOpen(true);
                    }
                    else {
                        setNavigateToOnConfirm('/');
                        onLeaveReservationFlowClick(navigateToOnConfirm);
                    }
                } })))),
            currentStep !== ReservationSteps.CONGRATULATIONS && (React.createElement(Button, { className: "hidden ml-3 cancel-button 768:flex", color: "neutral", variant: "outline", onClick: () => {
                    if (shouldWarnOnLeave) {
                        setIsCancelReservationModalOpen(true);
                    }
                    else {
                        setNavigateToOnConfirm('/');
                        onLeaveReservationFlowClick(navigateToOnConfirm);
                    }
                } },
                getTranslation('cancel'),
                React.createElement(Icon, { icon: "cross", className: "ml-2 -mr-1 text-xl" })))),
        React.createElement("div", null, (validationWarning || validationError) && !isDesktop && (React.createElement("div", { className: classNames('flex items-center font-semibold  text-sm text-center transition-colors', {
                'bg-error-200 text-error-main': validationError,
            }) },
            React.createElement("p", null, validationError || validationWarning)))),
        React.createElement("div", { className: "flex items-center space-x-2 768:space-x-3 " },
            React.createElement(Button, { onClick: () => {
                    if (!isPreviousButtonDisabled) {
                        if (currentStep === ReservationSteps.CONGRATULATIONS) {
                            onLeaveReservationFlowClick();
                        }
                        else {
                            setIsPreviousButtonSpamBlockEnabled(true);
                            handleGoToPreviousStep();
                            setTimeout(() => {
                                setIsPreviousButtonSpamBlockEnabled(false);
                            }, 500);
                        }
                    }
                }, color: "neutral", variant: "outline", isDisabled: isPreviousButtonDisabled, className: classNames('go-to-home-page-or-go-back-button hidden 768:flex') },
                React.createElement(Icon, { icon: "chevronLeft", className: "mr-2 text-xl" }),
                isCongratulationsStep
                    ? getTranslation('goToHomePage')
                    : getTranslation('back')),
            React.createElement(motion.div, { className: "flex", variants: nextButtonVariants, initial: "initial", animate: "animate" },
                React.createElement(Button, { onClick: () => {
                        if (!isNextButtonDisabled) {
                            if (currentStep === ReservationSteps.CONGRATULATIONS) {
                                resetReservationFlow(queryClient);
                            }
                            else {
                                setTimeout(() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }, 100);
                                if (isVerifyAvailabilityMode && isCompletionStep) {
                                    handleGoToNextStepFromCompleteVerification();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_ENTITY_TYPE) {
                                    handleGoToNextStepFromSelectEntityType();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_SERVICE) {
                                    handleGoToNextStepFromSelectService();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_ENTITY ||
                                    currentStep === ReservationSteps.SELECT_PERIOD ||
                                    currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY) {
                                    handleGoToNextStepFromSelectPeriodAndEntity();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_EXTRAS) {
                                    handleGoToNextStepFromExtras();
                                    return;
                                }
                                if (currentStep === ReservationSteps.BILLING_DETAILS) {
                                    handleGoToNextStepFromBillingDetails();
                                    return;
                                }
                                if (currentStep === ReservationSteps.PASSWORD) {
                                    handleGoToNextStepFromPassword();
                                    return;
                                }
                                if (currentStep === ReservationSteps.PAYMENT_DETAILS) {
                                    handleGoToNextStepFromPayment();
                                    return;
                                }
                                if (isCongratulationsStep) {
                                    return;
                                }
                            }
                        }
                    }, isDisabled: isNextButtonDisabled, className: classNames('next-or-complete-or-make-new-reservation-button hidden 768:flex') },
                    nextButtonText,
                    isNextStepLoading && (React.createElement(Spinner, { className: "w-5 h-5 ml-2 text-white" })),
                    isCompletionStep && !isNextStepLoading && (React.createElement(Icon, { icon: "check", className: "ml-2 text-2xl" })),
                    !isCompletionStep && !isNextStepLoading && (React.createElement(Icon, { icon: "chevronRight", className: "ml-2 text-xl" })))))));
};

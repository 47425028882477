import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion, LayoutGroup } from 'framer-motion';
import { Summary } from './summary';
import { ReservationSteps, useGetTranslation, usePrevious, } from '@reservation-app/common-used-in-web';
import { getFormattedPrice } from '../helpers/get-formatted-price';
import { useStepperStateSlice, useCompanyReservationConfig } from '../store';
export const SideSummary = () => {
    const getTranslation = useGetTranslation();
    const { currentStep, price: { totalAmount, discountAmount, totalAmountBeforeDiscount }, validationError, validationWarning, } = useStepperStateSlice();
    const { currency } = useCompanyReservationConfig();
    const prevTotalAmount = usePrevious(totalAmount);
    return (React.createElement(motion.div, { key: "side-summary-wrapper", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, className: "hidden 768:block mr-7 mt-8 flex-shrink-0 w-[330px]" },
        React.createElement("div", { className: "sticky top-24" },
            React.createElement(LayoutGroup, null,
                React.createElement(AnimatePresence, null, (validationWarning || validationError) && (React.createElement(motion.div, { layout: true, initial: { opacity: 0 }, animate: {
                        opacity: 1,
                    }, exit: {
                        opacity: 0,
                    }, transition: { type: 'tween', delay: 0.3 }, className: classNames('flex border-l-8 rounded-md p-4 mb-5 shadow-md transition-colors', {
                        'bg-error-100 border-error-main': validationError,
                        'bg-white border-yellow-600': !validationError,
                    }) },
                    React.createElement("div", null,
                        currentStep !==
                            ReservationSteps.SELECT_PERIOD_AND_ENTITY && (React.createElement("p", { className: classNames('font-semibold text-lg transition-colors', {
                                'text-error-main': validationError,
                                'text-warning-main': !validationError,
                            }) }, getTranslation('invalidData'))),
                        React.createElement("p", null, validationError || validationWarning))))),
                React.createElement(AnimatePresence, null,
                    React.createElement(motion.div, { key: 'summary-container-step-wrapper', layout: true, className: "rounded-t-md", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } },
                        React.createElement("div", { className: classNames('bg-white rounded-top-md shadow-md p-4', {
                                'rounded-t-md': totalAmount > 0,
                                'rounded-md': !totalAmount,
                            }), "data-test-id": "summary-container-step-wrapper" },
                            React.createElement(Summary, null)))),
                totalAmount > 0 && (React.createElement(motion.div, { layout: true, key: 'summary-price', initial: { opacity: 0 }, animate: {
                        opacity: 1,
                    }, exit: {
                        opacity: 0,
                    }, transition: {
                        type: 'tween',
                        delay: prevTotalAmount > 0 ? 0 : 0.5,
                    }, className: "flex justify-between bg-primary-main text-white shadow-md rounded-b-md py-4" },
                    React.createElement("p", { className: "ml-5 font-semibold uppercase" }, getTranslation('totalPrice')),
                    React.createElement("div", { className: "mr-4 flex h-full" },
                        discountAmount > 0 && (React.createElement("p", { className: "mr-2 text-gray-200 line-through" }, getFormattedPrice(totalAmountBeforeDiscount, currency))),
                        React.createElement(motion.p, { key: totalAmount, initial: { scale: 0.75, opacity: 0 }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, transition: { type: 'tween' }, className: "font-semibold" }, getFormattedPrice(totalAmount, currency)))))))));
};

import { areIntervalsIntersecting } from './are-intervals-intersecting';
export const getIntersectingReservedIntervalsWithSelectedIntervalsForEntity = ({ selectedIntervals, entity, }) => {
    const results = [];
    selectedIntervals.forEach((selectedInterval) => {
        entity.reservedIntervals.forEach((reservedInterval) => {
            if (areIntervalsIntersecting(Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval), Object.assign({ date: selectedInterval.date }, selectedInterval.reservableInterval.timeInterval))) {
                results.push(selectedInterval);
            }
        });
    });
    return results;
};

import React from 'react';
export const PartyPopper = ({ className, onClick }) => (React.createElement("svg", { className: className, onClick: onClick, stroke: "currentColor", fill: "currentColor", viewBox: "0 0 69 70", strokeWidth: 0, height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M1.40573 69.5757C2.72992 71.0107 8.18421 68.5082 12.4893 66.5015C15.7152 65.0023 28.6246 59.7055 35.0414 56.9579C36.774 56.2171 39.294 55.2487 41.1082 52.8628C42.7183 50.7395 46.9884 41.7209 38.3899 32.5857C29.663 23.3105 20.6736 25.8714 17.3136 28.2515C15.336 29.6515 13.726 32.8074 13.0026 34.4116C9.94591 41.1901 5.58832 53.6095 3.81494 58.4921C2.51408 62.0914 0.0931985 68.1523 1.40573 69.5757Z", fill: "#FFC107" }),
    React.createElement("path", { d: "M12.1383 36.4533C12.22 37.4683 12.4242 39.1192 13.1242 42.2984C13.6025 44.4801 14.3842 46.7668 15.02 48.2777C16.9276 52.822 19.6051 54.6537 22.3177 56.1237C26.9261 58.6204 30.0587 59.0871 30.0587 59.0871L26.302 60.6213C26.302 60.6213 24.0269 60.1488 20.9235 58.6204C17.9659 57.1621 14.8859 54.6945 12.6342 49.8644C11.66 47.7702 11.0941 45.7401 10.7675 44.1301C10.3649 42.1351 10.3008 41.0034 10.3008 41.0034L12.1383 36.4533Z", fill: "#FF8F00" }),
    React.createElement("path", { d: "M7.52539 48.2835C7.52539 48.2835 7.99207 52.0695 11.1188 56.8471C14.7822 62.4355 19.8982 63.3514 19.8982 63.3514L16.4973 64.7514C16.4973 64.7514 12.6997 63.5905 9.08876 58.6613C6.83705 55.587 6.20703 51.912 6.20703 51.912L7.52539 48.2835Z", fill: "#FF8F00" }),
    React.createElement("path", { d: "M4.28617 57.2554C4.28617 57.2554 5.13785 60.5221 7.00456 62.9605C9.22711 65.8714 12.0563 66.7173 12.0563 66.7173L9.44878 67.8723C9.44878 67.8723 7.47124 67.4581 5.30702 64.7164C3.66199 62.6339 3.19531 60.2421 3.19531 60.2421L4.28617 57.2554Z", fill: "#FF8F00" }),
    React.createElement("path", { opacity: "0.44", d: "M2.86804 65.5506C2.75137 65.2881 2.75137 64.9906 2.87387 64.7339L17.7317 33.9216L20.1759 43.1093L4.54224 65.6906C4.12223 66.3206 3.17721 66.2389 2.86804 65.5506Z", fill: "#FFFDE7" }),
    React.createElement("path", { d: "M21.3573 46.1952C28.2992 54.3153 36.2035 53.3003 38.8344 51.2469C41.4711 49.1877 43.5537 42.1117 36.641 34.1257C29.3959 25.7606 21.194 28.1464 19.2981 29.9548C17.4023 31.7632 14.9872 38.7458 21.3573 46.1952Z", fill: "url(#paint0_linear_2304_2202)" }),
    React.createElement("path", { d: "M45.1974 49.5378C42.6657 47.4144 41.3182 47.7936 39.5098 48.5461C37.1764 49.5144 33.5072 50.2319 28.5254 48.5461L30.0246 44.9352C32.9822 45.9327 35.123 45.4485 36.9722 44.3577C39.3523 42.9576 42.6074 41.0384 47.6708 45.291C49.7825 47.0644 51.9467 48.2427 53.5334 47.7061C54.6884 47.3211 55.301 45.6002 55.6101 44.2293C55.6393 44.1068 55.686 43.7568 55.721 43.4476C56.001 41.3068 56.4677 36.6867 59.9094 34.3241C63.5903 31.7982 67.4579 31.7982 67.4579 31.7982L68.1579 38.7517C66.3787 38.4892 65.142 38.8509 64.0978 39.4284C60.1661 41.6159 63.5903 50.0161 57.471 52.8395C51.5851 55.5754 46.7724 50.8561 45.1974 49.5378Z", fill: "#03A9F4" }),
    React.createElement("path", { d: "M23.5431 40.6709L21.0113 38.4017C25.6606 33.2099 24.4356 29.3948 23.5431 26.6181C23.3622 26.0581 23.193 25.5272 23.0822 25.0197C22.6855 23.223 22.6039 21.6596 22.7264 20.2946C20.9413 18.0721 20.1538 15.7445 20.1013 15.587C19.0163 12.3028 19.833 9.1002 21.7055 6.09596C25.4914 0 32.3457 0 32.3457 0L34.6325 6.1193C32.8941 6.0493 27.1948 6.1368 25.4448 8.89602C23.2339 12.3728 24.6864 14.5195 24.7914 14.7645C25.2173 14.2103 25.6489 13.767 26.0339 13.4228C28.8282 10.9436 31.2549 10.5877 32.8008 10.7277C34.5391 10.8852 36.1142 11.7603 37.24 13.1953C38.4709 14.7703 38.9784 16.8179 38.5875 18.6787C38.2084 20.4929 37.0008 22.0271 35.1866 23.0013C32.0191 24.7047 29.3823 24.4714 27.6148 23.8822C27.6265 23.923 27.6323 23.9697 27.644 24.0105C27.7081 24.3022 27.8365 24.7105 27.9882 25.183C29.0207 28.3798 30.9399 33.4549 23.5431 40.6709ZM27.8306 18.7779C28.169 19.0229 28.5248 19.2271 28.8923 19.3729C30.1174 19.8629 31.4532 19.6996 32.9699 18.8829C33.8624 18.4046 33.9674 17.8912 34.0024 17.722C34.1075 17.2145 33.9324 16.567 33.5533 16.0828C33.2208 15.657 32.8358 15.4353 32.3749 15.3887C31.4999 15.3128 30.3157 15.867 29.1315 16.9229C28.5657 17.4304 28.134 18.0546 27.8306 18.7779Z", fill: "#F44336" }),
    React.createElement("path", { d: "M33.6773 41.6217L30.0547 41.5225C30.0547 41.5225 31.7756 31.804 37.3465 30.1706C38.3907 29.8673 39.5341 29.5581 40.6832 29.3889C41.3658 29.2839 42.445 29.1264 42.9758 28.9281C43.0983 28.0123 42.7133 26.8456 42.2816 25.5214C41.9433 24.4947 41.5933 23.4388 41.4066 22.2838C41.0449 20.0321 41.6458 18.0429 43.0983 16.672C44.8717 15.0095 47.7359 14.4786 50.9676 15.2136C52.811 15.6337 54.1702 16.5378 55.3661 17.3312C57.0753 18.4687 58.0728 19.0462 60.1612 17.6404C62.687 15.937 59.3853 9.26935 57.6294 5.41927L64.1804 2.68921C65.0613 4.61425 69.3139 14.5195 66.508 20.1721C65.563 22.0738 63.9354 23.3338 61.8004 23.8063C57.1569 24.8447 54.4385 23.0363 52.4552 21.7179C51.516 21.0938 50.6935 20.6038 49.8009 20.3471C43.6 18.5795 52.2568 27.7031 48.2026 31.804C45.77 34.2599 39.8257 34.9074 39.4407 35.0007C35.614 35.9224 33.6773 41.6217 33.6773 41.6217Z", fill: "#F48FB1" }),
    React.createElement("path", { d: "M22.7194 20.2946C22.6086 21.578 22.5561 22.3422 22.8886 24.0105C24.4928 25.1889 27.987 25.1889 27.987 25.1889C27.8354 24.7164 27.7012 24.308 27.6429 24.0164C27.6312 23.9755 27.6254 23.9289 27.6137 23.888C24.0611 22.1147 22.7194 20.2946 22.7194 20.2946Z", fill: "#C92B27" }),
    React.createElement("path", { d: "M15.4537 26.0405L9.42188 23.083L12.4261 18.7429L17.157 21.8755L15.4537 26.0405Z", fill: "#FFC107" }),
    React.createElement("path", { d: "M6.56264 17.8504C3.48257 17.4362 0.344174 14.8228 0 14.5253L3.02756 10.9727C3.94342 11.7486 5.88596 13.0494 7.18682 13.2244L6.56264 17.8504Z", fill: "#FB8C00" }),
    React.createElement("path", { d: "M11.9998 10.0744L7.56641 8.62184C8.07392 7.07014 8.20809 5.39593 7.94558 3.78006L12.554 3.03922C12.9332 5.38427 12.7407 7.81682 11.9998 10.0744Z", fill: "#03A9F4" }),
    React.createElement("path", { d: "M39.6855 6.60632L44.2445 5.60904L45.5609 11.6269L41.002 12.6242L39.6855 6.60632Z", fill: "#FB8C00" }),
    React.createElement("path", { d: "M50.9955 8.03269L47.7871 4.64345C49.4671 3.05092 49.8522 0.968372 49.8522 0.945038L54.4606 1.69755C54.4023 2.06506 53.8131 5.3668 50.9955 8.03269Z", fill: "#FFC107" }),
    React.createElement("path", { d: "M52.7793 26.0063L56.8551 24.7325L58.2472 29.187L54.1714 30.4607L52.7793 26.0063Z", fill: "#FB8C00" }),
    React.createElement("path", { d: "M53.9655 63.6022L49.3279 63.0539C49.5262 61.403 48.2954 59.3788 47.957 58.9296L51.6904 56.1295C51.9705 56.4971 54.403 59.863 53.9655 63.6022Z", fill: "#F44336" }),
    React.createElement("path", { d: "M67.2766 57.6871C65.5324 57.4246 63.7473 57.3196 61.9856 57.3837L61.8281 52.717C63.8757 52.647 65.9465 52.7636 67.9708 53.0728L67.2766 57.6871Z", fill: "#FB8C00" }),
    React.createElement("path", { d: "M61.0039 64.1108L64.2828 60.79L68.795 65.2451L65.5161 68.566L61.0039 64.1108Z", fill: "#F48FB1" }),
    React.createElement("path", { d: "M51.3691 34.6106L54.7408 38.4658L50.8856 41.8374L47.514 37.9823L51.3691 34.6106Z", fill: "#F44336" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_2304_2202", x1: "40.4528", y1: "33.7399", x2: "23.0881", y2: "44.1586", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.0235", stopColor: "#8F4700" }),
            React.createElement("stop", { offset: "1", stopColor: "#703E2D" })))));

import React, { useCallback } from 'react';
import { isSameDay, addMonths, format } from 'date-fns';
import classNames from 'classnames';
import { useFilteredByServiceEntities, useIsAdminPanel, useIsDesktop, usePeriodAndEntityStepSpecificSlice, useReservableIntervalsList, useSelectedValuesSlice, useStepperStateSlice, useSyncGroupEntity, } from '../../store';
import { TileStatuses, Icon, useTranslationsStore, getTimelessDate, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { AgCalendar } from '../../../components';
import { areIntervalsTheSame } from '../../helpers/are-intervals-the-same';
import { getReservableIntervalByIdFromService } from '../../helpers/get-reservable-interval-by-id-from-service';
import { getSelectedIntervalNumberOfPersons } from '../../helpers/get-selected-interval-number-of-persons';
import { getStatusForCalendarDate } from '../../helpers/get-status-for-calendar-date';
export const Calendar = ({ className }) => {
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const isDesktop = useIsDesktop();
    const { defaultDate } = useStepperStateSlice();
    const isAdminPanel = useIsAdminPanel();
    const { selectedEntity, selectedEntityType, selectedService, selectedIntervals, selectedBulkNumberOfPersons, setSelectedBulkNumberOfPersons, removeSelectedIntervals, addSelectedIntervals, } = useSelectedValuesSlice();
    const { selectedDate, setSelectedDate, setIsReservableIntervalsListShowing } = usePeriodAndEntityStepSpecificSlice();
    const filteredByServiceEntities = useFilteredByServiceEntities();
    const syncGroupKeyEntity = useSyncGroupEntity();
    const { getReservableIntervalsList } = useReservableIntervalsList();
    const activeClassName = useCallback((day) => {
        let tileClassName = `${day} transition-colors 640:hover:bg-gray-100`;
        const isCurrentDate = isSameDay(day, selectedDate);
        if (isCurrentDate) {
            tileClassName +=
                ' calendar-current-selected-date bg-primary-main text-white 640:hover:bg-primary-hover';
            return tileClassName;
        }
        if (selectedService) {
            const dayStatus = getStatusForCalendarDate({
                currentDate: day,
                entities: selectedEntity
                    ? [selectedEntity]
                    : filteredByServiceEntities,
                selectedService,
                selectedIntervals,
                selectedNumberOfPersons: selectedBulkNumberOfPersons,
            });
            if (dayStatus === TileStatuses.RESERVED) {
                tileClassName +=
                    ' reserved-tile bg-error-100 pointer-events-none text-gray-700';
                return tileClassName;
            }
            if (dayStatus === TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_CLOSED) {
                tileClassName +=
                    ' diagonal-background-first-reserved-second-closed pointer-events-none text-gray-700';
                return tileClassName;
            }
            if (dayStatus === TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_RESERVED) {
                tileClassName +=
                    ' diagonal-background-first-closed-second-reserved pointer-events-none text-gray-700';
                return tileClassName;
            }
            switch (dayStatus) {
                case TileStatuses.CLOSED:
                    tileClassName +=
                        ' closed-tile bg-gray-200 pointer-events-none text-gray-700';
                    break;
                case TileStatuses.SELECTED:
                    tileClassName +=
                        ' selected-tile bg-primary-light 640:hover:bg-primary-300';
                    break;
                case TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_RESERVED:
                    tileClassName +=
                        ' diagonal-background-first-available-second-reserved text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_AVAILABLE:
                    tileClassName +=
                        ' diagonal-background-first-reserved-second-available text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_CLOSED:
                    tileClassName +=
                        ' diagonal-background-first-available-second-closed text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_AVAILABLE:
                    tileClassName +=
                        ' diagonal-background-first-closed-second-available text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_CLOSED:
                    tileClassName +=
                        ' diagonal-background-first-selected-second-closed text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_AVAILABLE:
                    tileClassName +=
                        ' diagonal-background-first-selected-second-available text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_SELECTED_SECOND_HALF_RESERVED:
                    tileClassName +=
                        ' diagonal-background-first-selected-second-reserved text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_CLOSED_SECOND_HALF_SELECTED:
                    tileClassName +=
                        ' diagonal-background-first-closed-second-selected text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_AVAILABLE_SECOND_HALF_SELECTED:
                    tileClassName +=
                        ' diagonal-background-first-available-second-selected text-gray-700';
                    break;
                case TileStatuses.FIRST_HALF_RESERVED_SECOND_HALF_SELECTED:
                    tileClassName +=
                        ' diagonal-background-first-reserved-second-selected text-gray-700';
                    break;
                default:
                    break;
            }
        }
        return tileClassName;
    }, [
        filteredByServiceEntities,
        selectedEntity,
        selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getDate(),
        selectedBulkNumberOfPersons,
        selectedIntervals,
    ]);
    return (React.createElement(AgCalendar, { restrictedBefore: defaultDate, restrictedAfter: isAdminPanel
            ? undefined
            : addMonths(defaultDate, selectedEntityType.numberOfMonthsToReserveInAdvance), language: activeLanguage, monthTitle: (currentDate) => getTranslation(`month-${new Date(currentDate)
            .getMonth()
            .toString()}`), todayTileClassName: "!font-bold", weekDays: [
            getTranslation('mondayShort'),
            getTranslation('tuesdayShort'),
            getTranslation('wednesdayShort'),
            getTranslation('thursdayShort'),
            getTranslation('fridayShort'),
            getTranslation('saturdayShort'),
            getTranslation('sundayShort'),
        ], rightArrowIcon: (isNextMonthRestricted, currentMonth) => {
            return (React.createElement(Icon, { icon: "chevronRight-2", className: classNames(`text-2xl flex-shrink-0 go-to-prev-month ${currentMonth}`, {
                    'text-gray-200': isNextMonthRestricted,
                }) }));
        }, leftArrowIcon: (isPrevMonthRestricted, currentMonth) => {
            return (React.createElement(Icon, { icon: "chevronLeft-2", className: classNames(`text-2xl flex-shrink-0 go-to-next-month ${currentMonth}`, {
                    'text-gray-200': isPrevMonthRestricted,
                }) }));
        }, calendarClassName: className, allTilesClassName: "rounded-full font-normal", selectedTileClassName: "bg-primary-main text-gray-50 640:bg-primary-hover", activeTilesClassName: activeClassName, disabledTilesClassName: "!bg-white !text-transparent", selectedDate: selectedDate, selectHandler: (day) => {
            const doEntitiesHaveOnlyOneReservableInterval = getPeriodPropertyValueForDate({
                date: new Date(day),
                periodSensitiveProperty: (syncGroupKeyEntity === null || syncGroupKeyEntity === void 0 ? void 0 : syncGroupKeyEntity.hasOnlyOneInterval)
                    ? syncGroupKeyEntity === null || syncGroupKeyEntity === void 0 ? void 0 : syncGroupKeyEntity.hasOnlyOneInterval
                    : [],
            });
            const reservableIntervalsListForDay = getReservableIntervalsList(day);
            if (doEntitiesHaveOnlyOneReservableInterval) {
                const isIntervalSelected = selectedIntervals.some((selectedInterval) => {
                    return (new Date(day) &&
                        areIntervalsTheSame(selectedInterval, Object.assign({ date: getTimelessDate(day) }, reservableIntervalsListForDay.intervals[0].timeInterval)));
                });
                if (isIntervalSelected) {
                    removeSelectedIntervals([
                        Object.assign({ date: format(new Date(day), 'yyyy-MM-dd') }, reservableIntervalsListForDay.intervals[0].timeInterval),
                    ]);
                }
                if (!isIntervalSelected) {
                    const numberOfPersons = getSelectedIntervalNumberOfPersons({
                        syncGroupEntity: syncGroupKeyEntity,
                        selectedBulkNumberOfPersons,
                        setSelectedBulkNumberOfPersons,
                        selectedService,
                        selectedDate: new Date(day),
                    });
                    addSelectedIntervals([
                        Object.assign(Object.assign({}, reservableIntervalsListForDay.intervals[0]), { date: format(new Date(day), 'yyyy-MM-dd'), selectedExtras: [], reservableIntervalId: reservableIntervalsListForDay.intervals[0]._id, numberOfPersons, reservableInterval: getReservableIntervalByIdFromService({
                                service: selectedService,
                                reservableIntervalId: reservableIntervalsListForDay.intervals[0]._id,
                            }) }),
                    ]);
                }
            }
            if (!doEntitiesHaveOnlyOneReservableInterval) {
                const didSelectTheCurrentlySelectedDay = selectedDate && isSameDay(new Date(day), selectedDate);
                if (didSelectTheCurrentlySelectedDay) {
                    setIsReservableIntervalsListShowing(false);
                    setSelectedDate(undefined);
                }
                if (!didSelectTheCurrentlySelectedDay) {
                    if (!selectedDate && !isDesktop) {
                        setTimeout(() => {
                            const elementToScrollTo = document.getElementById('reservable-intervals-list');
                            if (elementToScrollTo) {
                                elementToScrollTo.scrollIntoView({
                                    block: 'start',
                                    behavior: 'smooth',
                                });
                            }
                        }, 200);
                    }
                    setSelectedDate(new Date(day));
                    setIsReservableIntervalsListShowing(true);
                }
            }
        } }));
};

import React from 'react';
import { Modal, ScheduleSection, useTranslationsStore, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice, useStaticPropsSlice, } from '../../reservation-flow/store';
export const SchedulesSectionModal = () => {
    const { getTranslation } = useTranslationsStore();
    const { schedulesSectionProps } = useStaticPropsSlice();
    const { isSchedulesSectionModalOpen, setIsSchedulesSectionModalOpen } = useModalStatesDataSlice();
    return (React.createElement(Modal, { isOpen: isSchedulesSectionModalOpen, onClose: () => setIsSchedulesSectionModalOpen(false), modalClassName: "flex flex-col overflow-y-auto max-h-full", overlayClassName: "", withScrollLock: false },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement("p", { className: "mb-6 text-3xl font-semibold text-gray-900" }, getTranslation('timetable')),
            React.createElement(ScheduleSection, Object.assign({}, schedulesSectionProps)))));
};

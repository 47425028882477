import { useEffect } from 'react';
import { getTotalPriceInfo } from '../helpers/get-total-price-info';
import { useStepperStateSlice, useRemoteMasterDataSlice, useSelectedValuesSlice, useBillingDetailsStepSpecificSlice, } from '../store';
export const useCalculatePrice = () => {
    const { setPrice } = useStepperStateSlice();
    const { services } = useRemoteMasterDataSlice();
    const { selectedService, selectedIntervals, selectedEntity, selectedEntityType, } = useSelectedValuesSlice();
    const { billingDetails } = useBillingDetailsStepSpecificSlice();
    const { reservationPassword } = useStepperStateSlice();
    useEffect(() => {
        if (selectedEntity && selectedIntervals && selectedService) {
            const order = {
                selectedServiceId: selectedService._id,
                selectedEntityTypeId: selectedEntityType._id,
                selectedEntityId: selectedEntity._id,
                selectedIntervals: selectedIntervals.map((selectedInterval) => {
                    return {
                        date: selectedInterval.date,
                        numberOfPersons: selectedInterval.numberOfPersons,
                        selectedExtras: selectedInterval.selectedExtras,
                        reservableIntervalId: selectedInterval.reservableIntervalId,
                    };
                }),
                userInfo: billingDetails,
                password: reservationPassword,
                membershipCode: reservationPassword,
            };
            setPrice(getTotalPriceInfo({
                order,
                allServices: services,
            }));
        }
        else {
            setPrice({});
        }
    }, [selectedEntity, selectedIntervals, selectedService]);
};

import { isAfter } from 'date-fns';
export const isPeriodExpiredForDate = ({ period, date, }) => {
    if (!period.endDate) {
        return false;
    }
    if (period.startDate && period.endDate) {
        return !isAfter(new Date(date), new Date(period.endDate));
    }
    return true;
};

import React, { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { usePrevious } from '@reservation-app/common-used-in-web';
export const ToastContext = createContext({
    showToast: () => '',
    closeToast: () => '',
    closeAllToasts: () => { },
});
export const useToast = () => useContext(ToastContext);
export const ToastProvider = ({ children, }) => {
    const [topLeftToasts, setTopLeftToasts] = useState([]);
    const [topCenterToasts, setTopCenterToasts] = useState([]);
    const [topRightToasts, setTopRightToasts] = useState([]);
    const [bottomLeftToasts, setBottomLeftToasts] = useState([]);
    const [bottomCenterToasts, setBottomCenterToasts] = useState([]);
    const [bottomRightToasts, setBottomRightToasts] = useState([]);
    const prevTopLeftToasts = usePrevious(topLeftToasts, []);
    const prevTopCenterToasts = usePrevious(topCenterToasts, []);
    const prevTopRightToasts = usePrevious(topRightToasts, []);
    const prevBottomLeftToasts = usePrevious(bottomLeftToasts, []);
    const prevBottomCenterToasts = usePrevious(bottomCenterToasts, []);
    const prevBottomRightToasts = usePrevious(bottomRightToasts, []);
    useEffect(() => {
        if (prevTopLeftToasts &&
            topLeftToasts.length > 0 &&
            prevTopLeftToasts.length < topLeftToasts.length) {
            const newToast = topLeftToasts[topLeftToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setTopLeftToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [prevTopLeftToasts, topLeftToasts]);
    useEffect(() => {
        if (prevTopCenterToasts &&
            topCenterToasts.length > 0 &&
            prevTopCenterToasts.length < topCenterToasts.length) {
            const newToast = topCenterToasts[topCenterToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setTopCenterToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [prevTopCenterToasts, topCenterToasts]);
    useEffect(() => {
        if (prevTopRightToasts &&
            topRightToasts.length > 0 &&
            prevTopRightToasts.length < topRightToasts.length) {
            const newToast = topRightToasts[topRightToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setTopRightToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [prevTopRightToasts, topRightToasts]);
    useEffect(() => {
        if (prevBottomLeftToasts &&
            bottomLeftToasts.length > 0 &&
            prevBottomLeftToasts.length < bottomLeftToasts.length) {
            const newToast = bottomLeftToasts[bottomLeftToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setBottomLeftToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [bottomLeftToasts, prevBottomLeftToasts]);
    useEffect(() => {
        if (prevBottomCenterToasts &&
            bottomCenterToasts.length > 0 &&
            prevBottomCenterToasts.length < bottomCenterToasts.length) {
            const newToast = bottomCenterToasts[bottomCenterToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setBottomCenterToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [bottomCenterToasts, prevBottomCenterToasts]);
    useEffect(() => {
        if (prevBottomRightToasts &&
            bottomRightToasts.length > 0 &&
            prevBottomRightToasts.length < bottomRightToasts.length) {
            const newToast = bottomRightToasts[bottomRightToasts.length - 1];
            if (newToast) {
                setTimeout(() => {
                    setBottomRightToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
                }, newToast.timeout || 3000);
            }
        }
    }, [bottomRightToasts, prevBottomRightToasts]);
    const ToastComponent = ({ toast, onClick, }) => {
        let IntentIcon;
        if (toast.Icon) {
            IntentIcon = React.createElement(toast.Icon, { className: classNames(toast.iconClassName) });
        }
        else if (toast.Icon === null) {
            IntentIcon = undefined;
        }
        else {
            if (toast.Icon === undefined &&
                (toast.intent === 'primary' || !toast.intent)) {
                IntentIcon = (React.createElement("svg", { className: classNames({
                        'toast-default-icon-primary': toast.intent === 'primary',
                        'toast-default-icon-no-intent': !toast.intent,
                    }), stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 16 16", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { fillRule: "evenodd", d: "M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 100-2 1 1 0 000 2z", clipRule: "evenodd" })));
            }
            if (toast.Icon === undefined && toast.intent === 'success') {
                IntentIcon = (React.createElement("svg", { className: "toast-default-icon-success", stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" })));
            }
            if (toast.Icon === undefined && toast.intent === 'warning') {
                IntentIcon = (React.createElement("svg", { className: "toast-default-icon-warning", stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z" })));
            }
            if (toast.Icon === undefined && toast.intent === 'danger') {
                IntentIcon = (React.createElement("svg", { className: "toast-default-icon-danger", stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z" })));
            }
        }
        return (React.createElement("div", { id: toast.id, onClick: onClick, className: classNames(`toast`, {
                'toast-no-intent': !toast.intent,
                'toast-primary': toast.intent === 'primary',
                'toast-success': toast.intent === 'success',
                'toast-warning': toast.intent === 'warning',
                'toast-danger': toast.intent === 'danger',
            }) },
            toast.Icon !== null && React.createElement("div", null,
                " ",
                IntentIcon),
            React.createElement("div", { className: classNames('toast-text-container', {
                    'text-gray-800': !toast.intent,
                }) },
                toast.header && (React.createElement("p", { className: classNames('toast-header-container', {
                        'toast-header-no-intent': !toast.intent,
                    }) }, toast.header)),
                React.createElement("div", null, toast.text)),
            React.createElement("svg", { className: classNames('ml-3 text-gray-50', {
                    'text-gray-800': !toast.intent,
                }), stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "1.5em", width: "1.5em", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" }))));
    };
    const showToast = useCallback((newToast) => {
        const newToastId = nanoid();
        if (newToast.position === 'top-left') {
            setTopLeftToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        if (newToast.position === 'top-center') {
            setTopCenterToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        if (newToast.position === 'top-right') {
            setTopRightToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        if (newToast.position === 'bottom-left') {
            setBottomLeftToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        if (newToast.position === 'bottom-center') {
            setBottomCenterToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        if (newToast.position === 'bottom-right') {
            setBottomRightToasts((prevToasts) => [
                ...prevToasts,
                Object.assign(Object.assign({}, newToast), { id: newToastId }),
            ]);
        }
        return newToastId;
    }, []);
    const closeToast = (id) => {
        let found = false;
        setTopLeftToasts(topLeftToasts.filter((el) => el.id !== id));
        if (!found) {
            setTopLeftToasts(topLeftToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
        if (!found) {
            setTopCenterToasts(topCenterToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
        if (!found) {
            setTopRightToasts(topRightToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
        if (!found) {
            setBottomLeftToasts(bottomLeftToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
        if (!found) {
            setBottomCenterToasts(bottomCenterToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
        if (!found) {
            setBottomRightToasts(bottomRightToasts.filter((el) => {
                const res = el.id !== id;
                if (res === false) {
                    found = true;
                }
                return res;
            }));
        }
    };
    const closeAllToasts = () => {
        setTopLeftToasts([]);
        setTopCenterToasts([]);
        setTopRightToasts([]);
        setBottomLeftToasts([]);
        setBottomCenterToasts([]);
        setBottomRightToasts([]);
    };
    return (React.createElement(ToastContext.Provider, { value: { showToast, closeToast, closeAllToasts } },
        children,
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-top-left" },
                React.createElement(AnimatePresence, null, topLeftToasts.map((topLeftToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: topLeftToast.id, initial: { transform: 'translatex(-1000px)' }, animate: {
                            transform: 'translatex(0px)',
                        }, exit: { transform: 'translatex(-1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: topLeftToast, onClick: () => {
                                const newOption = topLeftToasts.filter((filteredToast) => filteredToast !== topLeftToast);
                                setTopLeftToasts(newOption);
                            } })));
                })))),
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-top-center transform -translate-x-1/2" },
                React.createElement(AnimatePresence, null, topCenterToasts.map((topCenterToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: topCenterToast.id, initial: { transform: 'translateY(-1000px)' }, animate: {
                            transform: 'translateY(0px)',
                        }, exit: { transform: 'translateY(-1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: topCenterToast, onClick: () => {
                                const newOption = topCenterToasts.filter((filteredToast) => filteredToast !== topCenterToast);
                                setTopCenterToasts(newOption);
                            } })));
                })))),
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-top-right" },
                React.createElement(AnimatePresence, null, topRightToasts.map((topRightToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: topRightToast.id, initial: { transform: 'translatex(1000px)' }, animate: {
                            transform: 'translatex(0px)',
                        }, exit: { transform: 'translatex(1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: topRightToast, onClick: () => {
                                const newOption = topRightToasts.filter((filteredToast) => filteredToast !== topRightToast);
                                setTopRightToasts(newOption);
                            } })));
                })))),
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-bottom-left" },
                React.createElement(AnimatePresence, null, bottomLeftToasts.map((bottomLeftToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: bottomLeftToast.id, initial: { transform: 'translatex(-1000px)' }, animate: {
                            transform: 'translatex(0px)',
                        }, exit: { transform: 'translatex(-1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: bottomLeftToast, onClick: () => {
                                const newOption = bottomLeftToasts.filter((filteredToast) => filteredToast !== bottomLeftToast);
                                setBottomLeftToasts(newOption);
                            } })));
                })))),
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-bottom-center transform -translate-x-1/2" },
                React.createElement(AnimatePresence, null, bottomCenterToasts.map((bottomCenterToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: bottomCenterToast.id, initial: { transform: 'translateY(1000px)' }, animate: {
                            transform: 'translateY(0px)',
                        }, exit: { transform: 'translateY(1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: bottomCenterToast, onClick: () => {
                                const newOption = bottomCenterToasts.filter((filteredToast) => filteredToast !== bottomCenterToast);
                                setBottomCenterToasts(newOption);
                            } })));
                })))),
        React.createElement(LayoutGroup, null,
            React.createElement(motion.div, { className: "toast-bottom-right" },
                React.createElement(AnimatePresence, null, bottomRightToasts.map((bottomRightToast) => {
                    return (React.createElement(motion.div, { className: "mb-5", layout: "position", key: bottomRightToast.id, initial: { transform: 'translatex(1000px)' }, animate: {
                            transform: 'translatex(0px)',
                        }, exit: { transform: 'translatex(1000px)' }, transition: { type: 'spring', stiffness: 120, damping: 20 } },
                        React.createElement(ToastComponent, { toast: bottomRightToast, onClick: () => {
                                const newOption = bottomRightToasts.filter((filteredToast) => filteredToast !== bottomRightToast);
                                setBottomRightToasts(newOption);
                            } })));
                }))))));
};

import { getWeek, hoursToMinutes } from 'date-fns';
import { RestrictionTypes, getTimelessDate, isDateInPeriod, getPeriodPropertyValueForDate, jsDayIndexToDayMap, } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame, areTimeIntervalsTheSame, } from './are-intervals-the-same';
import { dayToJsDayIndexMap } from './day-to-js-day-index';
import { getDayFromDate } from './get-day-from-date';
import { getSelectedIntervalsMinutesInfo } from './get-selected-intervals-minutes-info';
export const getRestrictionValidationErrors = ({ selectedService, selectedIntervals, selectedEntity, isAdmin, }) => {
    if (!selectedEntity || !(selectedIntervals === null || selectedIntervals === void 0 ? void 0 : selectedIntervals.length) || isAdmin) {
        return [];
    }
    const restrictionErrors = [];
    selectedService.restrictions.forEach(({ period: restrictionPeriod, value: restrictionsForPeriod }) => {
        const selectedIntervalsForPeriod = selectedIntervals.filter((selectedInterval) => {
            return (isDateInPeriod({
                period: restrictionPeriod,
                date: selectedInterval.date,
            }) && restrictionsForPeriod.entityIds.includes(selectedEntity._id));
        });
        restrictionsForPeriod.restrictions.forEach((restriction) => {
            var _a, _b;
            const todaysDayJsIndex = new Date().getDay();
            const totalMinutesPassedToday = hoursToMinutes(new Date().getHours()) + new Date().getMinutes();
            const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
            const todaysWeekNumber = getWeek(new Date(), {
                weekStartsOn: (_a = restriction.weekStartsOn) !== null && _a !== void 0 ? _a : 1,
            });
            const getTodaysLiftedRestriction = (restrictionType) => restriction.type === restrictionType
                ? restriction.shouldLiftRestrictionForCurrentWeekOnDays.find((shouldLiftRestriction) => {
                    if (dayToJsDayIndexMap[shouldLiftRestriction.day] ===
                        todaysDayJsIndex &&
                        (!shouldLiftRestriction.afterMinutes ||
                            totalMinutesPassedToday >=
                                shouldLiftRestriction.afterMinutes +
                                    timezoneOffsetInMinutes)) {
                        return true;
                    }
                    return false;
                })
                : null;
            switch (restriction.type) {
                case RestrictionTypes.A_B_Z_INTERVALS_ONLY_TOGETHER: {
                    const weekNumbers = [];
                    const relevantSelectedIntervals = [];
                    const todaysLiftedRestriction = getTodaysLiftedRestriction(RestrictionTypes.A_B_Z_INTERVALS_ONLY_TOGETHER);
                    selectedIntervalsForPeriod.forEach((selectedInterval) => {
                        var _a;
                        if (restriction.theseIntervalsCanOnlyBeReservedTogether.some((mustIncludeInterval) => mustIncludeInterval.day ===
                            getDayFromDate(selectedInterval.date) &&
                            areTimeIntervalsTheSame(mustIncludeInterval.timeInterval, selectedInterval))) {
                            const selectedIntervalWeekNumber = getWeek(new Date(selectedInterval.date), {
                                weekStartsOn: (_a = restriction.weekStartsOn) !== null && _a !== void 0 ? _a : 1,
                            });
                            if (!weekNumbers.includes(selectedIntervalWeekNumber)) {
                                weekNumbers.push(selectedIntervalWeekNumber);
                            }
                            relevantSelectedIntervals.push(Object.assign(Object.assign({}, selectedInterval), { weekNumber: selectedIntervalWeekNumber }));
                        }
                    });
                    if (relevantSelectedIntervals.length > 0) {
                        for (let i = 0; i < weekNumbers.length; i++) {
                            const relevantWeekIntervals = relevantSelectedIntervals.filter((relevantSelectedInterval) => relevantSelectedInterval.weekNumber === weekNumbers[i]);
                            let liftedIntervalsNumber = 0;
                            if (todaysLiftedRestriction &&
                                todaysLiftedRestriction.shouldRemoveLiftedDaysFromRestrictionIntervals) {
                                relevantWeekIntervals.forEach((relevantWeekInterval) => {
                                    const isDayLiftedForInterval = todaysLiftedRestriction.liftForDays.some((day) => {
                                        return (dayToJsDayIndexMap[day] ===
                                            getTimelessDate(relevantWeekInterval.date).getDay());
                                    });
                                    if (isDayLiftedForInterval) {
                                        liftedIntervalsNumber++;
                                    }
                                });
                            }
                            const isIntervalInTodaysWeek = weekNumbers[i] === todaysWeekNumber;
                            const relevantIntervalsThatCanBeOnlyBeReservedTogetherLength = todaysLiftedRestriction &&
                                todaysLiftedRestriction.shouldRemoveLiftedDaysFromRestrictionIntervals &&
                                isIntervalInTodaysWeek
                                ? restriction.theseIntervalsCanOnlyBeReservedTogether.filter((mustIncludeInterval) => !todaysLiftedRestriction.liftForDays.some((day) => day === mustIncludeInterval.day)).length
                                : restriction.theseIntervalsCanOnlyBeReservedTogether
                                    .length;
                            const areAllIntervalsLifted = todaysLiftedRestriction &&
                                !todaysLiftedRestriction.shouldRemoveLiftedDaysFromRestrictionIntervals &&
                                relevantWeekIntervals.every((relevantWeekInterval) => {
                                    return todaysLiftedRestriction.liftForDays.some((day) => day ===
                                        jsDayIndexToDayMap[new Date(relevantWeekInterval.date).getDay()]);
                                });
                            if (areAllIntervalsLifted && isIntervalInTodaysWeek) {
                                continue;
                            }
                            if (relevantWeekIntervals.length - liftedIntervalsNumber !==
                                relevantIntervalsThatCanBeOnlyBeReservedTogetherLength) {
                                restrictionErrors.push(Object.assign(Object.assign({}, getPeriodPropertyValueForDate({
                                    date: getTimelessDate(new Date()),
                                    periodSensitiveProperty: restriction.errorMessage,
                                })), { priority: restriction.priority }));
                                break;
                            }
                        }
                    }
                    break;
                }
                case RestrictionTypes.AT_LEAST_X_CONTINOUS_HOURS_TOUCHING_Y_DAYS: {
                    const todaysWeekNumber = getWeek(new Date(), {
                        weekStartsOn: (_b = restriction.weekStartsOn) !== null && _b !== void 0 ? _b : 1,
                    });
                    const { consecutiveIntervalsGroups } = getSelectedIntervalsMinutesInfo({
                        selectedIntervals: selectedIntervalsForPeriod,
                        days: restriction.days,
                    });
                    const relevantIntervals = consecutiveIntervalsGroups
                        .map((group) => group.selectedIntervals)
                        .flat();
                    const todaysLiftedRestriction = getTodaysLiftedRestriction(RestrictionTypes.AT_LEAST_X_CONTINOUS_HOURS_TOUCHING_Y_DAYS);
                    for (const relevantSelectedInterval of relevantIntervals) {
                        const consecutiveIntervalsGroupForSelectedInterval = consecutiveIntervalsGroups.find((consecutiveIntervalsGroup) => consecutiveIntervalsGroup.selectedIntervals.some((consecutiveGroupInterval) => areIntervalsTheSame(consecutiveGroupInterval, relevantSelectedInterval)));
                        const areAllConsecutiveGroupIntervalsInLiftedDays = todaysLiftedRestriction &&
                            consecutiveIntervalsGroupForSelectedInterval.selectedIntervals.every((interval) => todaysLiftedRestriction.liftForDays.some((liftForDay) => {
                                var _a;
                                const intervalWeek = getWeek(new Date(interval.date), {
                                    weekStartsOn: (_a = restriction.weekStartsOn) !== null && _a !== void 0 ? _a : 1,
                                });
                                return (liftForDay ===
                                    jsDayIndexToDayMap[new Date(interval.date).getDay()] && intervalWeek === todaysWeekNumber);
                            }));
                        if ((!consecutiveIntervalsGroupForSelectedInterval ||
                            consecutiveIntervalsGroupForSelectedInterval.numberOfMinutes <
                                restriction.atLeastThisNumberOfConsecutiveMinutes) &&
                            !areAllConsecutiveGroupIntervalsInLiftedDays) {
                            restrictionErrors.push(Object.assign(Object.assign({}, getPeriodPropertyValueForDate({
                                date: getTimelessDate(new Date()),
                                periodSensitiveProperty: restriction.errorMessage,
                            })), { priority: restriction.priority }));
                            break;
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        });
    });
    const uniqueRestrictionErrors = restrictionErrors.filter((restrictionError, index, self) => {
        const indexOfFoundElement = self.findIndex((re) => re.ro === restrictionError.ro &&
            re.hu === restrictionError.hu &&
            re.en === restrictionError.en);
        return indexOfFoundElement === index;
    });
    if (uniqueRestrictionErrors.length > 1) {
        const restrictionErrorsSortedByPriority = [...uniqueRestrictionErrors].sort((a, b) => b.priority - a.priority);
        const biggestPriority = restrictionErrorsSortedByPriority[0].priority;
        const restrictionErrorsFilteredByPriority = uniqueRestrictionErrors.filter((restrictionError) => restrictionError.priority === biggestPriority);
        return restrictionErrorsFilteredByPriority;
    }
    return uniqueRestrictionErrors;
};

import { getTimelessDate } from '@reservation-app/common-used-in-web';
import { addDays, areIntervalsOverlapping } from 'date-fns';
export const areIntervalsIntersecting = (interval1, interval2) => {
    const isInterval1CrossDay = interval1.startTime >= interval1.endTime;
    const isInterval2CrossDay = interval2.startTime >= interval2.endTime;
    const interval1TimelessDate = getTimelessDate(interval1.date);
    const interval2TimelessDate = getTimelessDate(interval2.date);
    const dateFnsInterval1 = {
        start: interval1TimelessDate.getTime() + interval1.startTime,
        end: new Date(isInterval1CrossDay
            ? addDays(interval1TimelessDate, 1)
            : interval1TimelessDate).getTime() + interval1.endTime,
    };
    const dateFnsInterval2 = {
        start: new Date(interval2TimelessDate).getTime() + interval2.startTime,
        end: new Date(isInterval2CrossDay
            ? addDays(new Date(interval2TimelessDate), 1)
            : interval2TimelessDate).getTime() + interval2.endTime,
    };
    return areIntervalsOverlapping(dateFnsInterval1, dateFnsInterval2);
};

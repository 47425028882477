import { TileStatuses, IntervalStatuses, isLastIndex, Icon, useTranslationsStore, Switch, getTimelessDate, jsDayIndexToDayMap, getPeriodPropertyValueForDate, getPeriodPropertyValuesForDate, ShortcutStatuses, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { addDays, addMonths, format, isAfter, isBefore, isSameDay, startOfDay, startOfWeek, subDays, } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { areIntervalsTheSame } from '../../helpers/are-intervals-the-same';
import { getReservableIntervalByIdFromService } from '../../helpers/get-reservable-interval-by-id-from-service';
import { getStatusForCalendarDate } from '../../helpers/get-status-for-calendar-date';
import { useFormattedDateLocale } from '../../hooks/use-formatted-date-locale';
import { useStepperStateSlice, useIsAdminPanel, usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, useRemoteMasterDataSlice, useFilteredByServiceEntities, useReservableIntervalsList, } from '../../store';
import { dayToJsDayIndexMap } from '../../helpers/day-to-js-day-index';
import { ReservableInterval } from './reservable-interval';
import { ReservableShortcut } from './reservable-shortcut';
export const ReservableIntervals = () => {
    const { activeLanguage, getTranslation } = useTranslationsStore();
    const { defaultDate } = useStepperStateSlice();
    const isAdminPanel = useIsAdminPanel();
    const { selectedDate, previousSelectedDate, setSelectedDate } = usePeriodAndEntityStepSpecificSlice();
    const { selectedIntervals, selectedEntity, selectedEntityType, selectedSyncGroupKey, selectedService, } = useSelectedValuesSlice();
    const { entities } = useRemoteMasterDataSlice();
    const { showAvailableIntervalsOnly, handleShowOnlyAvailableIntervalsChange } = usePeriodAndEntityStepSpecificSlice();
    const locale = useFormattedDateLocale(activeLanguage);
    const syncGroupKeyEntity = entities.find((entity) => entity.syncGroupKey === selectedSyncGroupKey);
    const filteredByServiceEntities = useFilteredByServiceEntities();
    const { reservableIntervalsList, shortcutReservableIntervalsToList } = useReservableIntervalsList();
    const reservableIntervalsToList = useMemo(() => {
        const result = showAvailableIntervalsOnly
            ? reservableIntervalsList.availableIntervals
            : reservableIntervalsList.intervals;
        return result
            .filter((reservableInterval) => !reservableInterval.isHidden)
            .sort((a, b) => a.timeInterval.startTime - b.timeInterval.startTime);
    }, [showAvailableIntervalsOnly, reservableIntervalsList]);
    const isDayClosedForSelectedEntity = useMemo(() => {
        if (selectedEntity && selectedDate) {
            const relevantSchedule = getPeriodPropertyValueForDate({
                date: selectedDate,
                periodSensitiveProperty: selectedEntity.schedule,
            });
            const currentDay = jsDayIndexToDayMap[getTimelessDate(selectedDate).getDay()];
            const currentDayFromSchedule = relevantSchedule[currentDay];
            if (currentDayFromSchedule.isClosed) {
                return true;
            }
        }
        return false;
    }, [selectedEntity, selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime()]);
    const shouldShowOnlyAvailablePeriodsSwitch = reservableIntervalsToList.length > 3;
    const headerDateLabel = selectedDate || previousSelectedDate
        ? format(selectedDate || previousSelectedDate, 'd EEEE, yyyy', {
            locale,
        })
        : '-';
    const beforeDay = useMemo(() => {
        if (selectedDate) {
            let dayBefore = subDays(selectedDate, 1);
            let dayBeforeStatus = getStatusForCalendarDate({
                currentDate: dayBefore,
                entities: selectedEntity ? [selectedEntity] : filteredByServiceEntities,
                selectedService,
                selectedIntervals,
            });
            while (dayBeforeStatus === TileStatuses.CLOSED ||
                dayBeforeStatus === TileStatuses.RESERVED) {
                dayBefore = subDays(dayBefore, 1);
                dayBeforeStatus = getStatusForCalendarDate({
                    currentDate: dayBefore,
                    entities: selectedEntity
                        ? [selectedEntity]
                        : filteredByServiceEntities,
                    selectedService,
                    selectedIntervals,
                });
            }
            if (isBefore(dayBefore, defaultDate) &&
                !isSameDay(dayBefore, defaultDate)) {
                return undefined;
            }
            return dayBefore;
        }
        return undefined;
    }, [
        selectedDate,
        selectedEntity,
        filteredByServiceEntities,
        defaultDate === null || defaultDate === void 0 ? void 0 : defaultDate.getTime(),
    ]);
    const afterDay = useMemo(() => {
        if (selectedDate) {
            let dayAfter = addDays(selectedDate, 1);
            let dayAfterStatus = getStatusForCalendarDate({
                currentDate: dayAfter,
                entities: selectedEntity ? [selectedEntity] : filteredByServiceEntities,
                selectedService,
                selectedIntervals,
            });
            while (dayAfterStatus === TileStatuses.CLOSED ||
                dayAfterStatus === TileStatuses.RESERVED) {
                dayAfter = addDays(dayAfter, 1);
                dayAfterStatus = getStatusForCalendarDate({
                    currentDate: dayAfter,
                    entities: selectedEntity
                        ? [selectedEntity]
                        : filteredByServiceEntities,
                    selectedService,
                    selectedIntervals,
                });
            }
            const restrictedAfter = isAdminPanel
                ? undefined
                : addMonths(defaultDate, selectedEntityType.numberOfMonthsToReserveInAdvance);
            if (isAfter(dayAfter, restrictedAfter) &&
                !isSameDay(dayAfter, restrictedAfter)) {
                return undefined;
            }
            return dayAfter;
        }
        return undefined;
    }, [
        selectedDate,
        selectedEntity,
        filteredByServiceEntities,
        defaultDate,
        selectedEntityType.numberOfMonthsToReserveInAdvance,
    ]);
    const beforeIntervalVariants = {
        initial: { x: 800 },
        animate: { x: 0 },
        exit: { x: -1000 },
    };
    const afterIntervalVariants = {
        initial: { x: -800 },
        animate: { x: 0 },
        exit: { x: 1000 },
    };
    const relevantShortcuts = useMemo(() => {
        const relevantShortcutsArray = [];
        if (selectedDate && (selectedService === null || selectedService === void 0 ? void 0 : selectedService.reservableShortcuts)) {
            const periodReservableShortcuts = getPeriodPropertyValuesForDate({
                date: selectedDate,
                periodSensitiveProperty: selectedService.reservableShortcuts,
            });
            for (let i = 0; i < periodReservableShortcuts.length; i++) {
                let smallestAvailablePersons = null;
                const shortcutStatuses = [];
                let status = ShortcutStatuses.CLOSED;
                let isContainingDateInPast = false;
                periodReservableShortcuts[i].reservableIntervalsIds.forEach((shortcutIntervalId) => {
                    const relevantIntervalFromService = getReservableIntervalByIdFromService({
                        service: selectedService,
                        reservableIntervalId: shortcutIntervalId,
                    });
                    const relevantWeek = startOfWeek(selectedDate);
                    const periodReservableShortcutDays = periodReservableShortcuts[i].days;
                    for (let j = 0; j < periodReservableShortcutDays.length; j++) {
                        {
                            const dayDate = addDays(relevantWeek, periodReservableShortcutDays[j].isNextWeek
                                ? dayToJsDayIndexMap[periodReservableShortcutDays[j].value] + 7
                                : dayToJsDayIndexMap[periodReservableShortcutDays[j].value]);
                            const formattedDate = format(dayDate, 'yyyy-MM-dd');
                            if (isBefore(dayDate, startOfDay(new Date()))) {
                                isContainingDateInPast = true;
                                break;
                            }
                            const relevantEntity = selectedEntity || syncGroupKeyEntity;
                            const relevantSchedule = getPeriodPropertyValueForDate({
                                date: dayDate,
                                periodSensitiveProperty: relevantEntity.schedule,
                            });
                            const isScheduleClosedForDay = relevantSchedule[periodReservableShortcutDays[j].value]
                                .isClosed;
                            const relevantIntervalFromIntervalsToList = shortcutReservableIntervalsToList[formattedDate].intervals.find((reservableInterval) => areIntervalsTheSame(Object.assign(Object.assign({}, reservableInterval.timeInterval), { date: dayDate }), Object.assign(Object.assign({}, relevantIntervalFromService.timeInterval), { date: dayDate })));
                            if (smallestAvailablePersons &&
                                relevantIntervalFromIntervalsToList.availableNumberOfPersons <
                                    smallestAvailablePersons) {
                                smallestAvailablePersons =
                                    relevantIntervalFromIntervalsToList.availableNumberOfPersons;
                            }
                            if (isScheduleClosedForDay) {
                                shortcutStatuses.push(IntervalStatuses.CLOSED);
                            }
                            else {
                                shortcutStatuses.push(relevantIntervalFromIntervalsToList.status);
                            }
                        }
                    }
                });
                if (isContainingDateInPast) {
                    continue;
                }
                if (shortcutStatuses.some((status) => status === IntervalStatuses.CLOSED)) {
                    status = ShortcutStatuses.PARTIALLY_CLOSED;
                    if (shortcutStatuses.every((status) => status === IntervalStatuses.CLOSED)) {
                        status = ShortcutStatuses.CLOSED;
                    }
                }
                else {
                    if (shortcutStatuses.every((status) => status === IntervalStatuses.AVAILABLE)) {
                        status = ShortcutStatuses.AVAILABLE;
                    }
                    else if (shortcutStatuses.some((status) => status === IntervalStatuses.RESERVED)) {
                        status = ShortcutStatuses.PARTIALLY_RESERVED;
                        if (shortcutStatuses.every((status) => status === IntervalStatuses.RESERVED)) {
                            status = ShortcutStatuses.RESERVED;
                        }
                    }
                }
                relevantShortcutsArray.push(Object.assign(Object.assign({}, periodReservableShortcuts[i]), { status, availableNumberOfPersons: smallestAvailablePersons }));
            }
        }
        return relevantShortcutsArray;
    }, [reservableIntervalsList]);
    const areAllReservableIntervalsToListHidden = reservableIntervalsToList.every((interval) => interval.isHidden === true);
    return (React.createElement("div", { className: "w-full bg-white shadow-lg rounded-lg pb-5 768:mb-0 scroll-mt-[200px]", id: "reservable-intervals-list" },
        React.createElement("div", { className: "flex items-center justify-between px-4 py-2 mb-2 text-sm font-semibold rounded-t-lg bg-primary-main" },
            React.createElement(Icon, { icon: "chevronLeft-2", className: classNames('text-white w-6 h-6 cursor-pointer', {
                    'opacity-50 pointer-events-none': !beforeDay,
                }), onClick: () => setSelectedDate(beforeDay) }),
            React.createElement(motion.p, { key: selectedDate || previousSelectedDate
                    ? (selectedDate || previousSelectedDate).getDate()
                    : 'no-date', initial: { scale: 0.75, opacity: 0 }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0.75, opacity: 0 }, transition: { type: 'tween' }, className: "text-white capitalize" }, headerDateLabel),
            React.createElement(Icon, { icon: "chevronRight-2", className: classNames('text-white w-6 h-6 cursor-pointer', {
                    'opacity-50 pointer-events-none': !afterDay,
                }), onClick: () => setSelectedDate(afterDay) })),
        !isDayClosedForSelectedEntity ? (React.createElement("div", { className: "flex flex-col w-full px-4" },
            React.createElement("p", { className: classNames('font-semibold text-gray-900', {
                    'mb-2': !shouldShowOnlyAvailablePeriodsSwitch,
                    'mb-1': shouldShowOnlyAvailablePeriodsSwitch,
                }) }, getTranslation('clickToSelectPeriods')),
            shouldShowOnlyAvailablePeriodsSwitch && (React.createElement("div", { className: "flex items-center mb-2" },
                React.createElement(Switch, { size: "lg", containerClassName: "reservable-intervals-show-only-available-intervals-button mr-2", value: showAvailableIntervalsOnly, onChange: (value) => handleShowOnlyAvailableIntervalsChange(value) }),
                React.createElement("p", { className: "text-sm font-semibold text-gray-500" }, getTranslation('showOnlyAvailableIntervals')))),
            React.createElement("div", { className: classNames('flex flex-col w-full overflow-y-auto overflow-x-hidden pb-1', {
                    '768:max-h-[256px]': !shouldShowOnlyAvailablePeriodsSwitch,
                    '768:max-h-[242px]': shouldShowOnlyAvailablePeriodsSwitch,
                }) },
                reservableIntervalsToList.length > 0 ? (reservableIntervalsToList.map((reservableInterval, index) => {
                    const relevantEntity = selectedEntity || syncGroupKeyEntity;
                    const isLastInterval = isLastIndex(index, reservableIntervalsToList);
                    return (React.createElement(ReservableInterval, { reservableInterval: reservableInterval, afterIntervalVariants: afterIntervalVariants, beforeIntervalVariants: beforeIntervalVariants, relevantEntity: relevantEntity, isLastInterval: isLastInterval, index: index }));
                })) : (React.createElement("div", { className: classNames('flex items-center justify-center h-full text-gray-900', {
                        hidden: areAllReservableIntervalsToListHidden,
                    }) }, getTranslation('noAvailableIntervalsToShow'))),
                relevantShortcuts.length > 0 &&
                    !areAllReservableIntervalsToListHidden && (React.createElement("div", { className: "flex items-center py-2" },
                    React.createElement("div", { className: "h-0.5 w-full bg-gray-400" }),
                    React.createElement("p", { className: "px-1 font-semibold text-gray-500 uppercase" }, getTranslation('or')),
                    React.createElement("div", { className: "h-0.5 w-full bg-gray-400" }))), relevantShortcuts === null || relevantShortcuts === void 0 ? void 0 :
                relevantShortcuts.map((reservableShortcut, index) => {
                    const relevantEntity = selectedEntity || syncGroupKeyEntity;
                    const isLastReservableShortcut = isLastIndex(index, reservableIntervalsToList);
                    return (React.createElement(ReservableShortcut, { reservableShortcut: reservableShortcut, relevantEntity: relevantEntity, isLastReservableShortcut: isLastReservableShortcut, afterIntervalVariants: afterIntervalVariants, beforeIntervalVariants: beforeIntervalVariants, index: index }));
                })))) : (React.createElement("p", { className: "p-2 font-semibold text-gray-700" }, `${getPeriodPropertyValueForDate({
            date: new Date(),
            periodSensitiveProperty: selectedEntity.uiProps.name,
        })[activeLanguage]} ${getTranslation('closed')}`))));
};

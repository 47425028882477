import { getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
export const getEntityCapacity = ({ entity, selectedIntervals, selectedDate, }) => {
    if (entity.capacity) {
        return entity.capacity;
    }
    if (selectedDate) {
        return getPeriodPropertyValueForDate({
            date: selectedDate,
            periodSensitiveProperty: entity.periodCapacity,
        });
    }
    const relevantCapacities = [];
    (selectedIntervals || []).forEach((interval) => {
        const relevantCapacity = getPeriodPropertyValueForDate({
            date: interval.date,
            periodSensitiveProperty: entity.periodCapacity,
        });
        relevantCapacities.push(relevantCapacity);
    });
    relevantCapacities.sort((a, b) => a - b);
    return relevantCapacities[0];
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { addDays, addMonths, endOfMonth, endOfWeek, format, isAfter, isSameDay, startOfMonth, isBefore, isSameMonth, } from 'date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import classNames from 'classnames';
import { ro, hu } from 'date-fns/locale';
import CalendarCell from './calendar-cell';
import { motion } from 'framer-motion';
export const useMultipleSelectCalendarState = (initialState) => {
    const [selectedDates, setState] = useState(initialState);
    const setSelectedDates = useCallback((date) => {
        const selectedDateIndex = selectedDates.findIndex((selectedDate) => isSameDay(date, selectedDate));
        if (selectedDateIndex === -1) {
            setState((prevState) => prevState.concat(date));
        }
        else {
            setState((prevState) => {
                const newState = [...prevState];
                newState.splice(selectedDateIndex, 1);
                return newState;
            });
        }
    }, [selectedDates]);
    return [selectedDates, setSelectedDates];
};
const Div = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("div", Object.assign({}, props), children));
};
export const AgCalendar = ({ calendarClassName = '', headerContainerClassName, monthClassName, monthTitle, weekDaysClassName, weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'], leftArrowIcon, rightArrowIcon, arrowsClassName, allTilesClassName = '', disabledTilesClassName = '', activeTilesClassName = '', selectedTileClassName = '', todayTileClassName = '', selectedDate, selectHandler, CellComponent, language, restrictedBefore, restrictedAfter, withLayoutAnimation, }) => {
    const [currentMonth, setCurrentMonth] = useState(selectedDate || new Date());
    useEffect(() => {
        if (selectedDate && !isSameMonth(selectedDate, currentMonth)) {
            setCurrentMonth(selectedDate);
        }
    }, [selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime()]);
    const goToNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };
    const goToPrevMonth = () => {
        setCurrentMonth(addMonths(currentMonth, -1));
    };
    let locale;
    if (language === 'ro') {
        locale = ro;
    }
    else if (language === 'hu') {
        locale = hu;
    }
    else {
        locale = undefined;
    }
    const datesInfo = useMemo(() => {
        const nextMonth = addMonths(currentMonth, 1);
        const isNextMonthNotRestricted = restrictedAfter
            ? isBefore(nextMonth, restrictedAfter)
            : true;
        const isNextMonthArrowNotRestricted = restrictedAfter
            ? isBefore(startOfMonth(nextMonth), addDays(new Date(restrictedAfter), 1))
            : true;
        const prevMonth = addMonths(currentMonth, -1);
        const isPrevMonthNotRestricted = restrictedBefore
            ? isAfter(prevMonth, restrictedBefore)
            : true;
        const isPrevMonthArrowNotRestricted = restrictedBefore
            ? isAfter(endOfMonth(prevMonth), new Date(restrictedBefore))
            : true;
        return {
            nextMonth,
            isNextMonthNotRestricted,
            isNextMonthArrowNotRestricted,
            prevMonth,
            isPrevMonthNotRestricted,
            isPrevMonthArrowNotRestricted,
        };
    }, [
        currentMonth === null || currentMonth === void 0 ? void 0 : currentMonth.getTime(),
        restrictedAfter === null || restrictedAfter === void 0 ? void 0 : restrictedAfter.getTime(),
        restrictedBefore === null || restrictedBefore === void 0 ? void 0 : restrictedBefore.getTime(),
    ]);
    const monthStart = useMemo(() => startOfMonth(currentMonth), [currentMonth]);
    const monthEnd = useMemo(() => endOfMonth(currentMonth), [currentMonth]);
    const daysContainerRef = useRef(null);
    const selectedDateButtonRef = useRef(null);
    const monthHeader = useMemo(() => {
        const dateFormat = 'yyyy MMMM';
        return (React.createElement("div", { className: classNames('calendar-header', headerContainerClassName) },
            React.createElement("button", { type: "button", className: classNames('calendar-arrow-container', {
                    'pointer-events-none text-gray-300': !datesInfo.isPrevMonthArrowNotRestricted,
                }, arrowsClassName), onClick: () => {
                    if (datesInfo.isPrevMonthArrowNotRestricted) {
                        goToPrevMonth();
                    }
                    if (!restrictedBefore) {
                        goToPrevMonth();
                    }
                } }, leftArrowIcon ? (leftArrowIcon(!datesInfo.isPrevMonthArrowNotRestricted, currentMonth)) : (React.createElement("svg", { stroke: "currentColor", fill: "none", strokeWidth: "0", viewBox: "0 0 24 24", height: "1.3em", width: "1.3em", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M15 19l-7-7 7-7" })))),
            React.createElement("span", { className: classNames('month-class', monthClassName) }, monthTitle
                ? monthTitle(currentMonth)
                : format(currentMonth, dateFormat, { locale })),
            React.createElement("button", { type: "button", className: classNames('calendar-arrow-container', {
                    'pointer-events-none text-gray-300': !datesInfo.isNextMonthArrowNotRestricted,
                }, arrowsClassName), onClick: () => {
                    if (datesInfo.isNextMonthArrowNotRestricted) {
                        goToNextMonth();
                    }
                    if (!restrictedAfter) {
                        goToNextMonth();
                    }
                } }, rightArrowIcon ? (rightArrowIcon(!datesInfo.isNextMonthArrowNotRestricted, currentMonth)) : (React.createElement("svg", { stroke: "currentColor", fill: "none", strokeWidth: "0", viewBox: "0 0 24 24", height: "1.3em", width: "1.3em", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M9 5l7 7-7 7" }))))));
    }, [monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime()]);
    const dayLabels = useMemo(() => (React.createElement("div", { className: classNames('days-container', weekDaysClassName) }, weekDays.map((day, index) => (React.createElement("div", { className: "days-class", key: `${index}-${day}` }, day))))), [monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(), weekDaysClassName]);
    const dayCells = useMemo(() => {
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
        const dateFormat = 'd';
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = '';
        let foundTheSelectedDate = false;
        while (day <= endDate) {
            for (let i = 0; i < 7; i += 1) {
                formattedDate = format(day, dateFormat, { locale });
                let isSelected = false;
                if (selectedDate && !foundTheSelectedDate) {
                    isSelected = isSameDay(day, selectedDate);
                    foundTheSelectedDate = true;
                }
                days.push(React.createElement(CalendarCell, { key: day.getTime(), day: day, isSelected: isSelected, selectedDateButtonRef: selectedDateButtonRef, activeTilesClassName: activeTilesClassName, monthStart: monthStart, restrictedAfter: restrictedAfter, restrictedBefore: restrictedBefore, disabledTilesClassName: disabledTilesClassName, selectedTileClassName: selectedTileClassName, todayTileClassName: todayTileClassName, allTilesClassName: allTilesClassName, selectHandler: selectHandler, CellComponent: CellComponent, formattedDate: formattedDate }));
                day = addDays(day, 1);
            }
            if (days.length > 0) {
                rows.push(React.createElement("div", { className: "row-item", key: day.valueOf() }, days));
            }
            days = [];
        }
        return (React.createElement("div", { className: "rows", tabIndex: 0, ref: daysContainerRef }, rows));
    }, [monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(), activeTilesClassName]);
    const WrapperComponent = useMemo(() => (withLayoutAnimation ? motion.div : Div), [withLayoutAnimation]);
    return (React.createElement(WrapperComponent, { layout: withLayoutAnimation ? 'size' : undefined, className: classNames('calendar', calendarClassName) },
        monthHeader,
        dayLabels,
        dayCells));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { TooltipContent } from '../steps/select-period-and-entity/legend-tooltip';
import { Button, getPeriodPropertyValueForDate, Icon, ReservationSteps, ReservationSubSteps, Switch, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { Tooltip } from '../../components';
import { useEntitiesWithStatus, useIsDesktop, useIsVerifyAvailabilityMode, usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, useStepperStateSlice, } from '../store';
const LOCAL_STORAGE_TOOLTIP_WAS_USED_KEY = 'wasTooltipUsed';
export const StepWrapper = ({ children, withAvailabilityInfoTooltip, wrapperClassName, withStepHeaderText = true, step, subStep, shouldAlignCenterOnMobile, withFirstStepInfo = true, }) => {
    const { shouldAnimateTootlipIcon, setShouldAnimateTootlipIcon } = usePeriodAndEntityStepSpecificSlice();
    const { steps, defaultDate } = useStepperStateSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const isVerifyAvailabilityMode = useIsVerifyAvailabilityMode();
    const activeLanguage = useActiveLanguage();
    const isDesktop = useIsDesktop();
    const getTranslation = useGetTranslation();
    const entitiesWithStatus = useEntitiesWithStatus();
    const { setIsBirdEyeModalOpen, showAvailableEntitiesOnly, handleShowOnlyAvailableEntitiesChange, } = usePeriodAndEntityStepSpecificSlice();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const wasTooltipUsed = localStorage.getItem(LOCAL_STORAGE_TOOLTIP_WAS_USED_KEY);
            if (!wasTooltipUsed) {
                setShouldAnimateTootlipIcon(true);
            }
        }))();
    }, []);
    const shouldHideCurrentStep = useMemo(() => step === ReservationSteps.SELECT_PERIOD_AND_ENTITY &&
        subStep === ReservationSubSteps.SELECT_ENTITY &&
        entitiesWithStatus.length === 1, [step, subStep, entitiesWithStatus]);
    const currentStepInfo = useMemo(() => {
        var _a, _b, _c;
        const currentStepObject = steps === null || steps === void 0 ? void 0 : steps.find(({ step: reservationStep }) => reservationStep === step);
        if (((_a = currentStepObject === null || currentStepObject === void 0 ? void 0 : currentStepObject.stepInfo) === null || _a === void 0 ? void 0 : _a.length) === 1) {
            return (_b = currentStepObject === null || currentStepObject === void 0 ? void 0 : currentStepObject.stepInfo) === null || _b === void 0 ? void 0 : _b[0];
        }
        return (_c = currentStepObject === null || currentStepObject === void 0 ? void 0 : currentStepObject.stepInfo) === null || _c === void 0 ? void 0 : _c.find((stepInfoObject) => stepInfoObject.subStep === subStep);
    }, [steps, step, subStep]);
    const tooltipIconVariants = shouldAnimateTootlipIcon
        ? {
            initial: { opacity: 0 },
            exit: { opacity: 0 },
            animate: {
                opacity: 1,
                rotate: [0, 30, -30, 30, -30, 0],
                transition: {
                    type: 'spring',
                    duration: 3,
                    delay: 0.5,
                },
            },
        }
        : {
            initial: { opacity: 0, rotate: 0 },
            exit: { opacity: 0, rotate: 0 },
            animate: {
                rotate: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    delay: 0.5,
                },
            },
        };
    const icon = useMemo(() => {
        var _a, _b, _c;
        if (isVerifyAvailabilityMode &&
            ((_a = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.verifyModeIcon) === null || _a === void 0 ? void 0 : _a[activeLanguage])) {
            return currentStepInfo.verifyModeIcon[activeLanguage];
        }
        if ((_b = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.icon) === null || _b === void 0 ? void 0 : _b[activeLanguage]) {
            return (_c = currentStepInfo.icon) === null || _c === void 0 ? void 0 : _c[activeLanguage];
        }
        return '';
    }, [currentStepInfo, isVerifyAvailabilityMode]);
    const headingText = useMemo(() => {
        var _a, _b, _c, _d;
        let headingTextFromAdmin;
        if (subStep === ReservationSubSteps.SELECT_ENTITY) {
            headingTextFromAdmin = ((_a = selectedEntityType.uiProps.translations
                .anEntitySubject) === null || _a === void 0 ? void 0 : _a[activeLanguage])
                ? getTranslation('chooseEntity', {
                    anEntitySubject: (_b = selectedEntityType.uiProps.translations.anEntitySubject) === null || _b === void 0 ? void 0 : _b[activeLanguage].toLowerCase(),
                })
                : '';
        }
        if ((_c = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.headingText) === null || _c === void 0 ? void 0 : _c[activeLanguage]) {
            headingTextFromAdmin = currentStepInfo.headingText[activeLanguage];
        }
        if (isVerifyAvailabilityMode &&
            ((_d = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.verifyModeHeadingText) === null || _d === void 0 ? void 0 : _d[activeLanguage])) {
            headingTextFromAdmin =
                currentStepInfo.verifyModeHeadingText[activeLanguage];
        }
        if (headingTextFromAdmin) {
            return headingTextFromAdmin;
        }
        if (step === ReservationSteps.SELECT_ENTITY_TYPE &&
            !isVerifyAvailabilityMode) {
            return getTranslation('selectEntityTypeHeaderText');
        }
        if (step === ReservationSteps.SELECT_ENTITY_TYPE &&
            isVerifyAvailabilityMode) {
            return getTranslation('selectEntityTypeHeaderVerifyFlowText');
        }
        if (step === ReservationSteps.SELECT_SERVICE) {
            return getTranslation('selectServiceHeaderText');
        }
        if (step === ReservationSteps.SELECT_PERIOD ||
            subStep === ReservationSubSteps.SELECT_PERIOD) {
            return getTranslation('selectPeriodHeaderText');
        }
        if (step === ReservationSteps.SELECT_ENTITY ||
            subStep === ReservationSubSteps.SELECT_ENTITY) {
            return '';
        }
        if (step === ReservationSteps.SELECT_EXTRAS) {
            return getTranslation('selectExtrasHeaderText');
        }
        if (step === ReservationSteps.BILLING_DETAILS) {
            return getTranslation('billingDetailsHeaderText');
        }
        if (step === ReservationSteps.PAYMENT_DETAILS) {
            return getTranslation('paymentDetailsHeaderText');
        }
        return '';
    }, [currentStepInfo, isVerifyAvailabilityMode]);
    const secondaryText = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (isVerifyAvailabilityMode &&
            ((_a = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.verifyModeSecondaryText) === null || _a === void 0 ? void 0 : _a[activeLanguage])) {
            return currentStepInfo.verifyModeSecondaryText[activeLanguage];
        }
        if ((_b = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.secondaryText) === null || _b === void 0 ? void 0 : _b[activeLanguage]) {
            return (_c = currentStepInfo.secondaryText) === null || _c === void 0 ? void 0 : _c[activeLanguage];
        }
        if (step === ReservationSteps.SELECT_PERIOD_AND_ENTITY &&
            subStep === ReservationSubSteps.SELECT_PERIOD &&
            ((_d = selectedEntityType.uiProps.translations.anEntitySubject) === null || _d === void 0 ? void 0 : _d[activeLanguage])) {
            return getTranslation('selectPeriodSecondaryText', {
                anEntitySubject: (_e = selectedEntityType.uiProps.translations.anEntitySubject) === null || _e === void 0 ? void 0 : _e[activeLanguage].toLowerCase(),
            });
        }
        if (step === ReservationSteps.SELECT_EXTRAS) {
            return getTranslation('selectExtrasSecondaryText');
        }
        return '';
    }, [currentStepInfo, isVerifyAvailabilityMode, subStep, entitiesWithStatus]);
    const bodyText = useMemo(() => {
        var _a, _b, _c, _d;
        if (isDesktop) {
            if (isVerifyAvailabilityMode &&
                ((_a = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.verifyModeDesktopBodyText) === null || _a === void 0 ? void 0 : _a[activeLanguage])) {
                return currentStepInfo.verifyModeDesktopBodyText[activeLanguage];
            }
            return ((_b = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.desktopBodyText) === null || _b === void 0 ? void 0 : _b[activeLanguage]) || '';
        }
        if (!isDesktop) {
            if (isVerifyAvailabilityMode &&
                ((_c = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.verifyModeMobileBodyText) === null || _c === void 0 ? void 0 : _c[activeLanguage])) {
                return currentStepInfo.verifyModeMobileBodyText[activeLanguage];
            }
            return ((_d = currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.mobileBodyText) === null || _d === void 0 ? void 0 : _d[activeLanguage]) || '';
        }
        return '';
    }, [currentStepInfo, isVerifyAvailabilityMode, isDesktop]);
    const showOnlyAvailableEntitiesSwitch = useMemo(() => {
        var _a, _b, _c;
        if (subStep === ReservationSubSteps.SELECT_ENTITY &&
            entitiesWithStatus.length > 3 &&
            !((_b = (_a = selectedEntityType.interactiveBirdEyeViewSvg) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b[activeLanguage])) {
            return (React.createElement("div", { className: "flex items-center mt-2 mb-3" },
                React.createElement(Switch, { size: "lg", containerClassName: "select-entity-list-show-only-available-entities-button mr-2", value: showAvailableEntitiesOnly, onChange: (value) => handleShowOnlyAvailableEntitiesChange(value) }),
                React.createElement("p", { className: "text-sm font-semibold text-gray-500" }, getTranslation('showOnlyAvailableEntities', {
                    entities: (_c = selectedEntityType.uiProps.translations.entities) === null || _c === void 0 ? void 0 : _c[activeLanguage],
                }))));
        }
        return undefined;
    }, [showAvailableEntitiesOnly, subStep, entitiesWithStatus]);
    const birdEyeImageValue = selectedEntityType
        ? getPeriodPropertyValueForDate({
            periodSensitiveProperty: selectedEntityType.uiProps.birdEyeImage,
            date: defaultDate,
        })
        : undefined;
    const birdEyeButton = useMemo(() => {
        var _a, _b;
        if (subStep === ReservationSubSteps.SELECT_ENTITY &&
            birdEyeImageValue &&
            !((_b = (_a = selectedEntityType.interactiveBirdEyeViewSvg) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b[activeLanguage])) {
            return (React.createElement(Button, { className: "self-start hidden ml-2 900:flex", size: "md", onClick: () => setIsBirdEyeModalOpen(true) }, getTranslation('showBirdEyeView')));
        }
        return undefined;
    }, [subStep, birdEyeImageValue]);
    const isFirstStep = steps[0].step === step;
    const bottomMostItem = useMemo(() => {
        if (bodyText) {
            return 'bodyText';
        }
        if (showOnlyAvailableEntitiesSwitch) {
            return 'showOnlyAvailableEntitiesSwitch';
        }
        if ((isFirstStep || bodyText) && withFirstStepInfo) {
            return 'firstStepInfo';
        }
        if (secondaryText) {
            return 'secondaryText';
        }
        if (headingText) {
            return 'headingText';
        }
        return '';
    }, [
        bodyText,
        showOnlyAvailableEntitiesSwitch,
        isFirstStep,
        withFirstStepInfo,
        secondaryText,
        headingText,
    ]);
    if (shouldHideCurrentStep) {
        return null;
    }
    return (React.createElement("div", { className: classNames('flex flex-col pb-28 768:pb-0 mt-2 768:mt-8 w-full bg-gray-50', wrapperClassName) },
        React.createElement("div", { className: "flex justify-between" },
            React.createElement("div", { className: classNames('flex flex-col flex-shrink-1 w-full', {
                    'items-center': !isDesktop && shouldAlignCenterOnMobile,
                    '!items-stretch': isDesktop,
                }) },
                withStepHeaderText && (React.createElement("div", { className: classNames('flex items-center', {
                        'mb-5': bottomMostItem === 'headingText',
                        'mb-3': !secondaryText &&
                            !showOnlyAvailableEntitiesSwitch &&
                            bottomMostItem !== 'headingText',
                    }) },
                    React.createElement("p", { className: classNames('step-header') }, headingText),
                    icon && (React.createElement(Icon, { icon: icon, className: "flex-shrink-0 w-6 h-6 ml-2" })),
                    React.createElement(AnimatePresence, null, withAvailabilityInfoTooltip && (React.createElement(motion.div, { variants: tooltipIconVariants, initial: "initial", animate: "animate", className: "flex items-center justify-center ml-2" },
                        React.createElement(Tooltip, { trigger: "click", content: React.createElement(TooltipContent, null), contentClassName: "bg-white shadow-md", arrowClassName: "hidden", direction: "bottom", onOpen: () => {
                                if (shouldAnimateTootlipIcon) {
                                    localStorage.setItem(LOCAL_STORAGE_TOOLTIP_WAS_USED_KEY, 'true');
                                    setShouldAnimateTootlipIcon(false);
                                }
                            } },
                            React.createElement("button", { className: "flex items-center justify-center tooltip-icon" },
                                React.createElement(Icon, { icon: "infoWithCircle", className: "flex-shrink-0 w-8 h-8 text-primary-main" })))))))),
                secondaryText && withStepHeaderText && (React.createElement("p", { className: classNames('mb-3 text-xs font-normal text-gray-900', {
                        '!mb-5': bottomMostItem === 'secondaryText',
                    }) }, secondaryText)),
                showOnlyAvailableEntitiesSwitch && (React.createElement("div", { className: classNames({
                        'mb-5': bottomMostItem === 'showOnlyAvailableEntitiesSwitch',
                    }) }, showOnlyAvailableEntitiesSwitch)),
                bodyText && (React.createElement("div", { className: classNames('text-gray-900', {
                        'mb-5': bottomMostItem === 'bodyText',
                    }), dangerouslySetInnerHTML: {
                        __html: bodyText,
                    } }))),
            birdEyeButton),
        children));
};

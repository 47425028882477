export const getTimelessDate = (date) => {
    if (date instanceof Date) {
        const timelessDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return timelessDate;
    }
    if (typeof date === 'string') {
        let dateParts = [];
        if (date.includes('T')) {
            dateParts = date.split('T')[0].split('-');
        }
        else if (date.indexOf('-') === 4) {
            dateParts = date.split('-');
        }
        else {
            dateParts = date.split('-').reverse();
        }
        const timelessDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
        return timelessDate;
    }
    if (typeof date === 'number') {
        const dateFromNumber = new Date(date);
        const timelessDate = new Date(dateFromNumber.getFullYear(), dateFromNumber.getMonth(), dateFromNumber.getDay());
        return timelessDate;
    }
    throw new Error(`Invalid date parameter for getTimelessDate: ${date}`);
};

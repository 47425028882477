import React, { useEffect } from 'react';
import { ExtraCard } from './extra-card';
import { StepWrapper } from '../../layout/step-wrapper';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { useSelectableExtras, useSelectedValuesSlice } from '../../store';
import { getTimelessDate, useGetTranslation, getPeriodPropertyValuesForDate, ReservationSteps, } from '@reservation-app/common-used-in-web';
export const SelectExtrasStep = () => {
    const getTranslation = useGetTranslation();
    const selectableExtras = useSelectableExtras();
    const { selectedIntervals, selectedService, setSelectedIntervals } = useSelectedValuesSlice();
    useEffect(() => {
        const selectedIntervalsWithExtras = selectedIntervals.map((selectedInterval) => {
            const relevantDay = getTimelessDate(selectedInterval.date);
            const relevantReservableIntervals = getPeriodPropertyValuesForDate({
                periodSensitiveProperty: selectedService.reservableIntervals,
                date: relevantDay,
            });
            const relevantReservableInterval = relevantReservableIntervals.find((ric) => ric.timeInterval.endTime ===
                selectedInterval.reservableInterval.timeInterval.endTime &&
                ric.timeInterval.startTime ===
                    selectedInterval.reservableInterval.timeInterval.startTime);
            return Object.assign(Object.assign({}, selectedInterval), { reservableInterval: Object.assign(Object.assign({}, selectedInterval.reservableInterval), { extras: relevantReservableInterval.extras }) });
        });
        setSelectedIntervals(selectedIntervalsWithExtras);
    }, []);
    return (React.createElement(StepWrapper, { step: ReservationSteps.SELECT_EXTRAS }, (selectableExtras === null || selectableExtras === void 0 ? void 0 : selectableExtras.length) > 0 ? (React.createElement(AnimatePresence, null,
        React.createElement(LayoutGroup, null,
            React.createElement("div", { className: "flex flex-col space-y-6" }, selectableExtras.map((extra) => (React.createElement(ExtraCard, { extra: extra, key: extra._id }))))))) : (getTranslation('noExtrasForThisCategory'))));
};

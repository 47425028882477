var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReservationSteps, } from '@reservation-app/common-used-in-web';
import { getConflictingIntervals } from '../../../reservation-flow/helpers/get-conflicting-intervals';
import { getEntitiesPopuplatedWithReservedIntervals } from '../../helpers/get-entities-popuplated-with-reserved-intervals';
import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createRemoteMasterDataPropertiesSlice = (set, get) => ({
    entities: [],
    setEntities: (entities, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.entities = entities;
    }, false, getDevtoolsLogMessage('setEntities', extraDevtoolsInfo)),
    syncGroupKeys: [],
    setSyncGroupKeys: (syncGroupKeys, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.syncGroupKeys = syncGroupKeys;
    }, false, getDevtoolsLogMessage('setSyncGroupKeys', extraDevtoolsInfo)),
    extras: [],
    setExtras: (extras, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.extras = extras;
    }, false, getDevtoolsLogMessage('setExtras', extraDevtoolsInfo)),
    promotions: [],
    setPromotions: (promotions, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.promotions = promotions;
    }, false, getDevtoolsLogMessage('setPromotions', extraDevtoolsInfo)),
    entityTypes: [],
    setEntityTypes: (entityTypes, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.entityTypes = entityTypes;
    }, false, getDevtoolsLogMessage('setEntityTypes', extraDevtoolsInfo)),
    services: [],
    setServices: (services, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.services = services;
    }, false, getDevtoolsLogMessage('setServices', extraDevtoolsInfo)),
    companyOfficialData: null,
    setCompanyOfficialData: (companyOfficialData, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.companyOfficialData = companyOfficialData;
    }, false, getDevtoolsLogMessage('setCompanyOfficialData', extraDevtoolsInfo)),
    companyReservationConfig: null,
    setCompanyReservationConfig: (companyReservationConfig, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.companyReservationConfig =
            companyReservationConfig;
    }, false, getDevtoolsLogMessage('setCompanyReservationConfig', extraDevtoolsInfo)),
    companyAvailabilityCheckConfig: null,
    setCompanyAvailabilityCheckConfig: (companyAvailabilityCheckConfig, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.companyAvailabilityCheckConfig =
            companyAvailabilityCheckConfig;
    }, false, getDevtoolsLogMessage('setCompanyAvailabilityCheckConfig', extraDevtoolsInfo)),
    isDerivingInitialStateFromRemoteData: true,
    setIsDerivingInitialStateFromRemoteData: (isDerivingInitialStateFromRemoteData, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.isDerivingInitialStateFromRemoteData =
            isDerivingInitialStateFromRemoteData;
    }, false, getDevtoolsLogMessage('setIsDerivingInitialStateFromRemoteData', extraDevtoolsInfo)),
    deriveInitialStateFromRemoteData: (companyReservationConfig, extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { remoteMasterData, billingDetailsStepSpecific: { setBillingDetails, billingDetails: { fullName }, }, selectedValues: { setSelectedEntityType, setSelectedService }, stepperState: { tryToEnterPasswordFromLocalStorage }, static: { staticProps: { isAdminPanel }, }, } = get();
        let relevantSteps;
        const areStepsInCompany = ((_a = companyReservationConfig.steps) === null || _a === void 0 ? void 0 : _a.length) > 0;
        const isOnlyOneEntityType = remoteMasterData.entityTypes.length === 1;
        const isOnlyOneService = remoteMasterData.services.length === 1;
        const areBillingDetailsCached = !!fullName;
        if (isOnlyOneEntityType) {
            const onlyEntityType = remoteMasterData.entityTypes[0];
            setSelectedEntityType(onlyEntityType);
            if (!areBillingDetailsCached) {
                setBillingDetails('shouldSendEmail', onlyEntityType.withEmailSendingToClients);
            }
            if (areStepsInCompany) {
                relevantSteps = companyReservationConfig.steps;
            }
            if (!areStepsInCompany) {
                relevantSteps = onlyEntityType.steps;
            }
            if (isOnlyOneService) {
                setSelectedService(remoteMasterData.services[0]);
            }
            if (onlyEntityType.withPassword || onlyEntityType.withMembershipCard) {
                const wasPasswordAcceptedAndRestored = yield tryToEnterPasswordFromLocalStorage(onlyEntityType);
                if (wasPasswordAcceptedAndRestored) {
                    relevantSteps = relevantSteps.filter(({ step }) => step !== ReservationSteps.PASSWORD);
                }
            }
            if (onlyEntityType.withMembershipCard && !isAdminPanel) {
                relevantSteps = relevantSteps.filter(({ step }) => step !== ReservationSteps.BILLING_DETAILS);
            }
        }
        set((state) => {
            state.remoteMasterData.companyReservationConfig =
                companyReservationConfig;
            if (areStepsInCompany && !isOnlyOneEntityType) {
                relevantSteps = companyReservationConfig.steps;
            }
            if (!areStepsInCompany && !isOnlyOneEntityType) {
                relevantSteps = [
                    state.remoteMasterData.entityTypes[0].steps.find(({ step }) => step === ReservationSteps.SELECT_ENTITY_TYPE),
                ];
            }
            if (state.static.staticProps.isVerifyAvailabilityMode) {
                relevantSteps = relevantSteps.filter(({ step }) => step !== ReservationSteps.BILLING_DETAILS &&
                    step !== ReservationSteps.PAYMENT_DETAILS &&
                    step !== ReservationSteps.PASSWORD);
            }
            const steps = relevantSteps.filter(({ step }) => {
                if (state.static.staticProps.isAdminPanel &&
                    (step === ReservationSteps.PAYMENT_DETAILS ||
                        step === ReservationSteps.PASSWORD)) {
                    return false;
                }
                return true;
            });
            state.stepperState.steps = steps;
            state.stepperState.currentStep = steps[0].step;
            state.remoteMasterData.isDerivingInitialStateFromRemoteData = false;
        }, false, getDevtoolsLogMessage('deriveInitialStateFromRemoteData', extraDevtoolsInfo));
    }),
    companyBuildInfo: null,
    setCompanyBuildInfo: (companyBuildInfo, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.companyBuildInfo = companyBuildInfo;
    }, false, getDevtoolsLogMessage('setCompanyBuildInfo', extraDevtoolsInfo)),
    handleReservedIntervalsResponse: (reservedIntervals, extraDevtoolsInfo) => set((state) => {
        const { remoteMasterData: { entities }, selectedValues: { selectedIntervals, selectedEntity }, } = get();
        state.remoteMasterData.entities =
            getEntitiesPopuplatedWithReservedIntervals({
                toBePopulatedEntities: entities,
                reservedIntervals,
            });
        if (state.selectedValues.selectedEntity) {
            state.selectedValues.selectedEntity =
                getEntitiesPopuplatedWithReservedIntervals({
                    toBePopulatedEntities: [selectedEntity],
                    reservedIntervals,
                })[0];
        }
        if (selectedIntervals && selectedEntity) {
            const conflictingSelectedIntervals = getConflictingIntervals({
                reservedIntervals,
                selectedEntity,
                selectedIntervals,
            });
            if (conflictingSelectedIntervals.length > 0) {
                state.modalStates.isConflictingSelectedIntervalsModalOpen = true;
                state.modalStates.conflictingSelectedIntervals =
                    conflictingSelectedIntervals;
            }
        }
    }, false, getDevtoolsLogMessage('handleReservedIntervalsResponse', extraDevtoolsInfo)),
    alerts: [],
    setAlerts: (alerts, extraDevtoolsInfo) => set((state) => {
        state.remoteMasterData.alerts = alerts;
    }, false, getDevtoolsLogMessage('setAlerts', extraDevtoolsInfo)),
});

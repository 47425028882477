import { isDateInPeriod } from './is-date-in-period';
export const getPeriodPropertyForDate = ({ periodSensitiveProperty, date, selectedServiceId, }) => {
    const propertiesForPeriod = [];
    const shouldIgnoreServiceId = Boolean(selectedServiceId) &&
        periodSensitiveProperty.every((periodProperty) => periodProperty.serviceId !== selectedServiceId);
    periodSensitiveProperty === null || periodSensitiveProperty === void 0 ? void 0 : periodSensitiveProperty.forEach((periodProperty) => {
        const isDateInRelevantPeriod = isDateInPeriod({
            period: periodProperty.period,
            date,
        });
        if (isDateInRelevantPeriod) {
            if (periodProperty.serviceId &&
                !shouldIgnoreServiceId &&
                periodProperty.serviceId === selectedServiceId) {
                propertiesForPeriod.push(periodProperty);
            }
            if (!periodProperty.serviceId || shouldIgnoreServiceId) {
                propertiesForPeriod.push(periodProperty);
            }
        }
    });
    propertiesForPeriod.sort((a, b) => b.period.priority - a.period.priority);
    return propertiesForPeriod[0];
};
export const getPeriodPropertyValueForDate = ({ periodSensitiveProperty, date, selectedServiceId, }) => {
    var _a;
    return (_a = getPeriodPropertyForDate({
        periodSensitiveProperty,
        date,
        selectedServiceId,
    })) === null || _a === void 0 ? void 0 : _a.value;
};

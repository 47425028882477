import React from 'react';
export const Orders = ({ className, onClick }) => (React.createElement("svg", { className: className, onClick: onClick, xmlns: "http://www.w3.org/2000/svg", stroke: "currentColor", fill: "currentColor", version: "1.1", x: "0px", y: "0px", viewBox: "0 0 1000 1000", enableBackground: "new 0 0 1000 1000" },
    React.createElement("path", { d: "M772.1,792.1" }),
    React.createElement("path", { d: "M772.1,451.2" }),
    React.createElement("path", { d: "M256.8,208.3v61.8h402.5v-61.8c0-11.8-9.5-21.3-21.3-21.3h-98V79.2c0-38.2-31-69.2-69.2-69.2h-19.7C412.9,10,382,41,382,79.2v107.8H278.1C266.4,187.1,256.8,196.6,256.8,208.3z M462.7,95c15.9,0,28.7,12.9,28.7,28.7s-12.9,28.7-28.7,28.7s-28.7-12.9-28.7-28.7C433.9,107.8,446.8,95,462.7,95z" }),
    React.createElement("path", { d: "M233.4,887.1c-24.1,0-43.6-16.8-43.6-37.6V186.8c0-20.7,19.5-37.6,43.6-37.6h123.4V99.7H195.5c-28.1,0-50.8,19.1-50.8,42.6V894c0,23.5,22.7,42.6,50.8,42.6h225.4c-19.2-17.2-37.4-31-67.3-49.5L233.4,887.1L233.4,887.1z" }),
    React.createElement("path", { d: "M772.1,142.3c0-23.5-22.7-42.6-50.8-42.6H568.7v49.5h114.7c24.1,0,43.6,16.8,43.6,37.6v303c14.2-13.2,29.3-26.2,45.1-38.6L772.1,142.3L772.1,142.3z" }),
    React.createElement("path", { d: "M727,833.2v16.3c0,20.7-19.5,37.6-43.6,37.6H678c-12.6,15-25.7,31.5-39.3,49.5h82.6c28.1,0,50.8-19.1,50.8-42.6v-102C757.8,803.8,742.8,817.3,727,833.2z" }),
    React.createElement("path", { d: "M453.3,705.3L533.2,849c0,0,140.5-313,308.2-376.9c-6.4,84.6-8.9,167.7,13.9,188.5c-49.1,20.8-202.4,146.3-311,329.5c-102.2-99.5-102.2-121.8-238-190.5C391,745.2,453.3,705.3,453.3,705.3z" }),
    React.createElement("path", { d: "M268.6,406.7h136.8v19.2H268.6V406.7z" }),
    React.createElement("path", { d: "M268.6,363h136.8v19.2H268.6V363z" }),
    React.createElement("path", { d: "M268.6,462.1h384.4v19.2H268.6V462.1z" }),
    React.createElement("path", { d: "M268.6,518.5h384.4v19.2H268.6V518.5z" }),
    React.createElement("path", { d: "M268.6,574.9h384.4v19.2H268.6V574.9z" })));

import classNames from 'classnames';
import React from 'react';
import { Fish } from './fish';
import { Piers } from './piers';
import { Plus } from './plus';
export const PierWithFish = ({ className, onClick, containerClassName }) => {
    return (React.createElement("div", { className: classNames('flex', containerClassName), onClick: onClick },
        React.createElement(Piers, { className: classNames('mr-1', className) }),
        React.createElement(Plus, { className: classNames('mr-1', className) }),
        React.createElement(Fish, { className: className })));
};

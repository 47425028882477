import React from 'react';
import { ModalWrapper } from './modal-wrapper';
import { Button, useGetTranslation, zIndexes, Modal, } from '@reservation-app/common-used-in-web';
import { useModalStatesDataSlice, useRemoteMasterDataSlice, useStepperStateSlice, } from '../store';
import { TextInput } from '../../components';
export const PasswordModal = () => {
    const getTranslation = useGetTranslation();
    const { isPasswordModalOpen, setIsPasswordModalOpen } = useModalStatesDataSlice();
    const { reservationPassword, setReservationPassword, reservationPasswordError, setReservationPasswordError, handleSwitchToReservationFlowAfterAvailabilityCheck, } = useStepperStateSlice();
    const { companyOfficialData } = useRemoteMasterDataSlice();
    const onModalClose = () => {
        setReservationPassword('');
        setReservationPasswordError('');
        setIsPasswordModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['TEXT_MODAL'], isOpen: isPasswordModalOpen, withCloseButton: false, onClose: onModalClose, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('passwordRequired'), className: "text-gray-900 bg-primary-light", onClose: onModalClose },
            React.createElement("div", { className: "p-4", "data-test-id": "warning-text-modal" },
                React.createElement("p", { className: "mb-4 text-gray-900" }, getTranslation('passwordExplanation')),
                React.createElement(TextInput, { value: reservationPassword, placeholder: getTranslation('enterPasswordHere'), onChange: (event) => {
                        setReservationPassword(event.target.value);
                        if (event.target.value) {
                            setReservationPasswordError('');
                        }
                    } }),
                reservationPasswordError && (React.createElement("p", { className: "mt-2 text-sm text-error-main" }, reservationPasswordError)),
                React.createElement(Button, { onClick: () => handleSwitchToReservationFlowAfterAvailabilityCheck(), size: "md", className: "w-full mt-4 mb-2 password-modal-submit-button" }, getTranslation('submit')),
                React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement("p", { className: "mb-2 text-lg text-gray-900" }, getTranslation('dontHaveAPassword?')),
                    React.createElement("a", { className: "!underline section-link", href: `tel:${companyOfficialData === null || companyOfficialData === void 0 ? void 0 : companyOfficialData.phoneNumber}` }, getTranslation('callAdminToReserve')))))));
};

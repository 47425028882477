export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["ID"] = "id";
    FilterTypes["BASIC"] = "basic";
    FilterTypes["BASIC_LT"] = "basic-lt";
    FilterTypes["BASIC_GT"] = "basic-gt";
    FilterTypes["BOOLEAN"] = "boolean";
    FilterTypes["TRANSLATION"] = "translation";
    FilterTypes["DATE"] = "date";
    FilterTypes["DATE_GT"] = "date-gt";
    FilterTypes["DATE_LT"] = "date-lt";
    FilterTypes["PERIOD_TRANSLATION"] = "period-translation";
})(FilterTypes || (FilterTypes = {}));

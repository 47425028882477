import React from 'react';
import classNames from 'classnames';
import { FadeInAnimation } from '../fade-in-animation';
import { getPeriodPropertyValueForDate, Table, isLastIndex, Td, Tr, } from '../../../';
const TablePricesSection = ({ items, promotions, activeLanguage, shouldDisableAnimation, }) => {
    const sortedItems = [...items].sort((a, b) => a.displayOrder - b.displayOrder);
    return (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "flex flex-col items-center justify-center w-full 768:flex-row 768:flex-wrap 768:max-w-3xl 768:space-x-8" }, sortedItems.map((item, idx) => {
        var _a;
        let relevantTable;
        if (item.priceTableId) {
            const relevantPeriodProperty = promotions.find((promo) => promo._id === item.priceTableId);
            if (relevantPeriodProperty) {
                relevantTable = getPeriodPropertyValueForDate({
                    periodSensitiveProperty: promotions.find((promo) => promo._id === item.priceTableId).uiProps.table,
                    date: new Date(),
                });
            }
        }
        else {
            relevantTable = item.table;
        }
        if (relevantTable) {
            const sortedRows = [...relevantTable.tableRows].sort((a, b) => {
                return a.displayOrder - b.displayOrder;
            });
            return (React.createElement("div", { key: idx, className: "max-w-full p-4 mb-8 overflow-x-auto bg-white rounded-md shadow-lg" },
                ((_a = relevantTable.tableHeader) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("div", { className: "flex justify-center w-full pb-2 font-semibold border-b-2 border-gray-300" }, relevantTable.tableHeader[activeLanguage])),
                React.createElement(Table, { containerClassName: "shadow-none border-none", tableClassName: "border-none" },
                    React.createElement("tbody", null, sortedRows.map((tableRow, i) => (React.createElement(Tr, { key: i, className: classNames('p-2 border-gray-300', {
                            'border-b': !isLastIndex(i, sortedRows) && i !== 0,
                            'border-b-2 font-semibold': i === 0,
                        }) }, tableRow.cells.map((cell, j) => (React.createElement(Td, { key: j, className: classNames('p-2', cell.color) }, cell.text[activeLanguage]))))))))));
        }
        else {
            return null;
        }
    })));
};
export default TablePricesSection;

import React from 'react';
import classNames from 'classnames';
import { useLanguageContext } from '../contexts/language-context';
import {
  Icon,
  IconKey,
  LAST_SELECTED_LANGUAGE_LS_KEY,
  Translation,
} from '@reservation-app/common-used-in-web';
import { sanitizeUrlSlashes } from '../helpers/sanitize-url-slashes';

export type LanguageChangeLinkProps = {
  className?: string;
  language: string;
  onClick?: () => void;
  pageUrl: Translation;
  withFlag?: boolean;
  withTranslation?: boolean;
};

const LanguageChangeLink = ({
  className,
  language,
  pageUrl,
  onClick,
  withFlag = false,
  withTranslation = false,
}: LanguageChangeLinkProps) => {
  const { setActiveLanguage, defaultLanguage } = useLanguageContext();

  const isDefaultLanguage = language === defaultLanguage;

  const isHomePage = pageUrl[language] === '/';

  const href = sanitizeUrlSlashes(
    `/${isDefaultLanguage ? '' : language}/${pageUrl[language]}`,
    {
      ensureSlashAtTheEnd: isHomePage,
      shouldRemoveLastCharIfSlash:
        isDefaultLanguage || (!isDefaultLanguage && !isHomePage),
    },
  );

  const languageTranslationsMap = {
    ro: 'Română',
    hu: 'Magyar',
    en: 'English',
  };

  return (
    <a
      className={classNames(
        'flex items-center cursor-pointer transition-colors duration-400',
        { uppercase: !withTranslation },
        className,
      )}
      onClick={(event) => {
        event.preventDefault();

        if (onClick) {
          onClick();
        }

        setActiveLanguage(language);
        localStorage.setItem(LAST_SELECTED_LANGUAGE_LS_KEY, language);

        window.history.pushState({}, document.title, href);
      }}
      href={href}
    >
        {withFlag && (
          <Icon icon={language as IconKey} className="w-5 h-5 mr-2" />
        )}
        {withTranslation ? languageTranslationsMap[language] : language}
    </a>
  );
};

export default LanguageChangeLink;

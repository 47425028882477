export const getUniqueReservableIntervals = ({ reservableIntervals, }) => {
    const uniqueReservableIntervals = [];
    const conflictingReservableIntervalsMap = {};
    reservableIntervals.forEach((reservableInterval) => {
        const objectKey = `${reservableInterval.value.timeInterval.startTime}-${reservableInterval.value.timeInterval.endTime}`;
        if (conflictingReservableIntervalsMap[objectKey]) {
            conflictingReservableIntervalsMap[objectKey].push(reservableInterval);
        }
        else {
            conflictingReservableIntervalsMap[objectKey] = [reservableInterval];
        }
    });
    Object.values(conflictingReservableIntervalsMap).forEach((conflictingReservableIntervals) => {
        conflictingReservableIntervals.sort((a, b) => b.period.priority - a.period.priority);
        uniqueReservableIntervals.push(conflictingReservableIntervals[0]);
    });
    return uniqueReservableIntervals;
};

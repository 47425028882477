var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, EntityStatuses, Spinner, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
import React, { useEffect, useRef, useState } from 'react';
import { useEntitiesWithStatus, useSelectedValuesSlice, usePeriodAndEntityStepSpecificSlice, } from '../../../index';
import axios from 'axios';
import classNames from 'classnames';
import PinchZoom from 'pinch-zoom-js';
export const InteractiveBirdEyeView = () => {
    const { selectedEntityType, selectedEntity, handleSelectEntity } = useSelectedValuesSlice();
    const { setLastEntitySelectedAt } = usePeriodAndEntityStepSpecificSlice();
    const getTranslation = useGetTranslation();
    const activeLanguage = useActiveLanguage();
    const entitiesWithStatus = useEntitiesWithStatus();
    const [wrapperHeight, setWrapperHeight] = useState(null);
    const [isSvgLoading, setIsSvgLoading] = useState(true);
    const [svgFetchingError, setSvgFetchingError] = useState(false);
    const pinchZoomRef = useRef(null);
    const fetchImage = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            setIsSvgLoading(true);
            setSvgFetchingError(false);
            const wrapperDiv = document.getElementById('wrapper-div');
            setWrapperHeight(wrapperDiv.clientHeight);
            const response = yield axios.get(selectedEntityType.interactiveBirdEyeViewSvg.url[activeLanguage]);
            const svgContainerDiv = document.getElementById('svg-div');
            svgContainerDiv.innerHTML = response.data;
            svgContainerDiv.querySelector('#svg-div > svg').removeAttribute('height');
            svgContainerDiv
                .querySelector('#svg-div > svg')
                .setAttribute('width', '100%');
            (_b = (_a = svgContainerDiv
                .querySelector('#svg-div > svg > g')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('g.Frame')) === null || _b === void 0 ? void 0 : _b.forEach((node) => node.setAttribute('style', 'pointer-events: none'));
            pinchZoomRef.current = new PinchZoom(svgContainerDiv, {
                draggableUnzoomed: false,
            });
            setIsSvgLoading(false);
            setTimeout(() => {
                setWrapperHeight(null);
            }, 2000);
        }
        catch (error) {
            console.error({ error });
            setIsSvgLoading(false);
            setSvgFetchingError(true);
        }
    });
    useEffect(() => {
        fetchImage();
        () => {
            if (pinchZoomRef.current) {
                pinchZoomRef.current.destroy();
            }
        };
    }, []);
    useEffect(() => {
        if (!isSvgLoading) {
            entitiesWithStatus.forEach((entity) => {
                const entitiesWithId = document.querySelectorAll(`.${entity.interactiveBirdEyeViewSvgShapeId}`);
                const isEntitySelected = (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity._id) === entity._id;
                let entityBgColorClassName;
                let entityHoverClassName;
                let entityFillOpacityClassName;
                let entityPointerEventsClassName;
                let entityCursorClassName;
                const possibleEntityClassNames = [
                    '!fill-primary-light',
                    '!fill-primary-50',
                    '!fill-error-light',
                    '[fill-opacity:1]',
                    '!bg-gray-200',
                    'hover:!fill-primary-hover',
                    '!cursor-pointer',
                    'pointer-events-none',
                ];
                if (isEntitySelected) {
                    entityBgColorClassName = '!fill-primary-light';
                    entityCursorClassName = '!cursor-pointer';
                    entityFillOpacityClassName = '[fill-opacity:1]';
                    entityHoverClassName = '';
                }
                else if (entity.status === EntityStatuses.CLOSED) {
                    entityBgColorClassName = '!bg-gray-200';
                    entityHoverClassName = '';
                    entityPointerEventsClassName = 'pointer-events-none';
                    entityFillOpacityClassName = '[fill-opacity:1]';
                }
                else if (entity.status === EntityStatuses.RESERVED) {
                    entityBgColorClassName = '!fill-error-light';
                    entityHoverClassName = '';
                    entityPointerEventsClassName = 'pointer-events-none';
                    entityFillOpacityClassName = '[fill-opacity:1]';
                }
                else if (entity.status === EntityStatuses.AVAILABLE) {
                    entityBgColorClassName = '!fill-primary-50';
                    entityCursorClassName = '!cursor-pointer';
                    entityHoverClassName = 'hover:!fill-primary-300';
                }
                entitiesWithId.forEach((entityWithId) => {
                    entityWithId.classList.remove(...possibleEntityClassNames);
                    if (entityBgColorClassName) {
                        entityWithId.classList.add(entityBgColorClassName);
                    }
                    if (entityFillOpacityClassName) {
                        entityWithId.classList.add(entityFillOpacityClassName);
                    }
                    if (entityCursorClassName) {
                        entityWithId.classList.add(entityCursorClassName);
                    }
                    if (entityHoverClassName) {
                        entityWithId.classList.add(entityHoverClassName);
                    }
                    if (entityPointerEventsClassName) {
                        entityWithId.classList.add(entityPointerEventsClassName);
                    }
                });
            });
        }
    }, [isSvgLoading, entitiesWithStatus, selectedEntity]);
    useEffect(() => {
        if (!isSvgLoading) {
            entitiesWithStatus.forEach((entity) => {
                const entitiesWithId = document.querySelectorAll(`.${entity.interactiveBirdEyeViewSvgShapeId}`);
                entitiesWithId.forEach((entityWithId) => {
                    entityWithId.onclick = () => {
                        setLastEntitySelectedAt('interactive-birdeye-image');
                        handleSelectEntity(entity);
                    };
                });
            });
        }
    }, [isSvgLoading, entitiesWithStatus]);
    return (React.createElement("div", { className: classNames('max-w-2xl mb-10 rounded-md overflow-hidden', {
            'aspect-[4/3]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '4/3',
            'aspect-[465/376]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio ===
                '465/376',
            'aspect-[9/5]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '9/5',
            'aspect-square': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '1/1',
        }), id: "wrapper-div", style: { height: wrapperHeight ? wrapperHeight : '100%' } },
        svgFetchingError && (React.createElement("div", { className: "flex flex-col items-center justify-center h-full bg-white rounded-md" },
            React.createElement("p", { className: "mb-3 text-lg text-center text-gray-900" }, getTranslation('errorAtLoadingBirdsEyeImage')),
            React.createElement(Button, { color: "danger", className: "error-card btn-neutral-filled", onClick: () => fetchImage() }, getTranslation('retry')))),
        isSvgLoading && !svgFetchingError && (React.createElement("div", { className: "flex flex-col items-center justify-center h-full bg-white rounded-md" },
            React.createElement(Spinner, { className: "w-8 h-8 mb-2 text-primary-main" }),
            React.createElement("p", { className: "text-lg text-center text-gray-900" }, getTranslation('loadingImage')))),
        React.createElement("div", { id: "svg-div", className: classNames('w-full fade-in', {
                'aspect-[4/3]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '4/3',
                'aspect-[465/376]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio ===
                    '465/376',
                'aspect-[9/5]': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '9/5',
                'aspect-square': selectedEntityType.interactiveBirdEyeViewSvg.aspectRatio === '1/1',
            }) })));
};

import React from 'react';
import classNames from 'classnames';
import { ReservationSteps, isLastIndex, Icon, useGetTranslation, useActiveLanguage, } from '@reservation-app/common-used-in-web';
import { useNumberOfSteps, useStepperStateSlice } from '../store';
import { AnimatePresence } from 'framer-motion';
export const StepsIndicator = () => {
    var _a;
    const getTranslation = useGetTranslation();
    const numberOfSteps = useNumberOfSteps();
    const { currentStep, steps } = useStepperStateSlice();
    const activeLanguage = useActiveLanguage();
    const stepsObjResults = steps
        .filter(({ step }) => step !== ReservationSteps.CONGRATULATIONS)
        .map(({ step, stepIndicatorText }, index) => ({
        name: step,
        label: stepIndicatorText,
        order: index,
    }));
    const organizedStepsObjArray = stepsObjResults
        .sort((a, b) => a.order - b.order)
        .map((step, index) => {
        return {
            name: step.name,
            label: step.label,
            order: index,
        };
    });
    let currentStepNumber = (_a = organizedStepsObjArray.find((stepObj) => stepObj.name === currentStep)) === null || _a === void 0 ? void 0 : _a.order;
    if (currentStepNumber === undefined || currentStepNumber === null) {
        currentStepNumber = 10000000;
    }
    const reservationSteps = organizedStepsObjArray.map((step) => {
        return step.label[activeLanguage];
    });
    const areAllStepsDone = reservationSteps.length - 1 < currentStepNumber;
    const desktopScreenSizeSteps = (React.createElement("div", { className: classNames('hidden 768:flex mb-8 w-full') }, reservationSteps.map((step, index) => {
        const isFirst = index === 0;
        const isLast = isLastIndex(index, reservationSteps);
        const isMiddle = !(isFirst || isLast);
        const isDone = index <= currentStepNumber;
        const isCurrentStep = index === currentStepNumber;
        return (React.createElement("div", { className: classNames('flex flex-col relative', {
                'flex-grow-2': isMiddle,
                'flex-grow': !isMiddle,
            }), key: step },
            React.createElement("div", { className: classNames('flex items-center flex-shrink-0') },
                !isFirst && (React.createElement("div", { className: classNames('flex-grow h-0.5 transition-colors duration-500', {
                        'bg-green-500': isDone,
                        'bg-gray-300': !isDone,
                    }) })),
                React.createElement("div", { className: classNames('flex relative items-center justify-center w-7 h-7 rounded-full flex-shrink-0 transition-colors duration-500', {
                        'bg-green-500 text-white': isDone && !isCurrentStep,
                        'bg-primary-main text-white': isCurrentStep,
                        'bg-gray-300 text-gray-700': !isDone,
                    }) },
                    React.createElement("p", { className: classNames('text-sm font-semibold') }, isDone && !isCurrentStep ? (React.createElement(Icon, { icon: "check", className: "w-6 h-6" })) : (index + 1)),
                    React.createElement("p", { className: classNames('absolute top-6 h-10 pt-3 text-sm uppercase transition-colors duration-500 whitespace-nowrap', {
                            'text-gray-900': isDone && !isCurrentStep,
                            'text-primary-main font-semibold': isCurrentStep,
                            'text-gray-500': !isCurrentStep,
                            'left-0': isFirst,
                            'right-0': isLast,
                            'text-center': isMiddle,
                        }) }, step)),
                !isLast && (React.createElement("div", { className: classNames('flex-grow h-0.5 transition-colors duration-500', {
                        'bg-green-500': isDone && !isCurrentStep,
                        'bg-gray-300': !(isDone && !isCurrentStep),
                    }) })))));
    })));
    const smallerThanDesktopScreenSizeSteps = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col w-full mb-4 -mt-5 768:hidden" },
            React.createElement("p", { className: "text-gray-600 font-semibold text-sm mb-2" }, !areAllStepsDone
                ? getTranslation('mobileStepsIndicatorText', {
                    currentStepNumber: String(currentStepNumber + 1),
                    totalSteps: String(reservationSteps.length),
                })
                : getTranslation('allDone')),
            React.createElement("div", { className: "flex space-x-3 640:space-x-6" }, reservationSteps.map((step, index) => {
                const isDone = index < currentStepNumber;
                const isCurrentStep = index === currentStepNumber;
                return (React.createElement("div", { key: step, className: classNames('rounded-full flex-1 h-2.5 transition-colors duration-500', {
                        'bg-primary-main': isCurrentStep,
                        'bg-green-500': isDone,
                        'bg-gray-300': !isDone && !isCurrentStep,
                    }) }));
            })))));
    const shouldShowStepsIndicator = numberOfSteps > 2;
    return (React.createElement(AnimatePresence, null, shouldShowStepsIndicator ? (React.createElement(React.Fragment, null,
        smallerThanDesktopScreenSizeSteps,
        desktopScreenSizeSteps)) :
        null));
};

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { DO_NOT_DISPLAY_IN_UI, isTextHtml, EntityStatuses, Icon, useTranslationsStore, Button, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { ImageWithLoadingIndicator } from '../../../components';
import { getEntityCapacity } from '../../helpers/get-entity-capacity';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
import { getIntersectingReservedIntervalsWithSelectedIntervalsForEntity } from '../../helpers/get-intersecting-reserved-intervals-with-selected-intervals-for-entity';
import { useSelectedValuesSlice, useStepperStateSlice, useCompanyReservationConfig, usePeriodAndEntityStepSpecificSlice, useModalStatesDataSlice, } from '../../store';
export const ENTITY_CARD_WIDTH = 240;
export const ENTITY_CARD_SPACE_BETWEEN = 16;
export const EntityCard = ({ entity, testId, isLast }) => {
    var _a;
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { selectedEntity, handleSelectEntity, selectedIntervals, selectedService, } = useSelectedValuesSlice();
    const { defaultDate } = useStepperStateSlice();
    const isSelected = (selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity._id) === entity._id;
    const { currency } = useCompanyReservationConfig();
    const { setIsEntityGalleryModalOpen, setEntityGalleryModalSelectedEntity, setLastEntitySelectedAt, } = usePeriodAndEntityStepSpecificSlice();
    const { setIsAlreadyReservedSelectedIntervalsModalOpen, setAlreadyReservedSelectedIntervals, setAlreadyReservedSelectedIntervalsModalEntityName, } = useModalStatesDataSlice();
    const name = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.name,
        selectedServiceId: selectedService._id,
    });
    const hours = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.hours || [],
        selectedServiceId: selectedService._id,
    });
    const price = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.price || [],
        selectedServiceId: selectedService._id,
    });
    const restrictionIndicatorHtml = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.restrictionIndicatorHtml || [],
        selectedServiceId: selectedService._id,
    });
    const withShowMorePictures = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.withShowMorePictures || [],
        selectedServiceId: selectedService._id,
    });
    const capacity = getPeriodPropertyValueForDate({
        date: defaultDate,
        periodSensitiveProperty: entity.uiProps.capacity || [],
        selectedServiceId: selectedService._id,
    });
    const entityCapacity = useMemo(() => {
        if ((capacity === null || capacity === void 0 ? void 0 : capacity[activeLanguage]) === DO_NOT_DISPLAY_IN_UI) {
            return null;
        }
        return ((capacity === null || capacity === void 0 ? void 0 : capacity[activeLanguage]) ||
            getEntityCapacity({ entity, selectedIntervals }));
    }, [activeLanguage]);
    return (React.createElement("div", { "data-test-id": testId, id: `entity-card-${testId}`, style: {
            width: ENTITY_CARD_WIDTH,
            marginRight: !isLast ? ENTITY_CARD_SPACE_BETWEEN : 0,
        }, className: classNames('flex flex-col rounded-md shadow-md flex-shrink-0 overflow-hidden transition-colors duration-300 relative', {
            'bg-white': !isSelected,
            'bg-primary-light': isSelected,
        }) },
        React.createElement(ImageWithLoadingIndicator, { src: (_a = entity.uiProps.images[0]) === null || _a === void 0 ? void 0 : _a.url, alt: "", className: "h-[165px] w-full" }),
        restrictionIndicatorHtml &&
            isTextHtml(restrictionIndicatorHtml === null || restrictionIndicatorHtml === void 0 ? void 0 : restrictionIndicatorHtml[activeLanguage]) && (React.createElement("div", { className: "absolute top-0 left-0", dangerouslySetInnerHTML: {
                __html: restrictionIndicatorHtml[activeLanguage],
            } })),
        restrictionIndicatorHtml &&
            !isTextHtml(restrictionIndicatorHtml === null || restrictionIndicatorHtml === void 0 ? void 0 : restrictionIndicatorHtml[activeLanguage]) && (React.createElement("div", { className: "absolute top-0 left-0 px-1 text-sm font-semibold text-white rounded bg-warning-main" }, restrictionIndicatorHtml === null || restrictionIndicatorHtml === void 0 ? void 0 : restrictionIndicatorHtml[activeLanguage])),
        React.createElement("div", { className: "flex flex-col flex-grow p-3" },
            React.createElement("div", { className: "flex items-baseline justify-between w-full mb-2" },
                React.createElement("p", { className: classNames('text-base font-bold text-gray-900') }, name[activeLanguage]),
                entity.status === EntityStatuses.AVAILABLE && (React.createElement("p", { className: "px-1 text-sm font-semibold text-gray-800 rounded bg-primary-light" }, getTranslation('available'))),
                entity.status === EntityStatuses.RESERVED && (React.createElement("p", { className: "px-1 text-sm font-semibold rounded bg-error-light text-error-main" }, getTranslation('reserved'))),
                entity.status === EntityStatuses.CLOSED && (React.createElement("p", { className: "px-1 text-sm font-semibold text-gray-600 bg-gray-200 rounded" }, getTranslation('closed')))),
            React.createElement("div", { className: "flex flex-col justify-between flex-grow" },
                React.createElement("div", { className: "flex items-center justify-between mb-2" },
                    price ? (React.createElement("p", { className: "text-sm font-semibold" },
                        getFormattedPrice(price, currency),
                        (hours === null || hours === void 0 ? void 0 : hours[activeLanguage]) ? ` / ${hours === null || hours === void 0 ? void 0 : hours[activeLanguage]}` : '')) : null,
                    entityCapacity && (React.createElement("div", { className: "flex items-center text-sm font-semibold text-gray-900" },
                        React.createElement(Icon, { icon: "user", className: "mr-1" }),
                        React.createElement("p", null, entityCapacity)))),
                React.createElement("div", { className: "w-full" },
                    entity.status === EntityStatuses.AVAILABLE && (React.createElement(Button, { className: classNames(`entity-card-select-entity-button ${entity.internalName} w-full flex-shrink-0 text-base`, {
                            'text-primary-main bg-gray-50': isSelected,
                            'text-gray-50': !isSelected,
                        }, name[activeLanguage]), size: "sm", onClick: () => {
                            setLastEntitySelectedAt('entity-list');
                            handleSelectEntity(entity);
                        } }, isSelected
                        ? getTranslation('deselect')
                        : getTranslation('select'))),
                    entity.status === EntityStatuses.RESERVED && (React.createElement(Button, { className: classNames(`entity-card-already-reserved-intervals-button ${entity.internalName} w-full flex-shrink-0 mt-auto text-base`, name[activeLanguage]), size: "sm", variant: "outline", color: "danger", onClick: () => {
                            setAlreadyReservedSelectedIntervals(getIntersectingReservedIntervalsWithSelectedIntervalsForEntity({ entity, selectedIntervals }));
                            setAlreadyReservedSelectedIntervalsModalEntityName(name[activeLanguage]);
                            setIsAlreadyReservedSelectedIntervalsModalOpen(true);
                        } }, getTranslation('showReservedPeriods'))),
                    withShowMorePictures && (React.createElement(Button, { className: classNames(`entity-card-show-more-pictures-button ${entity.internalName} w-full flex-shrink-0 mt-2 text-base`, {
                            'text-primary-main bg-transparent': isSelected,
                        }, name[activeLanguage]), size: "sm", variant: "ghost", onClick: () => {
                            setEntityGalleryModalSelectedEntity(entity);
                            setIsEntityGalleryModalOpen(true);
                        } }, getTranslation('showMorePicturesOfEntity'))))))));
};

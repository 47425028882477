import React from 'react';
import { isDevEnv } from '..';
export const AppGarageLogoSmall = ({ className, width = 81, onClick, }) => {
    if (isDevEnv) {
        return (React.createElement("svg", { className: className, width: width, height: width, viewBox: "0 0 83 81", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: onClick },
            React.createElement("path", { d: "M30.7207 18.7589L42.8324 12.3242L54.9351 18.7589L42.8324 25.2021L30.7207 18.7589Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M52.4375 7.26156L64.5495 0.818359L76.6521 7.26156L64.5495 13.6962L52.4375 7.26156Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M9.23633 7.48324L21.3483 1.04004L33.4507 7.48324L21.3483 13.9179L9.23633 7.48324Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M59.9679 27.9629V41.4374L47.8652 47.8806V34.4061L59.9679 27.9629Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M82.0458 16.5684V30.0426L69.9434 36.4858V23.003L82.0458 16.5684Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M82.0458 42.665V55.5429L69.9434 61.9861V49.1082L82.0458 42.665Z", fill: "#22c55e" }),
            React.createElement("path", { d: "M13.0575 57.3243L25.1694 63.7589V73.6282L37.4849 80.1735L37.2721 33.4608L0.955078 14.1143V60.7332L13.0575 67.1764V57.3243ZM13.0575 33.4608L25.1694 39.8954V51.9889L13.0575 45.5543V33.4608Z", fill: "#22c55e" })));
    }
    return (React.createElement("svg", { width: "109", height: "114", viewBox: "0 0 109 114", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className, onClick: onClick },
        React.createElement("g", { clipPath: "url(#clip0_422_2377)" },
            React.createElement("path", { d: "M40.3439 25.8642L56.2498 16.6492L72.1436 25.8642L56.2498 35.0914L40.3439 25.8642Z", fill: "#3B82F6" }),
            React.createElement("path", { d: "M68.8628 9.39911L84.7687 0.171875L100.663 9.39911L84.7687 18.6141L68.8628 9.39911Z", fill: "#34D399" }),
            React.createElement("path", { d: "M12.1288 9.71648L28.0347 0.489258L43.9285 9.71648L28.0347 18.9315L12.1288 9.71648Z", fill: "#FCD34D" }),
            React.createElement("path", { d: "M78.7539 39.0459V58.3425L62.8601 67.5697V48.2731L78.7539 39.0459Z", fill: "#34D399" }),
            React.createElement("path", { d: "M108.379 22.2V41.4966L92.4849 50.7238V31.415L108.379 22.2Z", fill: "#FCD34D" }),
            React.createElement("path", { d: "M108.379 58.6572V77.0995L92.4849 86.3267V67.8845L108.379 58.6572Z", fill: "#3B82F6" }),
            React.createElement("path", { d: "M15.8938 80.7667L31.7997 89.9818V104.116L47.973 113.489L47.6935 46.5918L0 18.8857V85.6489L15.8938 94.8761V80.7667ZM15.8938 46.5918L31.7997 55.8068V73.1262L15.8938 63.9112V46.5918Z", fill: "#3B82F6" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_422_2377" },
                React.createElement("rect", { width: "109", height: "114", fill: "white" })))));
};
export const AppGarageLogoWide = ({ className, width = 400, onClick, }) => (React.createElement("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: width, height: "70", viewBox: "0 0 400 70", fill: "none", onClick: onClick },
    React.createElement("path", { d: "M23.8025 16.7963L32.8739 11.3371L41.9453 16.7963L32.8739 22.2456L23.8025 16.7963Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M40.0678 7.04559L49.1392 1.58636L58.2106 7.04559L49.1392 12.5048L40.0678 7.04559Z", fill: "#65CBE6" }),
    React.createElement("path", { d: "M7.70963 7.23517L16.781 1.77594L25.8428 7.23517L16.781 12.6844L7.70963 7.23517Z", fill: "#8C50FF" }),
    React.createElement("path", { d: "M45.7099 24.5909V35.9984L36.6385 41.4676V30.0402L45.7099 24.5909Z", fill: "#65CBE6" }),
    React.createElement("path", { d: "M62.2529 14.93V26.3475L53.1815 31.8067V20.3892L62.2529 14.93Z", fill: "#8C50FF" }),
    React.createElement("path", { d: "M62.2529 37.0464V47.9549L53.1815 53.4141V42.4956L62.2529 37.0464Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M10.5642 49.4619L19.6452 54.9111V63.2746L28.8603 68.8137L28.707 29.2118L1.50244 12.8441V52.3561L10.5642 57.8254V49.4619ZM10.5642 29.2218L19.6356 34.671V44.9308L10.5642 39.4716V29.2218Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M119.766 47.7053L117.917 42.0864H106.757L104.918 47.7053H95.9233L108.271 14.91H116.815L129.182 47.7053H119.766ZM109.066 35.0603H115.58L112.342 25.1698L109.066 35.0603Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M146.434 14.91C153.848 14.91 158.666 19.501 158.666 26.3874C158.666 33.2738 153.58 38.1043 146.156 38.1043H140.897V47.7053H132.353V14.91H146.434ZM145.62 30.8785C148.359 30.8785 150.122 29.102 150.122 26.6668C150.122 24.2316 148.359 22.4452 145.62 22.4452H140.897V30.8785H145.62Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M177.47 14.91C184.884 14.91 189.702 19.501 189.702 26.3874C189.702 33.2738 184.616 38.1043 177.24 38.1043H171.981V47.7053H163.437V14.91H177.47ZM176.656 30.8785C179.395 30.8785 181.158 29.102 181.158 26.6668C181.158 24.2316 179.395 22.4452 176.656 22.4452H171.933V30.8785H176.656Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M238.364 31.637C238.364 40.8189 232.291 48.1744 222.712 48.1744C213.133 48.1744 206.207 40.9187 206.207 31.4474C206.207 21.9761 213.497 14.4809 222.99 14.4809C231.668 14.4809 235.356 19.7305 235.356 19.7305L229.369 25.9682C228.616 24.9133 227.632 24.0606 226.499 23.4809C225.367 22.9012 224.118 22.6112 222.856 22.6348C218.133 22.6348 214.752 26.3375 214.752 31.3975C214.752 36.4575 218.133 40.2101 222.856 40.2101C226.4 40.2101 228.785 38.5235 229.561 35.5294H222.415V29.2018H238.201C238.303 30.0094 238.357 30.8226 238.364 31.637Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M262.657 47.7053L260.818 42.0864H249.658L247.819 47.7053H238.824L251.191 14.91H259.735L272.073 47.7053H262.657ZM251.957 35.0603H258.48L255.243 25.1698L251.957 35.0603Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M289.785 14.91C296.797 14.91 301.567 19.3113 301.567 25.8884C301.62 27.9858 301.076 30.0527 300.004 31.8287C298.932 33.6048 297.379 35.0104 295.542 35.8687L302.918 47.6754H293.022L286.863 37.1362H283.798V47.6754H275.253V14.91H289.785ZM283.798 30.3695H288.75C291.538 30.3695 293.022 28.7328 293.022 26.3774C293.022 24.0221 291.538 22.3853 288.75 22.3853H283.798V30.3695Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M327.21 47.7053L325.361 42.0864H314.221L312.372 47.7053H303.377L315.744 14.91H324.288L336.665 47.7053H327.21ZM316.501 35.0603H323.024L319.786 25.1698L316.501 35.0603Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M369.262 31.637C369.262 40.8189 363.189 48.1744 353.61 48.1744C344.031 48.1744 337.105 40.9187 337.105 31.4474C337.105 21.9761 344.395 14.4809 353.878 14.4809C362.557 14.4809 366.245 19.7305 366.245 19.7305L360.268 25.9682C359.513 24.9125 358.527 24.0594 357.393 23.4797C356.258 22.9 355.007 22.6104 353.744 22.6348C349.022 22.6348 345.65 26.3375 345.65 31.3975C345.65 36.4575 349.022 40.2101 353.744 40.2101C357.298 40.2101 359.683 38.5235 360.45 35.5294H353.294V29.2018H369.08C369.191 30.0087 369.251 30.8221 369.262 31.637Z", fill: "#F1695C" }),
    React.createElement("path", { d: "M374.215 14.91H398.047V22.6348H382.721V27.3255H395.768V34.5912H382.759V39.9406H398.517V47.6654H374.215V14.91Z", fill: "#F1695C" })));

import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useId } from 'react-id-generator';
import { Label } from '@reservation-app/common-used-in-web';
export const Checkbox = forwardRef(({ checkboxClassNames, unCheckedBgClassName = 'bg-gray-50', checkedBgClassName = 'bg-primary-hover', tickCheckedClassName = 'text-white', value, label, labelPosition = 'right', labelClassName, containerClassName, name, onChange, onBlur, error, }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [checkboxId] = useId(1, 'checkbox');
    const { defaultContainerClassName, defaultLabelClassName } = useMemo(() => {
        switch (labelPosition) {
            case 'top':
                return {
                    defaultContainerClassName: 'label-top-container',
                    defaultLabelClassName: 'label-top',
                };
            case 'right':
                return {
                    defaultContainerClassName: 'label-right-container',
                    defaultLabelClassName: 'label-right',
                };
            case 'bottom':
                return {
                    defaultContainerClassName: 'label-bottom-container',
                    defaultLabelClassName: 'label-bottom',
                };
            case 'left':
                return {
                    defaultContainerClassName: 'label-left-container',
                    defaultLabelClassName: 'label-left',
                };
            default:
                return {
                    defaultContainerClassName: 'label-top-container',
                    defaultLabelClassName: 'label-top',
                };
        }
    }, [labelPosition]);
    const setFocusTrue = useCallback(() => {
        setIsFocused(true);
    }, []);
    const setFocusFalse = useCallback(() => {
        setIsFocused(false);
    }, []);
    return (React.createElement("label", { htmlFor: `${checkboxId}${name || ''}`, className: classNames(defaultContainerClassName, containerClassName) },
        React.createElement(Label, { as: "span", className: classNames(defaultLabelClassName, labelClassName) }, label),
        React.createElement("input", { ref: ref, name: name, id: `${checkboxId}${name || ''}`, type: "checkbox", checked: value, value: value ? 'true' : 'false', onFocus: setFocusTrue, onBlur: (event) => {
                onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
                setFocusFalse();
            }, className: "hidden-input-checkbox", onChange: (event) => {
                onChange === null || onChange === void 0 ? void 0 : onChange(event.target.checked);
            }, style: {
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: '1px',
                width: '1px',
                margin: '-1px',
            } }),
        React.createElement("div", { className: classNames('checkbox', {
                [unCheckedBgClassName]: !value,
                [checkedBgClassName]: value,
                'checkbox-focus': isFocused,
            }, checkboxClassNames) },
            React.createElement("svg", { className: classNames({
                    [tickCheckedClassName]: value,
                    'text-transparent': !value,
                }), fill: "currentColor", strokeWidth: "2", viewBox: "0 0 24 24", height: "1.2em", width: "1.2em", xmlns: "http://www.w3.org/2000/svg", style: {
                    stroke: !value ? 'transparent' : 'inherit',
                } },
                React.createElement("path", { d: "M10 15.586L6.707 12.293 5.293 13.707 10 18.414 19.707 8.707 18.293 7.293z" }))),
        error && React.createElement("span", { className: "checkbox-error" }, error)));
});

export const reservationPageLanguageMapping = {
  en: 'reservation',
  ro: 'rezervare',
  hu: 'foglalas',
};

export const verifyAvailabilityPageLanguageMapping = {
  en: 'find-available-period',
  ro: 'cautare-perioada-libera',
  hu: 'szabad-idopont-kereses',
};

export const defaultHomeLink = {
  label: {
    hu: 'Honlap',
    en: 'Home',
    ro: 'Acasă',
  },
  to: {
    hu: '/',
    ro: '/',
    en: '/',
  },
  displayOrder: 0,
};

export const copyRightText = {
  hu: 'Fejleszve az App Garage Solutions által',
  en: 'Developed by App Garage Solutions',
  ro: 'Dezvoltat de App Garage Solutions',
};

export const cookiePolicyLink = {
  label: {
    ro: 'Politica de cookies',
    hu: 'Cookies tájékoztató',
    en: 'Cookie policy',
  },
  to: {
    ro: '/politica-de-cookies',
    hu: '/cookie-tajekoztato',
    en: '/cookie-policy',
  },
};

export const termsAndConditionsLink = {
  label: {
    ro: 'Termeni de utilizare',
    hu: 'Használati feltételek',
    en: 'Terms and conditions',
  },
  to: {
    ro: '/termeni-de-utilizare',
    hu: '/hasznalati-feltetelek',
    en: '/terms-and-conditions',
  },
};

export const legalPageLinks = [
  {
    label: {
      ro: 'Regulament',
      hu: 'Szabályzat',
      en: 'Rules',
    },
    to: {
      ro: '/regulament',
      hu: '/szabalyzat',
      en: '/rules',
    },
  },
  cookiePolicyLink,
  termsAndConditionsLink,
];

export const reservationPageLink = {
  label: {
    en: 'Reservation',
    ro: 'Rezervare',
    hu: 'Foglalás',
  },
  to: {
    en: '/reservation',
    ro: '/rezervare',
    hu: '/foglalas',
  },
  displayOrder: 100000,
};

export const thisWebsiteWasCreatedBy = {
  ro: 'Acest website a fost creat de echipa "Lac de Pescuit Digital"',
  hu: 'Ez a weboldalt a "Digitális Halastó" csapata hozta létre',
  en: 'This website was created by the "Digital Fishing Lake" team',
};

export const ifYouWantASimilarPage = {
  ro: 'Dacă dorești un site similar pentru lacul tău vizitează linkurl de mai jos',
  hu: 'Ha hasonló weboldalt szeretnél a horgásztavad számára látogasd meg az alábbi linket',
  en: 'If you want similar website for your fishing lake visit the link below',
};

export const websiteUrl = {
  ro: 'https://lacdepescuitdigital.ro',
  hu: 'https://digitalishalasto.hu',
  en: 'https://lacdepescuitdigital.ro',
};

export const websiteText = {
  ro: 'Lac de Pescuit Digital Website',
  hu: 'Digitális Halastó Weboldal',
  en: 'Digital Fishing Lake Website',
};

const ImageUploaderBaseClasses = (theme) => ({
    '.image-uploader-container': {
        backgroundColor: theme('colors.white'),
        maxWidth: theme('maxWidth.2xl'),
    },
    '.image-uploader-error': {
        textAlign: 'center',
        fontSize: theme('fontSize.lg'),
        lineHeight: theme('lineHeight.7'),
        fontWeight: theme('fontWeight.semibold'),
        marginBottom: theme('spacing.4'),
        color: theme('colors.red.600'),
    },
    '.image-uploader-add-new-images': {
        display: 'flex',
        textTransform: 'uppercase',
        fontWeight: theme('fontWeight.bold'),
        fontSize: theme('fontSize.lg'),
        lineHeight: theme('lineHeight.7'),
        color: theme('colors.gray.400'),
        marginBottom: theme('spacing.10'),
    },
    '.image-uploader-box-drag-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: theme('borderRadius.DEFAULT'),
        borderWidth: theme('borderWidth.2'),
        borderColor: theme('colors.gray.200'),
        height: theme('spacing.50'),
        width: '100%',
    },
    '.image-uploader-box-drag-text-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme('spacing.4'),
        height: theme('spacing.20'),
        width: '100%',
        fontWeight: theme('fontWeight.semibold'),
        color: theme('colors.gray.500'),
        textAlign: 'center',
        backgroundColor: theme('colors.primary.100'),
    },
    '.image-uploader-box-drag-svg': {
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: theme('fontSize.8xl'),
    },
    '.image-uploader-box-drag-svg-drag-active': {
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
        '&:hover': {
            scale: theme('scale.110'),
        },
    },
    '.image-uploader-box-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: theme('borderRadius.md'),
        height: theme('spacing.50'),
        width: '100%',
        padding: theme('spacing.4'),
        '&:before': {
            content: "''",
            position: 'absolute',
            border: `10px dashed ${theme('components.imageUploader.borderColor') || theme('colors.primary.500')}`,
            borderRadius: theme('borderRadius.lg'),
            top: '-7px',
            bottom: '-7px',
            left: '-7px',
            right: '-7px',
        },
    },
    '.image-uploader-box-text-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.image-uploader-box-svg': {
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: theme('fontSize.7xl'),
    },
    '.image-uploader-image-sources-container': {
        display: 'flex',
    },
    '.image-uploader-image-sources-modal': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'min(100%, 500px)',
    },
    '.image-uploader-image-sources-modal-multiple-slider-container': {
        margin: 'auto',
        height: '100%',
        width: '100%',
    },
    '.image-uploader-image-sources-modal-slider-generate-image-container': {
        width: '100%',
        height: '100%',
        minHeight: '300px',
    },
    '.image-uploader-image-sources-modal-slider-generate-image-container-2': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    '.image-uploader-image-sources-modal-slider-generate-image-accepted-files': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'max-content',
        paddingTop: theme('spacing.2'),
        paddingBottom: theme('spacing.2'),
        backgroundColor: theme('colors.primary.100'),
        color: theme('colors.gray.500'),
        fontSize: theme('fontSize.sm'),
        lineHeight: theme('lineHeight.5'),
        borderRadius: theme('borderRadius.lg'),
        paddingRight: theme('spacing.4'),
        paddingLeft: theme('spacing.4'),
        marginBottom: theme('spacing.10'),
    },
    '.image-uploader-image-sources-modal-slider-generate-image-cropper-container': {
        position: 'relative',
        width: theme('spacing.72'),
        height: theme('spacing.48'),
        '@media (min-width: 640px)': {
            width: theme('spacing.96'),
            height: theme('height.4/6'),
        },
    },
    '.image-uploader-image-sources-modal-slider-generate-image-cropper': {
        backgroundColor: theme('colors.gray.900'),
    },
    '.image-uploader-image-sources-modal-slider-generate-image-button-container': {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    '.image-uploader-image-sources-modal-single': {
        marginTop: theme('spacing.2'),
    },
    '.image-uploader-image-display-text': {
        marginTop: theme('spacing.4'),
        marginBottom: theme('spacing.4'),
        fontWeight: theme('fontWeight.semibold'),
        color: theme('colors.gray.500'),
    },
    '.image-uploader-image-display-slider-with-modal': {
        width: '100%',
    },
});
module.exports = ImageUploaderBaseClasses;

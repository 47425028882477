import {
  Button,
  globalApiClient,
  useActiveLanguage,
  ConsentTypes,
  zIndexes,
  Languages,
  Switch,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { cookiePolicyLink } from '../../static-content';
import LocalizedLink from '../localized-link';
import { hotjar } from 'react-hotjar';
import { usePageContext } from '../../contexts/page-context';
import { useLanguageContext } from '../../contexts/language-context';

export const COOKIES_ACCEPTED_LS_KEY = 'cookies_consent_given';

export const CookieBanner = () => {
  const pageContext = usePageContext();

  const [isCookiesBannerOpen, setIsCookiesBannerOpen] = useState(false);

  const [arePreferencesOpen, setArePreferencesOpen] = useState(false);
  const [areAnalyticsCookiesActivated, setAreAnalyticsCookiesActivated] =
    useState(true);

  const activeLanguage = useActiveLanguage();

  const { translations } = useLanguageContext();

  useEffect(() => {
    const areCookiesAccepted = window.localStorage.getItem(
      COOKIES_ACCEPTED_LS_KEY,
    );

    if (!areCookiesAccepted) {
      setTimeout(() => {
        setIsCookiesBannerOpen(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (
      process.env.GATSBY_ACTIVE_ENV === 'production' &&
      pageContext.analyticsConfig.hotjarCode &&
      pageContext.analyticsConfig.hotjarVersion
    ) {
      hotjar.initialize(
        Number(pageContext.analyticsConfig.hotjarCode),
        Number(pageContext.analyticsConfig.hotjarVersion),
      );
    }
  }, []);

  const cookiesBannerHeaderText = {
    ro: 'Acest site utilizează cookie-uri',
    hu: 'Ez az oldal cookie-kat használ',
    en: 'This site uses cookies',
  };

  return (
    isCookiesBannerOpen && (
      <div
        className={classNames(
          'flex justify-center items-center fixed bottom-4 mx-4 bg-primary-100 shadow-xl rounded-lg p-4 640:p-6',
          zIndexes.COOKIE_BANNER_MODAL,
        )}
      >
        <div className="max-w-2xl">
          <p className="text-gray-900 font-semibold mb-6">
            {cookiesBannerHeaderText[activeLanguage]}
          </p>
          <p className="text-gray-900 mb-4">
            {activeLanguage === Languages.RO && (
              <span>
                <span className="block mb-2">
                  Folosim cookie-uri necesare pentru funcționalitatea corecta a
                  site-ului (ex. salvare preferințelor utilizatorului - de
                  exemplu limba).
                </span>
                <span className="block mb-2">
                  Mai folosim cookie-uri de la Hotjar și Google Analytics pentru
                  a analiza cum folosesc ultilizatorii site-ul. Acesta ne ajută
                  să înbunătățim site-ul pentru toți utilizatori. Aceste
                  cookie-uri vor fi folosite doar dacă dai click pe butonul{' '}
                  <b>"Închide și permite"</b>.
                </span>
                <span>
                  Pentru mai multe informații vizitează pagina{' '}
                  <LocalizedLink
                    className="!text-gray-900 !underline !hover:text-gray-800"
                    to={cookiePolicyLink.to[activeLanguage]}
                  >
                    Politica de cookies
                  </LocalizedLink>
                  .
                </span>
              </span>
            )}
            {activeLanguage === Languages.HU && (
              <span>
                <span className="block mb-2">
                  Használunk olyan cookie-kat, amik szükségsek a weboldal
                  helyeses működéséhez (többek között a felhasználói
                  preferenciák megjegyzéséhez mint pl. a nyelv).
                </span>
                <span className="block mb-2">
                  Ezek mellett a Hotjar és Google Analytics cookie-jait is
                  használjuk, hogy nyomon kövessük hogyan használják a
                  felhasználók az oldalt, ezáltal jobb élményt nyújtva
                  mindenkinek. Ezeket csak akkor használjuk, ha az "Bezár és
                  engedélyez" gombra kattintasz.
                </span>
                <span>
                  További információkért látogasd meg a{' '}
                  <LocalizedLink
                    className="!text-gray-900 !underline !hover:text-gray-800"
                    to={cookiePolicyLink.to[activeLanguage]}
                  >
                    Cookie tájékoztató
                  </LocalizedLink>{' '}
                  oldalunkat.
                </span>
              </span>
            )}
            {activeLanguage === Languages.EN && (
              <span>
                <span className="block mb-2">
                  We use cookies that are necessary for the correct
                  functionality of this site (ex. saving user preferences like
                  language).
                </span>
                <span className="block mb-2">
                  We also use third party cookies from Hotjar and Google
                  Analytics for analyzing user behaviour to help us enhance the
                  user experience for everybody. These will only get used if you
                  click on the "Close and allow" button.
                </span>
                <span>
                  For more information visit our{' '}
                  <LocalizedLink
                    className="!text-gray-900 !underline !hover:text-gray-800"
                    to={cookiePolicyLink.to[activeLanguage]}
                  >
                    Cookie policy
                  </LocalizedLink>{' '}
                  page.
                </span>
              </span>
            )}
          </p>
          {arePreferencesOpen && (
            <div className="flex">
              <div className="mr-4">
                <p className="mb-2">
                  {translations?.necessaryCookies?.[activeLanguage]}
                </p>
                <Switch value={true} isDisabled={true} />
              </div>
              <div>
                <p className="mb-2">
                  {translations?.analyticsCookies?.[activeLanguage]}
                </p>
                <Switch
                  value={areAnalyticsCookiesActivated}
                  onChange={() => {
                    setAreAnalyticsCookiesActivated((prevState) => !prevState);
                  }}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col justify-end 640:flex-row 640:justify-center mt-4">
            {arePreferencesOpen ? (
              <Button
                variant="ghost"
                className="640:mr-2"
                onClick={() => {
                  globalApiClient.post('/consents', {
                    consentType: areAnalyticsCookiesActivated
                      ? ConsentTypes.ALLOW_ALL
                      : ConsentTypes.ALLOW_ONLY_NECESSARY,
                  });
                  window.localStorage.setItem(
                    COOKIES_ACCEPTED_LS_KEY,
                    areAnalyticsCookiesActivated
                      ? ConsentTypes.ALLOW_ALL
                      : ConsentTypes.ALLOW_ONLY_NECESSARY,
                  );
                  setIsCookiesBannerOpen(false);
                  if (
                    areAnalyticsCookiesActivated &&
                    pageContext.analyticsConfig.hotjarCode &&
                    pageContext.analyticsConfig.hotjarVersion
                  ) {
                    hotjar.initialize(
                      Number(pageContext.analyticsConfig.hotjarCode),
                      Number(pageContext.analyticsConfig.hotjarVersion),
                    );
                  }
                }}
              >
                {translations?.allowOnlySelection?.[activeLanguage]}
              </Button>
            ) : (
              <Button
                variant="ghost"
                className="640:mr-2"
                onClick={() => {
                  setArePreferencesOpen(true);
                }}
              >
                {translations?.preferences?.[activeLanguage]}
              </Button>
            )}
            <Button
              onClick={() => {
                globalApiClient.post('/consents', {
                  consentType: ConsentTypes.ALLOW_ALL,
                });
                window.localStorage.setItem(
                  COOKIES_ACCEPTED_LS_KEY,
                  ConsentTypes.ALLOW_ALL,
                );
                setIsCookiesBannerOpen(false);
                if (
                  process.env.GATSBY_ACTIVE_ENV === 'production' &&
                  pageContext.analyticsConfig.hotjarCode &&
                  pageContext.analyticsConfig.hotjarVersion
                ) {
                  hotjar.initialize(
                    Number(pageContext.analyticsConfig.hotjarCode),
                    Number(pageContext.analyticsConfig.hotjarVersion),
                  );
                }
              }}
            >
              {translations?.closeAndAllow?.[activeLanguage]}
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

import { Currencies } from '@reservation-app/common-used-in-web';
const getNumberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
const formatPrice = (price, divideBy) => {
    const dividedPrice = price / divideBy;
    const decimalToFixed = dividedPrice % 1 === 0 ? dividedPrice : dividedPrice.toFixed(2);
    const formatted = decimalToFixed.toString().split('.');
    const integer = formatted[0];
    const decimal = formatted[1];
    return `${getNumberWithDots(integer)}${decimal ? `,${decimal}` : ''}`;
};
export const getFormattedPrice = (rawPrice, currency, returnCurrencyString = true) => {
    const price = Number(rawPrice);
    const getShouldReturnCurrency = (currencySymbol) => {
        return returnCurrencyString ? currencySymbol : '';
    };
    if (currency === Currencies.RON) {
        return `${formatPrice(price, 100)} ${getShouldReturnCurrency('RON')}`;
    }
    if (currency === Currencies.EUR) {
        return `${getShouldReturnCurrency('€')}${formatPrice(price, 1000)}`;
    }
    if (currency === Currencies.USD) {
        return `${getShouldReturnCurrency('$')}${formatPrice(price, 1000)}`;
    }
    if (currency === Currencies.HUF) {
        return `${formatPrice(price, 1)} ${getShouldReturnCurrency('Ft')}`;
    }
    throw new Error('Invalid currency');
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import classNames from 'classnames';
import { Summary } from './summary';
import { useCompanyReservationConfig, useIsCompletionStep, useIsNextButtonDisabled, useIsPreviousButtonDisabled, useIsVerifyAvailabilityMode, usePaymentDetailsStepSpecificSlice, usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, useStepperStateSlice, useWebSpecificSlice, } from '../store';
import { LayoutGroup, motion } from 'framer-motion';
import { zIndexes, ReservationSteps, Icon, useGetTranslation, Button, Spinner, } from '@reservation-app/common-used-in-web';
import { getFormattedPrice } from '../helpers/get-formatted-price';
import { useWiggleNextStepButton } from '../hooks/use-wiggle-next-step-button';
const WAS_BOTTOM_DRAWER_HANDLE_USED_LS_KEY = 'wasBottomDrawerHandleUsed';
export const BottomDrawer = () => {
    const getTranslation = useGetTranslation();
    const { currency } = useCompanyReservationConfig();
    const { selectedIntervals, selectedEntityType } = useSelectedValuesSlice();
    const isVerifyAvailabilityMode = useIsVerifyAvailabilityMode();
    const { isDesktop } = useWebSpecificSlice();
    const { currentStep, handleGoToNextStepFromSelectEntityType, handleGoToNextStepFromSelectService, handleGoToNextStepFromSelectPeriodAndEntity, handleGoToNextStepFromExtras, handleGoToNextStepFromBillingDetails, handleGoToNextStepFromPassword, handleGoToNextStepFromPayment, handleGoToNextStepFromCompleteVerification, handleGoToPreviousStep, price: { totalAmount, discountAmount, totalAmountBeforeDiscount }, setIsBottomSheetOpen, isNextStepLoading, bottomSheetSnapTo, setBottomSheetSnapTo, setIsPreviousButtonSpamBlockEnabled, } = useStepperStateSlice();
    usePaymentDetailsStepSpecificSlice();
    useSelectedValuesSlice();
    const nextButtonVariants = useWiggleNextStepButton();
    const isCompletionStep = useIsCompletionStep();
    const isPreviousButtonDisabled = useIsPreviousButtonDisabled();
    const isNextButtonDisabled = useIsNextButtonDisabled();
    const BOTTOM_DRAWER_SWIPE_UP_STARTING_HEIGHT = 122;
    const swipeUpHeight = window.innerHeight - 100;
    const bottomDrawerSwipeUpLimit = 600;
    const BOTTOM_SWIPE_UP_END_HEIGHT = swipeUpHeight <= bottomDrawerSwipeUpLimit
        ? swipeUpHeight
        : bottomDrawerSwipeUpLimit;
    const sheetRef = useRef();
    const { shouldAnimateBottomDrawerHandle, setShouldAnimateBottomDrawerHandle, } = usePeriodAndEntityStepSpecificSlice();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const wasBottomDrawerHandleUsed = localStorage.getItem(WAS_BOTTOM_DRAWER_HANDLE_USED_LS_KEY);
            if (!wasBottomDrawerHandleUsed && selectedIntervals.length > 0) {
                setShouldAnimateBottomDrawerHandle(true);
            }
        }))();
    }, [selectedIntervals]);
    useEffect(() => {
        setTimeout(() => {
            var _a;
            if (!isDesktop) {
                setBottomSheetSnapTo((_a = sheetRef === null || sheetRef === void 0 ? void 0 : sheetRef.current) === null || _a === void 0 ? void 0 : _a.snapTo);
            }
        }, 1000);
    }, [isDesktop]);
    const nextButtonText = useMemo(() => {
        if (isCompletionStep && !isVerifyAvailabilityMode) {
            return getTranslation('complete');
        }
        if (isCompletionStep && isVerifyAvailabilityMode) {
            return getTranslation('verify');
        }
        return getTranslation('next');
    }, [isCompletionStep, isVerifyAvailabilityMode]);
    return (React.createElement("div", { className: classNames('768:hidden pb-safe-bottom', zIndexes.BOTTOM_SHEET, {
            hidden: currentStep === ReservationSteps.CONGRATULATIONS,
        }) },
        currentStep !== ReservationSteps.CONGRATULATIONS && (React.createElement(BottomSheet, { ref: sheetRef, className: classNames('bottom-sheet 768:hidden'), blocking: false, scrollLocking: false, onSpringEnd: (event) => __awaiter(void 0, void 0, void 0, function* () {
                if (event.type !== 'OPEN' && shouldAnimateBottomDrawerHandle) {
                    localStorage.setItem(WAS_BOTTOM_DRAWER_HANDLE_USED_LS_KEY, 'true');
                    setShouldAnimateBottomDrawerHandle(false);
                }
                if (event.type === 'SNAP') {
                    if (sheetRef.current.height > 300) {
                        setIsBottomSheetOpen(true);
                    }
                    else {
                        setIsBottomSheetOpen(false);
                    }
                }
            }), header: React.createElement("div", { className: classNames('bottom-sheet-header flex items-center justify-center'), onClick: () => sheetRef.current.height < BOTTOM_SWIPE_UP_END_HEIGHT
                    ? sheetRef.current.snapTo(1000)
                    : sheetRef.current.snapTo(0) },
                React.createElement("div", { className: classNames('h-[8px] w-[52px] mt-[2px] rounded-full bg-gray-400', {
                        'animate-bottom-drawer-handle': shouldAnimateBottomDrawerHandle,
                    }) }),
                (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.shouldShowSelectedIntervalsNumberInSummary) &&
                    selectedIntervals.length > 0 && (React.createElement("div", { className: classNames('absolute top-[6px] left-1/2') },
                    React.createElement("div", { className: classNames('flex justify-center items-center rounded-full h-5 w-5 ml-9 text-xs bg-primary-main text-white font-semibold') }, selectedIntervals.length)))), snapPoints: () => [
                BOTTOM_DRAWER_SWIPE_UP_STARTING_HEIGHT,
                BOTTOM_SWIPE_UP_END_HEIGHT,
            ], defaultSnap: ({ snapPoints }) => Math.min(...snapPoints), open: true },
            React.createElement(LayoutGroup, null,
                React.createElement(motion.div, { layout: true, className: "bg-white", "data-test-id": "summary-container-bottom-drawer" },
                    React.createElement(motion.div, { layout: true, className: "px-4" },
                        React.createElement(Summary, null)))))),
        React.createElement("div", { className: classNames('h-24 bg-white px-4 pb-4 flex flex-col justify-between fixed bottom-0 w-full left-0 right-0', zIndexes['BOTTOM_DRAWER_PRICE_AND_BUTTONS_CONTAINER']) },
            React.createElement("div", { className: "flex justify-end" },
                React.createElement("div", { className: "text-lg font-bold text-right text-gray-900" }, totalAmount ? (React.createElement("div", { className: "flex" },
                    React.createElement("p", { className: "mr-2" },
                        getTranslation('totalPrice'),
                        ":"),
                    discountAmount > 0 && (React.createElement("p", { className: "mr-2 text-gray-400 line-through" }, getFormattedPrice(totalAmountBeforeDiscount, currency))),
                    getFormattedPrice(totalAmount, currency))) : (`${getTranslation('totalPrice')}: -`))),
            React.createElement("div", { className: "flex justify-between" },
                React.createElement(Button, { className: "flex-shrink w-40 mr-2 prev-step-button-bottom-drawer", size: "md", color: "neutral", variant: "outline", isDisabled: isPreviousButtonDisabled, onClick: () => {
                        if (!isPreviousButtonDisabled) {
                            setIsPreviousButtonSpamBlockEnabled(true);
                            handleGoToPreviousStep();
                            setTimeout(() => {
                                setIsPreviousButtonSpamBlockEnabled(false);
                            }, 500);
                            setTimeout(() => {
                                bottomSheetSnapTo === null || bottomSheetSnapTo === void 0 ? void 0 : bottomSheetSnapTo(0);
                            }, 200);
                        }
                    } },
                    React.createElement(Icon, { icon: "chevronLeft", className: "mr-2 text-xl" }),
                    getTranslation('back')),
                React.createElement(motion.div, { className: "flex", variants: nextButtonVariants, initial: "initial", animate: "animate" },
                    React.createElement(Button, { className: "flex-shrink w-40 next-step-button-bottom-drawer", testId: "bottom-drawer-continue", size: "md", isDisabled: isNextButtonDisabled, onClick: () => {
                            if (!isNextButtonDisabled) {
                                setTimeout(() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }, 100);
                                if (isVerifyAvailabilityMode && isCompletionStep) {
                                    handleGoToNextStepFromCompleteVerification();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_ENTITY_TYPE) {
                                    handleGoToNextStepFromSelectEntityType();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_SERVICE) {
                                    handleGoToNextStepFromSelectService();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_ENTITY ||
                                    currentStep === ReservationSteps.SELECT_PERIOD ||
                                    currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY) {
                                    handleGoToNextStepFromSelectPeriodAndEntity();
                                    return;
                                }
                                if (currentStep === ReservationSteps.SELECT_EXTRAS) {
                                    handleGoToNextStepFromExtras();
                                    return;
                                }
                                if (currentStep === ReservationSteps.BILLING_DETAILS) {
                                    handleGoToNextStepFromBillingDetails();
                                    return;
                                }
                                if (currentStep === ReservationSteps.PASSWORD) {
                                    handleGoToNextStepFromPassword();
                                    return;
                                }
                                if (currentStep === ReservationSteps.PAYMENT_DETAILS) {
                                    handleGoToNextStepFromPayment();
                                    return;
                                }
                                if (currentStep === ReservationSteps.CONGRATULATIONS) {
                                    return;
                                }
                                setTimeout(() => {
                                    bottomSheetSnapTo === null || bottomSheetSnapTo === void 0 ? void 0 : bottomSheetSnapTo(0);
                                }, 200);
                            }
                        } },
                        nextButtonText,
                        isNextStepLoading && (React.createElement(Spinner, { className: "w-5 h-5 ml-2 text-white" })),
                        isCompletionStep && !isNextStepLoading && (React.createElement(Icon, { icon: "check", className: "ml-2 text-2xl" })),
                        !isCompletionStep && !isNextStepLoading && (React.createElement(Icon, { icon: "chevronRight", className: "ml-2 text-xl" }))))))));
};

import { useActiveLanguage, getPeriodPropertyValueForDate, Table, Tr, Td, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React from 'react';
import { useStepperStateSlice } from '../../store';
export const PromotionTable = ({ promotion, isLast, className, }) => {
    const activeLanguage = useActiveLanguage();
    const { price: { appliedPromotionId }, } = useStepperStateSlice();
    const tableValues = getPeriodPropertyValueForDate({
        date: new Date(),
        periodSensitiveProperty: promotion.uiProps.table,
    });
    return (React.createElement("div", { className: classNames('flex flex-col 768:flex-row 768:flex-wrap justify-center items-center 768:justify-start w-full 768:max-w-3xl 768:space-x-8', className) },
        React.createElement("div", { key: promotion._id, className: classNames('mb-8 rounded-md shadow-lg bg-white p-4 overflow-x-auto max-w-full', {
                '!mb-0': isLast,
            }) },
            React.createElement("div", { className: "w-full flex justify-center border-b-2 pb-2 border-gray-300" }, tableValues.tableHeader[activeLanguage]),
            React.createElement(Table, { containerClassName: "shadow-none border-none", tableClassName: "border-none" },
                React.createElement("tbody", null, tableValues.tableRows.map((tableRow, i) => (React.createElement(Tr, { key: `tableRow-${i}`, className: "p-2" }, tableRow.cells.map((cell, j) => {
                    const isSelectedPromo = cell.linkedPromotionId === appliedPromotionId;
                    return (React.createElement(Td, { key: `tableCell-${j}`, className: classNames('p-2', {
                            [`${cell.color}`]: cell.color && !isSelectedPromo,
                            'bg-success-main': isSelectedPromo &&
                                cell.linkedPromotionId !== undefined,
                        }) },
                        React.createElement("p", { className: classNames({
                                'text-white': isSelectedPromo &&
                                    cell.linkedPromotionId !== undefined,
                            }) }, cell.text[activeLanguage])));
                })))))))));
};

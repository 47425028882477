export function useTextInputValues({ value, error, field, form, name, onChange, onBlur, }) {
    var _a, _b, _c;
    let formikCompatibleValue = '';
    if (value) {
        formikCompatibleValue = value;
    }
    else if (field) {
        formikCompatibleValue = field.value;
    }
    let formikCompatibleError = '';
    if (error) {
        formikCompatibleError = error;
    }
    else if (form && field) {
        formikCompatibleError =
            ((_a = form.errors) === null || _a === void 0 ? void 0 : _a[field.name]) && ((_b = form.touched) === null || _b === void 0 ? void 0 : _b[field.name]) ? (_c = form.errors) === null || _c === void 0 ? void 0 : _c[field === null || field === void 0 ? void 0 : field.name] : '';
    }
    let formikCompatibleName = '';
    if (name) {
        formikCompatibleName = name;
    }
    else if (field) {
        formikCompatibleName = field.name;
    }
    let formikCompatibleOnChange;
    if (onChange) {
        formikCompatibleOnChange = onChange;
    }
    else if (field) {
        formikCompatibleOnChange = field.onChange;
    }
    else {
        formikCompatibleOnChange = () => { };
    }
    let formikCompatibleOnBlur;
    if (onBlur) {
        formikCompatibleOnBlur = onBlur;
    }
    else if (field) {
        formikCompatibleOnBlur = field.onBlur;
    }
    else {
        formikCompatibleOnBlur = () => { };
    }
    return {
        formikCompatibleValue,
        formikCompatibleError,
        formikCompatibleOnBlur,
        formikCompatibleOnChange,
        formikCompatibleName,
    };
}

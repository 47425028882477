const getIsDevEnv = () => {
    let isDevEnv = false;
    if (process.env.REACT_APP_ENV &&
        process.env.REACT_APP_ENV === 'development') {
        isDevEnv = true;
    }
    else if (process.env.GATSBY_ACTIVE_ENV &&
        process.env.GATSBY_ACTIVE_ENV === 'development') {
        isDevEnv = true;
    }
    else if (process.env.RN_APP_ENV &&
        process.env.RN_APP_ENV === 'development') {
        isDevEnv = true;
    }
    return isDevEnv;
};
export const isDevEnv = getIsDevEnv();

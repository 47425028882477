import { BillingDetailsModes, useTranslationsStore, } from '@reservation-app/common-used-in-web';
import { isWithPaymentStep } from '../../helpers/is-with-payment-step';
import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const BILLING_DETAILS_LS_KEY = 'billingDetails';
export const createBillingDetailsStepSpecificPropertiesSlice = (set, get) => ({
    cachedBillingDetails: [],
    setCachedBillingDetails: (cachedBillingDetails, extraDevtoolsInfo) => set((state) => {
        state.billingDetailsStepSpecific.cachedBillingDetails =
            cachedBillingDetails;
    }, false, getDevtoolsLogMessage('setCachedBillingDetails', extraDevtoolsInfo)),
    billingDetails: {
        type: BillingDetailsModes.INDIVIDUAL,
        companyOfficialName: '',
        address: '',
        companyCountry: '',
        companyVatId: '',
        shouldSendEmail: true,
        email: '',
        fullName: '',
        phoneNumber: '',
        termsAndConditions: false,
        additionalMustAgreeTo1: false,
        additionalMustAgreeTo2: false,
    },
    setBillingDetails: (key, value, extraDevtoolsInfo) => set((state) => {
        state.billingDetailsStepSpecific.billingDetails[key] = value;
    }, false, getDevtoolsLogMessage('setBillingDetails', extraDevtoolsInfo)),
    modifyBillingDetailsCache: ({ action, keyToRemove }) => {
        const { billingDetailsStepSpecific: { billingDetails, cachedBillingDetails }, } = get();
        let newBillingData;
        const prevBillingData = localStorage.getItem(BILLING_DETAILS_LS_KEY);
        const currentBillingDetailsKey = billingDetails.email
            ? billingDetails.email
            : billingDetails.fullName;
        if (action === 'addCurrentBillingDetails') {
            if (prevBillingData) {
                newBillingData = Object.assign(Object.assign({}, JSON.parse(prevBillingData)), { [currentBillingDetailsKey]: Object.assign(Object.assign({}, billingDetails), { updatedAt: Date.now() }) });
            }
            else {
                newBillingData = {
                    [currentBillingDetailsKey]: Object.assign(Object.assign({}, billingDetails), { updatedAt: Date.now() }),
                };
            }
            set((state) => {
                state.billingDetailsStepSpecific.cachedBillingDetails = [
                    ...cachedBillingDetails.filter((billingDetailsOption) => {
                        const billingDetailsOptionKey = billingDetailsOption.email
                            ? billingDetailsOption.email
                            : billingDetailsOption.fullName;
                        const billingDetailsKey = billingDetails.email
                            ? billingDetails.email
                            : billingDetails.fullName;
                        return billingDetailsOptionKey !== billingDetailsKey;
                    }),
                    billingDetails,
                ];
            });
        }
        else {
            const parsedPrevBillingData = JSON.parse(prevBillingData);
            delete parsedPrevBillingData[keyToRemove];
            newBillingData = parsedPrevBillingData;
        }
        localStorage.setItem(BILLING_DETAILS_LS_KEY, JSON.stringify(newBillingData));
    },
    replaceBillingDetails: (billingDetails, extraDevtoolsInfo) => set((state) => {
        state.billingDetailsStepSpecific.billingDetails = billingDetails;
    }, false, getDevtoolsLogMessage('replaceBillingDetails', extraDevtoolsInfo)),
    billingDetailsValidationErrors: {
        type: '',
        companyOfficialName: '',
        address: '',
        companyCountry: '',
        companyVatId: '',
        shouldSendEmail: '',
        email: '',
        fullName: '',
        phoneNumber: '',
        termsAndConditions: '',
        additionalMustAgreeTo1: '',
        additionalMustAgreeTo2: '',
        isMembershipCard: '',
    },
    setBillingDetailsValidationErrors: (key, value, extraDevtoolsInfo) => set((state) => {
        state.billingDetailsStepSpecific.billingDetailsValidationErrors[key] =
            value;
    }, false, getDevtoolsLogMessage('setBillingDetailsValidationErrors', extraDevtoolsInfo)),
    billingDetailsTouchedState: {
        type: false,
        companyOfficialName: false,
        address: false,
        companyCountry: false,
        companyVatId: false,
        shouldSendEmail: false,
        email: false,
        fullName: false,
        phoneNumber: false,
        termsAndConditions: false,
        additionalMustAgreeTo1: false,
        additionalMustAgreeTo2: false,
        isMembershipCard: false,
    },
    setBillingDetailsTouchedState: (key, value, extraDevtoolsInfo) => set((state) => {
        state.billingDetailsStepSpecific.billingDetailsTouchedState[key] =
            value;
    }, false, getDevtoolsLogMessage('setBillingDetailsTouchedState', extraDevtoolsInfo)),
    validateBillingDetails: (key, setAllToTouched = false, extraDevtoolsInfo) => {
        let areAllDetailsValid = true;
        set((state) => {
            var _a, _b;
            const { billingDetailsStepSpecific: { billingDetails }, } = state;
            const getTranslation = useTranslationsStore.getState().getTranslation;
            const activeLanguage = useTranslationsStore.getState().activeLanguage;
            if (billingDetails.type === BillingDetailsModes.INDIVIDUAL) {
                if (!key || key === 'fullName') {
                    let fullNameError;
                    if (!billingDetails.fullName) {
                        fullNameError = getTranslation('fullNameIsRequired');
                    }
                    else if (billingDetails.fullName &&
                        billingDetails.fullName.length > 40) {
                        fullNameError = getTranslation('fullNameMustBeLessThan40Chars');
                    }
                    if (fullNameError) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.fullName =
                            fullNameError;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.fullName =
                            '';
                    }
                }
                state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyCountry =
                    '';
                state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyOfficialName =
                    '';
                state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyVatId =
                    '';
            }
            else if (billingDetails.type === BillingDetailsModes.COMPANY) {
                if (!key || key === 'companyOfficialName') {
                    let companyNameError;
                    if (!billingDetails.companyOfficialName) {
                        companyNameError = getTranslation('CompanyNameIsRequired');
                    }
                    else if (billingDetails.companyOfficialName &&
                        billingDetails.companyOfficialName.length > 40) {
                        companyNameError = getTranslation('companyNameMustBeLessThan40Chars');
                    }
                    if (companyNameError) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyOfficialName =
                            companyNameError;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyOfficialName =
                            '';
                    }
                }
                if (!key || key === 'companyVatId') {
                    let companyVatIdError;
                    if (!billingDetails.companyVatId) {
                        companyVatIdError = getTranslation('vatIdIsRequired');
                    }
                    else if (billingDetails.companyVatId.length > 40) {
                        companyVatIdError = getTranslation('vatIdMustBeLessThan40Chars');
                    }
                    if (companyVatIdError) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyVatId =
                            companyVatIdError;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyVatId =
                            '';
                    }
                }
                if (!key || key === 'companyCountry') {
                    let companyCountryError;
                    if (!billingDetails.companyCountry) {
                        companyCountryError = getTranslation('companyCountryIsRequired');
                    }
                    else if (billingDetails.companyCountry.length > 40) {
                        companyCountryError = getTranslation('countryMustBeLessThan40Chars');
                    }
                    if (companyCountryError) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyCountry =
                            companyCountryError;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.companyCountry =
                            '';
                    }
                }
                state.billingDetailsStepSpecific.billingDetailsValidationErrors.fullName =
                    '';
            }
            if (!key || key === 'email') {
                let emailError;
                if (billingDetails.shouldSendEmail) {
                    if (!billingDetails.email) {
                        emailError = getTranslation('emailIsRequired');
                    }
                    else if (billingDetails.email) {
                        const validateEmail = (email) => {
                            const re = /\S+@\S+\.\S+/;
                            return re.test(email);
                        };
                        if (!validateEmail(billingDetails.email)) {
                            emailError = getTranslation('invalidEmailFormat');
                        }
                        else if (billingDetails.email.length > 320) {
                            emailError = getTranslation('emailMustBeLessThan320Chars');
                        }
                    }
                }
                if (emailError) {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.email =
                        emailError;
                }
                else {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.email =
                        '';
                }
            }
            if ((!key || key === 'address') &&
                isWithPaymentStep({
                    companyReservationConfig: state.remoteMasterData.companyReservationConfig,
                    selectedEntityType: state.selectedValues.selectedEntityType,
                }) &&
                !state.static.staticProps.isAdminPanel &&
                billingDetails.shouldSendEmail) {
                let addressError;
                if (!billingDetails.address) {
                    addressError = getTranslation('addressIsRequired');
                }
                else if (billingDetails.address.length > 80) {
                    addressError = getTranslation('addressMustBeLessThan40Chars');
                }
                if (addressError) {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.address =
                        addressError;
                }
                else {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.address =
                        '';
                }
            }
            if (!key || key === 'phoneNumber') {
                let phoneNumberError;
                if (!billingDetails.phoneNumber) {
                    phoneNumberError = getTranslation('phoneNumberIsRequired');
                }
                else if (billingDetails.phoneNumber) {
                    if (billingDetails.phoneNumber.charAt(0) === '+') {
                        if (billingDetails.phoneNumber.length < 12) {
                            phoneNumberError = getTranslation('min11NumbersAfter+');
                        }
                        else {
                            const phoneNum = Number(billingDetails.phoneNumber.substring(1));
                            if (Number.isNaN(phoneNum)) {
                                phoneNumberError = getTranslation('numbersOnlyPhoneNumber');
                            }
                        }
                    }
                    else if (billingDetails.phoneNumber.charAt(0) === '0' &&
                        billingDetails.phoneNumber.charAt(1) === '0') {
                        if (billingDetails.phoneNumber.length < 13) {
                            phoneNumberError = getTranslation('min11NumbersAfter00');
                        }
                        else {
                            const phoneNum = Number(billingDetails.phoneNumber);
                            if (Number.isNaN(phoneNum)) {
                                phoneNumberError = getTranslation('numbersOnlyPhoneNumber');
                            }
                        }
                    }
                    else if (billingDetails.phoneNumber.length === 10) {
                        const firstNumber = Number(billingDetails.phoneNumber.charAt(0));
                        const phoneNum = Number(billingDetails.phoneNumber);
                        if (Number.isNaN(phoneNum) || Number.isNaN(firstNumber)) {
                            phoneNumberError = getTranslation('numbersOnlyPhoneNumber');
                        }
                    }
                    else if (billingDetails.phoneNumber.length === 11) {
                        phoneNumberError = getTranslation('invalidPhoneNumber');
                    }
                    else {
                        phoneNumberError = getTranslation('invalidPhoneNumber');
                    }
                }
                if (phoneNumberError) {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.phoneNumber =
                        phoneNumberError;
                }
                else {
                    state.billingDetailsStepSpecific.billingDetailsValidationErrors.phoneNumber =
                        '';
                }
            }
            if (!state.static.staticProps.isAdminPanel) {
                if (!key || key === 'termsAndConditions') {
                    let termsAndConditionsError;
                    if (!billingDetails.termsAndConditions) {
                        termsAndConditionsError = getTranslation('mustAcceptTermsAndConditions');
                    }
                    if (termsAndConditionsError) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.termsAndConditions =
                            termsAndConditionsError;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.termsAndConditions =
                            '';
                    }
                }
                if (!key ||
                    (key === 'additionalMustAgreeTo1' &&
                        ((_a = state.selectedValues.selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive))) {
                    let additionalMustAgreeTo1Error;
                    if (!billingDetails.additionalMustAgreeTo1) {
                        additionalMustAgreeTo1Error =
                            state.selectedValues.selectedEntityType.additionalMustAgreeTo1
                                .errorMessage[activeLanguage];
                    }
                    if (additionalMustAgreeTo1Error) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.additionalMustAgreeTo1 =
                            additionalMustAgreeTo1Error;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.additionalMustAgreeTo1 =
                            '';
                    }
                }
                if (!key ||
                    (key === 'additionalMustAgreeTo2' &&
                        ((_b = state.selectedValues.selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive))) {
                    let additionalMustAgreeTo2Error;
                    if (!billingDetails.additionalMustAgreeTo2) {
                        additionalMustAgreeTo2Error =
                            state.selectedValues.selectedEntityType.additionalMustAgreeTo2
                                .errorMessage[activeLanguage];
                    }
                    if (additionalMustAgreeTo2Error) {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.additionalMustAgreeTo2 =
                            additionalMustAgreeTo2Error;
                    }
                    else {
                        state.billingDetailsStepSpecific.billingDetailsValidationErrors.additionalMustAgreeTo2 =
                            '';
                    }
                }
            }
            areAllDetailsValid = Object.values(state.billingDetailsStepSpecific.billingDetailsValidationErrors).every((error) => !error);
            if (!areAllDetailsValid) {
                state.stepperState.validationError = getTranslation('billingDetailsHasErrors');
            }
            else {
                state.stepperState.validationError = '';
            }
            if (setAllToTouched) {
                state.billingDetailsStepSpecific.billingDetailsTouchedState = {
                    address: true,
                    companyOfficialName: true,
                    shouldSendEmail: true,
                    email: true,
                    fullName: true,
                    phoneNumber: true,
                    companyCountry: true,
                    companyVatId: true,
                    type: true,
                    termsAndConditions: true,
                    additionalMustAgreeTo1: true,
                    additionalMustAgreeTo2: true,
                    isMembershipCard: true,
                };
            }
        }, false, getDevtoolsLogMessage('validateBillingDetails', extraDevtoolsInfo));
        return areAllDetailsValid;
    },
});

import React, { useMemo } from 'react';
import { ModalWrapper } from './modal-wrapper';
import { Button, useGetTranslation, zIndexes, Modal, useActiveLanguage, Languages, ReservationSteps, } from '@reservation-app/common-used-in-web';
import { useCompanyReservationConfig, useIsAdminPanel, useIsDesktop, useIsVerifyAvailabilityMode, useModalStatesDataSlice, useRemoteMasterDataSlice, } from '../store';
export const RESERVATION_ONBOARDING_MODAL_LS_KEY = 'reservation-onboarding-modal';
export const VERIFY_ONBOARDING_MODAL_LS_KEY = 'verify-onboarding-modal';
export const OnboardingModal = () => {
    const getTranslation = useGetTranslation();
    const activeLanguage = useActiveLanguage();
    const isDesktop = useIsDesktop();
    const isAdminPanel = useIsAdminPanel();
    const reservationConfig = useCompanyReservationConfig();
    const { entityTypes, companyAvailabilityCheckConfig } = useRemoteMasterDataSlice();
    const isVerifyAvailabilityMode = useIsVerifyAvailabilityMode();
    const { isOnboardingModalOpen, setIsOnboardingModalOpen } = useModalStatesDataSlice();
    const onModalClose = () => {
        setIsOnboardingModalOpen(false);
        const onboardingModalLsValue = (isVerifyAvailabilityMode
            ? companyAvailabilityCheckConfig.onboardingModalLsKey
            : reservationConfig.onboardingModalLsKey) || 'v1';
        localStorage.setItem(isVerifyAvailabilityMode
            ? VERIFY_ONBOARDING_MODAL_LS_KEY
            : RESERVATION_ONBOARDING_MODAL_LS_KEY, onboardingModalLsValue);
    };
    const isWithPayment = useMemo(() => {
        var _a;
        if (isAdminPanel) {
            return false;
        }
        if (entityTypes.every((et) => et.steps.some((s) => s.step === ReservationSteps.PAYMENT_DETAILS))) {
            return true;
        }
        if ((_a = reservationConfig === null || reservationConfig === void 0 ? void 0 : reservationConfig.steps) === null || _a === void 0 ? void 0 : _a.some((s) => s.step === ReservationSteps.PAYMENT_DETAILS)) {
            return true;
        }
        return false;
    }, [entityTypes, reservationConfig, isAdminPanel]);
    return (React.createElement(Modal, { overlayClassName: zIndexes['TEXT_MODAL'], isOpen: isOnboardingModalOpen, withCloseButton: false, onClose: () => { }, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: `👋 ${getTranslation('welcome')}`, className: "text-white bg-primary-main" },
            React.createElement("div", { className: "p-4", "data-test-id": "onboarding-modal" },
                React.createElement("div", { className: "flex flex-col w-full max-w-sm mb-5 space-y-3" },
                    activeLanguage === Languages.HU && (React.createElement("div", { className: "text-gray-900" },
                        !isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 A foglal\u00E1s t\u00F6bb l\u00E9p\u00E9sb\u0151l \u00E1ll.")),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 A szabad id\u0151pontok ellen\u0151rz\u00E9se t\u00F6bb l\u00E9p\u00E9sb\u0151l \u00E1ll.")),
                        React.createElement("p", null,
                            "\u2705 Minden l\u00E9p\u00E9sn\u00E9l ki kell v\u00E1lassz vagy t\u00F6lts valamit \u00E9s azt\u00E1n megnyomd a ",
                            React.createElement("b", null, "Tov\u00E1bb"),
                            " gombot a k\u00E9perny\u0151 jobb",
                            ' ',
                            isDesktop ? 'felső' : 'alsó',
                            " sark\u00E1ban."),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDEAB Itt nem foglalhatsz le semmit, csak a szabad id\u0151pontok el\u00E9rhet\u0151s\u00E9g\u00E9t ellen\u0151rz\u00F6d.")),
                        isWithPayment && !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 A foglal\u00E1si folyamat v\u00E9g\u00E9n sz\u00FCks\u00E9ges lesz bankk\u00E1rty\u00E1val fizetni online.")),
                        !isWithPayment &&
                            !isAdminPanel &&
                            !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 \uD83D\uDEAB Nem sz\u00FCks\u00E9ges online fizetni a foglal\u00E1shoz.")))),
                    activeLanguage === Languages.EN && (React.createElement("div", { className: "text-gray-900" },
                        !isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 The reservation process has multiple steps.")),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 Verifying if a period is available has multiple steps.")),
                        React.createElement("p", null,
                            "\u2705 At each step you'll need to select or complete something and then click on ",
                            React.createElement("b", null, "Continue"),
                            " button in the",
                            ' ',
                            isDesktop ? 'top' : 'bottom',
                            " right corner of the screen."),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDEAB In this process you will not reserve anything, you just verify the available periods.")),
                        isWithPayment && !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 At the end of the reservation process it will be required to pay online with a debit card.")),
                        !isWithPayment &&
                            !isAdminPanel &&
                            !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 \uD83D\uDEAB No online payment is required for making reservations.")))),
                    activeLanguage === Languages.RO && (React.createElement("div", { className: "text-gray-900" },
                        !isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 Procesul de rezervare are mai mul\u021Bi pa\u0219i.")),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mb-3" }, "\uD83D\uDC63 Verificarea disponibilit\u0103\u021Bii are mai mul\u021Bi pa\u0219i.")),
                        React.createElement("p", null,
                            "\u2705 La fiecare pas va trebui s\u0103 selectezi sau completezi ceva \u0219i s\u0103 ape\u0219i pe butonul ",
                            React.createElement("b", null, "Continu\u0103"),
                            " din partea dreapt\u0103",
                            ' ',
                            isDesktop ? 'sus' : 'jos',
                            " a ecranului."),
                        isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDEAB \u00CEn acest proces nu vei rezerva nimic, doar verifici disponibilitatea.")),
                        isWithPayment && !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 La finalul procesului de rezervare va fi necesar\u0103 plata online cu card bancar.")),
                        !isWithPayment &&
                            !isAdminPanel &&
                            !isVerifyAvailabilityMode && (React.createElement("p", { className: "mt-3 font-semibold" }, "\uD83D\uDCB3 \uD83D\uDEAB Nu este necesar\u0103 plata online pentru rezervare."))))),
                React.createElement(Button, { onClick: onModalClose, color: "primary", size: "md", className: "w-full onboarding-modal-continue-button" }, getTranslation('IUnderstand'))))));
};

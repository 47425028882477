import React, { useMemo } from 'react';
import { StepWrapper } from '../../layout/step-wrapper';
import { EntityTypeCard } from './entity-type-card';
import FindOutMoreSection from '../../../components/find-out-more-section';
import { useRemoteMasterDataSlice, useStaticPropsSlice } from '../../store';
import { ReservationSteps } from '@reservation-app/common-used-in-web';
export const SelectEntityTypeStep = () => {
    const { entityTypes } = useRemoteMasterDataSlice();
    const { isAdminPanel } = useStaticPropsSlice();
    const sortedEntityTypes = useMemo(() => [...entityTypes].sort((a, b) => a.displayOrder - b.displayOrder), [entityTypes]);
    return (React.createElement(StepWrapper, { step: ReservationSteps.SELECT_ENTITY_TYPE },
        React.createElement("div", { className: "flex flex-col 768:flex-row 768:flex-wrap" }, sortedEntityTypes.map((entityType) => (React.createElement(EntityTypeCard, { entityType: entityType, key: entityType._id })))),
        !isAdminPanel && React.createElement(FindOutMoreSection, null)));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { getShowAvailableElementsOnlyCacheKey, usePeriodAndEntityStepSpecificSlice, useSelectedValuesSlice, } from '../store';
export const useSyncCachedValuesOfIntervalsSwitches = () => {
    const { selectedEntityType } = useSelectedValuesSlice();
    const { setShowAvailableInvervalsOnly } = usePeriodAndEntityStepSpecificSlice();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const showAvailableIntervalsOnlyCachedValue = localStorage.getItem(getShowAvailableElementsOnlyCacheKey(selectedEntityType._id, 'intervals'));
            if (showAvailableIntervalsOnlyCachedValue === 'true') {
                setShowAvailableInvervalsOnly(true);
            }
            else {
                setShowAvailableInvervalsOnly(false);
            }
        }))();
    }, []);
};

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import LocalizedLink from '../localized-link';
import { useLanguageContext } from '../../contexts/language-context';
import {
  Backdrop,
  drawerMenuItemVariants,
  drawerSidebarVariants,
  drawerWrapperDivVariants,
  FocusLock,
  Translation,
  useDrawerDimensions,
  zIndexes,
} from '@reservation-app/common-used-in-web';
import LanguageChangeLink from '../language-change-link';
import {
  defaultHomeLink,
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../static-content';
import { motion, Transition, useCycle, Variants } from 'framer-motion';
import ReactScrollLock, { TouchScrollable } from 'react-scrolllock';
import { PageContextType, usePageContext } from '../../contexts/page-context';

type PathProps = {
  variants: Variants;
  transition?: Transition;
  d?: string;
};

const Path = ({ variants, transition, d }: PathProps) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="currentColor"
    strokeLinecap="round"
    variants={variants}
    d={d}
    transition={transition}
  />
);

type HamburgerButtonProps = {
  handleToggle: () => void;
  className?: string;
  svgClassName?: string;
};

const HamburgerButton = ({
  handleToggle,
  className,
  svgClassName,
}: HamburgerButtonProps) => (
  <motion.button
    className={classNames(
      'flex justify-center items-center outline-none border-none select-none cursor-pointer 4 w-12 h-12 rounded-[50%] bg-gray-100 text-gray-900 ml-3',
      zIndexes['HAMBURGER'],
      className,
    )}
    onClick={handleToggle}
    aria-label="hamburger-menu"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames('mt-[5px] ml-[2px]', svgClassName)}
    >
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </motion.button>
);

export type HamburgerMenuProps = {
  headerScrollTriggered: boolean;
  headerConfig: PageContextType['headerConfig'];
  pageUrl: Translation;
  isReservationFlow: boolean;
  setNavigateToOnConfirm?: (to: string) => void;
  setIsCancelReservationModalOpen?: (isOpen: boolean) => void;
  onLeaveReservationFlowClick?: (to: string) => void;
  shouldWarnOnLeave?: boolean;
};

const HamburgerMenu = ({
  headerScrollTriggered,
  headerConfig,
  pageUrl,
  isReservationFlow,
  shouldWarnOnLeave,
  setNavigateToOnConfirm,
  setIsCancelReservationModalOpen,
  onLeaveReservationFlowClick,
}: HamburgerMenuProps) => {
  const { activeLanguage, languages } = useLanguageContext();

  const [isOpen, toggleOpen] = useCycle(false, true);

  const [isScrollLockActive, setIsScrollLockActive] = useState(false);

  const pageContext = usePageContext();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsScrollLockActive(true);
      }, 700);
    } else {
      setTimeout(() => {
        setIsScrollLockActive(false);
      }, 800);
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isScrollLockActive) {
      const bodyPaddingRight = window.getComputedStyle(
        document.body,
      ).paddingRight;
      document.documentElement.style.setProperty(
        '--body-padding-right',
        bodyPaddingRight,
      );
    } else {
      document.documentElement.style.setProperty('--body-padding-right', '0px');
    }
  }, [isScrollLockActive]);

  const ref = useRef(null);
  const { height } = useDrawerDimensions(ref);

  const drawerNavigationVariants: Variants = {
    open: {
      overflowY: isScrollLockActive ? 'auto' : 'hidden',
    },
    closed: {
      overflowY: 'hidden',
      transition: { delay: 0.5 },
    },
  };

  return (
    <>
      <FocusLock isDisabled={!isOpen} restoreFocus={false} autoFocus={false}>
        <Backdrop isOpen={isOpen} handleClose={toggleOpen} />
        <ReactScrollLock isActive={isScrollLockActive}>
          <motion.nav
            className={classNames(
              'absolute top-0 right-0 bottom-0 w-[calc(theme(spacing.72)+var(--body-padding-right))]',
              {
                'pointer-events-none': !isOpen,
              },
              zIndexes['DRAWER_CONTENT'],
            )}
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            custom={height}
            ref={ref}
          >
            <motion.div
              className="absolute top-0 bottom-0 right-0 w-full h-screen bg-white shadow-2xl"
              variants={drawerSidebarVariants}
            />
            <TouchScrollable>
              <motion.div
                variants={drawerNavigationVariants}
                className={classNames(
                  'm-0 px-4 absolute top-16 w-full h-screen',
                  {
                    'pointer-events-none': !isOpen,
                  },
                )}
              >
                <motion.div variants={drawerWrapperDivVariants}>
                  {languages.length > 1 && (
                    <motion.div
                      className="flex flex-col"
                      key="sidebar-component"
                      variants={drawerMenuItemVariants}
                    >
                      <div
                        className="flex flex-col pb-3 mb-3 ml-4 border-b-2 border-gray-300"
                        key="languages"
                      >
                        {languages.map((language) => {
                          const isActive = language === activeLanguage;

                          return (
                            <LanguageChangeLink
                              key={language}
                              pageUrl={pageUrl}
                              language={language}
                              className={classNames(
                                'py-2 hover:!text-primary-400 font-semibold text-gray-800',
                                {
                                  'text-gray-600':
                                    headerConfig?.areHeroSectionHeaderLinksLight &&
                                    !headerScrollTriggered &&
                                    !isActive,
                                  '!text-primary-main': isActive,
                                },
                              )}
                              withTranslation
                              withFlag
                            />
                          );
                        })}
                      </div>
                    </motion.div>
                  )}
                  <ul key="links" className="flex flex-col">
                    {[defaultHomeLink, ...(headerConfig?.links || [])].map(
                      (link, index) => (
                        <motion.li
                          variants={drawerMenuItemVariants}
                          key={index}
                          onClick={() => {
                            if (!isReservationFlow) {
                              toggleOpen();
                            }
                          }}
                        >
                          <LocalizedLink
                            className="flex items-center h-12 px-6 text-xl font-semibold text-gray-800 transition-colors duration-300 rounded hover:bg-primary-main hover:text-white"
                            key={link.to[activeLanguage]}
                            to={link.to[activeLanguage]}
                            currentPageUrl={pageUrl[activeLanguage]}
                            // @ts-ignore
                            onClick={
                              isReservationFlow
                                ? (to: string) => {
                                    if (shouldWarnOnLeave) {
                                      setNavigateToOnConfirm?.(to);
                                      setIsCancelReservationModalOpen?.(true);
                                    } else {
                                      onLeaveReservationFlowClick?.(to);
                                    }
                                  }
                                : undefined
                            }
                          >
                            {link.label[activeLanguage]}
                          </LocalizedLink>
                        </motion.li>
                      ),
                    )}
                    {pageContext.availabilityCheckConfig.isActive && (
                      <motion.li
                        variants={drawerMenuItemVariants}
                        key={'hamburger-availability-check-button'}
                        className="mt-3"
                      >
                        <LocalizedLink
                          to={`/${verifyAvailabilityPageLanguageMapping[activeLanguage]}`}
                          className={classNames(
                            'btn-primary-outline btn-lg mr-2 930:mr-3 930:opacity-100 transition-opacity duration-500',
                          )}
                          shouldUseDefaultStyle={false}
                        >
                          {
                            pageContext.headerConfig.secondaryCallToActionText[
                              activeLanguage
                            ]
                          }
                        </LocalizedLink>
                      </motion.li>
                    )}
                    {pageContext.reservationConfig.isActive && (
                      <motion.li
                        variants={drawerMenuItemVariants}
                        key={'hamburger-reservation-button'}
                        className="mt-3"
                      >
                        <LocalizedLink
                          to={`/${reservationPageLanguageMapping[activeLanguage]}`}
                          className={classNames(
                            'btn-primary-filled btn-lg mr-2 930:mr-0 930:opacity-100 transition-opacity duration-500',
                          )}
                          shouldUseDefaultStyle={false}
                        >
                          {
                            pageContext.headerConfig.callToActionText[
                              activeLanguage
                            ]
                          }
                        </LocalizedLink>
                      </motion.li>
                    )}
                  </ul>
                </motion.div>
              </motion.div>
            </TouchScrollable>
          </motion.nav>
        </ReactScrollLock>
      </FocusLock>
      <motion.div
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        className={classNames(zIndexes['HAMBURGER'], 'flex 930:hidden')}
      >
        <HamburgerButton
          handleToggle={toggleOpen}
          svgClassName="text-gray-800"
        />
      </motion.div>
    </>
  );
};

export default HamburgerMenu;

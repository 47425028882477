import { getTimelessDate, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { getNumberOfPersonsOptionsForIntervals } from './get-number-of-persons-options-for-intervals';
export const getSelectedIntervalNumberOfPersons = ({ syncGroupEntity, selectedBulkNumberOfPersons, selectedService, selectedDate, }) => {
    var _a;
    let numberOfPersons;
    if (syncGroupEntity.withBulkNumberOfPersonsSelect) {
        if (!selectedBulkNumberOfPersons) {
            const relevantNumberOfPersonsSelectOptions = getNumberOfPersonsOptionsForIntervals({
                selectedDate: getTimelessDate(selectedDate),
                numberOfPersonsSelectOptions: syncGroupEntity.numberOfPersonsSelectOptions,
            });
            numberOfPersons = relevantNumberOfPersonsSelectOptions[0];
        }
        else {
            numberOfPersons = selectedBulkNumberOfPersons;
        }
    }
    else {
        const selectedNrOfPersons = 1;
        numberOfPersons = ((_a = getPeriodPropertyValueForDate({
            date: selectedDate,
            periodSensitiveProperty: selectedService.allowOnlyMaxCapacityEntityIds,
        })) === null || _a === void 0 ? void 0 : _a.includes(syncGroupEntity._id))
            ? syncGroupEntity.capacity
            : selectedNrOfPersons;
    }
    return numberOfPersons;
};

import React from 'react';
export const HungarianFlag = ({ className, onClick }) => {
    return (React.createElement("svg", { onClick: onClick, className: className, version: "1.1", id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 473.684 473.684", enableBackground: "new 0 0 473.684 473.684", xmlSpace: "preserve" },
        React.createElement("circle", { fill: "#FFFFFF", cx: "236.85", cy: "236.85", r: "236.83" }),
        React.createElement("path", { fill: "#EFECEC", d: "M460.147,157.877H314.221c6.335,50.593,6.376,106.339,0.12,156.995h146.116\r\n\tc8.526-24.442,13.219-50.682,13.219-78.03C473.681,209.143,468.883,182.577,460.147,157.877z" }),
        React.createElement("path", { fill: "#E63026", d: "M314.221,157.877h145.922c-0.026-0.075-0.049-0.138-0.075-0.209\r\n\tC429.759,72.204,351.793,9.323,258.117,0.976C294.364,20.848,304.951,83.808,314.221,157.877z" }),
        React.createElement("path", { fill: "#008E56", d: "M258.117,472.705c93.848-8.362,171.927-71.46,202.12-157.159c0.079-0.228,0.146-0.452,0.228-0.673\r\n\th-146.12C305.149,389.346,294.518,452.75,258.117,472.705z" }),
        React.createElement("path", { fill: "#EFEFEF", d: "M0.004,236.842c0,27.348,4.693,53.588,13.219,78.03h313.313c6.256-50.66,6.215-106.402-0.12-156.995\r\n\tH13.537C4.802,182.577,0.004,209.143,0.004,236.842z" }),
        React.createElement("path", { fill: "#E73B36", d: "M13.612,157.668c-0.026,0.071-0.049,0.135-0.075,0.209h312.879\r\n\tc-9.27-74.07-32.052-137.029-68.303-156.901C251.101,0.355,244.011,0,236.838,0C133.809,0,46.191,65.805,13.612,157.668z" }),
        React.createElement("path", { fill: "#14B36F", d: "M326.536,314.872H13.223c0.079,0.221,0.15,0.445,0.228,0.673\r\n\tc32.452,92.106,120.19,158.139,223.387,158.139c7.173,0,14.263-0.352,21.274-0.98C294.518,452.75,317.34,389.346,326.536,314.872z" }),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null)));
};

import React from 'react';
import { SelectPeriod } from './select-period';
import { SelectEntity } from './select-entity';
import { useValidateRestrictions } from '../../hooks/use-validate-restrictions';
import { useSyncNumberOfPersons } from '../../hooks/use-sync-number-of-persons';
import { useSyncCachedValuesOfEntitiesSwitches } from '../../hooks/use-sync-cached-values-of-entities-switches';
import { useSyncCachedValuesOfIntervalsSwitches } from '../../hooks/use-sync-cached-values-of-intervals-switches';
import { useSelectedValuesSlice } from '../../store';
import { BulkNumberOfPersonsSelector } from './bulk-number-of-persons-selector';
import { PromotionsList } from './promotions-list';
import { ReservationSteps } from '@reservation-app/common-used-in-web';
export const SelectPeriodAndEntityStep = () => {
    useValidateRestrictions({ isDisabled: false });
    useSyncCachedValuesOfEntitiesSwitches();
    useSyncCachedValuesOfIntervalsSwitches();
    useSyncNumberOfPersons();
    const { selectedEntity } = useSelectedValuesSlice();
    return (React.createElement("div", null,
        React.createElement(SelectPeriod, { step: ReservationSteps.SELECT_PERIOD_AND_ENTITY }),
        React.createElement("div", { className: "w-full h-[2px] bg-gray-200 mt-5 mb-4" }),
        React.createElement(SelectEntity, { step: ReservationSteps.SELECT_PERIOD_AND_ENTITY }),
        selectedEntity && selectedEntity.withBulkNumberOfPersonsSelect && (React.createElement(BulkNumberOfPersonsSelector, { step: ReservationSteps.SELECT_PERIOD_AND_ENTITY })),
        React.createElement(PromotionsList, { className: "pb-28 768:pb-0" })));
};

import { isSameDay } from 'date-fns';
export const getEntitiesPopuplatedWithReservedIntervals = ({ toBePopulatedEntities, reservedIntervals, onlyForTheseDates, }) => {
    const populatedEntities = JSON.parse(JSON.stringify(toBePopulatedEntities));
    populatedEntities.forEach((entity) => {
        entity.reservedIntervals = [];
    });
    populatedEntities.forEach((entity) => {
        reservedIntervals.forEach((reservedInterval) => {
            if (entity._id === reservedInterval.entityId) {
                if (onlyForTheseDates &&
                    onlyForTheseDates.length > 0 &&
                    onlyForTheseDates.some((date) => isSameDay(date, new Date(reservedInterval.date)))) {
                    entity.reservedIntervals.push(reservedInterval);
                }
                else if (!onlyForTheseDates) {
                    entity.reservedIntervals.push(reservedInterval);
                }
            }
        });
    });
    return populatedEntities;
};

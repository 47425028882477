import React from 'react';
import classNames from 'classnames';
import { Spinner } from './spinner';
export const Button = React.forwardRef(({ className, children, isDisabled, isLoading, Icon, iconPosition = 'left', type = 'button', size = 'md', color = 'primary', variant = 'filled', onClick, onMouseEnter, onMouseLeave, onFocus, onBlur, ariaProps = {}, testId, }, ref) => (React.createElement("button", Object.assign({ "data-test-id": testId, ref: ref, type: type, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onFocus: onFocus, onBlur: onBlur, onClick: onClick, disabled: isDisabled || isLoading, className: classNames('keyboard-only-focus', {
        'btn-sm': size === 'sm',
    }, {
        'btn-md': size === 'md',
    }, {
        'btn-lg': size === 'lg',
    }, {
        'btn-xl': size === 'xl',
    }, { 'btn-primary-filled': color === 'primary' && variant === 'filled' }, { 'btn-neutral-filled': color === 'neutral' && variant === 'filled' }, { 'btn-warning-filled': color === 'warning' && variant === 'filled' }, { 'btn-danger-filled': color === 'danger' && variant === 'filled' }, { 'btn-success-filled': color === 'success' && variant === 'filled' }, { 'btn-primary-outline': color === 'primary' && variant === 'outline' }, { 'btn-neutral-outline': color === 'neutral' && variant === 'outline' }, { 'btn-warning-outline': color === 'warning' && variant === 'outline' }, { 'btn-danger-outline': color === 'danger' && variant === 'outline' }, { 'btn-success-outline': color === 'success' && variant === 'outline' }, { 'btn-primary-ghost': color === 'primary' && variant === 'ghost' }, { 'btn-neutral-ghost': color === 'neutral' && variant === 'ghost' }, { 'btn-warning-ghost': color === 'warning' && variant === 'ghost' }, { 'btn-danger-ghost': color === 'danger' && variant === 'ghost' }, { 'btn-success-ghost': color === 'success' && variant === 'ghost' }, className) }, ariaProps),
    isLoading && iconPosition === 'left' && (React.createElement(Spinner, { fill: "rgba(255, 255, 255, 1)", stroke: "rgba(255, 255, 255, 1)", className: classNames('btn-icon-spinner-left', {
            'btn-spinner-sm': size === 'sm',
            'btn-spinner-md': size === 'md',
            'btn-spinner-lg': size === 'lg',
            'btn-spinner-xl': size === 'xl',
        }) })),
    !isLoading && Icon && iconPosition === 'left' && (React.createElement(Icon, { className: classNames('btn-icon-spinner-left') })),
    children,
    !isLoading && Icon && iconPosition === 'right' && (React.createElement(Icon, { className: classNames('btn-icon-spinner-right') })),
    isLoading && iconPosition === 'right' && (React.createElement(Spinner, { fill: "rgba(255, 255, 255, 1)", stroke: "rgba(255, 255, 255, 1)", className: classNames('btn-icon-spinner-right', {
            'btn-spinner-sm': size === 'sm',
            'btn-spinner-md': size === 'md',
            'btn-spinner-lg': size === 'lg',
            'btn-spinner-xl': size === 'xl',
        }) })))));

import React, { useEffect } from 'react';
import { ModalWrapper } from './modal-wrapper';
import * as Sentry from '@sentry/react';
import { Button, SELECTED_COMPANY_NAME_LS_KEY, useGetTranslation, zIndexes, Modal, } from '@reservation-app/common-used-in-web';
import { useStaticPropsSlice, useModalStatesDataSlice } from '../store';
export const ForceRefreshModal = () => {
    const getTranslation = useGetTranslation();
    const { onLeaveReservationFlowClick } = useStaticPropsSlice();
    const { isForceRefreshModalOpen, forceRefreshModalText } = useModalStatesDataSlice();
    useEffect(() => {
        if (isForceRefreshModalOpen) {
            try {
                Sentry.captureMessage(forceRefreshModalText.header ===
                    getTranslation('timeIsOutOfSync')
                    ? 'SystemTimeModalOpened'
                    : 'ForceRefreshModal opened', {
                    level: 'info',
                    extra: {
                        modalText: JSON.stringify(forceRefreshModalText, null, 2),
                        companyName: process.env.GATSBY_COMPANY_NAME ||
                            localStorage.getItem(SELECTED_COMPANY_NAME_LS_KEY) ||
                            'UNKNOWN',
                    },
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }, [isForceRefreshModalOpen]);
    return (React.createElement(Modal, { overlayClassName: zIndexes['FORCE_REFRESH_MODAL'], isOpen: isForceRefreshModalOpen, withCloseButton: false, onClose: () => { }, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "text-white bg-warning-main" },
            React.createElement("div", { className: "p-4", "data-test-id": "force-refresh-modal" },
                React.createElement("p", { className: "mb-2 text-lg font-semibold text-gray-900" }, forceRefreshModalText.header),
                React.createElement("p", { className: "mb-2 text-gray-900" }, forceRefreshModalText.description),
                React.createElement("p", { className: "mb-5 font-semibold text-gray-900" }, forceRefreshModalText.callToAction),
                React.createElement(Button, { onClick: () => window.location.reload(), color: "warning", size: "md", className: "w-full mb-2 force-refresh-modal-reload-page-button" }, getTranslation('reloadPage')),
                React.createElement(Button, { onClick: () => onLeaveReservationFlowClick(), color: "warning", size: "md", variant: "outline", className: "w-full force-refresh-modal-go-to-home-button" }, getTranslation('goToHome'))))));
};

import { EntityStatuses, getTimelessDate, getPeriodPropertyValueForDate, jsDayIndexToDayMap, NumberOfPersonsOptionStatuses, } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame } from './are-intervals-the-same';
import { getNumberOfPersonsOptionsWithStatus } from './get-number-of-persons-options-status';
export const getEntitiesWithStatus = ({ selectedIntervals, entities, }) => {
    if (selectedIntervals.length === 0) {
        return entities;
    }
    return entities.map((entity) => {
        for (let i = 0; i < selectedIntervals.length; i++) {
            const currentSelectedInterval = selectedIntervals[i];
            const relevantSchedule = getPeriodPropertyValueForDate({
                date: currentSelectedInterval.date,
                periodSensitiveProperty: entity.schedule,
            });
            const currentDay = jsDayIndexToDayMap[getTimelessDate(currentSelectedInterval.date).getDay()];
            const currentDayFromSchedule = relevantSchedule[currentDay];
            if (currentDayFromSchedule.isClosed) {
                return Object.assign(Object.assign({}, entity), { status: EntityStatuses.CLOSED });
            }
            const isCurrentSelectedIntervalReserved = entity.reservedIntervals.some((reservedInterval) => {
                const isSelectedIntervalReserved = areIntervalsTheSame(Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval), currentSelectedInterval);
                if (entity.withBulkNumberOfPersonsSelect) {
                    const numberOfPersonsOptionsWithStatus = getNumberOfPersonsOptionsWithStatus({
                        selectedEntity: entity,
                        selectedIntervals,
                        selectedDate: new Date(reservedInterval.date),
                    });
                    return (isSelectedIntervalReserved &&
                        numberOfPersonsOptionsWithStatus.every((optionWithStatus) => optionWithStatus.status ===
                            NumberOfPersonsOptionStatuses.RESERVED ||
                            optionWithStatus.status ===
                                NumberOfPersonsOptionStatuses.CLOSED));
                }
                return isSelectedIntervalReserved;
            });
            if (isCurrentSelectedIntervalReserved) {
                return Object.assign(Object.assign({}, entity), { status: EntityStatuses.RESERVED });
            }
        }
        return Object.assign(Object.assign({}, entity), { status: EntityStatuses.AVAILABLE });
    });
};

import './src/index.css';
import { wrapPageElement } from './gatsby-ssr';
import smoothscroll from 'smoothscroll-polyfill';

const onClientEntry = () => {
  smoothscroll.polyfill();
};

const onRouteUpdate = ({ location }) => {
  const didUserGiveConsentToCookies =
    typeof window !== 'undefined'
      ? localStorage.getItem('cookies_consent_given') === 'allow_all'
      : false;

  if (
    typeof window === 'undefined' ||
    process.env.GATSBY_ACTIVE_ENV !== 'production' ||
    typeof gtag !== 'function' ||
    !didUserGiveConsentToCookies
  ) {
    return null;
  }

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    if (
      typeof window !== 'undefined' &&
      typeof window.gtag === 'function' &&
      didUserGiveConsentToCookies
    ) {
      const pagePath = location
        ? location.pathname + location.search + location.hash
        : undefined;

      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  };

  if (typeof window !== 'undefined' && didUserGiveConsentToCookies) {
    if ('requestAnimationFrame' in window) {
      requestAnimationFrame(() => {
        requestAnimationFrame(sendPageView);
      });
    } else {
      // simulate 2 rAF calls
      setTimeout(sendPageView, 32);
    }
  }

  return null;
};

export { wrapPageElement, onRouteUpdate, onClientEntry };

import classNames from 'classnames';
import { isAfter, isBefore, isSameDay, isSameMonth } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
export const CalendarCell = ({ day, isSelected, selectedDateButtonRef, activeTilesClassName, monthStart, restrictedAfter, restrictedBefore, disabledTilesClassName, selectedTileClassName, todayTileClassName, allTilesClassName, selectHandler, CellComponent, formattedDate, }) => {
    const cloneDay = day;
    const activeTileClassNamePrevValue = useRef();
    const activeTilesClassNameValue = useMemo(() => {
        if ((isSameMonth(day, monthStart) &&
            (restrictedAfter
                ? isBefore(day, restrictedAfter) || isSameDay(day, restrictedAfter)
                : true)) ||
            (restrictedBefore
                ? isAfter(day, restrictedBefore) || isSameDay(day, restrictedBefore)
                : true)) {
            return typeof activeTilesClassName === 'string'
                ? `${activeTilesClassName} active-tiles`
                : `${activeTilesClassName(day, activeTileClassNamePrevValue.current)} active-tiles`;
        }
        return '';
    }, [
        monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(),
        day === null || day === void 0 ? void 0 : day.getTime(),
        restrictedAfter === null || restrictedAfter === void 0 ? void 0 : restrictedAfter.getTime(),
        restrictedBefore === null || restrictedBefore === void 0 ? void 0 : restrictedBefore.getTime(),
        activeTilesClassName,
    ]);
    useEffect(() => {
        activeTileClassNamePrevValue.current = activeTilesClassNameValue;
    }, [activeTilesClassNameValue]);
    const disabledTilesClassNameValue = useMemo(() => {
        if (!isSameMonth(day, monthStart) ||
            (restrictedAfter &&
                isAfter(day, restrictedAfter) &&
                !isSameDay(day, restrictedAfter)) ||
            (restrictedBefore &&
                isBefore(day, restrictedBefore) &&
                !isSameDay(day, restrictedBefore))) {
            return typeof disabledTilesClassName === 'string'
                ? `${disabledTilesClassName} disabled-tiles`
                : `${disabledTilesClassName(day)} disabled-tiles`;
        }
        return '';
    }, [
        monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(),
        restrictedAfter === null || restrictedAfter === void 0 ? void 0 : restrictedAfter.getTime(),
        day === null || day === void 0 ? void 0 : day.getTime(),
        restrictedBefore === null || restrictedBefore === void 0 ? void 0 : restrictedBefore.getTime(),
        disabledTilesClassName,
    ]);
    const selectedTileClassNameValue = useMemo(() => {
        if (isSelected) {
            return typeof selectedTileClassName === 'string'
                ? `${selectedTileClassName} selected-tiles`
                : `${selectedTileClassName(day)} selected-tiles`;
        }
        return '';
    }, [monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(), isSelected, selectedTileClassName]);
    const todayTileClassNameValue = useMemo(() => {
        if (isSameDay(day, new Date())) {
            return `${todayTileClassName}`;
        }
        return '';
    }, [monthStart === null || monthStart === void 0 ? void 0 : monthStart.getTime(), todayTileClassName, day === null || day === void 0 ? void 0 : day.getTime()]);
    return (React.createElement("button", { key: day === null || day === void 0 ? void 0 : day.getTime(), type: "button", role: "option", "aria-selected": isSelected, tabIndex: -1, ref: isSelected ? selectedDateButtonRef : undefined, className: classNames('tile-class', todayTileClassNameValue, disabledTilesClassNameValue, selectedTileClassNameValue, activeTilesClassNameValue, allTilesClassName), style: { paddingTop: '14.29%' }, onMouseDown: (e) => {
            e.preventDefault();
            let isDayRestricted = false;
            if (restrictedBefore && isBefore(cloneDay, restrictedBefore)) {
                isDayRestricted = true;
            }
            if (restrictedAfter && isAfter(cloneDay, restrictedAfter)) {
                isDayRestricted = true;
            }
            if (!isDayRestricted) {
                selectHandler(cloneDay);
            }
        } },
        React.createElement("div", { className: "tile-characters-class" }, CellComponent ? React.createElement(CellComponent, { day: day }) : formattedDate)));
};
export default CalendarCell;

import { getPeriodPropertyValueForDate, getPeriodPropertyValuesForDate, getTimelessDate, isLastIndex, ReservationSteps, } from '@reservation-app/common-used-in-web';
import { addDays, format, startOfWeek } from 'date-fns';
import { useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { useReservationFlowStore } from '.';
import { dayToJsDayIndexMap } from '../helpers/day-to-js-day-index';
import { getClassifiedReservableIntervals } from '../helpers/get-classified-reservable-intervals';
import { getEntitiesWithStatus } from '../helpers/get-entities-with-status';
import { getPromotionsToList } from '../helpers/get-promotions-to-list';
import { getSelectableExtras } from '../helpers/get-selectable-extras';
export const shallowCompare = shallow;
export const staticSliceSelector = (state) => state.static;
export const staticPropsSliceSelector = (state) => state.static.staticProps;
export const componentsSliceSelector = (state) => state.static.components;
export const componentPropsSliceSelector = (state) => state.static.staticProps.componentProps;
export const webSpecificSliceSelector = (state) => state.webSpecific;
export const remoteMasterDataSliceSelector = (state) => state.remoteMasterData;
export const stepperStateSliceSelector = (state) => state.stepperState;
export const modalStatesDataSliceSelector = (state) => state.modalStates;
export const selectedValuesSliceSelector = (state) => state.selectedValues;
export const servicesStepSpecificSliceSelector = (state) => state.servicesStepSpecific;
export const periodAndEntityStepSpecificSliceSelector = (state) => state.periodAndEntityStepSpecific;
export const extrasStepSpecificSliceSelector = (state) => state.extrasStepSpecific;
export const billingDetailsStepSpecificSliceSelector = (state) => state.billingDetailsStepSpecific;
export const paymentDetailsStepSpecificSliceSelector = (state) => state.paymentDetailsStepSpecific;
export const isDesktopSelector = (state) => state.webSpecific.isDesktop;
export const companyReservationConfigSelector = (state) => { var _a; return (_a = state.remoteMasterData) === null || _a === void 0 ? void 0 : _a.companyReservationConfig; };
export const isAdminPanelSelector = (state) => state.static.staticProps.isAdminPanel;
export const isVerifyAvailabilityModeSelector = (state) => state.static.staticProps.isVerifyAvailabilityMode;
export const useStaticSlice = () => useReservationFlowStore(staticSliceSelector, shallowCompare);
export const useStaticPropsSlice = () => useReservationFlowStore(staticPropsSliceSelector, shallowCompare);
export const useComponentsSlice = () => useReservationFlowStore(componentsSliceSelector, shallowCompare);
export const useComponentPropsSlice = () => useReservationFlowStore(componentPropsSliceSelector, shallowCompare);
export const useWebSpecificSlice = () => useReservationFlowStore(webSpecificSliceSelector, shallowCompare);
export const useRemoteMasterDataSlice = () => useReservationFlowStore(remoteMasterDataSliceSelector, shallowCompare);
export const useStepperStateSlice = () => useReservationFlowStore(stepperStateSliceSelector, shallowCompare);
export const useModalStatesDataSlice = () => useReservationFlowStore(modalStatesDataSliceSelector, shallowCompare);
export const useSelectedValuesSlice = () => useReservationFlowStore(selectedValuesSliceSelector, shallowCompare);
export const useServicesStepSpecificSlice = () => useReservationFlowStore(servicesStepSpecificSliceSelector, shallowCompare);
export const usePeriodAndEntityStepSpecificSlice = () => useReservationFlowStore(periodAndEntityStepSpecificSliceSelector, shallowCompare);
export const useExtrasStepSpecificSlice = () => useReservationFlowStore(extrasStepSpecificSliceSelector, shallowCompare);
export const useBillingDetailsStepSpecificSlice = () => useReservationFlowStore(billingDetailsStepSpecificSliceSelector, shallowCompare);
export const usePaymentDetailsStepSpecificSlice = () => useReservationFlowStore(paymentDetailsStepSpecificSliceSelector, shallowCompare);
export const useCompanyReservationConfig = () => useReservationFlowStore(companyReservationConfigSelector);
export const useIsDesktop = () => useReservationFlowStore(isDesktopSelector);
export const useIsAdminPanel = () => useReservationFlowStore(isAdminPanelSelector);
export const useIsVerifyAvailabilityMode = () => useReservationFlowStore(isVerifyAvailabilityModeSelector);
export const useFilteredByEntityTypeServices = () => {
    const selectedEntityType = useReservationFlowStore((state) => state.selectedValues.selectedEntityType);
    const services = useReservationFlowStore((state) => state.remoteMasterData.services);
    const filteredByEntityTypeServices = useMemo(() => {
        return services
            .filter((service) => {
            if (getPeriodPropertyValueForDate({
                periodSensitiveProperty: service.entityTypesIds,
                date: new Date(),
            }).entityTypeId === (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType._id)) {
                return true;
            }
            return false;
        })
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [selectedEntityType, selectedEntityType]);
    return filteredByEntityTypeServices;
};
export const useFilteredByTypeEntities = () => {
    const selectedEntityType = useReservationFlowStore((state) => state.selectedValues.selectedEntityType);
    const entities = useReservationFlowStore((state) => state.remoteMasterData.entities);
    const filteredEntitiesByType = useMemo(() => {
        return entities.filter((entity) => entity.entityTypesIds.includes(selectedEntityType._id));
    }, [selectedEntityType, entities]);
    return filteredEntitiesByType;
};
export const useFilteredByServiceEntities = () => {
    const selectedService = useReservationFlowStore((state) => state.selectedValues.selectedService);
    const entities = useReservationFlowStore((state) => state.remoteMasterData.entities);
    const filteredEntitiesByService = useMemo(() => {
        return entities
            .filter((entity) => selectedService === null || selectedService === void 0 ? void 0 : selectedService.entitiesIds.some((entityId) => entityId === entity._id))
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [selectedService, entities]);
    return filteredEntitiesByService;
};
export const useEntitiesWithStatus = () => {
    const selectedIntervals = useReservationFlowStore((state) => state.selectedValues.selectedIntervals);
    const filteredByServiceEntities = useFilteredByServiceEntities();
    const entitiesWithStatus = useMemo(() => getEntitiesWithStatus({
        entities: filteredByServiceEntities,
        selectedIntervals,
    }), [filteredByServiceEntities, selectedIntervals]);
    return entitiesWithStatus;
};
export const useSyncGroupEntities = () => {
    const selectedSyncGroupKey = useReservationFlowStore((state) => state.selectedValues.selectedSyncGroupKey);
    const filteredEntitiesByService = useFilteredByServiceEntities();
    const syncGroupEntities = useMemo(() => {
        return filteredEntitiesByService.filter((entity) => entity.syncGroupKey === selectedSyncGroupKey);
    }, [filteredEntitiesByService, selectedSyncGroupKey]);
    return syncGroupEntities;
};
export const useFilteredPromotions = () => {
    const selectedService = useReservationFlowStore((state) => state.selectedValues.selectedService);
    const filteredPromotions = useMemo(() => {
        return getPromotionsToList({
            promotions: selectedService.promotions,
        });
    }, [selectedService]);
    return filteredPromotions;
};
export const useReservableIntervalsList = () => {
    const filteredByServiceEntities = useFilteredByServiceEntities();
    const selectedService = useReservationFlowStore((state) => state.selectedValues.selectedService);
    const selectedSyncGroupKey = useReservationFlowStore((state) => state.selectedValues.selectedSyncGroupKey);
    const selectedBulkNumberOfPersons = useReservationFlowStore((state) => state.selectedValues.selectedBulkNumberOfPersons);
    const selectedEntity = useReservationFlowStore((state) => state.selectedValues.selectedEntity);
    const selectedDate = useReservationFlowStore((state) => state.periodAndEntityStepSpecific.selectedDate);
    const shortcutReservableIntervalsToList = useMemo(() => {
        var _a;
        if (selectedDate && ((_a = selectedService.reservableShortcuts) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const obj = {};
            const relevantDate = selectedDate;
            const periodReservableShortcuts = getPeriodPropertyValuesForDate({
                date: relevantDate,
                periodSensitiveProperty: selectedService.reservableShortcuts,
            });
            const relevantWeek = startOfWeek(relevantDate);
            periodReservableShortcuts.forEach((reservableShortcut) => {
                reservableShortcut.days.forEach((day) => {
                    const dayDate = addDays(relevantWeek, day.isNextWeek
                        ? dayToJsDayIndexMap[day.value] + 7
                        : dayToJsDayIndexMap[day.value]);
                    const formattedDate = format(dayDate, 'yyyy-MM-dd');
                    obj[formattedDate] = getClassifiedReservableIntervals({
                        selectedDate: dayDate,
                        entities: filteredByServiceEntities,
                        selectedEntity,
                        selectedService,
                        selectedSyncGroupKey,
                        selectedBulkNumberOfPersons,
                    });
                });
            });
            return obj;
        }
        return null;
    }, [
        selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime(),
        filteredByServiceEntities,
        selectedEntity,
        selectedService,
        selectedSyncGroupKey,
        selectedBulkNumberOfPersons,
    ]);
    const reservableIntervalsList = useMemo(() => getClassifiedReservableIntervals({
        selectedDate: selectedDate || new Date(),
        entities: filteredByServiceEntities,
        selectedEntity,
        selectedService,
        selectedSyncGroupKey,
        selectedBulkNumberOfPersons,
    }), [
        selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime(),
        filteredByServiceEntities,
        selectedEntity,
        selectedService,
        selectedSyncGroupKey,
        selectedBulkNumberOfPersons,
    ]);
    const getReservableIntervalsList = useCallback((sd) => getClassifiedReservableIntervals({
        selectedDate: sd || new Date(),
        entities: filteredByServiceEntities,
        selectedEntity,
        selectedService,
        selectedSyncGroupKey,
        selectedBulkNumberOfPersons,
    }), [
        filteredByServiceEntities,
        selectedEntity,
        selectedService,
        selectedSyncGroupKey,
        selectedBulkNumberOfPersons,
    ]);
    return {
        reservableIntervalsList,
        getReservableIntervalsList,
        shortcutReservableIntervalsToList,
    };
};
export const useSyncGroupEntity = () => {
    const filteredByServiceEntities = useFilteredByServiceEntities();
    const selectedSyncGroupKey = useReservationFlowStore((state) => state.selectedValues.selectedSyncGroupKey);
    const syncGroupEntity = useMemo(() => filteredByServiceEntities.find((entity) => entity.syncGroupKey === selectedSyncGroupKey), [filteredByServiceEntities, selectedSyncGroupKey]);
    return syncGroupEntity;
};
export const useSelectableExtras = () => {
    const selectedService = useReservationFlowStore((state) => state.selectedValues.selectedService);
    const selectedIntervals = useReservationFlowStore((state) => state.selectedValues.selectedIntervals);
    const selectableExtras = useMemo(() => {
        const selectedIntervalsWithExtras = selectedIntervals.map((selectedInterval) => {
            const relevantDay = getTimelessDate(selectedInterval.date);
            const relevantReservableIntervals = getPeriodPropertyValuesForDate({
                periodSensitiveProperty: selectedService.reservableIntervals,
                date: relevantDay,
            });
            const relevantReservableInterval = relevantReservableIntervals.find((ric) => ric.timeInterval.endTime ===
                selectedInterval.reservableInterval.timeInterval.endTime &&
                ric.timeInterval.startTime ===
                    selectedInterval.reservableInterval.timeInterval.startTime);
            return Object.assign(Object.assign({}, selectedInterval), { reservableInterval: Object.assign(Object.assign({}, selectedInterval.reservableInterval), { extras: relevantReservableInterval.extras }) });
        });
        return getSelectableExtras({
            selectedIntervals: selectedIntervalsWithExtras,
        }).sort((a, b) => a.displayOrder - b.displayOrder);
    }, [selectedService]);
    return selectableExtras;
};
export const useShouldWarnOnLeave = () => {
    const selectedIntervals = useReservationFlowStore((state) => state.selectedValues.selectedIntervals);
    const currentStep = useReservationFlowStore((state) => state.stepperState.currentStep);
    if (currentStep === ReservationSteps.CONGRATULATIONS) {
        return false;
    }
    return selectedIntervals && selectedIntervals.length > 0;
};
export const useIsNextButtonDisabled = () => {
    const selectedIntervals = useReservationFlowStore((state) => state.selectedValues.selectedIntervals);
    const selectedEntity = useReservationFlowStore((state) => state.selectedValues.selectedEntity);
    const selectedEntityType = useReservationFlowStore((state) => state.selectedValues.selectedEntityType);
    const selectedService = useReservationFlowStore((state) => state.selectedValues.selectedService);
    const isNextStepLoading = useReservationFlowStore((state) => state.stepperState.isNextStepLoading);
    const validationError = useReservationFlowStore((state) => state.stepperState.validationError);
    const currentStep = useReservationFlowStore((state) => state.stepperState.currentStep);
    const isNextButtonDisabled = useMemo(() => {
        if (isNextStepLoading) {
            return true;
        }
        if (currentStep === ReservationSteps.SELECT_ENTITY_TYPE) {
            if (!selectedEntityType) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.SELECT_SERVICE) {
            if (!selectedService) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.SELECT_ENTITY) {
            if (!selectedEntity || validationError) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.SELECT_PERIOD) {
            if (!selectedIntervals.length || validationError) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY) {
            if (!selectedIntervals.length || !selectedEntity || validationError) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.BILLING_DETAILS) {
            if (validationError) {
                return true;
            }
        }
        else if (currentStep === ReservationSteps.PAYMENT_DETAILS) {
            return false;
        }
        return false;
    }, [
        currentStep,
        isNextStepLoading,
        selectedEntityType,
        selectedEntity,
        selectedService,
        validationError,
        selectedIntervals,
    ]);
    return isNextButtonDisabled;
};
export const useIsPreviousButtonDisabled = () => {
    const isPreviousButtonSpamBlockEnabled = useReservationFlowStore((state) => state.stepperState.isPreviousButtonSpamBlockEnabled);
    const isNextStepLoading = useReservationFlowStore((state) => state.stepperState.isNextStepLoading);
    const steps = useReservationFlowStore((state) => state.stepperState.steps);
    const currentStep = useReservationFlowStore((state) => state.stepperState.currentStep);
    const isPreviousButtonDisabled = useMemo(() => {
        var _a;
        if (!steps ||
            steps.length === 0 ||
            steps.length === 1 ||
            currentStep === ((_a = steps[0]) === null || _a === void 0 ? void 0 : _a.step) ||
            isNextStepLoading ||
            isPreviousButtonSpamBlockEnabled) {
            return true;
        }
        return false;
    }, [steps, currentStep, isNextStepLoading, isPreviousButtonSpamBlockEnabled]);
    return isPreviousButtonDisabled;
};
export const useGetExtraWithCounterCountValue = () => {
    const selectedIntervals = useReservationFlowStore((state) => state.selectedValues.selectedIntervals);
    const getExtraWithCounterCountValue = useCallback((extraId) => {
        for (let i = 0; i < selectedIntervals.length; i++) {
            for (let j = 0; j < selectedIntervals[i].selectedExtras.length; j++) {
                if (selectedIntervals[i].selectedExtras[j].extraId === extraId) {
                    return selectedIntervals[i].selectedExtras[j].amount;
                }
            }
        }
        return 0;
    }, [selectedIntervals]);
    return getExtraWithCounterCountValue;
};
export const useNumberOfSteps = () => {
    const steps = useReservationFlowStore((state) => state.stepperState.steps);
    const numberOfSteps = useMemo(() => {
        return (steps === null || steps === void 0 ? void 0 : steps.length) || 0;
    }, [steps]);
    return numberOfSteps;
};
export const useIsCompletionStep = () => {
    const steps = useReservationFlowStore((state) => state.stepperState.steps);
    const currentStep = useReservationFlowStore((state) => state.stepperState.currentStep);
    const isCompletionStep = useMemo(() => {
        if (steps.length === 0 || steps.length === 1) {
            return false;
        }
        const relevantSteps = steps.filter(({ step }) => step !== ReservationSteps.CONGRATULATIONS);
        const currentStepIndex = relevantSteps.findIndex(({ step }) => step === currentStep);
        return isLastIndex(currentStepIndex, relevantSteps);
    }, [steps, currentStep]);
    return isCompletionStep;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EntityStatuses, ReservationSteps, isDevEnv, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RESERVATION_FLOW_DATA_QUERY_KEY, RESERVATION_FLOW_RESERVED_INTERVALS_QUERY_KEY, CONTACT_INFOS_QUERY_KEY, } from '../../query-keys';
import { useStaticPropsSlice, useStepperStateSlice, useRemoteMasterDataSlice, useBillingDetailsStepSpecificSlice, useModalStatesDataSlice, } from '../store';
import { useEffect } from 'react';
import { RESERVATION_ONBOARDING_MODAL_LS_KEY, VERIFY_ONBOARDING_MODAL_LS_KEY, } from '../modals/onboarding-modal';
export const useRequestAndSetRemoteData = (apiClient) => {
    const getTranslation = useGetTranslation();
    const queryClient = useQueryClient();
    const { toastManager, isAdminPanel, isVerifyAvailabilityMode } = useStaticPropsSlice();
    const { isFocused, currentStep, isFinishingOrder } = useStepperStateSlice();
    const { setEntityTypes, setServices, setEntities, setExtras, deriveInitialStateFromRemoteData, setCompanyBuildInfo, setCompanyOfficialData, handleReservedIntervalsResponse, setAlerts, isDerivingInitialStateFromRemoteData, setCompanyAvailabilityCheckConfig, } = useRemoteMasterDataSlice();
    const { setIsOnboardingModalOpen } = useModalStatesDataSlice();
    const { setCachedBillingDetails } = useBillingDetailsStepSpecificSlice();
    const setRemoteDataStates = (remotaData) => {
        const { entityTypes, services, extras, entities, alerts, company } = remotaData;
        setEntityTypes(entityTypes);
        setServices(services);
        setExtras(extras);
        setEntities(entities.map((entity) => (Object.assign(Object.assign({}, entity), { reservedIntervals: [], status: EntityStatuses.AVAILABLE }))));
        setAlerts(alerts);
        setCompanyBuildInfo(company.buildInfo);
        setCompanyOfficialData(Object.assign(Object.assign({}, company.officialData), { currency: company.reservationConfig.currency, siteUrl: company.webUrl, image: company.image }));
        const onboardingModalLsValue = (isVerifyAvailabilityMode
            ? company.availabilityCheckConfig.onboardingModalLsKey
            : company.reservationConfig.onboardingModalLsKey) || 'v1';
        const wasOnboardingModalShown = isVerifyAvailabilityMode
            ? localStorage.getItem(VERIFY_ONBOARDING_MODAL_LS_KEY)
            : localStorage.getItem(RESERVATION_ONBOARDING_MODAL_LS_KEY);
        if (!wasOnboardingModalShown ||
            wasOnboardingModalShown !== onboardingModalLsValue) {
            setIsOnboardingModalOpen(true);
        }
        setCompanyAvailabilityCheckConfig(company.availabilityCheckConfig);
        deriveInitialStateFromRemoteData(company.reservationConfig);
    };
    useEffect(() => {
        const cachedReservationFlowData = queryClient.getQueryData(RESERVATION_FLOW_DATA_QUERY_KEY);
        if (cachedReservationFlowData) {
            setRemoteDataStates(cachedReservationFlowData);
        }
    }, []);
    const { isLoading: isReservationFlowDataLoading, error: getReservationFlowDataError, } = useQuery(RESERVATION_FLOW_DATA_QUERY_KEY, () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield apiClient.get('/published-data/reservation-flow');
        return response.data;
    }), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (remotaData) => {
            setRemoteDataStates(remotaData);
        },
    });
    const { error: getContactInfosError } = useQuery(CONTACT_INFOS_QUERY_KEY, () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield apiClient.get('/contact-infos/data');
        return response.data;
    }), {
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        enabled: isAdminPanel,
        onSuccess: (contactInfosData) => {
            setCachedBillingDetails(contactInfosData);
        },
    });
    useQuery(RESERVATION_FLOW_RESERVED_INTERVALS_QUERY_KEY, () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield apiClient.get('/reserved-intervals/reservation-flow');
        return response.data;
    }), {
        refetchOnMount: 'always',
        refetchOnWindowFocus: 'always',
        refetchInterval: isFocused && !isFinishingOrder ? 15000 : false,
        refetchIntervalInBackground: false,
        notifyOnChangeProps: ['data', 'error', 'isLoading'],
        enabled: !isReservationFlowDataLoading &&
            currentStep !== ReservationSteps.CONGRATULATIONS &&
            !isFinishingOrder &&
            !(isDevEnv &&
                (currentStep === ReservationSteps.PAYMENT_DETAILS ||
                    currentStep === ReservationSteps.BILLING_DETAILS)),
        onSuccess: (reservedIntervals) => {
            handleReservedIntervalsResponse(reservedIntervals);
        },
        onError: () => {
            toastManager.showToast({
                intent: 'danger',
                position: 'bottom-center',
                text: getTranslation('errorAtLoadingReservedIntervals'),
                timeout: 6000,
            });
        },
    });
    return {
        serverFetchError: getReservationFlowDataError || getContactInfosError,
        isLoadingRemoteData: isReservationFlowDataLoading || isDerivingInitialStateFromRemoteData,
    };
};

import React from 'react';

export const LanguageContext = React.createContext<{
  defaultLanguage: string;
  languages: string[];
  activeLanguage: string;
  translations: Record<string, { en: string; hu: string; ro: string }>;
  setActiveLanguage: (language: string) => void;
  // @ts-ignore
}>({
  languages: [],
});

export const useLanguageContext = () => React.useContext(LanguageContext);

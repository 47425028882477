import { isDevEnv } from '@reservation-app/common-used-in-web';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStaticPropertiesSlice } from './slices/static-properties-slice';
import { createWebSpecificPropertiesSlice } from './slices/web-specific-properties-slice';
import { createModalStatesSlice } from './slices/modal-states-slice';
import { createRemoteMasterDataPropertiesSlice } from './slices/remote-master-data-properties-slice';
import { createStepperStateSlice } from './slices/stepper-state-slice';
import { createSelectedValuesSlice } from './slices/selected-values-slice';
import { createPeriodAndEntityStepSpecificPropertiesSlice } from './slices/period-and-entity-step-specific-properties-slice';
import { createExtrasStepSpecificPropertiesSlice } from './slices/extras-step-specific-properties-slice';
import { createBillingDetailsStepSpecificPropertiesSlice } from './slices/billing-details-step-specific-properties-slice';
import { createPaymentDetailsStepSpecificPropertiesSlice } from './slices/payment-details-step-specific-properties-slice';
import { createServicesStepSpecificPropertiesSlice } from './slices/services-step-specific-properties-slice';
export { getShowAvailableElementsOnlyCacheKey } from './slices/stepper-state-slice';
export { BILLING_DETAILS_LS_KEY } from './slices/billing-details-step-specific-properties-slice';
export const useReservationFlowStore = create(devtools(immer((set, get, _api) => ({
    static: createStaticPropertiesSlice(set, get),
    webSpecific: createWebSpecificPropertiesSlice(set, get),
    modalStates: createModalStatesSlice(set, get),
    remoteMasterData: createRemoteMasterDataPropertiesSlice(set, get),
    stepperState: createStepperStateSlice(set, get),
    selectedValues: createSelectedValuesSlice(set, get),
    servicesStepSpecific: createServicesStepSpecificPropertiesSlice(set, get),
    periodAndEntityStepSpecific: createPeriodAndEntityStepSpecificPropertiesSlice(set, get),
    extrasStepSpecific: createExtrasStepSpecificPropertiesSlice(set, get),
    billingDetailsStepSpecific: createBillingDetailsStepSpecificPropertiesSlice(set, get),
    paymentDetailsStepSpecific: createPaymentDetailsStepSpecificPropertiesSlice(set, get),
})), { enabled: isDevEnv }));
export * from './selectors';

import classNames from 'classnames';
import React, { useMemo } from 'react';
import { isDateInPeriod } from '../helpers/is-date-in-period';
import { useActiveLanguage } from '../translations-store';
import { AlertTypes, AlertSeverities } from '../types/enums';
import { Icon } from './icon';
export const Alert = ({ alerts, isReservationFlow, alertType, className, iconClassName, selectedEntityType, }) => {
    const activeLanguage = useActiveLanguage();
    const relevantAlert = useMemo(() => {
        let filteredAlerts = [];
        if (alertType === AlertTypes.SCHEDULE) {
            filteredAlerts = alerts.filter((alert) => isDateInPeriod({ period: alert.period, date: new Date() }) &&
                alert.value.type === alertType);
        }
        if (alertType === AlertTypes.PRICES) {
            filteredAlerts = alerts.filter((alert) => isDateInPeriod({ period: alert.period, date: new Date() }) &&
                alert.value.type === alertType);
        }
        if (alertType === AlertTypes.ENTITIES) {
            filteredAlerts = alerts.filter((alert) => isDateInPeriod({ period: alert.period, date: new Date() }) &&
                alert.value.type === alertType &&
                alert.value.entityTypeIds.some((alertEntityTypeId) => alertEntityTypeId === (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType._id)));
        }
        if (filteredAlerts.length === 0) {
            return null;
        }
        filteredAlerts.sort((a, b) => b.value.priority - a.value.priority);
        return filteredAlerts[0];
    }, []);
    if (!relevantAlert) {
        return null;
    }
    if (isReservationFlow && !relevantAlert.shouldShowInReservationFlow) {
        return null;
    }
    return (React.createElement("div", { className: classNames('border-l-4 rounded-md p-4 shadow-md max-w-sm 768:max-w-md relative', {
            'bg-info-50 border-info-main': relevantAlert.value.severity === AlertSeverities.INFO,
            'bg-warning-50 border-warning-main': relevantAlert.value.severity === AlertSeverities.CRITICAL,
            'bg-white border-primary-main': relevantAlert.value.severity === AlertSeverities.PRIMARY,
            'bg-success-50 border-success-main': relevantAlert.value.severity === AlertSeverities.SUCCESS,
        }, className) },
        React.createElement("div", { className: "flex items-center mb-3" },
            React.createElement("p", { className: classNames('text font-semibold uppercase', {
                    'text-info-main': relevantAlert.value.severity === AlertSeverities.INFO,
                    'text-warning-main': relevantAlert.value.severity === AlertSeverities.CRITICAL,
                    'text-primary-main': relevantAlert.value.severity === AlertSeverities.PRIMARY,
                    'text-success-main': relevantAlert.value.severity === AlertSeverities.SUCCESS,
                }) }, relevantAlert.value.title[activeLanguage])),
        React.createElement("div", { className: "text-gray-900", dangerouslySetInnerHTML: {
                __html: relevantAlert.value.descriptionHtml[activeLanguage],
            } }),
        React.createElement("div", { className: classNames('w-10 h-10 flex items-center justify-center absolute rounded-full', iconClassName), style: { top: -20, left: -21.6 } },
            React.createElement(Icon, { icon: (relevantAlert.value.icon || 'infoWithCircle'), className: classNames('w-6 h-6', {
                    'text-info-main': relevantAlert.value.severity === AlertSeverities.INFO,
                    'text-warning-main': relevantAlert.value.severity === AlertSeverities.CRITICAL,
                    'text-primary-main': relevantAlert.value.severity === AlertSeverities.PRIMARY,
                    'text-success-main': relevantAlert.value.severity === AlertSeverities.SUCCESS,
                }) }))));
};

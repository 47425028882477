import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps, navigate } from 'gatsby';
import classNames from 'classnames';

import { useLanguageContext } from '../contexts/language-context';
import { sanitizeUrlSlashes } from '../helpers/sanitize-url-slashes';

type LocalizedLinkProps = {
  className?: string;
  shouldUseDefaultStyle?: boolean;
  withBlackText?: boolean;
  currentPageUrl?: string;
  onClick?: (to: string) => void;
};

const LocalizedLink = ({
  to,
  shouldUseDefaultStyle = true,
  withBlackText = false,
  currentPageUrl,
  className,
  children,
  onClick,
  ...rest
}: GatsbyLinkProps<unknown> & LocalizedLinkProps) => {
  const { activeLanguage, defaultLanguage } = useLanguageContext();

  return (
    // @ts-ignore
    <GatsbyLink
      {...rest}
      className={classNames(className, {
        'text-white hover:text-primary-light transition-colors duration-300':
          shouldUseDefaultStyle && !withBlackText,
        'text-gray-800 hover:text-primary-500 transition-colors duration-300':
          shouldUseDefaultStyle && withBlackText,
      })}
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          onClick(to);

          return;
        }

        if (to.includes('#')) {
          event.preventDefault();

          const hashPart = to.substring(to.lastIndexOf('#') + 1, to.length);

          const navigateToPart = to.replace(`#${hashPart}`, '');

          if (currentPageUrl !== navigateToPart) {
            // TODO: check if we can include the # part to the navigate function
            navigate(
              sanitizeUrlSlashes(
                `${
                  defaultLanguage === activeLanguage ? '' : `/${activeLanguage}`
                }${navigateToPart}` || '/',
              ),
            );
          }

          setTimeout(() => {
            const elementToScrollTo = document.getElementById(hashPart);

            if (elementToScrollTo) {
              elementToScrollTo.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              });
            }
          }, 200);
        } else if (to === '/' && currentPageUrl === '/') {
          event.preventDefault();

          setTimeout(() => {
            window.scrollTo({
              behavior: 'smooth',
              top: 0,
            });
          }, 200);
        }
      }}
      to={sanitizeUrlSlashes(
        activeLanguage
          ? `${
              defaultLanguage === activeLanguage ? '' : `/${activeLanguage}`
            }${to}`
          : to,
      )}
    >
      {children}
    </GatsbyLink>
  );
};

export default LocalizedLink;

import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import {
  LANGUAGE_COOKIE_KEY,
  usePrevious,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../contexts/language-context';
import LocalizedLink from '../localized-link';
import { useCookies } from 'react-cookie';
import HeaderWrapper from './header-wrapper';
import classNames from 'classnames';
import {
  defaultHomeLink,
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../static-content';
import { usePageContext } from '../../contexts/page-context';
import { LanguageSelector } from './language-selector';

const Header: React.FC = () => {
  const pageContext = usePageContext();
  const [_] = useCookies([LANGUAGE_COOKIE_KEY]);

  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);

  const [headerScrollTriggered, setHeaderScrollTriggered] = useState(null);
  const { activeLanguage, languages } = useLanguageContext();

  const isCurrentRouteLandingPage = useMemo(() => {
    for (let i = 0; i < languages.length; i += 1) {
      const language = languages[i];

      const homePageUrls = [
        `/${language}/`,
        `${language}/`,
        `${language}/`,
        '/',
        '',
      ];

      if (homePageUrls.includes(location.pathname)) {
        return true;
      }
    }

    return false;
  }, [location.pathname]);

  const isCurrentRouteReservationFlow = useMemo(
    () =>
      [
        reservationPageLanguageMapping.en,
        reservationPageLanguageMapping.hu,
        reservationPageLanguageMapping.ro,
        verifyAvailabilityPageLanguageMapping.en,
        verifyAvailabilityPageLanguageMapping.hu,
        verifyAvailabilityPageLanguageMapping.ro,
        `${reservationPageLanguageMapping.en}/`,
        `${reservationPageLanguageMapping.hu}/`,
        `${reservationPageLanguageMapping.ro}/`,
        `${verifyAvailabilityPageLanguageMapping.en}/`,
        `${verifyAvailabilityPageLanguageMapping.hu}/`,
        `${verifyAvailabilityPageLanguageMapping.ro}/`,
        `/en/${reservationPageLanguageMapping.en}`,
        `/hu/${reservationPageLanguageMapping.hu}`,
        `/ro/${reservationPageLanguageMapping.ro}`,
        `/en/${verifyAvailabilityPageLanguageMapping.en}`,
        `/hu/${verifyAvailabilityPageLanguageMapping.hu}`,
        `/ro/${verifyAvailabilityPageLanguageMapping.ro}`,
        `/en/${reservationPageLanguageMapping.en}/`,
        `/hu/${reservationPageLanguageMapping.hu}/`,
        `/ro/${reservationPageLanguageMapping.ro}/`,
        `/en/${verifyAvailabilityPageLanguageMapping.en}/`,
        `/hu/${verifyAvailabilityPageLanguageMapping.hu}/`,
        `/ro/${verifyAvailabilityPageLanguageMapping.ro}/`,
      ].includes(location.pathname),
    [location.pathname],
  );

  const isPrevRouteLandingPage = useMemo(() => {
    for (let i = 0; i < languages.length; i += 1) {
      const language = languages[i];

      const homePageUrls = [
        `/${language}/`,
        `${language}/`,
        `${language}/`,
        '/',
        '',
      ];

      if (homePageUrls.includes(prevPathname) && prevPathname !== undefined) {
        return true;
      }
    }

    return false;
  }, [prevPathname]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100 && headerScrollTriggered === false) {
        setHeaderScrollTriggered(true);
      } else if (
        window.pageYOffset < 100 &&
        headerScrollTriggered === true &&
        isCurrentRouteLandingPage
      ) {
        setHeaderScrollTriggered(false);
      }
    };

    if (headerScrollTriggered === null) {
      handleScroll();
    }

    if (!isCurrentRouteReservationFlow) {
      window.addEventListener('scroll', handleScroll);
    }

    if (!isCurrentRouteLandingPage && !headerScrollTriggered) {
      setHeaderScrollTriggered(true);
    }

    if (!isPrevRouteLandingPage && isCurrentRouteLandingPage) {
      setHeaderScrollTriggered(false);
    }

    if (isCurrentRouteReservationFlow) {
      setHeaderScrollTriggered(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    location.pathname,
    headerScrollTriggered,
    isCurrentRouteLandingPage,
    isPrevRouteLandingPage,
  ]);

  const isReservationFlow =
    pageContext.pageUrl.en === reservationPageLanguageMapping.en ||
    pageContext.pageUrl.en === verifyAvailabilityPageLanguageMapping.en;

  if (isReservationFlow) {
    return null;
  }

  return (
    <HeaderWrapper
      headerScrollTriggered={headerScrollTriggered}
      headerConfig={pageContext.headerConfig}
      pageUrl={pageContext.pageUrl}
    >
      <div className="flex items-center">
        <LocalizedLink
          to="/"
          className="mr-6"
          currentPageUrl={pageContext.pageUrl[activeLanguage]}
        >
          <img
            src={pageContext.companyLogo.square.url}
            alt="logo"
            className="min-w-[42px] max-w-[42px]"
          />
        </LocalizedLink>
        <div className="hidden 930:flex space-x-6">
          {[defaultHomeLink, ...pageContext.headerConfig.links]
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((link) => {
              return (
                <LocalizedLink
                  key={link.to[activeLanguage]}
                  to={link.to[activeLanguage]}
                  currentPageUrl={pageContext.pageUrl[activeLanguage]}
                  className="relative flex justify-center items-center font-semibold"
                  withBlackText={
                    (pageContext.headerConfig.areHeroSectionHeaderLinksLight &&
                      headerScrollTriggered) ||
                    !pageContext.headerConfig.areHeroSectionHeaderLinksLight
                  }
                >
                  {link.label[activeLanguage]}
                </LocalizedLink>
              );
            })}
        </div>
      </div>

      <div className="flex w-full justify-end items-center relative">
        {pageContext.availabilityCheckConfig.isActive && (
          <LocalizedLink
            to={`/${verifyAvailabilityPageLanguageMapping[activeLanguage]}`}
            className={classNames(
              'hidden 560:flex btn-primary-outline btn-sm 930:btn-md mr-3 transition-opacity duration-500',
              {
                'border-white text-white hover:bg-gray-50':
                  !headerScrollTriggered &&
                  pageContext.headerConfig.areHeroSectionHeaderLinksLight,
                'opacity-0': !headerScrollTriggered,
                'opacity-100': headerScrollTriggered,
              },
            )}
            shouldUseDefaultStyle={false}
          >
            {pageContext.headerConfig.secondaryCallToActionText[activeLanguage]}
          </LocalizedLink>
        )}
        {pageContext.reservationConfig.isActive && (
          <LocalizedLink
            to={`/${reservationPageLanguageMapping[activeLanguage]}`}
            className={classNames(
              'btn-primary-filled btn-sm 930:btn-md 930:mr-3 transition-opacity duration-500',
              {
                'opacity-0': !headerScrollTriggered,
                'opacity-100': headerScrollTriggered,
              },
            )}
            shouldUseDefaultStyle={false}
          >
            {pageContext.headerConfig.callToActionText[activeLanguage]}
          </LocalizedLink>
        )}
        <div className="hidden 930:flex">
          <LanguageSelector
            iconClassName={classNames({
              'text-gray-900':
                (headerScrollTriggered &&
                  pageContext.headerConfig.areHeroSectionHeaderLinksLight) ||
                !pageContext.headerConfig.areHeroSectionHeaderLinksLight,
              '!text-white':
                !headerScrollTriggered &&
                pageContext.headerConfig.areHeroSectionHeaderLinksLight,
            })}
          />
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Button, Modal, Slider } from '@reservation-app/common-used-in-web';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
const initialCropPosition = { x: 0, y: 0 };
const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
});
const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;
const getCroppedImg = (imageSrc, pixelCrop, rotation = 0, type = 'image/jpeg') => __awaiter(void 0, void 0, void 0, function* () {
    const image = yield createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    canvas.width = safeArea;
    canvas.height = safeArea;
    if (ctx) {
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
        const data = ctx.getImageData(0, 0, safeArea, safeArea);
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y));
    }
    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file));
        }, type);
    });
});
export function getRotatedImage(imageSrc, rotation = 0) {
    return __awaiter(this, void 0, void 0, function* () {
        const image = yield createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const orientationChanged = rotation === 90 ||
            rotation === -90 ||
            rotation === 270 ||
            rotation === -270;
        if (orientationChanged) {
            canvas.width = image.height;
            canvas.height = image.width;
        }
        else {
            canvas.width = image.width;
            canvas.height = image.height;
        }
        if (ctx) {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((rotation * Math.PI) / 180);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
        }
        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file));
            }, 'image/jpeg');
        });
    });
}
const readFile = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
});
const SortableItem = SortableElement(({ item, onDelete, items, }) => (React.createElement("div", { className: "flex flex-col items-center justify-center cursor-move z-10 relative" },
    React.createElement("img", { className: classNames('object-cover rounded w-64'), src: item.url, alt: item.url }),
    onDelete && (React.createElement("svg", { stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "0.5em", width: "0.5em", xmlns: "http://www.w3.org/2000/svg", className: "absolute top-2 right-2 text-5xl text-error-500 z-50 hover:scale-125 transition-all cursor-pointer bg-white rounded-full", onClick: () => onDelete(items.filter((filterImage) => {
            if (typeof filterImage === 'object' &&
                filterImage !== null &&
                typeof item === 'object' &&
                item !== null) {
                return filterImage.url !== item.url;
            }
            return true;
        })) },
        React.createElement("path", { d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" }))))));
const SortableList = SortableContainer(({ items, aspectRatio, onDelete, }) => (React.createElement("div", { className: "flex flex-wrap gap-4" }, items.map((item, index) => (React.createElement(SortableItem, { key: item.url, index: index, item: item, items: items, aspectRatio: aspectRatio, onDelete: onDelete }))))));
const DisplayOrderer = ({ images, onDisplayOrderChange, onDelete, aspectRatio = 4 / 3, }) => {
    const onSortEnd = ({ oldIndex, newIndex, }) => {
        onDisplayOrderChange(arrayMove(images, oldIndex, newIndex).map((img, indx) => (Object.assign(Object.assign({}, img), { displayOrder: indx + 1 }))));
    };
    return (React.createElement("div", { className: "flex flex-col justify-center items-center " },
        React.createElement(SortableList, { items: images, axis: "xy", onSortEnd: onSortEnd, distance: 1, aspectRatio: aspectRatio, onDelete: (items) => {
                onDelete(items.map((img, indx) => (Object.assign(Object.assign({}, img), { displayOrder: indx + 1 }))));
            } })));
};
export const ImageUploader = ({ images = [], setImages, isMultiple = false, aspectRatio = 1, withCrop = true, error, onDelete, onDisplayOrderChange, IconComponent, texts, }) => {
    const [isCroppingInProgress, setIsCroppingInProgress] = useState(false);
    const [imageSources, setImageSources] = useState([]);
    const [cropPositions, setCropPositions] = useState([]);
    const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);
    const [zoom, setZoom] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const [sliderActiveState, setSliderActiveIndex] = useState(0);
    const showCroppedImage = (index) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsCroppingInProgress(true);
            const croppedImage = yield getCroppedImg(imageSources[index], croppedAreaPixelsArray[index], 0, acceptedFiles[index].type);
            const newImages = [...tempImages];
            newImages[index] = croppedImage;
            setTempImages(newImages);
            setIsCroppingInProgress(false);
        }
        catch (e) {
            console.error(e);
            setIsCroppingInProgress(false);
        }
    });
    const onFileChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (event.target.files && event.target.files.length > 0) {
            const { files } = event.target;
            const newFiles = [];
            const readFilePromiseFns = [];
            for (let i = 0; i < files.length; i += 1) {
                readFilePromiseFns.push(() => readFile(files[i]));
                newFiles.push(files[i]);
            }
            const readyFiles = yield Promise.all(readFilePromiseFns.map((promiseFn) => promiseFn()));
            setAcceptedFiles(newFiles);
            setImageSources(readyFiles);
            if (withCrop) {
                setIsModalOpen(true);
                setCropPositions([]);
                setZoom(1);
                setTempImages([]);
            }
            else {
                const finalFiles = yield Promise.all(readyFiles.map((dataUrl) => __awaiter(void 0, void 0, void 0, function* () {
                    const blob = yield (yield fetch(dataUrl)).blob();
                    return URL.createObjectURL(blob);
                })));
                setImages(finalFiles.map((img, idx) => {
                    return { url: img, displayOrder: idx + 1 };
                }));
            }
        }
    });
    const onDrop = (newAcceptedFiles) => __awaiter(void 0, void 0, void 0, function* () {
        if (newAcceptedFiles && newAcceptedFiles.length > 0) {
            const newFiles = [];
            const readFilePromiseFns = [];
            newAcceptedFiles.forEach((acceptedFile) => {
                readFilePromiseFns.push(() => readFile(acceptedFile));
                newFiles.push(acceptedFile);
            });
            const readyFiles = yield Promise.all(readFilePromiseFns.map((promiseFn) => promiseFn()));
            setAcceptedFiles(newFiles);
            setImageSources(readyFiles);
            if (withCrop) {
                setIsModalOpen(true);
                setCropPositions([]);
                setZoom(1);
                setTempImages([]);
            }
            else {
                const finalFiles = yield Promise.all(readyFiles.map((dataUrl) => __awaiter(void 0, void 0, void 0, function* () {
                    const blob = yield (yield fetch(dataUrl)).blob();
                    return URL.createObjectURL(blob);
                })));
                setImages(finalFiles.map((img, idx) => {
                    return { url: img, displayOrder: idx + 1 };
                }));
            }
        }
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (React.createElement("div", { className: "image-uploader-container" },
        error && React.createElement("p", { className: "image-uploader-error" }, error),
        React.createElement("div", Object.assign({}, getRootProps({ className: 'dropzone' })),
            React.createElement("input", Object.assign({}, getInputProps(), { onChange: onFileChange, multiple: isMultiple ? true : undefined, accept: "image/*" })),
            isDragActive ? (React.createElement("div", { className: "image-uploader-box-drag-container" },
                React.createElement("div", { className: "image-uploader-box-drag-text-container" },
                    React.createElement("p", null,
                        (texts && texts.dragFilesHere) || 'Drag the files here..',
                        ' ')))) : (React.createElement("div", { className: "image-uploader-box-container" },
                IconComponent && React.createElement(IconComponent, null),
                React.createElement("div", { className: "image-uploader-box-text-container" },
                    React.createElement("p", { className: classNames('flex text-center font-bold text-gray-700', {
                            'mb-1.5': texts && texts.secondaryText,
                        }) }, texts && texts.primaryText
                        ? texts.primaryText
                        : 'Drop your image here, or browse.'),
                    texts && texts.secondaryText && (React.createElement("p", { className: "text-gray-400" }, texts.secondaryText)))))),
        imageSources.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "image-uploader-image-sources-container" }, withCrop ? (React.createElement(Modal, { closeOnOutsideClick: false, isOpen: isModalOpen, onClose: () => {
                    if (isMultiple) {
                        setIsModalOpen(false);
                        setSliderActiveIndex(0);
                    }
                    else {
                        setIsModalOpen(false);
                        setSliderActiveIndex(0);
                    }
                }, modalClassName: "image-uploader-image-sources-modal" },
                React.createElement("div", { className: "flex flex-col w-full" },
                    React.createElement("div", { className: "flex justify-between mb-4" },
                        React.createElement("div", { className: "text-xl font-semibold text-gray-900" }, texts && texts.modalHeader
                            ? texts.modalHeader
                            : 'Crop your images'),
                        texts && texts.modalImageCounter && (React.createElement("div", { className: "text-gray-900 font-semibold" }, texts.modalImageCounter(sliderActiveState + 1, imageSources.length)))),
                    React.createElement(Slider, { withArrows: !!isMultiple, withDots: false, activeIndex: sliderActiveState, onChange: setSliderActiveIndex, startAtIndex: 0, isHighlightMode: false, itemsToShow: 1, itemsToScroll: 1, mainContainerClassName: "image-uploader-image-sources-modal-multiple-slider-container" }, imageSources.map((image, index) => (React.createElement("div", { className: "image-uploader-image-sources-modal-slider-generate-image-container", key: index },
                        React.createElement("div", { className: "image-uploader-image-sources-modal-slider-generate-image-container-2" },
                            React.createElement("div", { className: "image-uploader-image-sources-modal-slider-generate-image-cropper-container" },
                                React.createElement(Cropper, { classes: {
                                        mediaClassName: 'image-uploader-image-sources-modal-slider-generate-image-cropper',
                                    }, image: image, crop: cropPositions[index] || initialCropPosition, zoom: zoom, onZoomChange: withCrop && setZoom, aspect: aspectRatio, onCropChange: (newCropPosition) => setCropPositions((prevCropPositions) => {
                                        const newCropPositions = [
                                            ...prevCropPositions,
                                        ];
                                        newCropPositions[index] = newCropPosition;
                                        return newCropPositions;
                                    }), onCropComplete: (_, croppedAreaPixels) => setCroppedAreaPixelsArray((prevCroppedAreaPixelsArray) => {
                                        const newCroppedAreaPixelsArray = [
                                            ...prevCroppedAreaPixelsArray,
                                        ];
                                        newCroppedAreaPixelsArray[index] =
                                            croppedAreaPixels;
                                        return newCroppedAreaPixelsArray;
                                    }) }))))))),
                    React.createElement("div", { className: "image-uploader-image-sources-modal-slider-generate-image-button-container" },
                        React.createElement(Button, { className: "mr-2 font-semibold", size: "sm", variant: "outline", onClick: () => {
                                showCroppedImage(sliderActiveState);
                            }, isLoading: isCroppingInProgress }, texts && texts.cropButton ? texts.cropButton : 'Crop'),
                        React.createElement(Button, { isDisabled: isCroppingInProgress, size: "sm", onClick: () => {
                                if (isMultiple) {
                                    setIsModalOpen(false);
                                    let lastDisplayOrder = 0;
                                    if (images.length > 0) {
                                        lastDisplayOrder = images.sort((a, b) => a.displayOrder - b.displayOrder)[images.length - 1].displayOrder;
                                    }
                                    setImages(tempImages.map((image, index) => ({
                                        url: image,
                                        displayOrder: lastDisplayOrder + index + 1,
                                    })));
                                    setSliderActiveIndex(0);
                                }
                                else {
                                    setIsModalOpen(false);
                                    setImages(tempImages.map((image, index) => ({
                                        url: image,
                                        displayOrder: index + 1,
                                    })));
                                    setSliderActiveIndex(0);
                                }
                            } }, texts && texts.finishButton
                            ? texts.finishButton
                            : 'Finish'))))) : null))) : null,
        images.length > 0 && (React.createElement("div", { className: "flex flex-col" },
            React.createElement("p", { className: "image-uploader-image-display-text" }, (texts && texts.uploadedImages) ||
                (isMultiple ? 'Upladed images:' : 'Uploaded image:')),
            React.createElement("div", { className: "flex flex-wrap gap-4" }, isMultiple && onDisplayOrderChange && images.length > 1 ? (React.createElement("div", null,
                React.createElement(DisplayOrderer, { images: images, onDisplayOrderChange: onDisplayOrderChange, onDelete: onDelete }))) : (images.map((image, index) => (React.createElement("div", { key: index, className: "relative max-w-64" },
                React.createElement("img", { alt: `cropped-preview-${index}`, className: "object-contain", src: image.url }),
                onDelete && (React.createElement("svg", { stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 1024 1024", height: "0.5em", width: "0.5em", xmlns: "http://www.w3.org/2000/svg", className: "text-error-500 absolute top-2 right-3 w-8 h-8 z-10 hover:scale-125 transition-all cursor-pointer rounded-full bg-white", onClick: () => onDelete(images.filter((filterImage) => {
                        if (typeof filterImage === 'object' &&
                            filterImage !== null &&
                            typeof image === 'object' &&
                            image !== null) {
                            return filterImage.url !== image.url;
                        }
                        return true;
                    })) },
                    React.createElement("path", { d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" }))))))))))));
};

import { getTimelessDate, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
export const getNumberOfPersonsOptionsForIntervals = ({ numberOfPersonsSelectOptions, selectedIntervals, selectedDate, }) => {
    if (selectedDate) {
        return getPeriodPropertyValueForDate({
            date: selectedDate,
            periodSensitiveProperty: numberOfPersonsSelectOptions,
        });
    }
    if (!selectedIntervals || !(selectedIntervals.length > 0)) {
        return getPeriodPropertyValueForDate({
            date: getTimelessDate(new Date()),
            periodSensitiveProperty: numberOfPersonsSelectOptions,
        });
    }
    const optionsMap = {};
    selectedIntervals.forEach((selectedInterval) => {
        const relevantNumberOfPersonsSelectOptions = getPeriodPropertyValueForDate({
            date: getTimelessDate(selectedInterval.date),
            periodSensitiveProperty: numberOfPersonsSelectOptions,
        });
        relevantNumberOfPersonsSelectOptions.forEach((option) => {
            if (optionsMap[option]) {
                optionsMap[option]++;
            }
            else {
                optionsMap[option] = 1;
            }
        });
    });
    const results = [];
    for (const [key, value] of Object.entries(optionsMap)) {
        if (value === selectedIntervals.length) {
            results.push(Number(key));
        }
    }
    return results.sort((a, b) => a - b);
};

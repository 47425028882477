import { Icon } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React from 'react';
export const ModalWrapper = ({ header, children, containerClassName, className, onClose, icon, }) => {
    return (React.createElement("div", { className: classNames('max-w-md min-w-[300px]', containerClassName) },
        React.createElement("div", { className: classNames('flex justify-between items-center rounded-t-lg px-4 py-1.5 font-semibold text-lg', className) },
            React.createElement("div", { className: "flex items-center" },
                icon && React.createElement(Icon, { icon: icon, className: "w-6 h-6 mr-2" }),
                header),
            onClose && (React.createElement(Icon, { icon: "closeWithCircle", className: "w-8 h-8 ml-2 cursor-pointer", onClick: () => onClose() }))),
        children));
};

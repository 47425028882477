import { useLocation } from '@reach/router';
import { RESERVATION_FLOW_DATA_QUERY_KEY } from '@reservation-app/common';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { usePageContext } from '../../contexts/page-context';
import {
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../static-content';
import { companyApiClient } from '../../helpers/company-api-client';

export const FetchReservationFlowData = () => {
  const pageContext = usePageContext();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const isReservationPage =
    pageContext?.pageUrl?.en === reservationPageLanguageMapping.en ||
    pageContext?.pageUrl?.en === verifyAvailabilityPageLanguageMapping.en;

  const prefetchReservationFlowData = async () => {
    await queryClient.prefetchQuery({
      queryKey: RESERVATION_FLOW_DATA_QUERY_KEY,
      queryFn: async () => {
        const response = await companyApiClient.get(
          '/published-data/reservation-flow',
        );

        return response.data;
      },
    });
  };

  useEffect(() => {
    if (!isReservationPage) {
      prefetchReservationFlowData();
    }
  }, [pathname, isReservationPage]);

  return null;
};

import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../contexts/language-context';
import Header from './header';
import { useCookies } from 'react-cookie';
import FooterSection from './footer';
import { useEffect } from 'react';
import {
  Languages,
  LANGUAGE_COOKIE_KEY,
  LAST_SELECTED_LANGUAGE_LS_KEY,
  ErrorBoundary,
  isDevEnv,
  useTranslationsStore,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { useLocation } from '@reach/router';
import { GoogleTagManager } from '../gtm';
import { usePageContext } from '../../contexts/page-context';
import {
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../static-content';
import { queryClient } from '../..//helpers/query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import { FetchReservationFlowData } from './fetch-reservation-flow-data';

if (typeof window !== 'undefined') {
  require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');
}

const Layout: React.FC = ({ children }) => {
  const location = useLocation();
  const {
    setTranslations,
    setActiveLanguage: setStoreActiveLanguage,
    activeLanguage,
  } = useTranslationsStore();

  const pageContext = usePageContext();
  const [_, setCookie, removeCookie] = useCookies([LANGUAGE_COOKIE_KEY]);
  const [finalLanguage, setFinalLanguage] = useState(
    pageContext.activeLanguage,
  );

  useEffect(() => {
    if (location.hash) {
      const navigateToPart = location.hash.replace(`#`, '');

      setTimeout(() => {
        const elementToScrollTo = document.getElementById(navigateToPart);

        if (elementToScrollTo) {
          elementToScrollTo.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
      }, 1500);
    }
  }, []);

  const setActiveLanguage = (language: string) => {
    if (typeof window !== 'undefined') {
      const isDefaultLanguage = language === pageContext.defaultLanguage;

      let languageToBeSet: string;

      if (!pageContext.languages.includes(language)) {
        languageToBeSet = pageContext.defaultLanguage;
      } else {
        languageToBeSet = language;
      }

      let pathname = pageContext.pageUrl[language];
      pageContext.languages.forEach((language: string) => {
        const search = `${language}/`;

        pathname = pathname.replace(new RegExp(search, 'g'), '');
      });

      if (!isDefaultLanguage) {
        setCookie(LANGUAGE_COOKIE_KEY, languageToBeSet, {
          path: '/',
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
        });
      } else {
        removeCookie(LANGUAGE_COOKIE_KEY, { path: '/' });
      }

      localStorage.setItem(LAST_SELECTED_LANGUAGE_LS_KEY, language);
      setStoreActiveLanguage(language as Languages);
      setFinalLanguage(languageToBeSet);
    }
  };

  useLayoutEffect(() => {
    setTranslations(pageContext.translations);

    setActiveLanguage(pageContext.activeLanguage || activeLanguage);
  }, []);

  const isReservationPage =
    pageContext.pageUrl.en === reservationPageLanguageMapping.en ||
    pageContext.pageUrl.en === verifyAvailabilityPageLanguageMapping.en;

  const isHomePage = pageContext.pageUrl.en === '/';

  return (
    <ErrorBoundary isErrorPage>
      <QueryClientProvider client={queryClient} contextSharing>
        <FetchReservationFlowData />
        <LanguageContext.Provider
          value={{
            activeLanguage: finalLanguage,
            setActiveLanguage,
            languages: pageContext.languages,
            defaultLanguage: pageContext.defaultLanguage,
            translations: pageContext.translations,
          }}
        >
          <Helmet>
            <html
              lang={finalLanguage}
              className="notranslate"
              translate="no"
            ></html>
            <meta name="google" content="notranslate" />
            {isDevEnv && (
              <link rel="icon" type="image/png" href="/dev-favicon.ico" />
            )}
            {pageContext.companySeo?.favicon512x?.url && !isDevEnv && (
              <link
                rel="icon"
                type="image/png"
                sizes="512x512"
                href={pageContext.companySeo?.favicon512x?.url}
              />
            )}
            {pageContext.companySeo?.favicon192x?.url && !isDevEnv && (
              <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href={pageContext.companySeo?.favicon192x?.url}
              />
            )}
            {pageContext.companySeo?.favicon16x?.url && !isDevEnv && (
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={pageContext.companySeo.favicon16x.url}
              />
            )}
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          <Header />
          <main
            className={classNames('min-h-screen w-full flex flex-col', {
              'pt-16': !isHomePage,
              'bg-gray-50': isReservationPage,
            })}
          >
            {/* <CookieBanner /> */}
            <GoogleTagManager />
            {children}
            {!isReservationPage && (
              <FooterSection containerClassName="mt-auto" />
            )}
          </main>
        </LanguageContext.Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default Layout;

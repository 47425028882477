import { PromoCardLayouts, isLastIndex, useGetTranslation, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useFilteredPromotions, useStepperStateSlice } from '../../store';
import { PromotionCard } from './promotion-card';
import { PromotionTable } from './promotion-table';
export const PromotionsList = ({ className }) => {
    const getTranslation = useGetTranslation();
    const { defaultDate } = useStepperStateSlice();
    const { price: { appliedPromotionId }, } = useStepperStateSlice();
    const filteredPromotions = useFilteredPromotions();
    const notHiddenFilteredPromos = useMemo(() => {
        return filteredPromotions.filter((promo) => {
            return !getPeriodPropertyValueForDate({
                date: defaultDate,
                periodSensitiveProperty: promo.uiProps.isHidden,
            });
        });
    }, [filteredPromotions]);
    return (notHiddenFilteredPromos.length > 0 && (React.createElement("div", { className: classNames('rounded-md mt-3 768:mt-10', className) },
        React.createElement("p", { className: "mb-4 text-xl font-bold text-gray-900" }, getTranslation('yourDeals')),
        React.createElement("div", { className: "flex flex-col" }, notHiddenFilteredPromos.map((promotion, index) => {
            var _a, _b;
            const promoLayout = getPeriodPropertyValueForDate({
                date: new Date(),
                periodSensitiveProperty: promotion.uiProps.cardLayout,
            });
            switch (promoLayout) {
                case PromoCardLayouts.TABLE:
                    if (((_a = promotion.uiProps.table) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        return (React.createElement(PromotionTable, { isLast: isLastIndex(index, notHiddenFilteredPromos), key: promotion._id, promotion: promotion, className: "!items-start" }));
                    }
                    return null;
                case PromoCardLayouts.BASIC:
                    return (React.createElement(PromotionCard, { key: promotion._id, promotion: promotion, isActive: ((_b = promotion.uiProps.activationPromosIds) === null || _b === void 0 ? void 0 : _b.some((id) => id === appliedPromotionId)) || promotion._id === appliedPromotionId, isLast: isLastIndex(index, notHiddenFilteredPromos) }));
                default:
                    return null;
            }
        })))));
};

import React from 'react';
import classNames from 'classnames';
export class ReactCarouselDots extends React.Component {
    constructor(props) {
        super(props);
        this.UNSAFE_componentWillReceiveProps = (nextProps) => {
            let newBigDots = [];
            if (nextProps.active > this.props.active) {
                if (nextProps.length - 3 < nextProps.active) {
                    this.setState({
                        translate: (nextProps.length - (nextProps.visible + 1)) *
                            (nextProps.size + 2 * nextProps.margin),
                    });
                }
                if (this.state.direction === 'forwards') {
                    if (this.state.changed) {
                        if (this.state.changeCount >=
                            nextProps.visible - 4 - (nextProps.visible % 2)) {
                            newBigDots = this.getNewBigDots(nextProps, false);
                            this.setState({
                                bigDots: newBigDots,
                                direction: 'forwards',
                                changed: false,
                                changeCount: 0,
                            });
                        }
                        else {
                            newBigDots = this.getNewBigDots(nextProps, true);
                            this.setState({
                                bigDots: newBigDots,
                                direction: 'forwards',
                                changed: true,
                                changeCount: this.state.changeCount + 1,
                            });
                        }
                    }
                    else {
                        newBigDots = this.getNewBigDots(nextProps, false);
                        this.setState({
                            bigDots: newBigDots,
                            translate: (nextProps.active - (nextProps.visible - 2)) *
                                (nextProps.size + 2 * nextProps.margin),
                            direction: 'forwards',
                        });
                    }
                }
                else if (this.state.direction === 'backwards') {
                    if (nextProps.visible > 4) {
                        newBigDots = this.getNewBigDots(nextProps, true);
                        this.setState({
                            bigDots: newBigDots,
                            direction: 'forwards',
                            changed: true,
                            changeCount: this.state.changeCount + 1,
                        });
                    }
                    else {
                        newBigDots = this.getNewBigDots(nextProps, false);
                        this.setState({ bigDots: newBigDots, direction: 'forwards' });
                    }
                }
            }
            else if (nextProps.active < this.props.active) {
                if (nextProps.length - nextProps.visible < nextProps.active) {
                    this.setState({
                        bigDots: newBigDots,
                        translate: (nextProps.length - (nextProps.visible + 1)) *
                            (nextProps.size + 2 * nextProps.margin),
                    });
                }
                if (this.state.direction === 'backwards') {
                    if (this.state.changed) {
                        if (this.state.changeCount >=
                            nextProps.visible - 4 - (nextProps.visible % 2)) {
                            newBigDots = this.getNewBigDots(nextProps, false);
                            this.setState({
                                bigDots: newBigDots,
                                direction: 'backwards',
                                changed: false,
                                changeCount: 0,
                            });
                        }
                        else {
                            newBigDots = this.getNewBigDots(nextProps, true);
                            this.setState({
                                bigDots: newBigDots,
                                direction: 'backwards',
                                changed: true,
                                changeCount: this.state.changeCount + 1,
                            });
                        }
                    }
                    else {
                        newBigDots = this.getNewBigDots(nextProps, false);
                        this.setState({
                            bigDots: newBigDots,
                            translate: (nextProps.active - 2) * (nextProps.size + 2 * nextProps.margin),
                            direction: 'backwards',
                        });
                    }
                }
                else if (this.state.direction === 'forwards') {
                    if (nextProps.visible > 4) {
                        newBigDots = this.getNewBigDots(nextProps, true);
                        this.setState({
                            bigDots: newBigDots,
                            direction: 'backwards',
                            changed: true,
                            changeCount: this.state.changeCount + 1,
                        });
                    }
                    else {
                        newBigDots = this.getNewBigDots(nextProps, false);
                        this.setState({ direction: 'backwards', bigDots: newBigDots });
                    }
                }
            }
        };
        this.getNewBigDots = (nextProps, changed) => {
            let newBigDots = [];
            if (nextProps.active >= this.props.active) {
                if (nextProps.visible % 2 === 1) {
                    if (nextProps.active < nextProps.visible - 2) {
                        for (let j = 0; j < nextProps.visible - 1; j += 1) {
                            newBigDots.push(j);
                        }
                    }
                    else if (nextProps.active === nextProps.visible - 2) {
                        for (let j = 0; j < nextProps.visible; j += 1) {
                            newBigDots.push(j);
                        }
                    }
                    else if (nextProps.length - 4 < nextProps.active) {
                        for (let j = nextProps.length - nextProps.visible; j < nextProps.length; j += 1) {
                            newBigDots.push(j);
                        }
                    }
                    else if (!changed) {
                        for (let j = nextProps.active - (nextProps.visible - 3); j < nextProps.active + 2; j += 1) {
                            newBigDots.push(j);
                        }
                    }
                    else {
                        newBigDots = this.state.bigDots;
                    }
                }
                else if (nextProps.active < nextProps.visible - 2) {
                    for (let j = 0; j < nextProps.visible - 1; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else if (nextProps.active === nextProps.visible - 2) {
                    for (let j = 0; j < nextProps.visible; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else if (nextProps.length - 4 < nextProps.active) {
                    for (let j = nextProps.length - nextProps.visible; j < nextProps.length; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else if (!changed) {
                    for (let j = nextProps.active - (nextProps.visible - 3); j < nextProps.active + 2; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else {
                    newBigDots = this.state.bigDots;
                }
            }
            else if (nextProps.visible % 2 === 1) {
                if (nextProps.active < nextProps.visible - (nextProps.visible - 3)) {
                    for (let j = 0; j < nextProps.visible - 1; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else if (nextProps.length - nextProps.visible < nextProps.active) {
                    for (let j = nextProps.length - nextProps.visible; j < nextProps.length; j += 1) {
                        newBigDots.push(j);
                    }
                }
                else if (!changed) {
                    for (let j = nextProps.active - 1; j < nextProps.active + (nextProps.visible - 2); j += 1) {
                        newBigDots.push(j);
                    }
                }
                else {
                    newBigDots = this.state.bigDots;
                }
            }
            else if (nextProps.active < 3) {
                for (let j = 0; j < nextProps.visible - 1; j += 1) {
                    newBigDots.push(j);
                }
            }
            else if (nextProps.length - 4 < nextProps.active) {
                for (let j = nextProps.length - nextProps.visible; j < nextProps.length; j += 1) {
                    newBigDots.push(j);
                }
            }
            else if (!changed) {
                for (let j = nextProps.active - 1; j < nextProps.active + (nextProps.visible - 2); j += 1) {
                    newBigDots.push(j);
                }
            }
            else {
                newBigDots = this.state.bigDots;
            }
            return newBigDots;
        };
        this.getDotStyle = () => {
            let style = {
                height: this.props.size,
                width: this.props.size,
                marginRight: this.props.margin,
                marginLeft: this.props.margin,
            };
            if (this.state.direction === 'forwards') {
                if (this.props.active < this.props.visible - 2) {
                    style = Object.assign({}, style);
                }
                else if (this.props.length - 3 < this.props.active) {
                    style = Object.assign(Object.assign({}, style), { transform: `translateX(-${(this.props.length - (this.props.visible + 1)) *
                            (this.props.size + 2 * this.props.margin)}px)` });
                }
                else if (!this.state.changed) {
                    style = Object.assign(Object.assign({}, style), { transform: `translateX(-${(this.props.active - (this.props.visible - 2)) *
                            (this.props.size + 2 * this.props.margin)}px)` });
                }
                else {
                    style = Object.assign(Object.assign({}, style), { transform: `translateX(-${this.state.translate}px)` });
                }
            }
            else if (this.props.active < 2) {
                style = Object.assign({}, style);
            }
            else if (this.props.length - this.props.visible < this.props.active) {
                style = Object.assign(Object.assign({}, style), { transform: `translateX(-${(this.props.length - (this.props.visible + 1)) *
                        (this.props.size + 2 * this.props.margin)}px)` });
            }
            else if (!this.state.changed) {
                style = Object.assign(Object.assign({}, style), { transform: `translateX(-${(this.props.active - 2) * (this.props.size + 2 * this.props.margin)}px)` });
            }
            else {
                style = Object.assign(Object.assign({}, style), { transform: `translateX(-${this.state.translate}px)` });
            }
            return style;
        };
        this.getHolderStyle = () => {
            let style = {
                height: this.props.size,
            };
            if (this.state.direction === 'forwards') {
                if (this.props.active < this.props.visible - 2) {
                    style = Object.assign(Object.assign({}, style), { maxWidth: this.props.size * this.props.visible +
                            this.props.visible * this.props.margin * 2 });
                }
                else {
                    style = Object.assign(Object.assign({}, style), { maxWidth: this.props.size * (this.props.visible + 1) +
                            (this.props.visible + 1) * this.props.margin * 2 });
                }
            }
            else if (this.props.active < 3) {
                style = Object.assign(Object.assign({}, style), { maxWidth: this.props.size * this.props.visible +
                        this.props.visible * this.props.margin * 2 });
            }
            else {
                style = Object.assign(Object.assign({}, style), { maxWidth: this.props.size * (this.props.visible + 1) +
                        (this.props.visible + 1) * this.props.margin * 2 });
            }
            return style;
        };
        this.getDotClassName = (index) => {
            if (this.state.bigDots.includes(index)) {
                return '';
            }
            return 'slider-dot-small';
        };
        this.getDots = () => {
            const dots = [];
            for (let i = 0; i < this.props.length; i += 1) {
                dots.push(React.createElement("div", { key: i, style: this.getDotStyle(), className: "slider-dot-container" },
                    React.createElement("button", { key: `${i}-inner`, "aria-label": `go-to-slide-${i + 1}`, className: `slider-dot
                      ${this.getDotClassName(i)}
                      ${this.props.active === i
                            ? 'slider-dot-active'
                            : 'slider-dot-not-active'}`, onClick: () => this.props.onClick(i) })));
            }
            return dots;
        };
        this.state = {
            direction: 'forwards',
            bigDots: this.getNewBigDots(props),
            changed: false,
            changeCount: 0,
            translate: 0,
        };
    }
    render() {
        return (React.createElement("div", { style: this.getHolderStyle(), className: classNames('slider-dots', this.props.className) }, this.getDots()));
    }
}
ReactCarouselDots.defaultProps = {
    size: 16,
    margin: 1,
    visible: 5,
    className: '',
};

import React from 'react';
import ScrollLock from 'react-scrolllock';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { FocusLock } from './focus-trap';
import { AnimatePresence, motion } from 'framer-motion';
import { useStopPropagation, useHotkeys } from './hooks';
export const Modal = ({ initialFocusRef, ariaProps, withScrollLock = true, overlayClassName, modalClassName, onClose, children, isOpen, closeButtonClassName, withCloseButton = true, closeOnOutsideClick = true, overlayTransition, modalTransition, autoFocus = true, restoreFocus = false, overlayChildren, }) => {
    useHotkeys([['Escape', () => onClose === null || onClose === void 0 ? void 0 : onClose()]]);
    const stopPropagation = useStopPropagation();
    const backdropVariants = {
        visible: { opacity: 1, transition: { type: 'tween', duration: 0.3 } },
        hidden: { opacity: 0, transition: { type: 'tween', duration: 0.3 } },
    };
    const modalVariants = {
        hidden: {
            y: '-10px',
            opacity: 0,
            transition: { type: 'tween', duration: 0.3 },
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: { delay: 0.3, type: 'tween', duration: 0.3 },
        },
    };
    return ReactDOM.createPortal(React.createElement(AnimatePresence, { mode: "wait" }, isOpen && (React.createElement(FocusLock, { isDisabled: !isOpen, restoreFocus: restoreFocus, initialFocusRef: initialFocusRef, autoFocus: autoFocus },
        React.createElement(ScrollLock, { isActive: withScrollLock && isOpen },
            React.createElement(motion.div, Object.assign({}, ariaProps, { className: classNames('modal-overlay', overlayClassName), onClick: () => {
                    if (closeOnOutsideClick) {
                        onClose === null || onClose === void 0 ? void 0 : onClose();
                    }
                }, initial: "hidden", animate: "visible", exit: "hidden", variants: backdropVariants, transition: overlayTransition }),
                overlayChildren,
                React.createElement(motion.div, { className: classNames('modal', modalClassName), onClick: stopPropagation, variants: modalVariants, transition: modalTransition }, children),
                withCloseButton && (React.createElement("button", { onClick: () => {
                        onClose === null || onClose === void 0 ? void 0 : onClose();
                    }, className: classNames('modal-button cursor-pointer hover:bg-gray-300 rounded-full bg-gray-200 text-gray-900 transition-colors duration-200', closeButtonClassName) },
                    React.createElement("svg", { stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 512 512", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("path", { d: "M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z" }))))))))), document.body);
};

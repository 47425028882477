import create from 'zustand';
import { Languages } from './types/enums';
export const useTranslationsStore = create((set, get) => ({
    activeLanguage: Languages.EN,
    setActiveLanguage: (activeLanguage) => set(() => ({
        activeLanguage,
    })),
    translations: {},
    setTranslations: (translations) => set(() => ({
        translations,
    })),
    getTranslation: (key, params, fallback) => {
        const translations = get().translations;
        const activeLanguage = get().activeLanguage;
        let foundTranslation = translations[key];
        if (!foundTranslation) {
            if (!fallback) {
                return key;
            }
            foundTranslation = fallback;
        }
        foundTranslation = foundTranslation[activeLanguage];
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                const regExp = new RegExp(`\\$\\{${key}\\}`, 'g');
                foundTranslation = foundTranslation.replace(regExp, value);
            });
        }
        return foundTranslation;
    },
}));
const createSelectors = (store) => {
    store.use = {};
    Object.keys(store.getState()).forEach((key) => {
        const selector = (state) => state[key];
        store.use[key] = () => store(selector);
    });
    return store;
};
const useTranslationsSelectors = createSelectors(useTranslationsStore);
export const useGetTranslation = useTranslationsSelectors.use.getTranslation;
export const useActiveLanguage = useTranslationsSelectors.use.activeLanguage;
export const useTranslations = useTranslationsSelectors.use.translations;

import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { ModalWrapper } from './modal-wrapper';
import { useModalStatesDataSlice, useSelectedValuesSlice } from '../store';
import { zIndexes, isLastIndex, Icon, useTranslationsStore, Button, Modal, getDisplayTime, } from '@reservation-app/common-used-in-web';
import { useFormattedDateLocale } from '../hooks/use-formatted-date-locale';
export const EntityAlreadyReservedSelectedIntervalsModal = () => {
    var _a, _b;
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { isAlreadyReservedSelectedIntervalsModalOpen, setIsAlreadyReservedSelectedIntervalsModalOpen, alreadyReservedSelectedIntervals, alreadyReservedSelectedIntervalsModalEntityName, } = useModalStatesDataSlice();
    const { selectedEntityType, } = useSelectedValuesSlice();
    const locale = useFormattedDateLocale(activeLanguage);
    const onModalClose = () => {
        setIsAlreadyReservedSelectedIntervalsModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['CONFLICTING_INTERVALS_MODAL'], isOpen: isAlreadyReservedSelectedIntervalsModalOpen, onClose: onModalClose, withCloseButton: false, modalClassName: "p-0", restoreFocus: false },
        React.createElement(ModalWrapper, { header: getTranslation('reservedIntervals'), className: "text-white bg-warning-main", onClose: onModalClose },
            React.createElement("div", { className: "p-4" },
                React.createElement("p", { className: "mb-2 text-lg font-semibold text-gray-900" }, alreadyReservedSelectedIntervalsModalEntityName),
                React.createElement("p", { className: "mb-5 text-gray-900" }, alreadyReservedSelectedIntervals.length === 1
                    ? getTranslation('thisPeriodisReservedAlready', {
                        forThisEntity: (_a = selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.uiProps.translations.forThisEntity) === null || _a === void 0 ? void 0 : _a[activeLanguage],
                    })
                    : getTranslation('thesePeriodsAreReservedAlready', {
                        forThisEntity: (_b = selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType.uiProps.translations.forThisEntity) === null || _b === void 0 ? void 0 : _b[activeLanguage],
                    })),
                alreadyReservedSelectedIntervals.map((conflictingInterval, index) => {
                    const displayTime = getDisplayTime({
                        interval: conflictingInterval.reservableInterval.timeInterval,
                        activeLanguage,
                    });
                    return (React.createElement("div", { key: conflictingInterval.reservableIntervalId, className: classNames('flex justify-between items-center mb-3 rounded-md shadow-md py-2 px-4', {
                            'mb-5': isLastIndex(index, alreadyReservedSelectedIntervals),
                        }) },
                        React.createElement("p", { className: "text-lg font-semibold text-gray-900" }, format(new Date(conflictingInterval.date), 'd MMMM', {
                            locale,
                        })),
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(Icon, { icon: "clock", className: "flex-shrink-0 mr-1 text-gray-900 stroke-2" }),
                            React.createElement("p", { className: "text-gray-900" }, displayTime))));
                }),
                React.createElement(Button, { onClick: onModalClose, color: "warning", size: "md", variant: "outline", className: "w-full mt-6 conflicting-reserved-intervals-modal-go-back-to-intervals-step" }, getTranslation('close'))))));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadStripe } from '@stripe/stripe-js/pure';
import { useEffect } from 'react';
import { isWithPaymentStep } from '../helpers/is-with-payment-step';
import { usePaymentDetailsStepSpecificSlice, useRemoteMasterDataSlice, useSelectedValuesSlice, useStaticPropsSlice, } from '../store';
export const useLoadStripe = () => {
    const { isAdminPanel } = useStaticPropsSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const { companyReservationConfig } = useRemoteMasterDataSlice();
    const { setStripeInstance, stripeInstance } = usePaymentDetailsStepSpecificSlice();
    const withPayment = selectedEntityType && companyReservationConfig
        ? isWithPaymentStep({
            companyReservationConfig,
            selectedEntityType,
        })
        : null;
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (withPayment && !isAdminPanel && !stripeInstance) {
                try {
                    const loadedStripeInstance = yield loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY ||
                        process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                    setStripeInstance(loadedStripeInstance);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }))();
    }, [withPayment, isAdminPanel, stripeInstance]);
};

import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorCard, ErrorPage } from './error-page';
import { LOCAL_STORAGE_LOGGED_IN_USER_EMAIL_KEY, SELECTED_COMPANY_LS_KEY, } from '../global-api-client';
export class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { error: null };
    }
    componentDidCatch(error) {
        var _a, _b, _c;
        if (process.env.GATSBY_ACTIVE_ENV) {
            const sentryErrorParams = {
                level: 'error',
                extra: {
                    company: (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem) === null || _a === void 0 ? void 0 : _a.call(localStorage, SELECTED_COMPANY_LS_KEY),
                },
            };
            try {
                window.Sentry.captureException(error, sentryErrorParams);
            }
            catch (_d) {
                Sentry.captureException(error, sentryErrorParams);
            }
        }
        else {
            const loggedInUserEmail = (_b = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem) === null || _b === void 0 ? void 0 : _b.call(localStorage, LOCAL_STORAGE_LOGGED_IN_USER_EMAIL_KEY);
            Sentry.captureException(error, {
                level: 'error',
                user: loggedInUserEmail ? { email: loggedInUserEmail } : undefined,
                extra: {
                    company: (_c = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem) === null || _c === void 0 ? void 0 : _c.call(localStorage, SELECTED_COMPANY_LS_KEY),
                },
            });
        }
        this.setState({ error });
        console.error(error);
    }
    render() {
        if (this.state.error) {
            if (this.props.Component) {
                return React.createElement(this.props.Component, { errorMessage: this.state.error });
            }
            if (this.props.isErrorPage) {
                return React.createElement(ErrorPage, { errorMessage: this.state.error });
            }
            return React.createElement(ErrorCard, { errorMessage: this.state.error });
        }
        return this.props.children;
    }
}

const SwitchBaseClasses = (theme) => ({
    '.switch-not-active-bg-default': {
        backgroundColor: theme('colors.gray.400'),
    },
    '.switch-color-transition': {
        transitionProperty: theme('transitionProperty.colors'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: '150ms',
    },
    '.switch-active-bg-default': {
        backgroundColor: theme('colors.gray.200'),
    },
    '.switch-dot-bg-default': {
        backgroundColor: theme('colors.gray.100'),
    },
    '.switch-container': {
        display: 'flex',
        flexDirection: 'column',
    },
    '.switch-button': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.8'),
        height: theme('spacing.3'),
    },
    '.switch-button-lg': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.9'),
        height: theme('spacing.3'),
    },
    '.switch-button-xl': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.10'),
        height: theme('spacing.4'),
    },
    '.switch-button-ios': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.8'),
        height: theme('spacing.4'),
    },
    '.switch-button-ios-lg': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.10'),
        height: theme('spacing.5'),
    },
    '.switch-button-ios-xl': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        verticalAlign: 'middle',
        cursor: 'pointer',
        userSelect: 'none',
        width: theme('spacing.12'),
        height: theme('spacing.6'),
    },
    '.switch-button-disabled': {
        opacity: 0.7,
        cursor: 'not-allowed',
    },
    '.switch-box': {
        position: 'absolute',
        top: theme('spacing.0'),
        right: theme('spacing.0'),
        bottom: theme('spacing.0'),
        left: theme('spacing.0'),
        borderRadius: theme('borderRadius.xl'),
    },
    '.switch-dot': {
        position: 'absolute',
        borderRadius: theme('borderRadius.full'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.switch-active-dot': {
        left: theme('spacing.4'),
        top: `-${theme('spacing.1')}`,
        bottom: `-${theme('spacing.1')}`,
        right: theme('spacing.4'),
        width: theme('spacing.5'),
        height: theme('spacing.5'),
    },
    '.switch-not-active-dot': {
        left: `-${theme('spacing.1')}`,
        top: `-${theme('spacing.1')}`,
        bottom: `-${theme('spacing.1')}`,
        right: theme('spacing.0'),
        width: theme('spacing.5'),
        height: theme('spacing.5'),
    },
    '.switch-active-dot-lg': {
        left: theme('spacing.4'),
        top: `-${theme('spacing.1.5')}`,
        bottom: `-${theme('spacing.1.5')}`,
        right: theme('spacing.4'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-not-active-dot-lg': {
        left: `-${theme('spacing.1')}`,
        top: `-${theme('spacing.1.5')}`,
        bottom: `-${theme('spacing.1.5')}`,
        right: theme('spacing.0'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-active-dot-xl': {
        left: theme('spacing.5'),
        top: `-${theme('spacing.1.5')}`,
        bottom: `-${theme('spacing.1.5')}`,
        right: theme('spacing.4'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-not-active-dot-xl': {
        left: `-${theme('spacing.1')}`,
        top: `-${theme('spacing.1.5')}`,
        bottom: `-${theme('spacing.1.5')}`,
        right: theme('spacing.0'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-active-dot-ios': {
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        right: theme('spacing.0'),
        left: theme('spacing.4'),
        width: theme('spacing.4'),
        height: theme('spacing.4'),
    },
    '.switch-not-active-dot-ios': {
        left: theme('spacing.0'),
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        width: theme('spacing.4'),
        height: theme('spacing.4'),
    },
    '.switch-active-dot-ios-lg': {
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        right: theme('spacing.0'),
        left: theme('spacing.5'),
        width: theme('spacing.5'),
        height: theme('spacing.5'),
    },
    '.switch-not-active-dot-ios-lg': {
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        left: theme('spacing.0'),
        width: theme('spacing.5'),
        height: theme('spacing.5'),
    },
    '.switch-active-dot-ios-xl': {
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        right: theme('spacing.0'),
        left: theme('spacing.6'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-not-active-dot-ios-xl': {
        top: `-${theme('spacing.0')}`,
        bottom: `-${theme('spacing.0')}`,
        left: theme('spacing.0'),
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.switch-error': {
        color: theme('colors.red.600'),
        marginTop: theme('spacing.2'),
    },
    '.keyboard-only-focus': {
        '&:focus-visible': {
            boxShadow: 'var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
            outline: 'none',
        },
    },
});
module.exports = SwitchBaseClasses;

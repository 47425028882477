import { NumberOfPersonsOptionStatuses } from '@reservation-app/common-used-in-web';
import { areIntervalsTheSame } from './are-intervals-the-same';
import { getEntityCapacity } from './get-entity-capacity';
import { getNumberOfPersonsOptionsForIntervals } from './get-number-of-persons-options-for-intervals';
export const getNumberOfPersonsOptionsWithStatus = ({ selectedIntervals, selectedEntity, selectedDate, }) => {
    const relevantNumberOfPersonsSelectOptions = getNumberOfPersonsOptionsForIntervals({
        numberOfPersonsSelectOptions: selectedEntity.numberOfPersonsSelectOptions,
        selectedIntervals,
        selectedDate,
    });
    return relevantNumberOfPersonsSelectOptions
        .map((option) => {
        for (let i = 0; i < selectedIntervals.length; i++) {
            const currentSelectedInterval = selectedIntervals[i];
            let isCurrentSelectedIntervalReserved;
            const conflictingReservedIntervals = selectedEntity.reservedIntervals.filter((reservedInterval) => areIntervalsTheSame(Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval), currentSelectedInterval));
            const conflictingNumberOfPersons = conflictingReservedIntervals.reduce((result, reservedInterval) => {
                return result + reservedInterval.numberOfPersons;
            }, 0);
            const entityCapacity = getEntityCapacity({
                entity: selectedEntity,
                selectedIntervals,
            });
            if (conflictingNumberOfPersons + option > entityCapacity) {
                isCurrentSelectedIntervalReserved = true;
            }
            else {
                isCurrentSelectedIntervalReserved = false;
            }
            if (isCurrentSelectedIntervalReserved) {
                return {
                    value: option,
                    status: NumberOfPersonsOptionStatuses.RESERVED,
                };
            }
        }
        return { value: option, status: NumberOfPersonsOptionStatuses.AVAILABLE };
    })
        .sort((a, b) => a.value - b.value);
};

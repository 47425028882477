var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { globalApiClient, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { isSameDay, format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { RESERVATION_FLOW_SYSTEM_TIME_QUERY_KEY } from '../../query-keys';
import { useModalStatesDataSlice } from '../store';
export const useSystemTimeCheck = () => {
    const { setForceRefreshModalText, setIsForceRefreshModalOpen } = useModalStatesDataSlice();
    const getTranslation = useGetTranslation();
    useQuery(RESERVATION_FLOW_SYSTEM_TIME_QUERY_KEY, () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield globalApiClient.get('system-time');
        return response.data;
    }), {
        keepPreviousData: true,
        refetchOnMount: 'always',
        refetchOnWindowFocus: 'always',
        notifyOnChangeProps: ['data'],
        onSuccess: (date) => {
            if (!isSameDay(new Date(date), new Date())) {
                setForceRefreshModalText({
                    header: getTranslation('timeIsOutOfSync'),
                    description: getTranslation('youNeedToChangeTheTime', {
                        correctDate: format(new Date(date), 'yyyy-MM-dd'),
                    }),
                    callToAction: getTranslation('changeTimeAndRefresh'),
                });
                setIsForceRefreshModalOpen(true);
            }
        },
    });
};

import { useGetTranslation, getPeriodPropertyValueForDate, getPeriodPropertyValuesForDate, } from '@reservation-app/common-used-in-web';
import React from 'react';
import { SelectableCard } from '../../common-components/complex/selectable-card';
import { useRemoteMasterDataSlice, useStepperStateSlice, useSelectedValuesSlice, } from '../../store';
export const EntityTypeCard = ({ entityType }) => {
    const getTranslation = useGetTranslation();
    const { services } = useRemoteMasterDataSlice();
    const { defaultDate } = useStepperStateSlice();
    const { selectedEntityType, setSelectedEntityType } = useSelectedValuesSlice();
    const isSelected = entityType._id === (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType._id);
    const nameValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: entityType.uiProps.name,
        date: defaultDate,
    });
    const descriptionHtmlValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: entityType.uiProps.descriptionHtml,
        date: defaultDate,
    });
    const imageValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: entityType.uiProps.image,
        date: defaultDate,
    });
    const iconValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: entityType.uiProps.icon,
        date: defaultDate,
    });
    const layoutValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: entityType.uiProps.cardLayout,
        date: defaultDate,
    });
    let additionalText;
    if (entityType.uiProps.additionalText) {
        additionalText = getPeriodPropertyValueForDate({
            periodSensitiveProperty: entityType.uiProps.additionalText,
            date: defaultDate,
        });
    }
    const entityIdsForCurrentEntityType = services.reduce((results, value) => {
        const entityTypeIdsForCurrentPeriod = getPeriodPropertyValuesForDate({
            periodSensitiveProperty: value.entityTypesIds,
            date: defaultDate,
        });
        const addToResults = [];
        entityTypeIdsForCurrentPeriod.forEach((entityTypeIds) => {
            if (entityTypeIds.entityTypeId === entityType._id) {
                entityTypeIds.entityIds.forEach((entityId) => {
                    if (!results.some((id) => id === entityId)) {
                        addToResults.push(entityId);
                    }
                });
            }
        });
        if (addToResults.length) {
            return [...results, ...addToResults];
        }
        else {
            return results;
        }
    }, []);
    return (React.createElement(SelectableCard, { icon: iconValue, layout: layoutValue, testId: entityType._id, name: nameValue, descriptionHtml: descriptionHtmlValue, image: imageValue === null || imageValue === void 0 ? void 0 : imageValue.url, isSelected: entityType._id === (selectedEntityType === null || selectedEntityType === void 0 ? void 0 : selectedEntityType._id), additionalText: additionalText
            ? additionalText
            : {
                en: `${getTranslation('available')}: ${entityIdsForCurrentEntityType.length}`,
                hu: `${getTranslation('available')}: ${entityIdsForCurrentEntityType.length}`,
                ro: `${getTranslation('available')}: ${entityIdsForCurrentEntityType.length}`,
            }, onSelect: () => {
            if (entityType.hasSubtypes) {
            }
            if (isSelected) {
                setSelectedEntityType(null);
            }
            else {
                setSelectedEntityType(entityType);
            }
        } }));
};

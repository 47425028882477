const colors = require('tailwindcss/colors');
module.exports = (primaryPalette = colors.violet, grayPalette = colors.gray) => ({
    theme: {
        extend: {
            screens: {
                640: '640px',
                768: '768px',
                800: '800px',
                1024: '1024px',
                1280: '1280px',
                1410: '1410px',
                1536: '1536px',
            },
            flexGrow: {
                2: '2',
            },
            maxHeight: (theme) => (Object.assign({}, theme('spacing'))),
            maxWidth: (theme) => (Object.assign({}, theme('spacing'))),
            minHeight: (theme) => (Object.assign({}, theme('spacing'))),
            minWidth: (theme) => (Object.assign({}, theme('spacing'))),
            ringColor: (theme) => ({
                DEFAULT: theme('colors.primary.main', '#3b82f6'),
            }),
            transitionDelay: {
                400: '400ms',
            },
            transitionDuration: {
                400: '400ms',
            },
            colors: {
                transparent: 'transparent',
                current: 'currentColor',
                black: colors.black,
                white: colors.white,
                gray: grayPalette,
                primary: Object.assign({ main: primaryPalette.main || primaryPalette[500], hover: primaryPalette.hover || primaryPalette[400], light: primaryPalette.light || primaryPalette[200] }, primaryPalette),
            },
        },
        components: ({ theme }) => ({
            button: {
                primary: {
                    filled: {
                        default: {
                            bg: theme('colors.primary.main'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.primary.hover'),
                            textColor: theme('colors.white'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.primary.main'),
                            textColor: theme('colors.white'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.primary.main'),
                            border: `1px solid ${theme('colors.primary.main')}`,
                        },
                        hovered: {
                            bg: theme('colors.primary.300'),
                            textColor: theme('colors.primary.900'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.primary.400'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.primary.main'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.primary.300'),
                            textColor: theme('colors.primary.900'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.primary.400'),
                        },
                    },
                },
                neutral: {
                    focusRing: theme('colors.gray.500'),
                    filled: {
                        default: {
                            bg: theme('colors.gray.300'),
                            textColor: theme('colors.gray.900'),
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.gray.900'),
                            border: `1px solid ${grayPalette[600]}`,
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                            border: `1px solid ${grayPalette[900]}`,
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                            border: `1px solid ${grayPalette[900]}`,
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.gray.900'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                        },
                    },
                },
                warning: {
                    focusRing: theme('colors.yellow.300'),
                    filled: {
                        default: {
                            bg: theme('colors.yellow.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.yellow.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.yellow.700'),
                            border: `1px solid ${theme('colors.yellow.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.yellow.100'),
                            textColor: theme('colors.yellow.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.200'),
                            textColor: theme('colors.yellow.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.yellow.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.yellow.100'),
                            textColor: theme('colors.yellow.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.200'),
                        },
                    },
                },
                danger: {
                    focusRing: theme('colors.red.300'),
                    filled: {
                        default: {
                            bg: theme('colors.red.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.red.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.red.700'),
                            border: `1px solid ${theme('colors.red.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.red.100'),
                            textColor: theme('colors.red.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.200'),
                            textColor: theme('colors.red.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.red.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.red.100'),
                            textColor: theme('colors.red.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.200'),
                        },
                    },
                },
                success: {
                    focusRing: theme('colors.green.300'),
                    filled: {
                        default: {
                            bg: theme('colors.green.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.green.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.green.700'),
                            border: `1px solid ${theme('colors.green.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.green.100'),
                            textColor: theme('colors.green.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.200'),
                            textColor: theme('colors.green.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.green.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.green.100'),
                            textColor: theme('colors.green.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.200'),
                        },
                    },
                },
            },
        }),
    },
});

import React from 'react';
import { StepWrapper } from '../../layout/step-wrapper';
import { SelectEntityList } from './select-entity-list';
import { ReservationSteps, AlertTypes, Alert, ReservationSubSteps, useActiveLanguage, } from '@reservation-app/common-used-in-web';
import { useSelectedValuesSlice, useRemoteMasterDataSlice, useFilteredPromotions, } from '../../store';
import { InteractiveBirdEyeView } from './interactive-bird-eye-view';
export const SelectEntity = ({ step }) => {
    var _a, _b;
    const { alerts } = useRemoteMasterDataSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const filteredPromotions = useFilteredPromotions();
    const activeLanguage = useActiveLanguage();
    return (React.createElement(StepWrapper, { step: step, subStep: ReservationSubSteps.SELECT_ENTITY, withFirstStepInfo: false, wrapperClassName: `${step === ReservationSteps.SELECT_PERIOD_AND_ENTITY ? '!mt-0' : ''}
        ${filteredPromotions && filteredPromotions.length > 0
            ? ' !pb-6 768:!pb-0'
            : ''}` },
        ((_b = (_a = selectedEntityType.interactiveBirdEyeViewSvg) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b[activeLanguage]) && (React.createElement(InteractiveBirdEyeView, null)),
        React.createElement(SelectEntityList, null),
        React.createElement(Alert, { alertType: AlertTypes.ENTITIES, className: "mt-6 !max-w-sm", iconClassName: "bg-gray-50", selectedEntityType: selectedEntityType, alerts: alerts, isReservationFlow: true })));
};

import React from 'react';
import classNames from 'classnames';
import { EntityTypeCardLayouts, Icon, Button, useTranslationsStore, } from '@reservation-app/common-used-in-web';
export const SelectableCard = ({ image, name, descriptionHtml, additionalText, isSelected, onSelect, testId, layout, icon, }) => {
    const { getTranslation, activeLanguage } = useTranslationsStore();
    if (layout === EntityTypeCardLayouts.ICON_ON_LEFT_SIDE ||
        layout === EntityTypeCardLayouts.ICON_ON_TOP) {
        return (React.createElement("div", { "data-test-id": testId, className: classNames('flex items-center rounded-md shadow-lg transition-colors duration-500 mb-5 768:mr-5 max-w-md w-full', {
                'bg-primary-light': isSelected,
                'bg-white': !isSelected,
                'flex-col': layout === EntityTypeCardLayouts.ICON_ON_TOP,
            }) },
            icon && layout === EntityTypeCardLayouts.ICON_ON_LEFT_SIDE && (React.createElement("div", { className: "flex justify-center items-center h-full bg-primary-200 px-5 rounded-l-md" },
                React.createElement(Icon, { icon: icon, className: classNames('flex-shrink-0 w-8 h-8 text-gray-900') }))),
            React.createElement("div", { className: "p-3 w-full flex flex-col justify-between flex-grow" },
                React.createElement("div", { className: classNames('flex', {
                        'items-start flex-row': layout === EntityTypeCardLayouts.ICON_ON_LEFT_SIDE,
                        'items-center flex-col': layout === EntityTypeCardLayouts.ICON_ON_TOP,
                    }) },
                    icon && layout === EntityTypeCardLayouts.ICON_ON_TOP && (React.createElement(Icon, { icon: icon, className: classNames('flex-shrink-0 mb-2 w-8 h-8') })),
                    React.createElement("div", null,
                        React.createElement("p", { className: classNames('font-bold mb-2 text-lg text-gray-900', {
                                'text-left': layout === EntityTypeCardLayouts.ICON_ON_LEFT_SIDE,
                                'text-center': layout === EntityTypeCardLayouts.ICON_ON_TOP,
                            }) }, name[activeLanguage]),
                        React.createElement("div", { className: classNames('text-base font-normal mb-6 text-gray-900', {
                                'text-left': layout === EntityTypeCardLayouts.ICON_ON_LEFT_SIDE,
                                'text-center': layout === EntityTypeCardLayouts.ICON_ON_TOP,
                            }), dangerouslySetInnerHTML: {
                                __html: descriptionHtml[activeLanguage],
                            } }))),
                React.createElement("div", { className: "flex w-full justify-between items-center" },
                    (additionalText === null || additionalText === void 0 ? void 0 : additionalText[activeLanguage]) && (React.createElement("p", { className: classNames('text-sm font-semibold transition-colors duration-500 whitespace-nowrap', {
                            'text-gray-800': isSelected,
                            'text-gray-400': !isSelected,
                        }) }, additionalText[activeLanguage])),
                    React.createElement(Button, { onClick: () => {
                            onSelect();
                        }, className: classNames('btn-md btn-primary-filled ml-auto w-22 transition-colors duration-500', {
                            'text-primary-main bg-gray-50': isSelected,
                        }, name[activeLanguage]) }, isSelected
                        ? getTranslation('deselect')
                        : getTranslation('select'))))));
    }
    else {
        return (React.createElement("div", { "data-test-id": testId, className: classNames('flex flex-col items-center rounded-md shadow-lg transition-colors duration-500 mb-5 768:mr-5 max-w-60 w-full', {
                'bg-primary-light': isSelected,
                'bg-white': !isSelected,
            }) },
            React.createElement("div", { className: "relative" },
                React.createElement("img", { src: image, className: classNames('w-full rounded-t-md', {
                        'rounded-md': layout !== EntityTypeCardLayouts.IMAGE,
                    }) }),
                layout === EntityTypeCardLayouts.IMAGE_TEXT_INSIDE && (React.createElement("div", { className: "absolute inset-0 flex justify-center items-center" },
                    React.createElement("div", { onClick: () => {
                            onSelect();
                        }, className: classNames('w-22 transition-colors duration-500 text-lg font-semibold px-3 py-2 bg-white bg-opacity-80 text-gray-900 uppercase rounded max-w-[60%] text-center hover:cursor-pointer hover:bg-opacity-100', {
                            'bg-primary-main text-white bg-opacity-100': isSelected,
                        }, name[activeLanguage]) }, name[activeLanguage])))),
            layout === EntityTypeCardLayouts.IMAGE && (React.createElement("div", { className: "flex justify-between items-center py-3 px-3 w-full h-full" },
                React.createElement("p", { className: "text-lg font-semibold" },
                    " ",
                    name[activeLanguage]),
                React.createElement(Button, { onClick: () => {
                        onSelect();
                    }, className: classNames('btn-primary-filled ml-auto w-22 transition-colors duration-500 text-sm px-3 py-3', {
                        'text-primary-main bg-gray-50': isSelected,
                    }, name[activeLanguage]) }, isSelected
                    ? getTranslation('deselect')
                    : getTranslation('select'))))));
    }
};

import { useEffect } from 'react';
import debounce from 'lodash.debounce';
export const useDebouncedEventListener = (event, eventHandler, debounceValue) => {
    const debounceEventHandler = debounce(eventHandler, debounceValue);
    useEffect(() => {
        eventHandler();
        window.addEventListener(event, debounceEventHandler);
        return () => {
            window.removeEventListener(event, debounceEventHandler);
        };
    }, []);
};

import axios from 'axios';
import {
  LAST_SELECTED_LANGUAGE_LS_KEY,
  SELECTED_COMPANY_HEADER,
} from '@reservation-app/common-used-in-web';
import { LANGUAGE_HEADER_KEY } from '@reservation-app/common-used-in-web';

export const companyApiClient = axios.create({
  baseURL: process.env.GATSBY_API_URL,
});

companyApiClient.defaults.timeout = 15000;

companyApiClient.interceptors.request.use((req) => {
  try {
    const lastSelectedLanguage = localStorage.getItem(
      LAST_SELECTED_LANGUAGE_LS_KEY,
    );

    if (lastSelectedLanguage) {
      req.headers[LANGUAGE_HEADER_KEY] = lastSelectedLanguage;
    }

    req.headers[SELECTED_COMPANY_HEADER] = process.env.GATSBY_COMPANY_NAME;

    return req;
  } catch (error) {
    return Promise.reject(error);
  }
});

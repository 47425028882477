import { SchemaTypes, Translation } from '@reservation-app/common-used-in-web';
import React from 'react';

export type PageContextType = {
  companySeo: SchemaTypes['Company']['seo'];
  companyLogo: {
    square: SchemaTypes['Company']['image'];
    wide: SchemaTypes['Company']['wideImage'];
  };
  companyOfficialData: SchemaTypes['OfficialData'] & { currency: string };
  rulesHtml: Translation;
  sections: any[];
  seo: {
    title: Translation;
    description?: Translation;
    keywords?: Translation;
    siteUrl: string;
    pageUrl: Translation;
    image?: { url: string };
  };
  alerts: SchemaTypes['Alert'][];
  promotions: SchemaTypes['Promotion'][];
  schedules: SchemaTypes['Schedule'][];
  pricesSection: SchemaTypes['PricesSectionDto'];
  schedulesSection: SchemaTypes['ScheduleSectionDto'];
  headerConfig: SchemaTypes['Company']['headerConfig'];
  availabilityCheckConfig: SchemaTypes['Company']['availabilityCheckConfig'];
  reservationConfig: SchemaTypes['Company']['reservationConfig'];
  hasRulePage: SchemaTypes['Company']['hasRulePage'];
  analyticsConfig: {
    hotjarCode: string;
    hotjarVersion: string;
    googleAnalyticsCode: string;
    googleTagCode: string;
  };
  translations: Record<string, { en: string; ro: string; hu: string }>;
  activeLanguage: string;
  languages: string[];
  defaultLanguage: string;
  pageUrl: Translation;
  siteUrl: string;
  localizedPageUrl: string;
  isVerifyAvailabilityMode: boolean;
};

// @ts-ignore React faulty createContext initial value type
export const PageContextContext = React.createContext<PageContextType>({});

export const usePageContext = () => React.useContext(PageContextContext);

const PaginationBaseClasses = (theme) => ({
    '.pagination-contaier': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.pagination-contaier-div': {
        display: 'flex',
    },
    '.pagination-buttons': {
        marginRight: theme('spacing.2'),
        paddingLeft: theme('spacing.2'),
        paddingRight: theme('spacing.2'),
        borderRadius: theme('borderRadius.md'),
        boxShadow: theme('boxShadow.DEFAULT'),
        color: theme('colors.primary.hover'),
        fontWeight: '500',
        cursor: 'pointer',
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-contaier-prev-next-buttons-disabled': {
        cursor: 'default',
        backgroundColor: theme('colors.gray.100'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-active-button': {
        backgroundColor: theme('colors.primary.hover'),
        color: theme('colors.white'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-not-active-button': {
        color: theme('colors.primary.hover'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-dots': {
        fontSize: theme('fontSize.xl'),
        lineHeight: theme('lineHeight.7'),
        alignSelf: 'flex-end',
        marginRight: theme('spacing.2'),
        marginLeft: theme('spacing.2'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-initial-go-to-input': {
        backgroundColor: theme('colors.gray.100'),
        boxShadow: theme('boxShadow.DEFAULT'),
        borderRadius: theme('borderRadius.DEFAULT'),
        paddingRight: theme('spacing.2'),
        paddingLeft: theme('spacing.2'),
        paddingTop: theme('spacing.1'),
        paddingBottom: theme('spacing.1'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-items-per-page-select': {
        maxWidth: theme('spacing.16'),
        width: theme('spacing.64'),
        marginBottom: theme('spacing.4'),
        marginTop: theme('spacing.4'),
        marginRight: theme('spacing.2'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-action-button': {
        fontWeight: theme('fontWeight.medium'),
        cursor: 'pointer',
        backgroundColor: theme('colors.gray.500'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.200'),
    },
    '.pagination-action-button-invalid-input': {
        cursor: 'default',
    },
});
module.exports = PaginationBaseClasses;

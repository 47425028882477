import { Days } from '../types/enums';
export const jsDayIndexToDayMap = {
    1: Days.MONDAY,
    2: Days.TUESDAY,
    3: Days.WEDNESDAY,
    4: Days.THURSDAY,
    5: Days.FRIDAY,
    6: Days.SATURDAY,
    0: Days.SUNDAY,
};

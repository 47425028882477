import { NumberOfPersonsOptionStatuses, getPeriodPropertyValueForDate, ReservationSubSteps, } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { StepWrapper } from '../../layout';
import { useSelectedValuesSlice, usePeriodAndEntityStepSpecificSlice, useStepperStateSlice, } from '../../store';
export const BulkNumberOfPersonsSelector = ({ step, }) => {
    const { selectedBulkNumberOfPersons, setSelectedBulkNumberOfPersons, handleBulkNumberOfPersonsChange, } = useSelectedValuesSlice();
    const { numberOfPersonsOptions } = usePeriodAndEntityStepSpecificSlice();
    const { defaultDate } = useStepperStateSlice();
    const { selectedEntity } = useSelectedValuesSlice();
    useEffect(() => {
        if (!selectedBulkNumberOfPersons) {
            setSelectedBulkNumberOfPersons(getPeriodPropertyValueForDate({
                date: defaultDate,
                periodSensitiveProperty: selectedEntity.numberOfPersonsSelectOptions,
            })[0]);
        }
    }, []);
    return (React.createElement(StepWrapper, { step: step, withFirstStepInfo: false, subStep: ReservationSubSteps.SELECT_NUMBER_OF_PERSONS },
        React.createElement("div", { className: "flex flex-wrap" }, numberOfPersonsOptions.map((option, index) => {
            const isSelected = selectedBulkNumberOfPersons === option.value;
            return (React.createElement("div", { onClick: () => {
                    handleBulkNumberOfPersonsChange(option.value);
                }, key: `${index}-${option.value}`, className: classNames('flex justify-center w-24 py-1 rounded-md mx-1 mb-2 cursor-pointer transition-all shadow-md duration-200 text-gray-900 select-none text-sm', {
                    'bg-primary-light': isSelected &&
                        option.status === NumberOfPersonsOptionStatuses.AVAILABLE,
                    'bg-gray-50 hover:bg-primary-100': !isSelected &&
                        option.status === NumberOfPersonsOptionStatuses.AVAILABLE,
                    'bg-error-200 pointer-events-none': option.status === NumberOfPersonsOptionStatuses.RESERVED,
                }) }, option.value));
        }))));
};

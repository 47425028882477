import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createModalStatesSlice = (set, _get) => ({
    isConflictingSelectedIntervalsModalOpen: false,
    setIsConflictingSelectedIntervalsModalOpen: (isConflictingSelectedIntervalsModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isConflictingSelectedIntervalsModalOpen =
            isConflictingSelectedIntervalsModalOpen;
    }, false, getDevtoolsLogMessage('setIsConflictingSelectedIntervalsModalOpen', extraDevtoolsInfo)),
    conflictingSelectedIntervals: [],
    setConflictingSelectedIntervals: (conflictingSelectedIntervals, extraDevtoolsInfo) => set((state) => {
        state.modalStates.conflictingSelectedIntervals =
            conflictingSelectedIntervals;
    }, false, getDevtoolsLogMessage('setConflictingSelectedIntervals', extraDevtoolsInfo)),
    isAlreadyReservedSelectedIntervalsModalOpen: false,
    setIsAlreadyReservedSelectedIntervalsModalOpen: (isAlreadyReservedSelectedIntervalsModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isAlreadyReservedSelectedIntervalsModalOpen =
            isAlreadyReservedSelectedIntervalsModalOpen;
    }, false, getDevtoolsLogMessage('setIsAlreadyReservedSelectedIntervalsModalOpen', extraDevtoolsInfo)),
    alreadyReservedSelectedIntervalsModalEntityName: '',
    setAlreadyReservedSelectedIntervalsModalEntityName: (alreadyReservedSelectedIntervalsModalEntityName, extraDevtoolsInfo) => set((state) => {
        state.modalStates.alreadyReservedSelectedIntervalsModalEntityName =
            alreadyReservedSelectedIntervalsModalEntityName;
    }, false, getDevtoolsLogMessage('setAlreadyReservedSelectedIntervalsModalEntityName', extraDevtoolsInfo)),
    alreadyReservedSelectedIntervals: [],
    setAlreadyReservedSelectedIntervals: (alreadyReservedSelectedIntervals, extraDevtoolsInfo) => set((state) => {
        state.modalStates.alreadyReservedSelectedIntervals =
            alreadyReservedSelectedIntervals;
    }, false, getDevtoolsLogMessage('alreadyReservedSelectedIntervals', extraDevtoolsInfo)),
    isRestrictionErrorModalOpen: false,
    setIsRestrictionErrorModalOpen: (isRestrictionErrorModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isRestrictionErrorModalOpen =
            isRestrictionErrorModalOpen;
    }, false, getDevtoolsLogMessage('setIsRestrictionErrorModalOpen', extraDevtoolsInfo)),
    restrictionErrorModalMessage: '',
    setRestrictionErrorModalMessage: (restrictionErrorModalMessage, extraDevtoolsInfo) => set((state) => {
        state.modalStates.restrictionErrorModalMessage =
            restrictionErrorModalMessage;
    }, false, getDevtoolsLogMessage('setRestrictionErrorModalMessage', extraDevtoolsInfo)),
    restrictionErrorModalSelectedInterval: null,
    setRestrictionErrorModalSelectedInterval: (restrictionErrorModalSelectedInterval, extraDevtoolsInfo) => set((state) => {
        state.modalStates.restrictionErrorModalSelectedInterval =
            restrictionErrorModalSelectedInterval;
    }, false, getDevtoolsLogMessage('setRestrictionErrorModalSelectedInterval', extraDevtoolsInfo)),
    isCancelReservationModalOpen: false,
    setIsCancelReservationModalOpen: (isCancelReservationModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isCancelReservationModalOpen =
            isCancelReservationModalOpen;
    }, false, getDevtoolsLogMessage('setIsCancelReservationModalOpen', extraDevtoolsInfo)),
    isForceRefreshModalOpen: false,
    setIsForceRefreshModalOpen: (isForceRefreshModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isForceRefreshModalOpen = isForceRefreshModalOpen;
    }, false, getDevtoolsLogMessage('setIsForceRefreshModalOpen', extraDevtoolsInfo)),
    forceRefreshModalText: {
        header: '',
        description: '',
        callToAction: '',
    },
    setForceRefreshModalText: (forceRefreshModalText, extraDevtoolsInfo) => set((state) => {
        state.modalStates.forceRefreshModalText = forceRefreshModalText;
    }, false, getDevtoolsLogMessage('setForceRefreshModalText', extraDevtoolsInfo)),
    isHtmlContentModalOpen: false,
    setIsHtmlContentModalOpen: (isHtmlContentModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isHtmlContentModalOpen = isHtmlContentModalOpen;
    }, false, getDevtoolsLogMessage('setIsHtmlContentModalOpen', extraDevtoolsInfo)),
    htmlContentModalText: null,
    setHtmlContentModalText: (htmlContentModalText, extraDevtoolsInfo) => set((state) => {
        state.modalStates.htmlContentModalText = htmlContentModalText;
    }, false, getDevtoolsLogMessage('setHtmlContentModalText', extraDevtoolsInfo)),
    navigateToOnConfirm: '',
    setNavigateToOnConfirm: (navigateToOnConfirm, extraDevtoolsInfo) => set((state) => {
        state.modalStates.navigateToOnConfirm = navigateToOnConfirm;
    }, false, getDevtoolsLogMessage('setNavigateToOnConfirm', extraDevtoolsInfo)),
    isServerSideErrorModalOpen: false,
    setIsServerSideErrorModalOpen: (isServerSideErrorModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isServerSideErrorModalOpen =
            isServerSideErrorModalOpen;
    }, false, getDevtoolsLogMessage('setIsServerSideErrorModalOpen', extraDevtoolsInfo)),
    serverSideError: '',
    setServerSideError: (serverSideError, extraDevtoolsInfo) => set((state) => {
        state.modalStates.serverSideError = serverSideError;
    }, false, getDevtoolsLogMessage('setServerSideError', extraDevtoolsInfo)),
    isWarningTextModalOpen: false,
    setIsWarningTextModalOpen: (isWarningTextModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isWarningTextModalOpen = isWarningTextModalOpen;
    }, false, getDevtoolsLogMessage('setIsWarningTextModalOpen', extraDevtoolsInfo)),
    warningTextModalText: '',
    setWarningTextModalText: (warningTextModalText, extraDevtoolsInfo) => set((state) => {
        state.modalStates.warningTextModalText = warningTextModalText;
    }, false, getDevtoolsLogMessage('setWarningTextModalText', extraDevtoolsInfo)),
    isPasswordModalOpen: false,
    setIsPasswordModalOpen: (isPasswordModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isPasswordModalOpen = isPasswordModalOpen;
    }, false, getDevtoolsLogMessage('setIsPasswordModalOpen', extraDevtoolsInfo)),
    isPricesSectionModalOpen: false,
    setIsPricesSectionModalOpen: (isPricesSectionModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isPricesSectionModalOpen = isPricesSectionModalOpen;
    }, false, getDevtoolsLogMessage('setIsPricesSectionModalOpen', extraDevtoolsInfo)),
    isSchedulesSectionModalOpen: false,
    setIsSchedulesSectionModalOpen: (isSchedulesSectionModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isSchedulesSectionModalOpen =
            isSchedulesSectionModalOpen;
    }, false, getDevtoolsLogMessage('setIsSchedulesSectionModalOpen', extraDevtoolsInfo)),
    isOnboardingModalOpen: false,
    setIsOnboardingModalOpen: (isOnboardingModalOpen, extraDevtoolsInfo) => set((state) => {
        state.modalStates.isOnboardingModalOpen = isOnboardingModalOpen;
    }, false, getDevtoolsLogMessage('setIsOnboardingModalOpen', extraDevtoolsInfo)),
});

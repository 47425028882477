import { getClosedIntervals } from './get-closed-intervals';
import { getEntityCapacity } from './get-entity-capacity';
import { getNumberOfPersonsOptionsForIntervals } from './get-number-of-persons-options-for-intervals';
import { getUniqueReservableIntervals } from './get-unique-reservable-intervals';
import { IntervalStatuses, getTimelessDate, getPeriodPropertyValueForDate, getPeriodPropertiesForDate, jsDayIndexToDayMap, } from '@reservation-app/common-used-in-web';
import { areIntervalsIntersecting } from './are-intervals-intersecting';
export const getClassifiedReservableIntervals = ({ selectedDate: nonTimelessSelectedDate, selectedEntity, entities, selectedService, selectedSyncGroupKey, selectedBulkNumberOfPersons, }) => {
    const entity = selectedEntity ||
        entities.find((entity) => entity.syncGroupKey === selectedSyncGroupKey);
    const selectedDate = getTimelessDate(nonTimelessSelectedDate);
    const entityCapacity = getEntityCapacity({ entity, selectedDate });
    const selectedDateDay = jsDayIndexToDayMap[selectedDate.getDay()];
    const relevantReservableIntervals = getPeriodPropertiesForDate({
        date: selectedDate,
        periodSensitiveProperty: selectedService.reservableIntervals,
    }).filter((reservableInterval) => {
        const isInSchedule = reservableInterval.value.days
            .map(({ value: day }) => day)
            .includes(selectedDateDay);
        const isSelectedEntityCompatible = selectedEntity
            ? reservableInterval.value.entitiesIds.includes(selectedEntity._id)
            : true;
        return isInSchedule && isSelectedEntityCompatible;
    });
    const uniqueRelevantReservableIntervals = getUniqueReservableIntervals({
        reservableIntervals: relevantReservableIntervals,
    });
    const relevantSchedule = getPeriodPropertyValueForDate({
        date: selectedDate,
        periodSensitiveProperty: entity.schedule,
    });
    const currentDayFromSchedule = relevantSchedule[selectedDateDay];
    const closedIntervals = getClosedIntervals({
        openIntervals: currentDayFromSchedule.openIntervals,
        date: selectedDate,
    });
    const availableIntervals = [];
    const reservedIntervals = [];
    if (selectedEntity) {
        uniqueRelevantReservableIntervals.forEach(({ value: reservableInterval }) => {
            let availableNumberOfPersons;
            let areAnyReservedIntervalsConflicting;
            if (selectedEntity.withBulkNumberOfPersonsSelect) {
                const conflictingReservedIntervals = selectedEntity.reservedIntervals.filter((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: selectedDate }, reservableInterval.timeInterval), Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval)));
                const conflictingNumberOfPersons = conflictingReservedIntervals.reduce((result, reservedInterval) => {
                    return result + reservedInterval.numberOfPersons;
                }, 0);
                if (conflictingNumberOfPersons) {
                    if (selectedBulkNumberOfPersons) {
                        if (conflictingNumberOfPersons + selectedBulkNumberOfPersons >
                            entityCapacity) {
                            areAnyReservedIntervalsConflicting = true;
                        }
                        else {
                            areAnyReservedIntervalsConflicting = false;
                        }
                    }
                    else {
                        const relevantNumberOfPersonsSelectOptions = getNumberOfPersonsOptionsForIntervals({
                            selectedDate: getTimelessDate(nonTimelessSelectedDate),
                            numberOfPersonsSelectOptions: entity.numberOfPersonsSelectOptions,
                        });
                        const smallestNumberOfPersonsOption = [
                            ...relevantNumberOfPersonsSelectOptions,
                        ].sort((a, b) => a - b)[0];
                        if (conflictingNumberOfPersons + smallestNumberOfPersonsOption >
                            entityCapacity) {
                            areAnyReservedIntervalsConflicting = true;
                        }
                        else {
                            areAnyReservedIntervalsConflicting = false;
                        }
                    }
                    availableNumberOfPersons =
                        entityCapacity - conflictingNumberOfPersons;
                }
            }
            else {
                areAnyReservedIntervalsConflicting =
                    selectedEntity.reservedIntervals.some((reservedInterval) => {
                        if (areIntervalsIntersecting(Object.assign({ date: selectedDate }, reservableInterval.timeInterval), Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval
                            .timeInterval))) {
                            return true;
                        }
                        return false;
                    });
            }
            if (!areAnyReservedIntervalsConflicting) {
                availableIntervals.push(Object.assign(Object.assign({}, reservableInterval), { status: IntervalStatuses.AVAILABLE }));
            }
            else {
                reservedIntervals.push(Object.assign(Object.assign({}, reservableInterval), { status: IntervalStatuses.RESERVED, availableNumberOfPersons }));
            }
        });
    }
    else {
        const reservedIntervalsCountMap = {};
        uniqueRelevantReservableIntervals.forEach(({ value: reservableInterval }) => {
            const reservableIntervalEntities = entities.filter((e) => reservableInterval.entitiesIds.includes(e._id));
            for (let i = 0; i < reservableIntervalEntities.length; i++) {
                const currentEntity = reservableIntervalEntities[i];
                const doAvailableIntervalsAlreadyContainCurrentInterval = availableIntervals.some((ri) => ri.timeInterval.startTime ===
                    reservableInterval.timeInterval.startTime &&
                    ri.timeInterval.endTime ===
                        reservableInterval.timeInterval.endTime);
                let areAnyReservedIntervalsConflicting;
                let availableNumberOfPersons;
                if (entity.withBulkNumberOfPersonsSelect) {
                    const conflictingReservedIntervals = entity.reservedIntervals.filter((reservedInterval) => areIntervalsIntersecting(Object.assign({ date: selectedDate }, reservableInterval.timeInterval), Object.assign({ date: reservedInterval.date }, reservedInterval.historicReservableInterval.timeInterval)));
                    const conflictingNumberOfPersons = conflictingReservedIntervals.reduce((result, reservedInterval) => {
                        return result + reservedInterval.numberOfPersons;
                    }, 0);
                    if (conflictingNumberOfPersons) {
                        if (selectedBulkNumberOfPersons) {
                            if (conflictingNumberOfPersons + selectedBulkNumberOfPersons >
                                entityCapacity) {
                                areAnyReservedIntervalsConflicting = true;
                            }
                            else {
                                areAnyReservedIntervalsConflicting = false;
                            }
                        }
                        else {
                            const relevantNumberOfPersonsSelectOptions = getNumberOfPersonsOptionsForIntervals({
                                selectedDate: getTimelessDate(nonTimelessSelectedDate),
                                numberOfPersonsSelectOptions: entity.numberOfPersonsSelectOptions,
                            });
                            const smallestNumberOfPersonsOption = [
                                ...relevantNumberOfPersonsSelectOptions,
                            ].sort((a, b) => a - b)[0];
                            if (conflictingNumberOfPersons + smallestNumberOfPersonsOption >
                                entityCapacity) {
                                areAnyReservedIntervalsConflicting = true;
                            }
                            else {
                                areAnyReservedIntervalsConflicting = false;
                            }
                        }
                        availableNumberOfPersons =
                            entityCapacity - conflictingNumberOfPersons;
                    }
                }
                else {
                    areAnyReservedIntervalsConflicting =
                        currentEntity.reservedIntervals.some((reservedInterval) => {
                            return areIntervalsIntersecting(Object.assign(Object.assign({}, reservableInterval.timeInterval), { date: selectedDate }), Object.assign(Object.assign({}, reservedInterval.historicReservableInterval.timeInterval), { date: getTimelessDate(reservedInterval.date) }));
                        });
                }
                if (areAnyReservedIntervalsConflicting) {
                    const key = `${reservableInterval.timeInterval.startTime}-${reservableInterval.timeInterval.endTime}`;
                    reservedIntervalsCountMap[key] = reservedIntervalsCountMap[key]
                        ? reservedIntervalsCountMap[key] + 1
                        : 1;
                    if (reservedIntervalsCountMap[key] ===
                        reservableIntervalEntities.length) {
                        reservedIntervals.push(Object.assign(Object.assign({}, reservableInterval), { status: IntervalStatuses.RESERVED, availableNumberOfPersons }));
                    }
                }
                if (!areAnyReservedIntervalsConflicting &&
                    !doAvailableIntervalsAlreadyContainCurrentInterval) {
                    availableIntervals.push(Object.assign(Object.assign({}, reservableInterval), { status: IntervalStatuses.AVAILABLE }));
                    break;
                }
            }
        });
    }
    let filteredReservedIntervals = [];
    if (selectedEntity) {
        filteredReservedIntervals = reservedIntervals;
    }
    else {
        reservedIntervals.forEach((reservedInterval) => {
            const isThereAnIdenticAvailableInterval = availableIntervals.some((availableInterval) => {
                return areIntervalsIntersecting(Object.assign({ date: selectedDate }, reservedInterval.timeInterval), Object.assign({ date: selectedDate }, availableInterval.timeInterval));
            });
            if (!isThereAnIdenticAvailableInterval) {
                filteredReservedIntervals.push(reservedInterval);
            }
        });
    }
    const intervals = [...availableIntervals, ...filteredReservedIntervals].sort((a, b) => b.timeInterval.startTime - a.timeInterval.startTime);
    return {
        closedIntervals,
        availableIntervals,
        reservedIntervals,
        intervals,
    };
};

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ExtraInterval } from './extra-interval';
import { useCompanyReservationConfig, useExtrasStepSpecificSlice, useGetExtraWithCounterCountValue, useRemoteMasterDataSlice, useSelectedValuesSlice, useStepperStateSlice, } from '../../store';
import { ExtraCardLayouts, Icon, Switch, useTranslationsStore, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { CounterInput } from '../../../components';
import { getExtraAvailableCapacityForInterval } from '../../helpers/get-extra-available-capacity-for-interval';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
export const ExtraCard = ({ extra }) => {
    const { getTranslation, activeLanguage } = useTranslationsStore();
    const { entities } = useRemoteMasterDataSlice();
    const { defaultDate } = useStepperStateSlice();
    const { selectedIntervals } = useSelectedValuesSlice();
    const { openExtras, setOpenExtras, handleActivateExtra, handleDeactivateExtra, handleExtrasCountChange, } = useExtrasStepSpecificSlice();
    const getExtraWithCounterCountValue = useGetExtraWithCounterCountValue();
    const { currency } = useCompanyReservationConfig();
    const descriptionHtmlValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: extra.uiProps.descriptionHtml,
        date: defaultDate,
    });
    const layoutValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: extra.uiProps.cardLayout,
        date: defaultDate,
    });
    const iconValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: extra.uiProps.icon,
        date: defaultDate,
    });
    const additionalText = getPeriodPropertyValueForDate({
        periodSensitiveProperty: extra.uiProps.additionalText,
        date: defaultDate,
    });
    const sortedExtraIntervals = useMemo(() => {
        return [...extra.intervals].sort((a, b) => {
            return (new Date(a.date).getTime() +
                a.startTime -
                (new Date(b.date).getTime() + b.endTime));
        });
    }, [extra.intervals]);
    const isExtraAvabilableForAtLeastOneInterval = useMemo(() => !extra.withCapacity ||
        sortedExtraIntervals.some((extraInterval) => {
            const availableCapacity = getExtraAvailableCapacityForInterval({
                entities,
                extra,
                extraReservableInterval: extraInterval,
            });
            return Boolean(availableCapacity);
        }), [entities]);
    return (React.createElement(motion.div, { layout: true, key: extra._id, initial: { y: -10, opacity: 0 }, animate: { y: 0, opacity: 1 }, exit: { y: -10, opacity: 0 }, transition: { type: 'tween' }, className: "flex flex-col 1280:flex-row", "data-test-id": extra._id },
        React.createElement(motion.div, { layout: true, className: classNames('flex self-start rounded-md bg-white shadow-md max-w-sm w-full 1280:flex-grow 1280:w-auto') },
            layoutValue === ExtraCardLayouts.IMAGE && (React.createElement("img", { src: getPeriodPropertyValueForDate({
                    periodSensitiveProperty: extra.uiProps.image,
                    date: defaultDate,
                }).url, alt: "image", className: "h-full shadow-md rounded-l-md" })),
            layoutValue === ExtraCardLayouts.ICON && (React.createElement("div", { className: "rounded-l-md bg-primary-200 flex justify-center items-center px-5" },
                React.createElement(Icon, { icon: iconValue, className: "flex-shrink-0 w-8 h-8" }))),
            React.createElement("div", { className: "p-3 1280:p-4 w-full" },
                React.createElement("div", { className: "flex justify-between items-center w-full" },
                    React.createElement("p", { className: classNames('text-lg font-bold text-gray-900') }, getPeriodPropertyValueForDate({
                        periodSensitiveProperty: extra.uiProps.name,
                        date: defaultDate,
                    })[activeLanguage]),
                    isExtraAvabilableForAtLeastOneInterval &&
                        (!extra.withUnitCounter ||
                            (extra.withUnitCounter &&
                                !extra.shouldBeBulkSelectableOnly)) && (React.createElement(Switch, { containerClassName: `extra-card-switch ${extra.internalName}`, size: "lg", onChange: () => {
                            const openExtrasCopy = Object.assign({}, openExtras);
                            if (!openExtrasCopy[extra._id]) {
                                handleActivateExtra(extra);
                            }
                            else {
                                handleDeactivateExtra(extra);
                            }
                            if (!openExtrasCopy[extra._id]) {
                                openExtrasCopy[extra._id] = true;
                            }
                            else {
                                openExtrasCopy[extra._id] = false;
                            }
                            setOpenExtras(openExtrasCopy);
                        }, value: openExtras[extra._id] ? true : false })),
                    isExtraAvabilableForAtLeastOneInterval &&
                        extra.withUnitCounter &&
                        extra.shouldBeBulkSelectableOnly && (React.createElement(CounterInput, { containerClassName: `extra-card-counter-input ${extra.internalName}`, mode: "div", value: getExtraWithCounterCountValue(extra._id), onChange: (_value, buttonClicked) => {
                            if (buttonClicked === 'plus') {
                                handleExtrasCountChange(extra, 'increment');
                            }
                            else {
                                handleExtrasCountChange(extra, 'decrement');
                            }
                        } }))),
                React.createElement("p", { className: "inline-flex bg-primary-light px-1 rounded text-gray-900 font-bold text-sm" }, (additionalText === null || additionalText === void 0 ? void 0 : additionalText[activeLanguage])
                    ? additionalText[activeLanguage]
                    : getTranslation('fromXPrice', {
                        value: getFormattedPrice(getPeriodPropertyValueForDate({
                            periodSensitiveProperty: extra.price,
                            date: new Date(),
                        }).value.toString(), currency, false),
                        currency,
                        conjugatedCurrency: getTranslation(`conjugated${currency}`),
                    })),
                descriptionHtmlValue && (React.createElement("div", { className: classNames('text-gray-900 text-sm mt-2'), dangerouslySetInnerHTML: {
                        __html: descriptionHtmlValue[activeLanguage],
                    } })),
                !isExtraAvabilableForAtLeastOneInterval && (React.createElement("p", { className: classNames('text-error-500 text-sm mt-2') }, selectedIntervals.length > 1
                    ? getTranslation('notAvailableForSelectedPeriods')
                    : getTranslation('notAvailableForSelectedPeriod'))))),
        React.createElement("div", { className: "hidden 1280:flex w-5" }),
        !extra.shouldBeBulkSelectableOnly && (React.createElement("div", { className: "flex-grow 1280:max-w-[512px] 1280:mt-5" },
            React.createElement(AnimatePresence, null, openExtras[extra._id] === true && (React.createElement(motion.div, { layout: "position", key: `${extra._id}-intervals-container`, initial: { opacity: 0, y: -20 }, animate: {
                    opacity: 1,
                    y: 0,
                }, exit: { opacity: 0, y: -20 }, transition: { type: 'tween', duration: 0.2, ease: 'easeIn' }, className: "shadow-md px-4 rounded bg-white" },
                extra.withUnitCounter && (React.createElement("div", null,
                    React.createElement("div", { className: "flex justify-between items-baseline pt-4" },
                        React.createElement("p", { className: "mr-2 text-gray-500 font-semibold" },
                            getTranslation('modifyAll'),
                            ":"),
                        React.createElement(CounterInput, { containerClassName: `extra-card-counter-input ${extra.internalName}`, mode: extra.shouldBeBulkSelectableOnly
                                ? 'div'
                                : 'without-number', value: extra.shouldBeBulkSelectableOnly
                                ? getExtraWithCounterCountValue(extra._id)
                                : 0, onChange: (_value, buttonClicked) => {
                                if (buttonClicked === 'plus') {
                                    handleExtrasCountChange(extra, 'increment');
                                }
                                else {
                                    handleExtrasCountChange(extra, 'decrement');
                                }
                            } })),
                    !extra.shouldBeBulkSelectableOnly && (React.createElement("div", { className: "h-[2px] w-full bg-gray-300 mt-2 rounded-full" })))),
                !extra.shouldBeBulkSelectableOnly &&
                    sortedExtraIntervals.map((extraReservableInterval, index) => (React.createElement(ExtraInterval, { key: index, extra: extra, index: index, extraReservableInterval: extraReservableInterval }))))))))));
};

import { useActiveLanguage } from '@reservation-app/common-used-in-web';
import { useEffect } from 'react';
import { getRestrictionValidationErrors } from '../helpers/get-restriction-validation-errors';
import { useSelectedValuesSlice, useStepperStateSlice, useIsAdminPanel, } from '../store';
export const useValidateRestrictions = ({ isDisabled, }) => {
    const { selectedIntervals, selectedEntity, selectedService } = useSelectedValuesSlice();
    const { setValidationWarning, setValidationError } = useStepperStateSlice();
    const activeLanguage = useActiveLanguage();
    const isAdminPanel = useIsAdminPanel();
    useEffect(() => {
        if (!isDisabled) {
            const restrictionValidationError = getRestrictionValidationErrors({
                selectedEntity,
                selectedIntervals,
                selectedService,
                isAdmin: isAdminPanel,
            });
            if (restrictionValidationError.length > 0) {
                setValidationWarning(restrictionValidationError
                    .map((error) => error[activeLanguage])
                    .join(' \n'));
            }
            else {
                setValidationWarning('');
                setValidationError('');
            }
        }
    }, [selectedEntity, selectedIntervals, selectedService, activeLanguage]);
};

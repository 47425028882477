const { rgba } = require('polished');
const CalendarBaseClasses = (theme) => ({
    '.calendar-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '.calendar-arrow-container': {
        borderRadius: theme('borderRadius.full'),
        padding: theme('spacing.1'),
        margin: theme('spacing.2'),
        transitionProperty: theme('transitionProperty.all'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.300'),
        outline: '2px solid transparent',
        outlineOffset: '2px',
        '&:focus-visible': {
            boxShadow: 'var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
            outline: 'none',
        },
    },
    '.month-container': {
        display: 'flex',
        maxWidth: theme('maxWidth.full'),
        justifyContent: 'center',
        textAlign: 'center',
    },
    '.month-class': {
        fontSize: theme('fontSize.sm'),
        lineHeight: theme('lineHeight.5'),
        fontWeight: theme('fontWeight.bold'),
        color: theme('colors.gray.500'),
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    '.days-container': {
        fontSize: theme('fontSize.xs'),
        lineHeight: theme('lineHeight.4'),
        textTransform: 'uppercase',
        color: theme('colors.gray.400'),
        fontWeight: theme('fontWeight.medium'),
        paddingTop: theme('spacing.3'),
        paddingBottom: theme('spacing.3'),
        borderBottomWidth: theme('borderWidth.DEFAULT'),
        borderColor: theme('color.gray.200'),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: theme('width.full'),
    },
    '.days-class': {
        display: 'flex',
        maxWidth: theme('maxWidth.full'),
        justifyContent: 'center',
        textAlign: 'center',
    },
    '.tile-class': {
        fontSize: theme('fontSize.sm'),
        fontWeight: theme('fontWeight.bold'),
        lineHeight: theme('lineHeight.5'),
        display: 'flex',
        flexGrow: theme('flexGrow.DEFAULT'),
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme('colors.white'),
        transitionProperty: theme('transitionProperty.DEFAULT'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.300'),
        maxWidth: theme('maxWidth.full'),
        '&:focus': {
            '--tw-ring-color': rgba(theme('colors.primary.main'), Number(theme('ringOpacity.DEFAULT'))),
            boxShadow: 'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
        },
    },
    '.rounded-full-left-side': {
        borderTopLeftRadius: '9999px',
        borderBottomLeftRadius: '9999px',
    },
    '.rounded-full-right-side': {
        borderTopRightRadius: '9999px',
        borderBottomRightRadius: '9999px',
    },
    '.first-day-in-range-no-selected-end-date': {
        backgroundColor: theme('colors.gray.200'),
        boxShadow: theme('boxShadow.inner'),
    },
    '.disabled-tiles': {
        color: theme('colors.gray.300'),
        pointerEvents: 'none',
        backgroundImage: `none !important`,
    },
    '.active-tiles': {
        cursor: 'pointer',
    },
    '.selected-tiles': {
        backgroundColor: theme('colors.primary.light'),
        boxShadow: theme('boxShadow.inner'),
    },
    '.selected-and-hovered-tiles': {
        backgroundColor: theme('colors.primary.main'),
    },
    '.tile-characters-class': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0px',
        right: '0px',
        left: '0px',
        bottom: '0px',
        position: 'absolute',
    },
    '.row-item': {
        display: 'flex',
        flexWrap: 'wrap',
        width: theme('width.full'),
    },
    '.rows': {
        fontWeight: theme('fontWeight.light'),
        color: theme('colors.gray.700'),
        backgroundColor: theme('colors.white'),
        position: 'relative',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        borderBottomRightRadius: theme('borderRadius.md'),
        borderBottomLeftRadius: theme('borderRadius.md'),
    },
    '.calendar': {
        display: 'block',
        userSelect: 'none',
        position: 'relative',
        width: theme('width.full'),
        backgroundColor: theme('colors.white'),
        borderWidth: theme('borderWidth.DEFAULT'),
        borderColor: theme('color.gray.200'),
    },
});
module.exports = CalendarBaseClasses;

import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import { getAllFocusable } from './get-all-focusable';
import { focus } from './focus';
export const FocusLock = (props) => {
    const { initialFocusRef, finalFocusRef, contentRef, restoreFocus, children, isDisabled, autoFocus, lockFocusAcrossFrames, persistentFocus, } = props;
    const onActivation = React.useCallback(() => {
        if (initialFocusRef === null || initialFocusRef === void 0 ? void 0 : initialFocusRef.current) {
            initialFocusRef.current.focus();
        }
        else if (contentRef === null || contentRef === void 0 ? void 0 : contentRef.current) {
            const focusables = getAllFocusable(contentRef.current);
            if (focusables.length === 0) {
                focus(contentRef.current);
            }
        }
    }, [initialFocusRef, contentRef]);
    const onDeactivation = React.useCallback(() => {
        var _a;
        (_a = finalFocusRef === null || finalFocusRef === void 0 ? void 0 : finalFocusRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [finalFocusRef]);
    const returnFocus = restoreFocus && !finalFocusRef;
    return (React.createElement(ReactFocusLock, { crossFrame: lockFocusAcrossFrames, persistentFocus: persistentFocus, autoFocus: autoFocus, disabled: isDisabled, onActivation: onActivation, onDeactivation: onDeactivation, returnFocus: returnFocus }, children));
};

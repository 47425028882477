import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { usePrevious } from '@reservation-app/common-used-in-web';
export const CounterInput = ({ value, onChange, containerClassName, buttonContainerClassName, buttonClassName, numberContainerClassName, mode = 'div', max, min, }) => {
    const [inputWidth, setInputWidth] = useState(20);
    const setWidth = (inputValueLength) => {
        if (inputValueLength > 0) {
            setInputWidth(inputValueLength * 9);
        }
        else if (inputValueLength === 0) {
            setInputWidth(8);
        }
    };
    useLayoutEffect(() => {
        setWidth(value.toString().length);
    }, [value]);
    const setValueIfNotRestricted = (newValue, buttonPressed) => {
        let restricted = false;
        if (max && newValue > max) {
            restricted = true;
        }
        if (min && newValue < min) {
            restricted = true;
        }
        if (!restricted) {
            onChange(newValue, buttonPressed);
        }
    };
    const prevValue = usePrevious(value);
    const forward = prevValue === undefined || prevValue < value;
    return (React.createElement("div", { className: classNames('counter-input-container', containerClassName) },
        React.createElement("div", { className: classNames('counter-input-button-container', buttonContainerClassName) },
            React.createElement("button", { onClick: () => {
                    setValueIfNotRestricted(Number(value) - 1, 'minus');
                }, type: "button", className: classNames('counter-input-button', buttonClassName) },
                React.createElement("svg", { stroke: "currentColor", fill: "none", strokeWidth: "4", viewBox: "0 0 24 24", strokeLinecap: "round", strokeLinejoin: "round", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("line", { x1: "5", y1: "12", x2: "19", y2: "12" })))),
        mode === 'input' && (React.createElement(motion.input, { key: value, initial: { x: forward ? 20 : -20, opacity: 0 }, animate: {
                x: 0,
                opacity: 1,
            }, exit: { x: forward ? -20 : 20, opacity: 0 }, transition: { type: 'tween', duration: 0.2 }, type: "number", value: value, style: { width: inputWidth }, onChange: (e) => {
                setValueIfNotRestricted(Number(e.target.value));
            }, className: classNames('no-arrows-numeric-input counter-input-input', numberContainerClassName) })),
        mode === 'div' && (React.createElement(motion.div, { key: value, initial: { x: forward ? 20 : -20, opacity: 0 }, animate: {
                x: 0,
                opacity: 1,
            }, exit: { x: forward ? -20 : 20, opacity: 0 }, transition: { type: 'tween', duration: 0.2 }, className: classNames('counter-input-input flex justify-center', numberContainerClassName) }, value)),
        React.createElement("div", { className: classNames('counter-input-button-container', buttonContainerClassName) },
            React.createElement("button", { onClick: () => {
                    setValueIfNotRestricted(Number(value) + 1, 'plus');
                }, type: "button", className: classNames('counter-input-button', buttonClassName) },
                React.createElement("svg", { stroke: "currentColor", fill: "currentColor", strokeWidth: "2", viewBox: "0 0 16 16", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { fillRule: "evenodd", d: "M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z", clipRule: "evenodd" }),
                    React.createElement("path", { fillRule: "evenodd", d: "M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z", clipRule: "evenodd" }))))));
};

import { useAnimation, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
export const FadeInAnimation = ({ htmlTag = 'div', children, withNoWrapper = false, isDisabled = false, className, delay = 100, }) => {
    const [ref, inView] = useInView();
    const AnimationWrapperTag = isDisabled ? `${htmlTag}` : motion[`${htmlTag}`];
    const cardVariants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0.01, y: -50 },
    };
    const controls = useAnimation();
    useEffect(() => {
        if (inView && !isDisabled) {
            setTimeout(() => {
                controls.start('visible');
            }, delay);
        }
    }, [controls, inView, isDisabled]);
    if (isDisabled && withNoWrapper) {
        return children;
    }
    if (isDisabled) {
        React.createElement(AnimationWrapperTag, { className: className, ref: ref }, children);
    }
    return (React.createElement(AnimationWrapperTag, { className: className, ref: ref, initial: "hidden", animate: controls, variants: cardVariants, transition: { duration: 0.5 } }, children));
};

import React from 'react';
export const EnglishFlag = ({ className, onClick }) => {
    return (React.createElement("svg", { onClick: onClick, className: className, version: "1.1", id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 473.68 473.68", enableBackground: "new 0 0 473.68 473.68", xmlSpace: "preserve" },
        React.createElement("g", null,
            React.createElement("path", { fill: "#29337A", d: "M41.712,102.641c-15.273,22.168-26.88,47.059-33.918,73.812h107.734L41.712,102.641z" }),
            React.createElement("path", { fill: "#29337A", d: "M170.511,9.48c-27.288,7.947-52.56,20.628-74.814,37.168l74.814,74.814V9.48z" }),
            React.createElement("path", { fill: "#29337A", d: "M101.261,430.982c20.874,14.607,44.195,25.915,69.25,33.211v-102.45L101.261,430.982z" }),
            React.createElement("path", { fill: "#29337A", d: "M10.512,306.771c7.831,25.366,19.831,48.899,35.167,69.833l69.833-69.833H10.512z" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#FFFFFF", d: "M45.619,97.144c-1.324,1.81-2.629,3.646-3.908,5.501l73.816,73.812H7.793\r\n\t\tc-1.746,6.645-3.171,13.418-4.345,20.284h141.776L45.619,97.144z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M95.767,427.074c1.802,1.343,3.654,2.621,5.493,3.908l69.25-69.242v102.45\r\n\t\tc6.653,1.945,13.41,3.624,20.284,4.974V332.05L95.767,427.074z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M5.25,286.487c1.47,6.873,3.205,13.642,5.258,20.284h105.001l-69.833,69.833\r\n\t\tc7.595,10.377,16.017,20.115,25.168,29.12L190.08,286.487H5.25L5.25,286.487z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M170.511,9.48v111.982l-74.815-74.81c-10.314,7.67-19.955,16.185-28.888,25.403l123.983,123.983\r\n\t\tV4.506C183.921,5.864,177.164,7.547,170.511,9.48z" })),
        React.createElement("g", null,
            React.createElement("polygon", { fill: "#D32030", points: "170.511,306.056 169.8,306.771 170.511,306.771 \t" }),
            React.createElement("polygon", { fill: "#D32030", points: "190.084,286.487 190.794,286.487 190.794,285.773 \t" }),
            React.createElement("polygon", { fill: "#D32030", points: "281.229,196.737 280.545,196.737 280.545,197.425 \t" }),
            React.createElement("polygon", { fill: "#D32030", points: "171.21,176.457 170.511,175.754 170.511,176.457 \t" }),
            React.createElement("polygon", { fill: "#D32030", points: "190.794,196.037 190.794,196.737 191.494,196.737 \t" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#252F6C", d: "M300.825,411.764v53.091c25.381-7.105,49.045-18.305,70.211-32.897l-57.526-57.526\r\n\t\tC308.913,390.583,307.231,398.933,300.825,411.764z" }),
            React.createElement("path", { fill: "#252F6C", d: "M313.812,108.471l62.799-62.799C354.05,29.15,328.456,16.559,300.824,8.818v54.538\r\n\t\tC308.21,78.146,308.831,89.384,313.812,108.471z" }),
            React.createElement("path", { fill: "#252F6C", d: "M427.029,377.984c15.815-21.275,28.141-45.29,36.147-71.213h-107.36L427.029,377.984z" }),
            React.createElement("path", { fill: "#252F6C", d: "M465.887,176.457c-7.188-27.318-19.143-52.676-34.898-75.192l-75.2,75.192H465.887z" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#E7E7E7", d: "M327.638,290.5l16.275,16.275l77.903,77.903c1.769-2.214,3.526-4.42,5.217-6.69l-71.213-71.213\r\n\t\th107.36c2.046-6.638,3.784-13.41,5.25-20.284H329.16C328.932,289.367,327.911,287.643,327.638,290.5z" }),
            React.createElement("path", { fill: "#E7E7E7", d: "M311.352,120.348l70.607-70.615c-1.769-1.372-3.541-2.737-5.348-4.061l-62.799,62.799\r\n\t\tC314.463,110.954,310.746,117.805,311.352,120.348z" }),
            React.createElement("path", { fill: "#E7E7E7", d: "M300.825,58.992V8.814c-6.645-1.862-13.41-3.44-20.284-4.727v24.476\r\n\t\tC288.088,36.745,294.853,47.022,300.825,58.992z" }),
            React.createElement("path", { fill: "#E7E7E7", d: "M326.041,196.737h144.195c-1.171-6.866-2.599-13.635-4.345-20.284H355.793l75.2-75.192\r\n\t\tC423.6,90.7,415.384,80.768,406.409,71.565l-84.702,84.694C323.988,171.622,325.009,180.544,326.041,196.737z" }),
            React.createElement("path", { fill: "#E7E7E7", d: "M310.088,371.002l60.952,60.959c10.138-6.982,19.685-14.753,28.593-23.189l-80.173-80.177\r\n\t\tC316.901,343.423,313.865,357.745,310.088,371.002z" }),
            React.createElement("path", { fill: "#E7E7E7", d: "M280.545,442.301v27.28c6.873-1.279,13.635-2.865,20.284-4.727v-53.091\r\n\t\tC294.853,423.738,288.088,434.13,280.545,442.301z" })),
        React.createElement("path", { fill: "#D71F28", d: "M321.707,156.259l84.694-84.694c-7.625-7.831-15.8-15.119-24.446-21.832l-66.55,66.561\r\n\tC318.363,128.657,319.706,142.808,321.707,156.259z" }),
        React.createElement("g", null,
            React.createElement("path", { fill: "#D32030", d: "M225.019,0.292C228.965,0.101,232.899,0,236.836,0C232.876,0,228.935,0.101,225.019,0.292z" }),
            React.createElement("path", { fill: "#D32030", d: "M236.836,473.68c-3.938,0-7.872-0.108-11.81-0.299C228.942,473.579,232.876,473.68,236.836,473.68z" }),
            React.createElement("path", { fill: "#D32030", d: "M236.836,473.68c14.943,0,29.535-1.447,43.708-4.099v-27.28\r\n\t\tC268.103,455.786,253.549,473.68,236.836,473.68z" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#D71F28", d: "M470.232,196.737H327.911c1.885,29.704,1.657,60.249-0.681,89.75h141.2\r\n\t\tc3.418-16.017,5.25-32.613,5.25-49.643C473.68,223.164,472.461,209.784,470.232,196.737z" }),
            React.createElement("path", { fill: "#D71F28", d: "M327.638,290.5c-1.316,13.994-5.901,24.898-8.182,38.099l80.173,80.173\r\n\t\tc7.932-7.517,15.347-15.557,22.183-24.094l-77.9-77.907L327.638,290.5z" })),
        React.createElement("path", { fill: "#D32030", d: "M280.545,30.324V4.091C266.376,1.447,251.784,0,236.836,0C253.549,0,268.103,16.843,280.545,30.324z" }),
        React.createElement("g", null,
            React.createElement("path", { fill: "#29337A", d: "M300.825,422.007c6.406-12.834,11.899-27.609,16.499-43.757l-16.499-16.499V422.007z" }),
            React.createElement("path", { fill: "#29337A", d: "M319.377,102.906c-4.989-19.087-11.166-36.439-18.552-51.229v69.773L319.377,102.906z" })),
        React.createElement("g", null,
            React.createElement("path", { fill: "#FFFFFF", d: "M332.234,295.092c0.269-2.857,0.512-5.725,0.744-8.605h-9.349L332.234,295.092z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M300.825,121.451V51.674c-5.976-11.97-12.737-22.254-20.284-30.429v129.906l40.735-40.735\r\n\t\tc-0.613-2.543-1.257-5.034-1.9-7.517L300.825,121.451z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M281.229,196.737h52.429c-1.028-16.192-2.666-32.123-4.944-47.482L281.229,196.737z" }),
            React.createElement("path", { fill: "#FFFFFF", d: "M280.545,452.432c7.547-8.182,14.308-18.459,20.284-30.429v-60.256l16.499,16.499\r\n\t\tc3.784-13.264,6.959-27.434,9.525-42.261l-46.307-46.304L280.545,452.432L280.545,452.432z" })),
        React.createElement("path", { fill: "#E51D35", d: "M280.545,452.432V289.681l46.304,46.307c2.277-13.205,4.069-26.899,5.381-40.896l-8.605-8.605h9.349\r\n\tc2.337-29.502,2.565-60.047,0.681-89.75h-52.429l47.482-47.482c-2.001-13.455-4.476-26.469-7.434-38.836l-40.728,40.735V21.248\r\n\tC268.103,7.763,253.549,0,236.836,0c-3.938,0-7.872,0.101-11.817,0.292c-11.649,0.583-23.073,2.016-34.225,4.215v191.531\r\n\tL66.808,72.055c-7.618,7.861-14.704,16.237-21.189,25.089l79.313,79.313l20.291,20.284H3.448C1.227,209.784,0,223.164,0,236.844\r\n\tc0,17.034,1.84,33.626,5.25,49.643h184.834L70.847,405.724c7.808,7.67,16.121,14.813,24.921,21.349l95.023-95.023v137.116\r\n\tc11.151,2.199,22.583,3.631,34.232,4.215c3.938,0.191,7.872,0.299,11.81,0.299C253.549,473.68,268.103,465.917,280.545,452.432z" }),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null),
        React.createElement("g", null)));
};

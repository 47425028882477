const ButtonBaseClasses = (theme) => ({
    '.btn-sm': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme('components.button.sizes.sm.boxShadow') ||
            theme('boxShadow.DEFAULT'),
        borderRadius: theme('components.button.sizes.sm.borderRadius') || theme('spacing.1'),
        paddingRight: theme('components.button.sizes.sm.px') || theme('spacing.4'),
        paddingLeft: theme('components.button.sizes.sm.px') || theme('spacing.4'),
        paddingTop: theme('components.button.sizes.sm.py') || theme('spacing.2'),
        paddingBottom: theme('components.button.sizes.sm.py') || theme('spacing.2'),
        fontSize: theme('components.button.sizes.sm.fontSize') || theme('fontSize.sm'),
        fontWeight: theme('components.button.sizes.sm.fontWeight') ||
            theme('fontWeight.semibold'),
        height: theme('components.button.sizes.sm.height'),
        whiteSpace: 'nowrap',
        transitionProperty: 'background-color, border-color, color, fill, stroke, opacity',
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: '300ms',
    },
    '.btn-md': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme('components.button.sizes.md.boxShadow') ||
            theme('boxShadow.DEFAULT'),
        borderRadius: theme('components.button.sizes.md.borderRadius') || theme('spacing.1'),
        paddingRight: theme('components.button.sizes.md.px') || theme('spacing.6'),
        paddingLeft: theme('components.button.sizes.md.px') || theme('spacing.6'),
        paddingTop: theme('components.button.sizes.md.py') || theme('spacing.2'),
        paddingBottom: theme('components.button.sizes.md.py') || theme('spacing.2'),
        fontSize: theme('components.button.sizes.md.fontSize') || theme('fontSize.base'),
        fontWeight: theme('components.button.sizes.md.fontWeight') ||
            theme('fontWeight.semibold'),
        height: theme('components.button.sizes.md.height'),
        whiteSpace: 'nowrap',
        transitionProperty: 'background-color, border-color, color, fill, stroke, opacity',
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: '300ms',
    },
    '.btn-lg': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme('components.button.sizes.lg.boxShadow') || theme('boxShadow.lg'),
        borderRadius: theme('components.button.sizes.lg.borderRadius') || theme('spacing.1'),
        paddingRight: theme('components.button.sizes.lg.px') || theme('spacing.8'),
        paddingLeft: theme('components.button.sizes.lg.px') || theme('spacing.8'),
        paddingTop: theme('components.button.sizes.lg.py') || theme('spacing.2'),
        paddingBottom: theme('components.button.sizes.lg.py') || theme('spacing.2'),
        fontSize: theme('components.button.sizes.lg.fontSize') || theme('fontSize.xl'),
        fontWeight: theme('components.button.sizes.lg.fontWeight') ||
            theme('fontWeight.semibold'),
        height: theme('components.button.sizes.lg.height'),
        whiteSpace: 'nowrap',
        transitionProperty: 'background-color, border-color, color, fill, stroke, opacity',
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: '300ms',
    },
    '.btn-xl': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme('components.button.sizes.xl.boxShadow') || theme('boxShadow.xl'),
        borderRadius: theme('components.button.sizes.xl.borderRadius') || theme('spacing.1'),
        paddingRight: theme('components.button.sizes.xl.px') || theme('spacing.10'),
        paddingLeft: theme('components.button.sizes.xl.px') || theme('spacing.10'),
        paddingTop: theme('components.button.sizes.xl.py') || theme('spacing.2'),
        paddingBottom: theme('components.button.sizes.xl.py') || theme('spacing.2'),
        fontSize: theme('components.button.sizes.xl.fontSize') || theme('fontSize.3xl'),
        fontWeight: theme('components.button.sizes.xl.fontWeight') ||
            theme('fontWeight.semibold'),
        height: theme('components.button.sizes.xl.height'),
        whiteSpace: 'nowrap',
        transitionProperty: 'background-color, border-color, color, fill, stroke, opacity',
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: '300ms',
    },
    '.btn-primary-outline': {
        backgroundColor: theme('components.button.primary.outline.default.bg'),
        color: theme('components.button.primary.outline.default.textColor'),
        border: theme('components.button.primary.outline.default.border'),
        opacity: theme('components.button.primary.outline.default.opacity'),
        boxShadow: theme('components.button.primary.outline.default.boxShadow'),
        '--tw-ring-color': theme('components.button.primary.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.primary.outline.hovered.bg'),
            color: theme('components.button.primary.outline.hovered.textColor'),
            border: theme('components.button.primary.outline.hovered.border'),
            opacity: theme('components.button.primary.outline.hovered.opacity'),
            boxShadow: theme('components.button.primary.outline.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.primary.outline.pressed.bg'),
            color: theme('components.button.primary.outline.pressed.textColor'),
            border: theme('components.button.primary.outline.pressed.border'),
            opacity: theme('components.button.primary.outline.pressed.opacity'),
            boxShadow: theme('components.button.primary.outline.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.primary.outline.disabled.bg'),
            color: theme('components.button.primary.outline.disabled.textColor'),
            border: theme('components.button.primary.outline.disabled.border'),
            opacity: theme('components.button.primary.outline.disabled.opacity'),
            boxShadow: theme('components.button.primary.outline.disabled.boxShadow'),
        },
    },
    '.btn-primary-filled': {
        backgroundColor: theme('components.button.primary.filled.default.bg'),
        color: theme('components.button.primary.filled.default.textColor'),
        border: theme('components.button.primary.filled.default.border'),
        opacity: theme('components.button.primary.filled.default.opacity'),
        boxShadow: theme('components.button.primary.filled.default.boxShadow'),
        '--tw-ring-color': theme('components.button.primary.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.primary.filled.hovered.bg'),
            color: theme('components.button.primary.filled.hovered.textColor'),
            border: theme('components.button.primary.filled.hovered.border'),
            opacity: theme('components.button.primary.filled.hovered.opacity'),
            boxShadow: theme('components.button.primary.filled.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.primary.filled.pressed.bg'),
            color: theme('components.button.primary.filled.pressed.textColor'),
            border: theme('components.button.primary.filled.pressed.border'),
            opacity: theme('components.button.primary.filled.pressed.opacity'),
            boxShadow: theme('components.button.primary.filled.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.primary.filled.disabled.bg'),
            color: theme('components.button.primary.filled.disabled.textColor'),
            border: theme('components.button.primary.filled.disabled.border'),
            opacity: theme('components.button.primary.filled.disabled.opacity'),
            boxShadow: theme('components.button.primary.filled.disabled.boxShadow'),
        },
    },
    '.btn-primary-ghost': {
        backgroundColor: theme('components.button.primary.ghost.default.bg'),
        color: theme('components.button.primary.ghost.default.textColor'),
        border: theme('components.button.primary.ghost.default.border'),
        opacity: theme('components.button.primary.ghost.default.opacity'),
        boxShadow: theme('components.button.primary.ghost.default.boxShadow'),
        '--tw-ring-color': theme('components.button.primary.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.primary.ghost.hovered.bg'),
            color: theme('components.button.primary.ghost.hovered.textColor'),
            border: theme('components.button.primary.ghost.hovered.border'),
            opacity: theme('components.button.primary.ghost.hovered.opacity'),
            boxShadow: theme('components.button.primary.ghost.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.primary.ghost.pressed.bg'),
            color: theme('components.button.primary.ghost.pressed.textColor'),
            border: theme('components.button.primary.ghost.pressed.border'),
            opacity: theme('components.button.primary.ghost.pressed.opacity'),
            boxShadow: theme('components.button.primary.ghost.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.primary.ghost.disabled.bg'),
            color: theme('components.button.primary.ghost.disabled.textColor'),
            border: theme('components.button.primary.ghost.disabled.border'),
            opacity: theme('components.button.primary.ghost.disabled.opacity'),
            boxShadow: theme('components.button.primary.ghost.disabled.boxShadow'),
        },
    },
    '.btn-neutral-outline': {
        backgroundColor: theme('components.button.neutral.outline.default.bg'),
        color: theme('components.button.neutral.outline.default.textColor'),
        border: theme('components.button.neutral.outline.default.border'),
        opacity: theme('components.button.neutral.outline.default.opacity'),
        boxShadow: theme('components.button.neutral.outline.default.boxShadow'),
        '--tw-ring-color': theme('components.button.neutral.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.neutral.outline.hovered.bg'),
            color: theme('components.button.neutral.outline.hovered.textColor'),
            border: theme('components.button.neutral.outline.hovered.border'),
            opacity: theme('components.button.neutral.outline.hovered.opacity'),
            boxShadow: theme('components.button.neutral.outline.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.neutral.outline.pressed.bg'),
            color: theme('components.button.neutral.outline.pressed.textColor'),
            border: theme('components.button.neutral.outline.pressed.border'),
            opacity: theme('components.button.neutral.outline.pressed.opacity'),
            boxShadow: theme('components.button.neutral.outline.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.neutral.outline.disabled.bg'),
            color: theme('components.button.neutral.outline.disabled.textColor'),
            border: theme('components.button.neutral.outline.disabled.border'),
            opacity: theme('components.button.neutral.outline.disabled.opacity'),
            boxShadow: theme('components.button.neutral.outline.disabled.boxShadow'),
        },
    },
    '.btn-neutral-filled': {
        backgroundColor: theme('components.button.neutral.filled.default.bg'),
        color: theme('components.button.neutral.filled.default.textColor'),
        border: theme('components.button.neutral.filled.default.border'),
        opacity: theme('components.button.neutral.filled.default.opacity'),
        boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        '--tw-ring-color': theme('components.button.neutral.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.neutral.filled.hovered.bg'),
            color: theme('components.button.neutral.filled.hovered.textColor'),
            border: theme('components.button.neutral.filled.hovered.border'),
            opacity: theme('components.button.neutral.filled.hovered.opacity'),
            boxShadow: theme('components.button.neutral.filled.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.neutral.filled.pressed.bg'),
            color: theme('components.button.neutral.filled.pressed.textColor'),
            border: theme('components.button.neutral.filled.pressed.border'),
            opacity: theme('components.button.neutral.filled.pressed.opacity'),
            boxShadow: theme('components.button.neutral.filled.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.neutral.filled.disabled.bg'),
            color: theme('components.button.neutral.filled.disabled.textColor'),
            border: theme('components.button.neutral.filled.disabled.border'),
            opacity: theme('components.button.neutral.filled.disabled.opacity'),
            boxShadow: theme('components.button.neutral.filled.disabled.boxShadow'),
        },
    },
    '.btn-neutral-ghost': {
        backgroundColor: theme('components.button.neutral.ghost.default.bg'),
        color: theme('components.button.neutral.ghost.default.textColor'),
        border: theme('components.button.neutral.ghost.default.border'),
        opacity: theme('components.button.neutral.ghost.default.opacity'),
        boxShadow: theme('components.button.neutral.ghost.default.boxShadow'),
        '--tw-ring-color': theme('components.button.neutral.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.neutral.ghost.hovered.bg'),
            color: theme('components.button.neutral.ghost.hovered.textColor'),
            border: theme('components.button.neutral.ghost.hovered.border'),
            opacity: theme('components.button.neutral.ghost.hovered.opacity'),
            boxShadow: theme('components.button.neutral.ghost.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.neutral.ghost.pressed.bg'),
            color: theme('components.button.neutral.ghost.pressed.textColor'),
            border: theme('components.button.neutral.ghost.pressed.border'),
            opacity: theme('components.button.neutral.ghost.pressed.opacity'),
            boxShadow: theme('components.button.neutral.ghost.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.neutral.ghost.disabled.bg'),
            color: theme('components.button.neutral.ghost.disabled.textColor'),
            border: theme('components.button.neutral.ghost.disabled.border'),
            opacity: theme('components.button.neutral.ghost.disabled.opacity'),
            boxShadow: theme('components.button.neutral.ghost.disabled.boxShadow'),
        },
    },
    '.btn-warning-outline': {
        backgroundColor: theme('components.button.warning.outline.default.bg'),
        color: theme('components.button.warning.outline.default.textColor'),
        border: theme('components.button.warning.outline.default.border'),
        opacity: theme('components.button.warning.outline.default.opacity'),
        boxShadow: theme('components.button.warning.outline.default.boxShadow'),
        '--tw-ring-color': theme('components.button.warning.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.warning.outline.hovered.bg'),
            color: theme('components.button.warning.outline.hovered.textColor'),
            border: theme('components.button.warning.outline.hovered.border'),
            opacity: theme('components.button.warning.outline.hovered.opacity'),
            boxShadow: theme('components.button.warning.outline.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.warning.outline.pressed.bg'),
            color: theme('components.button.warning.outline.pressed.textColor'),
            border: theme('components.button.warning.outline.pressed.border'),
            opacity: theme('components.button.warning.outline.pressed.opacity'),
            boxShadow: theme('components.button.warning.outline.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.warning.outline.disabled.bg'),
            color: theme('components.button.warning.outline.disabled.textColor'),
            border: theme('components.button.warning.outline.disabled.border'),
            opacity: theme('components.button.warning.outline.disabled.opacity'),
            boxShadow: theme('components.button.warning.outline.disabled.boxShadow'),
        },
    },
    '.btn-warning-filled': {
        backgroundColor: theme('components.button.warning.filled.default.bg'),
        color: theme('components.button.warning.filled.default.textColor'),
        border: theme('components.button.warning.filled.default.border'),
        opacity: theme('components.button.warning.filled.default.opacity'),
        boxShadow: theme('components.button.warning.filled.default.boxShadow'),
        '--tw-ring-color': theme('components.button.warning.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.warning.filled.hovered.bg'),
            color: theme('components.button.warning.filled.hovered.textColor'),
            border: theme('components.button.warning.filled.hovered.border'),
            opacity: theme('components.button.warning.filled.hovered.opacity'),
            boxShadow: theme('components.button.warning.filled.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.warning.filled.pressed.bg'),
            color: theme('components.button.warning.filled.pressed.textColor'),
            border: theme('components.button.warning.filled.pressed.border'),
            opacity: theme('components.button.warning.filled.pressed.opacity'),
            boxShadow: theme('components.button.warning.filled.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.warning.filled.disabled.bg'),
            color: theme('components.button.warning.filled.disabled.textColor'),
            border: theme('components.button.warning.filled.disabled.border'),
            opacity: theme('components.button.warning.filled.disabled.opacity'),
            boxShadow: theme('components.button.warning.filled.disabled.boxShadow'),
        },
    },
    '.btn-warning-ghost': {
        backgroundColor: theme('components.button.warning.ghost.default.bg'),
        color: theme('components.button.warning.ghost.default.textColor'),
        border: theme('components.button.warning.ghost.default.border'),
        opacity: theme('components.button.warning.ghost.default.opacity'),
        boxShadow: theme('components.button.warning.ghost.default.boxShadow'),
        '--tw-ring-color': theme('components.button.warning.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.warning.ghost.hovered.bg'),
            color: theme('components.button.warning.ghost.hovered.textColor'),
            border: theme('components.button.warning.ghost.hovered.border'),
            opacity: theme('components.button.warning.ghost.hovered.opacity'),
            boxShadow: theme('components.button.warning.ghost.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.warning.ghost.pressed.bg'),
            color: theme('components.button.warning.ghost.pressed.textColor'),
            border: theme('components.button.warning.ghost.pressed.border'),
            opacity: theme('components.button.warning.ghost.pressed.opacity'),
            boxShadow: theme('components.button.warning.ghost.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.warning.ghost.disabled.bg'),
            color: theme('components.button.warning.ghost.disabled.textColor'),
            border: theme('components.button.warning.ghost.disabled.border'),
            opacity: theme('components.button.warning.ghost.disabled.opacity'),
            boxShadow: theme('components.button.warning.ghost.disabled.boxShadow'),
        },
    },
    '.btn-success-outline': {
        backgroundColor: theme('components.button.success.outline.default.bg'),
        color: theme('components.button.success.outline.default.textColor'),
        border: theme('components.button.success.outline.default.border'),
        opacity: theme('components.button.success.outline.default.opacity'),
        boxShadow: theme('components.button.success.outline.default.boxShadow'),
        '--tw-ring-color': theme('components.button.success.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.success.outline.hovered.bg'),
            color: theme('components.button.success.outline.hovered.textColor'),
            border: theme('components.button.success.outline.hovered.border'),
            opacity: theme('components.button.success.outline.hovered.opacity'),
            boxShadow: theme('components.button.success.outline.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.success.outline.pressed.bg'),
            color: theme('components.button.success.outline.pressed.textColor'),
            border: theme('components.button.success.outline.pressed.border'),
            opacity: theme('components.button.success.outline.pressed.opacity'),
            boxShadow: theme('components.button.success.outline.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.success.outline.disabled.bg'),
            color: theme('components.button.success.outline.disabled.textColor'),
            border: theme('components.button.success.outline.disabled.border'),
            opacity: theme('components.button.success.outline.disabled.opacity'),
            boxShadow: theme('components.button.success.outline.disabled.boxShadow'),
        },
    },
    '.btn-success-filled': {
        backgroundColor: theme('components.button.success.filled.default.bg'),
        color: theme('components.button.success.filled.default.textColor'),
        border: theme('components.button.success.filled.default.border'),
        opacity: theme('components.button.success.filled.default.opacity'),
        boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        '--tw-ring-color': theme('components.button.success.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.success.filled.hovered.bg'),
            color: theme('components.button.success.filled.hovered.textColor'),
            border: theme('components.button.success.filled.hovered.border'),
            opacity: theme('components.button.success.filled.hovered.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.success.filled.pressed.bg'),
            color: theme('components.button.success.filled.pressed.textColor'),
            border: theme('components.button.success.filled.pressed.border'),
            opacity: theme('components.button.success.filled.pressed.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.success.filled.disabled.bg'),
            color: theme('components.button.success.filled.disabled.textColor'),
            border: theme('components.button.success.filled.disabled.border'),
            opacity: theme('components.button.success.filled.disabled.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
    },
    '.btn-success-ghost': {
        backgroundColor: theme('components.button.success.ghost.default.bg'),
        color: theme('components.button.success.ghost.default.textColor'),
        border: theme('components.button.success.ghost.default.border'),
        opacity: theme('components.button.success.ghost.default.opacity'),
        boxShadow: theme('components.button.neutral.ghost.default.boxShadow'),
        '--tw-ring-color': theme('components.button.success.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.success.ghost.hovered.bg'),
            color: theme('components.button.success.ghost.hovered.textColor'),
            border: theme('components.button.success.ghost.hovered.border'),
            opacity: theme('components.button.success.ghost.hovered.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.success.ghost.pressed.bg'),
            color: theme('components.button.success.ghost.pressed.textColor'),
            border: theme('components.button.success.ghost.pressed.border'),
            opacity: theme('components.button.success.ghost.pressed.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.success.ghost.disabled.bg'),
            color: theme('components.button.success.ghost.disabled.textColor'),
            border: theme('components.button.success.ghost.disabled.border'),
            opacity: theme('components.button.success.ghost.disabled.opacity'),
            boxShadow: theme('components.button.neutral.filled.default.boxShadow'),
        },
    },
    '.btn-danger-outline': {
        backgroundColor: theme('components.button.danger.outline.default.bg'),
        color: theme('components.button.danger.outline.default.textColor'),
        border: theme('components.button.danger.outline.default.border'),
        opacity: theme('components.button.danger.outline.default.opacity'),
        boxShadow: theme('components.button.danger.outline.default.boxShadow'),
        '--tw-ring-color': theme('components.button.danger.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.danger.outline.hovered.bg'),
            color: theme('components.button.danger.outline.hovered.textColor'),
            border: theme('components.button.danger.outline.hovered.border'),
            opacity: theme('components.button.danger.outline.hovered.opacity'),
            boxShadow: theme('components.button.danger.outline.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.danger.outline.pressed.bg'),
            color: theme('components.button.danger.outline.pressed.textColor'),
            border: theme('components.button.danger.outline.pressed.border'),
            opacity: theme('components.button.danger.outline.pressed.opacity'),
            boxShadow: theme('components.button.danger.outline.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.danger.outline.disabled.bg'),
            color: theme('components.button.danger.outline.disabled.textColor'),
            border: theme('components.button.danger.outline.disabled.border'),
            opacity: theme('components.button.danger.outline.disabled.opacity'),
            boxShadow: theme('components.button.danger.outline.disabled.boxShadow'),
        },
    },
    '.btn-danger-filled': {
        backgroundColor: theme('components.button.danger.filled.default.bg'),
        color: theme('components.button.danger.filled.default.textColor'),
        border: theme('components.button.danger.filled.default.border'),
        opacity: theme('components.button.danger.filled.default.opacity'),
        boxShadow: theme('components.button.danger.filled.default.boxShadow'),
        '--tw-ring-color': theme('components.button.danger.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.danger.filled.hovered.bg'),
            color: theme('components.button.danger.filled.hovered.textColor'),
            border: theme('components.button.danger.filled.hovered.border'),
            opacity: theme('components.button.danger.filled.hovered.opacity'),
            boxShadow: theme('components.button.danger.filled.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.danger.filled.pressed.bg'),
            color: theme('components.button.danger.filled.pressed.textColor'),
            border: theme('components.button.danger.filled.pressed.border'),
            opacity: theme('components.button.danger.filled.pressed.opacity'),
            boxShadow: theme('components.button.danger.filled.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.danger.filled.disabled.bg'),
            color: theme('components.button.danger.filled.disabled.textColor'),
            border: theme('components.button.danger.filled.disabled.border'),
            opacity: theme('components.button.danger.filled.disabled.opacity'),
            boxShadow: theme('components.button.danger.filled.disabled.boxShadow'),
        },
    },
    '.btn-danger-ghost': {
        backgroundColor: theme('components.button.danger.ghost.default.bg'),
        color: theme('components.button.danger.ghost.default.textColor'),
        border: theme('components.button.danger.ghost.default.border'),
        opacity: theme('components.button.danger.ghost.default.opacity'),
        boxShadow: theme('components.button.danger.ghost.default.boxShadow'),
        '--tw-ring-color': theme('components.button.danger.focusRing'),
        '&:hover': {
            backgroundColor: theme('components.button.danger.ghost.hovered.bg'),
            color: theme('components.button.danger.ghost.hovered.textColor'),
            border: theme('components.button.danger.ghost.hovered.border'),
            opacity: theme('components.button.danger.ghost.hovered.opacity'),
            boxShadow: theme('components.button.danger.ghost.hovered.boxShadow'),
        },
        '&:active': {
            backgroundColor: theme('components.button.danger.ghost.pressed.bg'),
            color: theme('components.button.danger.ghost.pressed.textColor'),
            border: theme('components.button.danger.ghost.pressed.border'),
            opacity: theme('components.button.danger.ghost.pressed.opacity'),
            boxShadow: theme('components.button.danger.ghost.pressed.boxShadow'),
        },
        '&:disabled': {
            pointerEvents: 'none',
            backgroundColor: theme('components.button.danger.ghost.disabled.bg'),
            color: theme('components.button.danger.ghost.disabled.textColor'),
            border: theme('components.button.danger.ghost.disabled.border'),
            opacity: theme('components.button.danger.ghost.disabled.opacity'),
            boxShadow: theme('components.button.danger.ghost.disabled.boxShadow'),
        },
    },
    '.btn-icon-spinner-left': {
        marginRight: theme('spacing.2'),
        flexShrink: 0,
    },
    '.btn-icon-spinner-right': {
        marginLeft: theme('spacing.2'),
        flexShrink: 0,
    },
    '.btn-spinner-sm': {
        width: theme('spacing.3'),
        height: theme('spacing.3'),
    },
    '.btn-spinner-md': {
        width: theme('spacing.4'),
        height: theme('spacing.4'),
    },
    '.btn-spinner-lg': {
        width: theme('spacing.5'),
        height: theme('spacing.5'),
    },
    '.btn-spinner-xl': {
        width: theme('spacing.6'),
        height: theme('spacing.6'),
    },
    '.keyboard-only-focus': {
        '&:focus-visible': {
            boxShadow: 'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
            outline: 'none',
        },
    },
});
module.exports = ButtonBaseClasses;

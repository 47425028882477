import { getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
export const getExtraPrice = ({ selectedExtra, selectedInterval, }) => {
    const selectedExtraData = selectedInterval.extras.find((e) => {
        return e._id.toString() === selectedExtra.extraId.toString();
    });
    const selectedExtraPrice = getPeriodPropertyValueForDate({
        date: selectedInterval.date,
        periodSensitiveProperty: selectedExtraData.price,
    });
    return (selectedExtraPrice.value *
        (selectedExtraPrice.shouldScaleWithNrOfPersons
            ? selectedInterval.numberOfPersons
            : 1) *
        (selectedExtraPrice.shouldScaleWithNrOfUnits ? selectedExtra.amount : 1));
};

import colors from 'tailwindcss/colors';
import { BrandColors } from './types/enums';
export const zIndexes = {
    CREATE_EDIT_PAGE_CANCEL_BUTTON_CONTAINER: 'z-30',
    BOTTOM_SHEET: 'z-15',
    HEADER_WRAPPER: 'z-20',
    BOTTOM_DRAWER_PRICE_AND_BUTTONS_CONTAINER: 'z-20',
    PROFILE_SELECTOR_CONTENT: 'z-20',
    LANGUAGE_SELECTOR_CONTENT: 'z-20',
    DRAWER_BACKDROP: 'z-25',
    DRAWER: 'z-30',
    HTML_CONTENT_MODAL: 'z-35',
    TEXT_MODAL: 'z-35',
    DRAWER_CONTENT: 'z-35',
    RESTRICTION_ERROR_MODAL: 'z-40',
    CONFLICTING_INTERVALS_MODAL: 'z-40',
    GENERATE_REPORT_CALENDAR_WRAPPER: 'z-40',
    CONFIRM_LEAVE_MODAL: 'z-45',
    HAMBURGER: 'z-50',
    INTERVALS_MODAL: 'z-60',
    SERVER_SIDE_ERROR_MODAL: 'z-70',
    FORCE_REFRESH_MODAL: 'z-100',
    COOKIE_BANNER_MODAL: 'z-240',
};
const getBrandGrayPalette = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return colors.gray;
        case BrandColors.BLUE:
            return colors.gray;
        case BrandColors.GREEN:
            return colors.gray;
        default:
            return colors.gray;
    }
};
const getGrayPalette = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return colors.gray;
        case BrandColors.BLUE:
            return colors.gray;
        case BrandColors.GREEN:
            return colors.gray;
        default:
            return colors.gray;
    }
};
const getPrimaryPalete = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return Object.assign(Object.assign({}, colors.violet), { main: colors.violet[600], hover: colors.violet[500], light: colors.violet[200] });
        case BrandColors.BLUE:
            return Object.assign(Object.assign({}, colors.blue), { main: colors.blue[600], hover: colors.blue[500], light: colors.blue[200] });
        case BrandColors.GREEN:
            return Object.assign(Object.assign({}, colors.green), { main: colors.green[600], hover: colors.green[500], light: colors.green[200] });
        default:
            return Object.assign(Object.assign({}, colors.blue), { main: colors.blue[600], hover: colors.blue[500], light: colors.blue[200] });
    }
};
const getSuccessPalete = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return Object.assign(Object.assign({}, colors.green), { main: colors.green[600], hover: colors.green[500], light: colors.green[200] });
        case BrandColors.BLUE:
            return Object.assign(Object.assign({}, colors.green), { main: colors.green[600], hover: colors.green[500], light: colors.green[200] });
        case BrandColors.GREEN:
            return Object.assign(Object.assign({}, colors.green), { main: colors.green[600], hover: colors.green[500], light: colors.green[200] });
        default:
            return Object.assign(Object.assign({}, colors.green), { main: colors.green[600], hover: colors.green[500], light: colors.green[200] });
    }
};
const getErrorPalete = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return Object.assign(Object.assign({}, colors.red), { main: colors.red[600], hover: colors.red[500], light: colors.red[200] });
        case BrandColors.BLUE:
            return Object.assign(Object.assign({}, colors.red), { main: colors.red[600], hover: colors.red[500], light: colors.red[200] });
        case BrandColors.GREEN:
            return Object.assign(Object.assign({}, colors.red), { main: colors.red[600], hover: colors.red[500], light: colors.red[200] });
        default:
            return Object.assign(Object.assign({}, colors.red), { main: colors.red[600], hover: colors.red[500], light: colors.red[200] });
    }
};
const getWarningPalete = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return Object.assign(Object.assign({}, colors.amber), { main: colors.amber[600], hover: colors.amber[500], light: colors.amber[200] });
        case BrandColors.BLUE:
            return Object.assign(Object.assign({}, colors.amber), { main: colors.amber[600], hover: colors.amber[500], light: colors.amber[200] });
        case BrandColors.GREEN:
            return Object.assign(Object.assign({}, colors.amber), { main: colors.amber[600], hover: colors.amber[500], light: colors.amber[200] });
        default:
            return Object.assign(Object.assign({}, colors.amber), { main: colors.amber[600], hover: colors.amber[500], light: colors.amber[200] });
    }
};
const getInfoPalete = () => {
    switch (process.env.BRAND_COLOR) {
        case BrandColors.PURPLE:
            return Object.assign(Object.assign({}, colors.cyan), { main: colors.cyan[600], hover: colors.cyan[500], light: colors.cyan[200] });
        case BrandColors.BLUE:
            return Object.assign(Object.assign({}, colors.cyan), { main: colors.cyan[600], hover: colors.cyan[500], light: colors.cyan[200] });
        case BrandColors.GREEN:
            return Object.assign(Object.assign({}, colors.cyan), { main: colors.cyan[600], hover: colors.cyan[500], light: colors.cyan[200] });
        default:
            return Object.assign(Object.assign({}, colors.cyan), { main: colors.cyan[600], hover: colors.cyan[500], light: colors.cyan[200] });
    }
};
export const primaryPalette = getPrimaryPalete();
export const successPalette = getSuccessPalete();
export const warningPalette = getWarningPalete();
export const errorPalette = getErrorPalete();
export const infoPalette = getInfoPalete();
export const grayPalette = getGrayPalette();
export const brandGrayPalette = getBrandGrayPalette();
export const tailwindConfig = {
    screens: {
        420: '420px',
        490: '490px',
        560: '560px',
        640: '640px',
        768: '768px',
        800: '800px',
        860: '860px',
        900: '900px',
        930: '930px',
        960: '960px',
        1024: '1024px',
        1140: '1140px',
        1200: '1200px',
        1280: '1280px',
        1410: '1410px',
        1536: '1536px',
    },
    safelist: [
        'prose',
        'section-link',
        'full-bleed',
        'text-success-main',
        'text-green-600',
        'underline',
        '1280:mt-0',
        'step-header',
        'bg-gray-800',
        'bg-gray-300',
        'bg-white',
    ],
    spacing: {
        'safe-top': 'env(safe-area-inset-top)',
        'safe-bottom': 'env(safe-area-inset-bottom)',
        'safe-left': 'env(safe-area-inset-left)',
        'safe-right': 'env(safe-area-inset-right)',
    },
    container: (theme) => ({
        padding: {
            DEFAULT: theme('spacing.3'),
            420: theme('spacing.3'),
            490: theme('spacing.3'),
            640: theme('spacing.6'),
            768: theme('spacing.6'),
            800: theme('spacing.6'),
            900: theme('spacing.6'),
            960: theme('spacing.6'),
            1024: theme('spacing.6'),
            1140: theme('spacing.6'),
            1280: theme('spacing.8'),
            1410: theme('spacing.10'),
            1536: theme('spacing.10'),
        },
    }),
    colors: {
        inherit: colors.inherit,
        current: colors.current,
        transparent: colors.transparent,
        black: colors.black,
        white: colors.white,
        slate: colors.slate,
        zinc: colors.zinc,
        neutral: colors.neutral,
        stone: colors.stone,
        red: colors.red,
        orange: colors.orange,
        yellow: colors.amber,
        lime: colors.lime,
        green: colors.emerald,
        teal: colors.teal,
        cyan: colors.cyan,
        sky: colors.sky,
        blue: colors.blue,
        indigo: colors.indigo,
        violet: colors.violet,
        purple: colors.purple,
        fuchsia: colors.fuchsia,
        pink: colors.pink,
        rose: colors.rose,
        gray: grayPalette,
        bgray: brandGrayPalette,
        primary: primaryPalette,
        success: successPalette,
        error: errorPalette,
        warning: warningPalette,
        info: infoPalette,
    },
    zIndex: {
        auto: 'auto',
        0: '0',
        10: '10',
        15: '15',
        20: '20',
        25: '25',
        30: '30',
        35: '35',
        40: '40',
        45: '45',
        50: '50',
        55: '55',
        60: '60',
        65: '65',
        70: '70',
        75: '75',
        80: '80',
        85: '85',
        90: '90',
        95: '95',
        100: '100',
        110: '110',
        120: '120',
        130: '130',
        140: '140',
        150: '150',
        160: '160',
        170: '170',
        180: '180',
        190: '190',
        200: '200',
        210: '210',
        220: '220',
        230: '230',
        240: '240',
        250: '250',
        260: '260',
        270: '270',
        280: '280',
        290: '290',
        300: '300',
    },
    flexGrow: {
        0: '0',
        DEFAULT: '1',
        2: '2',
    },
    extend: {
        components: ({ theme }) => ({
            button: {
                sizes: {
                    sm: {
                        height: '38px',
                    },
                    md: {
                        px: theme('spacing.5'),
                        fontSize: theme('fontSize.base'),
                        height: '40px',
                    },
                    lg: {
                        height: '42px',
                    },
                },
                primary: {
                    filled: {
                        default: {
                            bg: theme('colors.primary.main'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.primary.hover'),
                            textColor: theme('colors.white'),
                        },
                        disabled: {
                            opacity: theme('opacity.50'),
                        },
                        pressed: {
                            bg: theme('colors.primary.main'),
                            textColor: theme('colors.white'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.primary.main'),
                            border: `1px solid ${theme('colors.primary.main')}`,
                        },
                        hovered: {
                            bg: theme('colors.primary.100'),
                            textColor: theme('colors.primary.900'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                            bg: theme('colors.gray.100'),
                        },
                        pressed: {
                            bg: theme('colors.primary.400'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.primary.main'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.primary.100'),
                            textColor: theme('colors.primary.900'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.primary.100'),
                        },
                    },
                },
                neutral: {
                    focusRing: theme('colors.gray.500'),
                    filled: {
                        default: {
                            bg: theme('colors.gray.300'),
                            textColor: theme('colors.gray.900'),
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.gray.900'),
                            border: `1px solid ${grayPalette[600]}`,
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                            border: `1px solid ${grayPalette[900]}`,
                        },
                        disabled: {
                            opacity: theme('opacity.60'),
                            bg: theme('colors.gray.100'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                            border: `1px solid ${grayPalette[900]}`,
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.gray.900'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.gray.200'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.gray.300'),
                        },
                    },
                },
                warning: {
                    focusRing: theme('colors.yellow.300'),
                    filled: {
                        default: {
                            bg: theme('colors.yellow.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.yellow.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.yellow.700'),
                            border: `1px solid ${theme('colors.yellow.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.yellow.100'),
                            textColor: theme('colors.yellow.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.200'),
                            textColor: theme('colors.yellow.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.yellow.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.yellow.100'),
                            textColor: theme('colors.yellow.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.yellow.200'),
                        },
                    },
                },
                danger: {
                    focusRing: theme('colors.red.300'),
                    filled: {
                        default: {
                            bg: theme('colors.red.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.red.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.red.700'),
                            border: `1px solid ${theme('colors.red.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.red.100'),
                            textColor: theme('colors.red.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.200'),
                            textColor: theme('colors.red.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.red.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.red.100'),
                            textColor: theme('colors.red.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.red.200'),
                        },
                    },
                },
                success: {
                    focusRing: theme('colors.green.300'),
                    filled: {
                        default: {
                            bg: theme('colors.green.600'),
                            textColor: theme('colors.white'),
                        },
                        hovered: {
                            bg: theme('colors.green.500'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.600'),
                        },
                    },
                    outline: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.green.700'),
                            border: `1px solid ${theme('colors.green.600')}`,
                        },
                        hovered: {
                            bg: theme('colors.green.100'),
                            textColor: theme('colors.green.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.200'),
                            textColor: theme('colors.green.800'),
                        },
                    },
                    ghost: {
                        default: {
                            bg: theme('colors.transparent'),
                            textColor: theme('colors.green.700'),
                            boxShadow: 'none',
                        },
                        hovered: {
                            bg: theme('colors.green.100'),
                            textColor: theme('colors.green.800'),
                        },
                        disabled: {
                            opacity: theme('opacity.70'),
                        },
                        pressed: {
                            bg: theme('colors.green.200'),
                        },
                    },
                },
            },
        }),
    },
};

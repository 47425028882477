import React from 'react';
import classnames from 'classnames';
import { Button } from './button';
import { getServerErrorMessage } from '../helpers/get-server-error-message';
import { useGetTranslation } from '../translations-store';
import { isDevEnv } from '../helpers/is-dev-env';
import { Icon } from './icon';
export const ErrorCard = ({ errorMessage, onRetryClick }) => {
    const getTranslation = useGetTranslation();
    return (React.createElement("div", { className: "flex bg-gray-50 justify-center items-center w-full" },
        React.createElement("div", { className: "flex justify-center items-center flex-col bg-white w-full py-4 rounded shadow-md mx-6 max-w-sm px-4 768:max-w-md 768:py-8" },
            React.createElement(Icon, { icon: "errorWithCircle", className: "w-20 h-20 text-error-500 mb-4" }),
            React.createElement("p", { className: "text-error-main font-semibold 768:text-lg mb-4" }, getTranslation('anErrorHasOccurred', {}, {
                en: 'An error has occurred',
                hu: 'Hiba történt',
                ro: 'A apărut o eroare',
            })),
            React.createElement("p", { className: "text-sm text-gray-700 font-semibold mb-4 768:text-base text-center" }, !isDevEnv &&
                getTranslation('errorMessage', {}, {
                    en: 'We have sent the details of this error to the engineers of this website. In the meantime, you can try again.',
                    hu: 'A hiba részleteit elküldtük a weboldal fejlesztőinek. Addig is próbálkozhatsz újra.',
                    ro: 'Am trimis detaliile acestei erori inginerilor acestui site. Între timp, poți să încerci din nou.',
                })),
            React.createElement("p", { className: "text-sm text-gray-700 mb-4 768:text-base text-center" },
                React.createElement("span", { className: "font-semibold" },
                    getTranslation('message', {}, {
                        en: 'Message',
                        hu: 'Üzenet',
                        ro: 'Mesaj',
                    }),
                    ":",
                    ' '),
                getServerErrorMessage(errorMessage, getTranslation)),
            React.createElement(Button, { color: "danger", className: "error-card btn-neutral-filled", onClick: () => {
                    if (onRetryClick) {
                        onRetryClick();
                    }
                    else {
                        location.reload();
                    }
                } }, getTranslation('retry', {}, { en: 'Retry', hu: 'Próbáld újra', ro: 'Reîncercare' })))));
};
export const ErrorPage = ({ containerClassName, errorMessage, onRetryClick, }) => {
    return (React.createElement("div", { className: classnames('fixed bottom-0 top-0 left-0 right-0 flex items-center justify-center bg-gray-50', containerClassName) },
        React.createElement(ErrorCard, { errorMessage: errorMessage, onRetryClick: onRetryClick })));
};

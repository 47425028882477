import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, zIndexes, IconKey } from '@reservation-app/common-used-in-web';
import LanguageChangeLink from '../language-change-link';
import { usePageContext } from '../../contexts/page-context';
import { useLanguageContext } from '../../contexts/language-context';

type LanguageSelectorProps = {
  buttonClassName?: string;
  iconClassName?: string;
};

export const LanguageSelector = ({
  buttonClassName,
  iconClassName,
}: LanguageSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    placement: 'bottom',
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        popperElement &&
        !popperElement.contains(event.target) &&
        referenceElement &&
        !referenceElement.contains(event.target)
      ) {
        if (isOpen && setIsOpen) {
          setIsOpen(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen, popperElement, referenceElement]);

  const { activeLanguage, languages } = useLanguageContext();

  const pageContext = usePageContext();

  return (
    <>
      <button
        ref={setReferenceElement}
        className={classNames(
          'flex h-10 justify-between items-center text-gray-900 bg-transparent py-2 rounded-md cursor-pointer ',
          buttonClassName,
        )}
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
        aria-label="language-selector"
      >
        <Icon
          icon={activeLanguage as unknown as IconKey}
          className="w-5 h-5 mr-2"
        />
        <Icon
          icon="chevronDown"
          className={classNames('ml-auto w-4 h-4 text-gray-900', iconClassName)}
        />
      </button>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            className={classNames('', zIndexes['LANGUAGE_SELECTOR_CONTENT'])}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <motion.div
              className="bg-white text-gray-900 rounded-md shadow-xl p-3 "
              style={{ transformOrigin: 'top center' }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ type: 'spring', damping: 20, stiffness: 250 }}
            >
              <div className="flex flex-col overflow-y-auto max-h-72">
                {languages.length > 1 &&
                  languages.map((language) => {
                    const isActive = language === activeLanguage;

                    return (
                      <LanguageChangeLink
                        key={language}
                        onClick={() => setIsOpen(false)}
                        pageUrl={pageContext.pageUrl}
                        language={language}
                        className={classNames(
                          'hover:text-primary-400 transition-colors duration-300 font-semibold text-gray-800 p-1',
                          {
                            '!text-primary-main font-semibold': isActive,
                          },
                        )}
                        withFlag
                        withTranslation
                      />
                    );
                  })}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { PromotionTypes, useTranslationsStore, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
import { useStepperStateSlice, useCompanyReservationConfig } from '../../store';
export const PromotionCard = ({ promotion: { value, type, uiProps, startDate, endDate, internalName }, isActive, isLast, }) => {
    const { activeLanguage, getTranslation } = useTranslationsStore();
    const { defaultDate } = useStepperStateSlice();
    const { currency } = useCompanyReservationConfig();
    const name = getPeriodPropertyValueForDate({
        periodSensitiveProperty: uiProps.name,
        date: defaultDate,
    });
    const descriptionHtmlValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: uiProps.descriptionHtml,
        date: defaultDate,
    });
    return (React.createElement("div", { "data-test-id": internalName, className: classNames('border-l-8 border-green-600 rounded-md mb-3 768:mb-6 p-4 shadow-md max-w-sm', {
            'bg-white': !isActive,
            'bg-success-50': isActive,
            '!mb-0': isLast,
        }) },
        React.createElement("div", { className: "flex justify-between items-center mb-3" },
            name[activeLanguage] ? (React.createElement("p", { className: "text-success-main text font-semibold" }, name[activeLanguage])) : (React.createElement("p", { className: "text-success-main text font-semibold uppercase" },
                type === PromotionTypes.FIXED
                    ? ` ${getFormattedPrice(value, currency)}`
                    : `${value}%`,
                ' ',
                getTranslation('discount'))),
            isActive && (React.createElement("p", { className: 'text-xs px-2 py-1 rounded-md font-semibold bg-success-200' }, getTranslation('active')))),
        (descriptionHtmlValue === null || descriptionHtmlValue === void 0 ? void 0 : descriptionHtmlValue[activeLanguage]) && (React.createElement("div", { className: "mb-2", dangerouslySetInnerHTML: {
                __html: descriptionHtmlValue[activeLanguage],
            } })),
        startDate && (React.createElement("p", { className: "text-xs text-gray-500 " },
            getTranslation('valid'),
            ": ",
            format(new Date(startDate), 'dd.MM.yyyy'),
            ' ',
            "- ",
            endDate ? format(new Date(endDate), 'dd.MM.yyyy') : undefined))));
};

import React from 'react';
import classNames from 'classnames';
export const Label = ({ secondaryText, className, withRequiredIndicator, children, errorText, htmlFor, as = 'label', }) => {
    const wrapperProps = {
        className: classNames('label', className),
    };
    if (as === 'span') {
        return (React.createElement("span", Object.assign({}, wrapperProps),
            children,
            withRequiredIndicator && (React.createElement("span", { className: "label-with-required-indicator" }, "*")),
            secondaryText && (React.createElement("span", { className: classNames('label-secondary-text', {
                    'label-with-indicator-margin': withRequiredIndicator,
                    'label-without-indicator-margin': !withRequiredIndicator,
                }) }, secondaryText)),
            errorText && React.createElement("span", { className: "label-error-text" },
                "- ",
                errorText)));
    }
    return (React.createElement("label", Object.assign({ htmlFor: htmlFor }, wrapperProps),
        children,
        withRequiredIndicator && (React.createElement("span", { className: "label-with-required-indicator" }, "*")),
        secondaryText && (React.createElement("span", { className: classNames('label-secondary-text', {
                'label-with-indicator-margin': withRequiredIndicator,
                'label-without-indicator-margin': !withRequiredIndicator,
            }) }, secondaryText)),
        errorText && React.createElement("span", { className: "label-error-text" },
            "- ",
            errorText)));
};

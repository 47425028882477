import React, { useMemo } from 'react';
import { minutesToHoursMap, Alert, AlertTypes, Icon, jsDayIndexToDayMap, isLastIndex, FadeInAnimation, getDisplayTime, } from '../../';
import classNames from 'classnames';
export const ScheduleSection = ({ section, translations, activeLanguage, alerts, schedules, shouldDisableAnimation, }) => {
    const { textAboveHtml, textBelowHtml, backgroundColor, schedules: sectionSchedules, metadata, } = section;
    const populatedSchedules = useMemo(() => sectionSchedules.map((schedule) => {
        const relevantSchedule = schedules.find((pageContextSchedule) => {
            return pageContextSchedule._id === schedule.scheduleId;
        });
        return Object.assign(Object.assign({}, schedule), { schedule: relevantSchedule });
    }), [sectionSchedules, schedules]);
    const sectionMetadata = useMemo(() => metadata
        ? Object.assign({}, JSON.parse(metadata)) : {}, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "flex flex-col items-center self-center w-full" },
            sectionMetadata.alertPosition === 'top' && (React.createElement(Alert, { alertType: AlertTypes.SCHEDULE, className: "mb-10", alerts: alerts, iconClassName: backgroundColor, isReservationFlow: false })),
            (textAboveHtml === null || textAboveHtml === void 0 ? void 0 : textAboveHtml[activeLanguage]) && (React.createElement("div", { className: "max-w-md mb-10 text-center text-gray-900 860:max-w-lg", dangerouslySetInnerHTML: {
                    __html: textAboveHtml[activeLanguage],
                } }))),
        populatedSchedules.map((populatedSchedule, i) => {
            var _a, _b, _c;
            return (React.createElement("div", { key: populatedSchedule.scheduleId + i, className: classNames('flex flex-col items-center mb-12', {
                    'mb-0': isLastIndex(i, populatedSchedules),
                }) },
                React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "flex flex-col items-center text-center" },
                    ((_a = populatedSchedule.title) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("div", { className: classNames('font-semibold text-xl mb-1', {
                            'mb-5': (_b = populatedSchedule.bodyText) === null || _b === void 0 ? void 0 : _b[activeLanguage],
                        }), dangerouslySetInnerHTML: {
                            __html: populatedSchedule.title[activeLanguage],
                        } })),
                    ((_c = populatedSchedule.bodyText) === null || _c === void 0 ? void 0 : _c[activeLanguage]) && (React.createElement("div", { className: "mb-5 text-gray-900", dangerouslySetInnerHTML: {
                            __html: populatedSchedule.bodyText[activeLanguage],
                        } }))),
                React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "mb-10 rounded-lg shadow-lg" },
                    React.createElement("div", { className: classNames('flex flex-col 860:flex-row py-5 px-5 rounded-lg 860:px-0', {
                            [sectionMetadata.scheduleCardsBg || '']: !!sectionMetadata.scheduleCardsBg,
                            'bg-white': !sectionMetadata.scheduleCardsBg,
                        }) }, Array.from({ length: 5 }, (_, i) => i + 1).map((dayIndex) => (React.createElement(DaySchedule, { key: dayIndex, dayIndex: dayIndex, lastDayIndex: 5, schedule: populatedSchedule.schedule, translations: translations, activeLanguage: activeLanguage }))))),
                React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "rounded-lg shadow-lg" },
                    React.createElement("div", { className: classNames('flex flex-col 860:flex-row py-5 px-5 rounded-lg 860:px-0', {
                            [sectionMetadata.scheduleCardsBg || '']: !!sectionMetadata.scheduleCardsBg,
                            'bg-white': !sectionMetadata.scheduleCardsBg,
                        }) }, [6, 0].map((dayIndex) => (React.createElement(DaySchedule, { key: dayIndex, dayIndex: dayIndex, lastDayIndex: 0, schedule: populatedSchedule.schedule, translations: translations, activeLanguage: activeLanguage })))))));
        }),
        React.createElement(FadeInAnimation, { className: "flex flex-col items-center" },
            (textBelowHtml === null || textBelowHtml === void 0 ? void 0 : textBelowHtml[activeLanguage]) && (React.createElement("div", { className: "max-w-md mt-10 mb-10 text-center text-gray-900 860:max-w-lg", dangerouslySetInnerHTML: {
                    __html: textBelowHtml[activeLanguage],
                } })),
            sectionMetadata.alertPosition === 'bottom' && (React.createElement(Alert, { alertType: AlertTypes.SCHEDULE, className: "mt-10", alerts: alerts, iconClassName: backgroundColor, isReservationFlow: false })))));
};
const DaySchedule = ({ dayIndex, schedule, lastDayIndex, translations, activeLanguage, }) => {
    const mergedIntervals = useMemo(() => [
        ...schedule[jsDayIndexToDayMap[dayIndex]].openIntervals,
        ...schedule[jsDayIndexToDayMap[dayIndex]].showAsClosedIntervals.map((int) => {
            return Object.assign(Object.assign({}, int), { isClosed: true });
        }),
    ].sort((a, b) => a.startTime - b.startTime), [schedule]);
    const isAllClosed = mergedIntervals.every((int) => int.isClosed === true);
    const isWeekendDay = dayIndex === 0 || dayIndex === 6;
    const desktopDaySchedule = useMemo(() => {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames('hidden 860:flex flex-col border-r-2 border-gray-300 px-5 py-0 w-[156px]', {
                    'border-none': dayIndex === lastDayIndex,
                }) },
                React.createElement("p", { className: classNames('mb-5 text-center', {
                        'text-gray-500': isAllClosed,
                        'text-primary-main': !isAllClosed,
                    }) }, (_a = translations[jsDayIndexToDayMap[dayIndex]]) === null || _a === void 0 ? void 0 : _a[activeLanguage]),
                mergedIntervals.map((interval, index) => {
                    var _a;
                    const displayTime = getDisplayTime({
                        interval,
                        activeLanguage,
                    });
                    if (!interval.isClosed) {
                        return (React.createElement("div", { className: classNames('flex items-center mb-3', {
                                'justify-center': !interval.icon,
                            }), key: index },
                            interval.icon && (React.createElement(Icon, { icon: interval.icon, className: "w-4 h-4 mr-2" })),
                            React.createElement("p", { className: "text-sm tracking-tighter text-gray-900 whitespace-nowrap" }, displayTime)));
                    }
                    else {
                        return (React.createElement("div", { className: "flex items-center mb-3 text-gray-600", key: index },
                            interval.icon && (React.createElement(Icon, { icon: interval.icon, className: "w-4 h-4 mr-2" })),
                            React.createElement("p", { className: "text-sm" }, (_a = translations.closed) === null || _a === void 0 ? void 0 : _a[activeLanguage])));
                    }
                }))));
    }, [
        mergedIntervals,
        translations,
        activeLanguage,
        dayIndex,
        minutesToHoursMap,
        isWeekendDay,
        lastDayIndex,
    ]);
    const mobileDaySchedule = useMemo(() => {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames('flex 860:hidden border-gray-300', {
                    'border-none': dayIndex === lastDayIndex,
                    'pb-3 mb-3 border-b-2': dayIndex !== lastDayIndex,
                }) },
                React.createElement("p", { className: classNames('w-[120px]', {
                        'text-gray-500': isAllClosed,
                        'text-primary-main': !isAllClosed,
                    }) }, (_a = translations[jsDayIndexToDayMap[dayIndex]]) === null || _a === void 0 ? void 0 : _a[activeLanguage]),
                React.createElement("div", { className: "flex flex-col" }, mergedIntervals.map((interval, index) => {
                    var _a;
                    const displayTime = getDisplayTime({
                        interval,
                        activeLanguage,
                    });
                    if (!interval.isClosed) {
                        return (React.createElement("div", { className: classNames('flex items-center', {
                                'justify-center': !interval.icon,
                                'mb-3': !isLastIndex(index, mergedIntervals),
                            }), key: index },
                            interval.icon && (React.createElement(Icon, { icon: interval.icon, className: "w-4 h-4 mr-2" })),
                            React.createElement("p", { className: "text-sm tracking-tighter text-gray-900 whitespace-nowrap" }, displayTime)));
                    }
                    if (interval.isClosed) {
                        return (React.createElement("div", { className: classNames('flex items-center text-gray-600', {
                                'mb-3': !isLastIndex(index, mergedIntervals),
                            }), key: index },
                            interval.icon && (React.createElement(Icon, { icon: interval.icon, className: "w-4 h-4 mr-2" })),
                            React.createElement("p", { className: "text-sm" }, (_a = translations.closed) === null || _a === void 0 ? void 0 : _a[activeLanguage])));
                    }
                    return null;
                })))));
    }, [
        mergedIntervals,
        translations,
        activeLanguage,
        dayIndex,
        minutesToHoursMap,
        isWeekendDay,
        lastDayIndex,
    ]);
    return (React.createElement(React.Fragment, null,
        mobileDaySchedule,
        desktopDaySchedule));
};

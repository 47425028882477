import { getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import React from 'react';
import { SelectableCard } from '../../common-components/complex/selectable-card';
import { useSelectedValuesSlice, useStepperStateSlice } from '../../store';
export const ServiceCard = ({ service }) => {
    const { selectedService, setSelectedService } = useSelectedValuesSlice();
    const { defaultDate } = useStepperStateSlice();
    const isSelected = service._id === (selectedService === null || selectedService === void 0 ? void 0 : selectedService._id);
    const descriptionHtmlValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.descriptionHtml,
        date: defaultDate,
    });
    const nameValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.name,
        date: defaultDate,
    });
    const imageValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.image,
        date: defaultDate,
    });
    const layoutValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.cardLayout,
        date: defaultDate,
    });
    const iconValue = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.icon,
        date: defaultDate,
    });
    const additionalText = getPeriodPropertyValueForDate({
        periodSensitiveProperty: service.uiProps.additionalText,
        date: defaultDate,
    });
    return (React.createElement(SelectableCard, { icon: iconValue, layout: layoutValue, testId: service._id, name: nameValue, descriptionHtml: descriptionHtmlValue, image: imageValue === null || imageValue === void 0 ? void 0 : imageValue.url, isSelected: isSelected, additionalText: additionalText, onSelect: () => {
            if (isSelected) {
                setSelectedService(null);
            }
            else {
                setSelectedService(service);
            }
        } }));
};

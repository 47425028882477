import { areIntervalsTheSame, areTimeIntervalsTheSame, } from '../../helpers/are-intervals-the-same';
import { getDayFromDate } from '../../helpers/get-day-from-date';
import { getEntityCapacity } from '../../helpers/get-entity-capacity';
import { getPeriodPropertyValuesForDate, DEFAULT_SYNC_GROUP_KEY, getPeriodPropertyValueForDate, } from '@reservation-app/common-used-in-web';
import { getSelectedIntervalNumberOfPersons } from '../../helpers/get-selected-interval-number-of-persons';
import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const createSelectedValuesSlice = (set, _get) => ({
    selectedEntityType: null,
    setSelectedEntityType: (selectedEntityType, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedEntityType = selectedEntityType;
    }, false, getDevtoolsLogMessage('setSelectedEntityType', extraDevtoolsInfo)),
    selectedService: null,
    setSelectedService: (selectedService, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedService = selectedService;
    }, false, getDevtoolsLogMessage('setSelectedService', extraDevtoolsInfo)),
    selectedSyncGroupKey: DEFAULT_SYNC_GROUP_KEY,
    setSelectedSyncGroupKey: (selectedSyncGroupKey, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedSyncGroupKey = selectedSyncGroupKey;
    }, false, getDevtoolsLogMessage('setSelectedSyncGroupKey', extraDevtoolsInfo)),
    selectedEntity: null,
    setSelectedEntity: (selectedEntity, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedEntity = selectedEntity;
    }, false, getDevtoolsLogMessage('setSelectedEntity', extraDevtoolsInfo)),
    selectedIntervals: [],
    setSelectedIntervals: (selectedIntervals, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedIntervals = selectedIntervals;
    }, false, getDevtoolsLogMessage('setSelectedIntervals', extraDevtoolsInfo)),
    addSelectedIntervals: (intervalsToBeAdded, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedIntervals.push(...intervalsToBeAdded);
    }, false, getDevtoolsLogMessage('removeSelectedIntervals', extraDevtoolsInfo)),
    removeSelectedIntervals: (intervalsToBeRemoved, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedIntervals =
            state.selectedValues.selectedIntervals.filter((selectedInterval) => !intervalsToBeRemoved.some((intervalToBeRemoved) => areIntervalsTheSame(selectedInterval, intervalToBeRemoved)));
    }, false, getDevtoolsLogMessage('removeSelectedIntervals', extraDevtoolsInfo)),
    selectedNrOfPersons: 1,
    setSelectedNrOfPersons: (selectedNrOfPersons, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedNrOfPersons = selectedNrOfPersons;
    }, false, getDevtoolsLogMessage('setSelectedNrOfPersons', extraDevtoolsInfo)),
    selectedBulkNumberOfPersons: null,
    setSelectedBulkNumberOfPersons: (selectedBulkNumberOfPersons, extraDevtoolsInfo) => set((state) => {
        state.selectedValues.selectedBulkNumberOfPersons =
            selectedBulkNumberOfPersons;
    }, false, getDevtoolsLogMessage('setSelectedBulkNumberOfPersons', extraDevtoolsInfo)),
    handleBulkNumberOfPersonsChange: (value, extraDevtoolsInfo) => set((state) => {
        if (state.selectedValues.selectedBulkNumberOfPersons &&
            state.selectedValues.selectedIntervals.length > 0) {
            state.selectedValues.selectedIntervals.forEach((selectedInterval) => {
                selectedInterval.numberOfPersons =
                    getSelectedIntervalNumberOfPersons({
                        syncGroupEntity: state.selectedValues.selectedEntity,
                        selectedBulkNumberOfPersons: value,
                        selectedDate: state.periodAndEntityStepSpecific.selectedDate,
                        selectedService: state.selectedValues.selectedService,
                        setSelectedBulkNumberOfPersons: state.selectedValues.setSelectedBulkNumberOfPersons,
                    });
            });
        }
        state.selectedValues.selectedBulkNumberOfPersons = value;
    }, false, getDevtoolsLogMessage('handleBulkNumberOfPersonsChange', extraDevtoolsInfo)),
    handleSelectEntity: (selectedEntity, extraDevtoolsInfo) => set((state) => {
        var _a;
        const isSelected = ((_a = state.selectedValues.selectedEntity) === null || _a === void 0 ? void 0 : _a._id) === selectedEntity._id;
        if (isSelected) {
            state.selectedValues.selectedEntity = null;
            return;
        }
        if (!isSelected) {
            const smallestNumberOfPersonsSelectOption = selectedEntity.withBulkNumberOfPersonsSelect
                ? getPeriodPropertyValueForDate({
                    date: state.stepperState.defaultDate,
                    periodSensitiveProperty: selectedEntity.numberOfPersonsSelectOptions,
                })[0]
                : getEntityCapacity({
                    entity: selectedEntity,
                    selectedIntervals: state.selectedValues.selectedIntervals,
                });
            state.selectedValues.selectedEntity = selectedEntity;
            state.selectedValues.selectedIntervals.forEach((selectedInterval) => {
                selectedInterval.numberOfPersons =
                    smallestNumberOfPersonsSelectOption;
                const relevantInterval = getPeriodPropertyValuesForDate({
                    date: selectedInterval.date,
                    periodSensitiveProperty: state.selectedValues.selectedService.reservableIntervals,
                }).find((reservableInterval) => reservableInterval.entitiesIds.some((id) => id === selectedEntity._id) &&
                    reservableInterval.days.some(({ value: day }) => day === getDayFromDate(selectedInterval.date)) &&
                    areTimeIntervalsTheSame(Object.assign({}, reservableInterval.timeInterval), selectedInterval));
                selectedInterval.reservableInterval = relevantInterval;
                selectedInterval.reservableIntervalId = relevantInterval._id;
            });
            if (selectedEntity.withBulkNumberOfPersonsSelect) {
                state.selectedValues.selectedBulkNumberOfPersons =
                    smallestNumberOfPersonsSelectOption;
            }
        }
    }, false, getDevtoolsLogMessage('handleSelectEntity', extraDevtoolsInfo)),
});

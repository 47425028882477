import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import { StepWrapper } from '../../layout/step-wrapper';
import { Elements } from '@stripe/react-stripe-js';
import { DebitCard } from './debit-card';
import classNames from 'classnames';
import { useStepperStateSlice, usePaymentDetailsStepSpecificSlice, useSelectedValuesSlice, useCompanyReservationConfig, } from '../../store';
import { Label, Languages, ReservationSteps, useActiveLanguage, useGetTranslation, } from '@reservation-app/common-used-in-web';
import { getFormattedPrice } from '../../helpers/get-formatted-price';
const cardStyle = {
    base: {
        color: '#18181b',
        backgroundColor: 'white',
        fontFamily: "'Inter', sans-serif",
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#a1a1aa',
        },
    },
};
const PaymentDetailsWithStripe = () => {
    const getTranslation = useGetTranslation();
    const language = useActiveLanguage();
    const { currency } = useCompanyReservationConfig();
    const { setValidationError, setIsAnyInputFocused, price } = useStepperStateSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const { setHandlePayment, paymentIntentClientSecret } = usePaymentDetailsStepSpecificSlice();
    const stripeClient = useStripe();
    const stripeElements = useElements();
    useEffect(() => {
        setHandlePayment(() => stripeClient.confirmCardPayment(paymentIntentClientSecret, {
            payment_method: {
                card: stripeElements.getElement(CardNumberElement),
            },
        }));
    }, []);
    const [focusedElement, setFocusedElement] = useState('');
    const handleChange = (event) => {
        if (event.error) {
            if (event.error.code === 'invalid_expiry_month_past') {
                setValidationError(getTranslation('invalidExpiryMonthPast'));
            }
            else if (event.error.code === 'incomplete_expiry') {
                setValidationError(getTranslation('incompleteExpiry'));
            }
            else if (event.error.code === 'incomplete_number') {
                setValidationError(getTranslation('incompleteNumber'));
            }
            else if (event.error.code === 'invalid_number') {
                setValidationError(getTranslation('invalidNumber'));
            }
            else if (event.error.code === 'incomplete_cvc') {
                setValidationError(getTranslation('incompleteCvc'));
            }
            else {
                setValidationError(event.error.message);
            }
        }
        else {
            setValidationError('');
        }
        setHandlePayment(() => stripeClient.confirmCardPayment(paymentIntentClientSecret, {
            payment_method: {
                card: stripeElements.getElement(CardNumberElement),
            },
        }));
    };
    const handleFocus = (event) => {
        if (event.elementType === 'cardNumber') {
            setFocusedElement('number');
        }
        else if (event.elementType === 'cardCvc') {
            setFocusedElement('cvc');
        }
        else if (event.elementType === 'cardExpiry') {
            setFocusedElement('expiry');
        }
        else {
            setFocusedElement('name');
        }
    };
    return (React.createElement(StepWrapper, { step: ReservationSteps.PAYMENT_DETAILS, wrapperClassName: "!pb-[420px] 768:!pb-0" },
        React.createElement("div", { className: "flex flex-col items-start 768:justify-between" },
            React.createElement("div", { className: "mb-5" },
                React.createElement(DebitCard, { focusedElement: focusedElement })),
            React.createElement("div", { className: "w-full 768:max-w-xs 1410:max-w-md" },
                React.createElement("div", { className: "flex flex-col mb-3", "data-test-id": "card-number" },
                    React.createElement(Label, { className: "text-sm font-semibold text-gray-900" }, getTranslation('cardNumber')),
                    React.createElement(CardNumberElement, { options: { placeholder: '4242 4242 4242 4242', style: cardStyle }, className: classNames('payment-details-card-number-element px-2 py-3 bg-white border border-gray-400 rounded-md', {
                            'ring-2 ring-primary-main': focusedElement === 'number',
                        }), onChange: (event) => {
                            handleChange(event);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setFocusedElement('');
                        }, onFocus: (event) => {
                            setIsAnyInputFocused(true);
                            handleFocus(event);
                        } })),
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "flex flex-col w-1/2 mr-2", "data-test-id": "card-expiry" },
                        React.createElement(Label, { className: "text-sm font-semibold text-gray-900" }, getTranslation('expirationDate')),
                        React.createElement(CardExpiryElement, { options: { placeholder: '03/24', style: cardStyle }, className: classNames('payment-details-card-expiry-element px-2 py-3 bg-white border border-gray-400 rounded-md transition-all ', { 'ring-2 ring-primary-main': focusedElement === 'expiry' }), onBlur: () => {
                                setIsAnyInputFocused(false);
                                setFocusedElement('');
                            }, onChange: handleChange, onFocus: (event) => {
                                setIsAnyInputFocused(true);
                                handleFocus(event);
                            } })),
                    React.createElement("div", { className: "flex flex-col w-1/2 ml-2", "data-test-id": "card-cvc" },
                        React.createElement(Label, { className: "text-sm font-semibold text-gray-900" }, "CVC"),
                        React.createElement(CardCvcElement, { options: { placeholder: '412', style: cardStyle }, className: classNames('payment-details-card-cvc-element px-2 py-3 bg-white border border-gray-400 rounded-md', {
                                'ring-2 ring-primary-main': focusedElement === 'cvc',
                            }), onChange: handleChange, onBlur: () => {
                                setIsAnyInputFocused(false);
                                setFocusedElement('');
                            }, onFocus: (event) => {
                                setIsAnyInputFocused(true);
                                handleFocus(event);
                            } })))),
            selectedEntityType.with50PercentPaymentOnly && (React.createElement("div", { className: "w-full max-w-md mt-6 text-center text-gray-900" },
                language === Languages.RO && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "text-center text-gray-900 768:text-left" },
                        React.createElement("span", { className: "font-semibold" },
                            '⚠️',
                            " Aten\u021Bie:"),
                        " Doar 50% din suma total\u0103",
                        ' ',
                        React.createElement("span", { className: "font-semibold" },
                            "(deci",
                            ' ',
                            getFormattedPrice(Math.ceil(price.totalAmount / 2), currency),
                            ")"),
                        ' ',
                        "trebuie achitat acum online pentru finalizarea rezerv\u0103rii. Restul sumei trebuie achitat la recep\u021Bie pe teritoriul lacului."),
                    selectedEntityType.possibleRefundWithXHoursBefore && (React.createElement("p", { className: "mt-3 text-center text-gray-900 768:text-left" },
                        "Dac\u0103 anulezi rezervarea cu mai mult de",
                        ' ',
                        React.createElement("span", { className: "font-semibold" },
                            selectedEntityType.possibleRefundWithXHoursBefore,
                            " de ore"),
                        ' ',
                        "\u00EEnainte de ziua rezervat\u0103, atunci vom rambursa suma achitat\u0103.")))),
                language === Languages.EN && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "text-center text-gray-900 768:text-left" },
                        React.createElement("span", { className: "font-semibold" },
                            '⚠️',
                            " Attention:"),
                        " You will need to pay for only 50% of the total cost",
                        ' ',
                        React.createElement("span", { className: "font-semibold" },
                            "(so",
                            ' ',
                            getFormattedPrice(Math.ceil(price.totalAmount / 2), currency),
                            ")"),
                        ' ',
                        "now online. For the remaining 50% you will need to pay at the reception when arriving to the lake."),
                    selectedEntityType.possibleRefundWithXHoursBefore && (React.createElement("p", { className: "mt-3 text-center text-gray-900 768:text-left" },
                        "If you cancel your reservation sooner than",
                        React.createElement("span", { className: "font-semibold" }, selectedEntityType.possibleRefundWithXHoursBefore),
                        ' ',
                        "hours before the first reserved day, we will return the charges made.")))),
                language === Languages.HU && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "text-center text-gray-900 768:text-left" },
                        React.createElement("span", { className: "font-semibold" },
                            '⚠️',
                            " Figyelem:"),
                        " Most csak a tejles \u00F6sszeg 50%-\u00E1t kell kifizesd,",
                        ' ',
                        React.createElement("span", { className: "font-semibold" },
                            "teh\u00E1t",
                            ' ',
                            getFormattedPrice(Math.ceil(price.totalAmount / 2), currency),
                            "-t"),
                        ". A marad\u00E9k 50%-ot majd csak a t\u00F3n\u00E1l kell kifizesd."),
                    selectedEntityType.possibleRefundWithXHoursBefore && (React.createElement("p", { className: "mt-3 text-center text-gray-900 768:text-left" },
                        "Ha a foglal\u00E1sod",
                        React.createElement("span", { className: "font-semibold" }, selectedEntityType.possibleRefundWithXHoursBefore),
                        ' ',
                        "\u00F3r\u00E1val a lefoglalt nap el\u0151tt \u00E9rv\u00E9nytelen\u00EDted, akkor visszautaljuk a p\u00E9nzt.")))))))));
};
export const PaymentDetailsStep = () => {
    const activeLanguage = useActiveLanguage();
    const { stripeInstance } = usePaymentDetailsStepSpecificSlice();
    return stripeInstance ? (React.createElement(Elements, { stripe: stripeInstance, options: {
            locale: activeLanguage,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
                },
            ],
        } },
        React.createElement(PaymentDetailsWithStripe, null))) : null;
};

/* eslint-disable no-unused-vars */
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import Layout from './src/components/layout/layout';
import { PageContextContext } from './src/contexts/page-context';

export const wrapPageElement = ({ element, props }) => {
  return (
    <CookiesProvider>
      <PageContextContext.Provider value={props.pageContext}>
        <Layout {...props}>{element}</Layout>
      </PageContextContext.Provider>
    </CookiesProvider>
  );
};

const { rgba } = require('polished');
const SliderBaseClasses = (theme) => ({
    '.slider-main-container': {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.slider-carousel-and-arrow-container': {
        width: '100%',
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.slider-highlight-arrow-container': {
        position: 'absolute',
        top: theme('spacing.0'),
        bottom: theme('spacing.0'),
        zIndex: 15,
        display: 'flex',
        alignItems: 'center',
    },
    '.slider-carousel': {
        position: 'relative',
        alignSelf: 'stretch',
        overflow: 'hidden',
        flexGrow: 1,
        width: '100%',
    },
    '.slider-content': {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    '.slider-slide-without-modal': {
        cursor: 'default',
    },
    '.slider-arrow-container': {
        display: 'flex',
        flexShrink: theme('flexShrink.0'),
        width: theme('spacing.10'),
        height: theme('spacing.10'),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: rgba(theme('colors.white'), Number(theme('ringOpacity.80'))),
        borderRadius: theme('borderRadius.full'),
        cursor: 'pointer',
        transitionProperty: theme('transitionProperty.transform'),
        transitionTimingFunction: theme('transitionTimingFunction.in'),
        transitionDuration: theme('transitionDuration.100'),
    },
    '.slider-arrow-icon': {
        color: theme('colors.white'),
        fontSize: theme('fontSize.xl'),
        lineHeight: theme('lineHeight.7'),
        '&:hover': {
            outline: 'none',
        },
    },
    '.slider-dots-container': {
        marginTop: theme('spacing.3'),
    },
    '.slider-dots': {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        transition: 'all 0.5s ease',
    },
    '.slider-dot-container': {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
    },
    '.slider-dot': {
        width: theme('spacing.3'),
        height: theme('spacing.3'),
        borderRadius: theme('borderRadius.full'),
        flexShrink: 0,
        cursor: 'pointer',
        transition: 'all 0.5s ease',
        borderWidth: theme('borderWidth.DEFAULT'),
        borderColor: theme('colors.gray.400'),
    },
    '.slider-dot-active': {
        backgroundColor: theme('colors.primary.600'),
    },
    '.slider-dot-not-active': {
        backgroundColor: theme('colors.primary.300'),
    },
    '.slider-dot-small': {
        transform: 'scale(0.5)',
    },
    '.slider-with-modal': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.slider-slide': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flex: '1 1 0%',
        transitionProperty: theme('transitionProperty.transform'),
        transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
        transitionDuration: theme('transitionDuration.300'),
    },
    '.slider-slide-highlight-active': {
        transform: 'scaleX(1.5) scaleY(1.5)',
        zIndex: 10,
    },
    '.slider-slide-highlight-not-active': {
        filter: 'brightness(.6)',
        zIndex: 0,
    },
});
module.exports = SliderBaseClasses;

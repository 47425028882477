import React from 'react';
import { ModalWrapper } from './modal-wrapper';
import { zIndexes, Button, useGetTranslation, Modal, } from '@reservation-app/common-used-in-web';
import { useStaticPropsSlice, useModalStatesDataSlice } from '../store';
export const ConfirmLeaveModal = () => {
    const getTranslation = useGetTranslation();
    const { onLeaveReservationFlowClick } = useStaticPropsSlice();
    const { isCancelReservationModalOpen, setIsCancelReservationModalOpen, navigateToOnConfirm, setNavigateToOnConfirm, } = useModalStatesDataSlice();
    const onModalClose = () => {
        setNavigateToOnConfirm('/');
        setIsCancelReservationModalOpen(false);
    };
    return (React.createElement(Modal, { overlayClassName: zIndexes['CONFIRM_LEAVE_MODAL'], isOpen: isCancelReservationModalOpen, onClose: onModalClose, withCloseButton: false, modalClassName: "p-0" },
        React.createElement(ModalWrapper, { header: getTranslation('warning'), className: "text-white bg-warning-main", onClose: onModalClose },
            React.createElement("div", { className: "p-4" },
                React.createElement("p", { className: "mb-5 text-gray-900" }, getTranslation('theDetailsWillBeLostDoYouWantToContinue')),
                React.createElement("div", { className: "flex justify-end" },
                    React.createElement(Button, { color: "warning", variant: "outline", className: "mr-2 confirm-leave-modal-cancel", size: "md", onClick: onModalClose }, getTranslation('no')),
                    React.createElement(Button, { onClick: () => onLeaveReservationFlowClick(navigateToOnConfirm), color: "warning", size: "md", className: "confirm-leave-modal-confirm" }, getTranslation('yes')))))));
};

import React from 'react';
import { StepsIndicator } from './steps-indicator';
import { Header } from './header';
import classNames from 'classnames';
import { BottomDrawer } from './bottom-drawer';
import { SideSummary } from './side-summary';
export const ContentWrapper = ({ children }) => {
    return (React.createElement("div", { className: "flex flex-col bg-gray-50 min-w-0" },
        React.createElement(Header, null),
        React.createElement("div", { className: classNames('flex flex-col w-full 768:items-start') },
            React.createElement(StepsIndicator, null),
            React.createElement("div", { className: "flex w-full" },
                React.createElement(SideSummary, null),
                children)),
        React.createElement(BottomDrawer, null)));
};

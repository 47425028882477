import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon, useGetTranslation, Spinner, } from '@reservation-app/common-used-in-web';
export const ImageWithLoadingIndicator = ({ src, alt, className, wrapperClassName, }) => {
    const [currentImage, setCurrentImage] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [hidden, setHidden] = useState(true);
    const getTranslation = useGetTranslation();
    const fetchImage = (src) => {
        const loadingImage = new Image();
        loadingImage.src = src;
        if (loadingImage.complete) {
            setCurrentImage(loadingImage.src);
            setTimeout(() => {
                setHidden(false);
            }, 50);
        }
        else {
            setIsLoading(true);
            loadingImage.onload = () => {
                setCurrentImage(loadingImage.src);
                setIsLoading(false);
                setTimeout(() => {
                    setHidden(false);
                }, 50);
            };
        }
        loadingImage.onerror = () => {
            setIsError(true);
            setIsLoading(false);
        };
    };
    useLayoutEffect(() => {
        fetchImage(src);
    }, []);
    return (React.createElement("div", { className: wrapperClassName, style: { overflow: 'hidden' } },
        !isError && (React.createElement(React.Fragment, null,
            !isLoading && (React.createElement("img", { src: currentImage, alt: alt, className: classNames('transition-all duration-1000', {
                    'opacity-0': hidden,
                    'opacity-100': !hidden,
                }, className) })),
            isLoading && (React.createElement("div", { className: classNames('flex flex-col justify-center items-center w-full h-full', className) },
                React.createElement(Spinner, { className: "w-10 h-10 text-primary-main mb-2" }),
                React.createElement("p", { className: "text-sm" }, getTranslation('loading')))))),
        isError && (React.createElement("div", { className: "flex flex-col w-full h-full justify-center items-center bg-error-light" },
            React.createElement(Icon, { icon: "errorWithCircle", className: "w-10 h-10 text-error-main" }),
            React.createElement("p", { className: "text-sm" }, getTranslation('imageError'))))));
};

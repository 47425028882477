import { IntervalStatuses, isLastIndex, } from '@reservation-app/common-used-in-web';
import { hoursToMinutesMap } from './hours-to-minutes-map';
export const getClosedIntervals = ({ openIntervals, date, }) => {
    if (!(openIntervals === null || openIntervals === void 0 ? void 0 : openIntervals.length)) {
        return [
            {
                startTime: hoursToMinutesMap['00:00'],
                endTime: hoursToMinutesMap['24:00'],
                date,
                status: IntervalStatuses.CLOSED,
            },
        ];
    }
    const sortedOpenIntervals = [...openIntervals];
    sortedOpenIntervals.sort((a, b) => a.startTime - b.startTime);
    const closedIntervals = [];
    if (openIntervals[0].startTime !== 0) {
        closedIntervals.push({
            startTime: 0,
            endTime: openIntervals[0].startTime,
            date,
            status: IntervalStatuses.CLOSED,
        });
    }
    for (let i = 0; i < openIntervals.length - 1; i++) {
        if (!isLastIndex(i, openIntervals) &&
            openIntervals[i].endTime !== openIntervals[i + 1].startTime) {
            closedIntervals.push({
                startTime: openIntervals[i].endTime,
                endTime: openIntervals[i + 1].startTime,
                date,
                status: IntervalStatuses.CLOSED,
            });
        }
    }
    if (openIntervals[openIntervals.length - 1].endTime !==
        hoursToMinutesMap['24:00']) {
        closedIntervals.push({
            startTime: openIntervals[openIntervals.length - 1].endTime,
            endTime: hoursToMinutesMap['24:00'],
            date,
            status: IntervalStatuses.CLOSED,
        });
    }
    return closedIntervals;
};

import React from 'react';
import { SectionBasicWrapper } from '../sections/wrappers/section-basic-wrapper';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import LanguageChangeLink from '../language-change-link';
import {
  defaultHomeLink,
  legalPageLinks,
  reservationPageLink,
  ifYouWantASimilarPage,
  websiteUrl,
  websiteText,
  thisWebsiteWasCreatedBy,
} from '../../static-content';
import { useLanguageContext } from '../../contexts/language-context';
import { usePageContext } from '../../contexts/page-context';

type FooterSectionProps = {
  containerClassName?: string;
};

const FooterSection = ({ containerClassName }: FooterSectionProps) => {
  const { defaultLanguage, pageUrl, languages, headerConfig, hasRulePage } =
    usePageContext();

  const { activeLanguage } = useLanguageContext();

  return (
    <SectionBasicWrapper
      wrapperClassName={classNames('bg-gray-800 pt-0', containerClassName)}
      contentClassName="!pb-4"
      tag="footer"
    >
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center w-full 768:flex-row 768:items-start 768:justify-around 768:mb-6">
          <div className="flex flex-col items-center mb-4 768:mb-0 768:items-start 768:flex-grow 768:w-0">
            {[defaultHomeLink, ...headerConfig.links, reservationPageLink]
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((link) => {
                if (!link.to[activeLanguage].includes('#')) {
                  return (
                    <GatsbyLink
                      key={link.to[activeLanguage]}
                      to={
                        activeLanguage === defaultLanguage
                          ? link.to[activeLanguage]
                          : `/${activeLanguage}${link.to[activeLanguage]}`
                      }
                      className="w-full mb-4 text-base font-medium text-center text-white hover:text-gray-200"
                    >
                      {link.label[activeLanguage]}
                    </GatsbyLink>
                  );
                } else {
                  return undefined;
                }
              })}
          </div>
          <div className="flex flex-col items-center mb-4 768:mb-0 768:items-start 768:flex-grow 768:w-0">
            {legalPageLinks.map((link) => {
              if (
                (!hasRulePage ||
                  process.env.GATSBY_COMPANY_NAME === 'first-sport-pitches') &&
                ['Regulament', 'Szabályzat', 'Rules'].includes(
                  link.label[activeLanguage],
                )
              ) {
                return null;
              }

              return (
                <GatsbyLink
                  key={link.to[activeLanguage]}
                  to={
                    activeLanguage === defaultLanguage
                      ? link.to[activeLanguage]
                      : `/${activeLanguage}${link.to[activeLanguage]}`
                  }
                  className="w-full mb-4 text-base font-medium text-center text-white hover:text-gray-200"
                >
                  {link.label[activeLanguage]}
                </GatsbyLink>
              );
            })}
          </div>

          <div className="flex flex-col items-center mb-4 768:mb-0 768:items-start 768:flex-grow 768:w-0">
            {languages.length > 1 &&
              languages.map((lang) => (
                <LanguageChangeLink
                  key={lang}
                  language={lang}
                  pageUrl={pageUrl}
                  className="justify-center w-full mb-4 text-base font-semibold text-white hover:text-gray-200"
                  withTranslation
                  withFlag
                />
              ))}
          </div>
        </div>
        <div className="w-full max-w-[300px] h-[2px] bg-gray-200 mb-4 768:mb-4" />
        <div className="flex flex-col items-center my-3 768:space-y-3">
          <p className="mb-2 text-base text-center text-white 768:mb-0">
            {thisWebsiteWasCreatedBy[activeLanguage]}
          </p>
          <img
            src={`https://ags-public-assets.s3.eu-central-1.amazonaws.com/${activeLanguage}/logo-wide-light.min.png`}
            className="h-12 mb-4"
            loading="lazy"
          />
          <p className="mb-2 text-base text-center text-white 768:mb-0">
            {ifYouWantASimilarPage[activeLanguage]}
          </p>
          <div className="flex self-center space-x-4">
            <a
              className="mb-2 text-base text-center text-white underline section-link 768:mb-0"
              href={websiteUrl[activeLanguage]}
              rel="noopener noreferrer"
              target="_blank"
            >
              {websiteText[activeLanguage]}
            </a>
          </div>
        </div>
      </div>
    </SectionBasicWrapper>
  );
};

export default FooterSection;

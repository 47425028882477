import React from 'react';
import { Icon, isLastIndex } from '../../../';
import classNames from 'classnames';
import { FadeInAnimation } from '../fade-in-animation';
const BasicPricesSection = ({ items, activeLanguage, shouldDisableAnimation, }) => {
    const sortedItems = [...items].sort((a, b) => a.displayOrder - b.displayOrder);
    return (React.createElement(FadeInAnimation, { isDisabled: shouldDisableAnimation, className: "flex flex-col items-center justify-center w-full max-w-md px-4 py-6 bg-white rounded-lg shadow-lg 768:max-w-3xl 768:px-8 768:py-8" }, sortedItems.map((item, index) => {
        var _a;
        return (React.createElement("div", { key: index, className: classNames('flex flex-col 768:flex-row justify-between items-center 768:items-start w-full mb-6 768:mb-4', {
                '!mb-0': isLastIndex(index, items),
            }) },
            React.createElement("div", { className: "flex items-center mb-3 text-gray-900 768:mb-0" },
                React.createElement(Icon, { icon: item.icon, className: "w-8 h-8 mr-2 text-primary-main" }),
                React.createElement("p", { className: "font-semibold text-primary-main" }, item.text[activeLanguage])),
            React.createElement("div", { className: classNames('768:w-7/12 768:border-l-2 768:pl-6 border-gray-300', {
                    'border-b-2 768:border-b-0 pb-6 768:mb-6 768:pb-0': !isLastIndex(index, items),
                }) },
                item.rows.map((row, idx) => (React.createElement("div", { key: idx, className: classNames('flex', {
                        'mb-2': !isLastIndex(idx, item.rows) && !row.shouldPriceBeUnderText,
                        'mb-4': !isLastIndex(idx, item.rows) && row.shouldPriceBeUnderText,
                        'font-semibold justify-between': !row.shouldPriceBeUnderText,
                        'flex-col': row.shouldPriceBeUnderText,
                        'text-gray-900': !row.color,
                    }) },
                    React.createElement("p", { className: classNames({
                            'mr-6': !row.shouldPriceBeUnderText,
                            'text-center 768:text-left': row.shouldPriceBeUnderText,
                        }, row.color || '') }, row.text[activeLanguage]),
                    React.createElement("p", { className: classNames({
                            'whitespace-nowrap': !row.shouldPriceBeUnderText,
                            'text-center 768:text-left': row.shouldPriceBeUnderText,
                        }, row.priceColor || row.color || '') }, row.price[activeLanguage])))),
                ((_a = item.commentHtml) === null || _a === void 0 ? void 0 : _a[activeLanguage]) && (React.createElement("div", { className: "mt-4", dangerouslySetInnerHTML: {
                        __html: item.commentHtml[activeLanguage],
                    } })))));
    })));
};
export default BasicPricesSection;

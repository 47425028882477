import React from 'react';
import classNames from 'classnames';
import { Translation, zIndexes } from '@reservation-app/common-used-in-web';
import HamburgerMenu from './hamburger-menu';
import { PageContextType } from '../../contexts/page-context';
import {
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../static-content';

export type HeaderWrapperProps = {
  headerScrollTriggered?: boolean;
  children?: React.ReactNode;
  headerConfig?: PageContextType['headerConfig'];
  className?: string;
  pageUrl: Translation;
  setNavigateToOnConfirm?: (to: string) => void;
  setIsCancelReservationModalOpen?: (isOpen: boolean) => void;
  onLeaveReservationFlowClick?: (to: string) => void;
  shouldWarnOnLeave?: boolean;
};

const HeaderWrapper = ({
  headerScrollTriggered,
  children,
  className,
  headerConfig,
  pageUrl,
  shouldWarnOnLeave,
  setNavigateToOnConfirm,
  setIsCancelReservationModalOpen,
  onLeaveReservationFlowClick,
}: HeaderWrapperProps) => {
  const isReservationFlow =
    pageUrl.en === reservationPageLanguageMapping.en ||
    pageUrl.en === verifyAvailabilityPageLanguageMapping.en;

  return (
    <header
      className={classNames(
        'fixed top-0 left-0 right-0 w-full h-16 flex items-center transition-colors duration-300 pr-[var(--body-padding-right)]',
        {
          'bg-white shadow-lg': headerScrollTriggered,
        },
        zIndexes['HEADER_WRAPPER'],
        className,
      )}
      style={{
        zIndex: !isReservationFlow ? zIndexes['HEADER_WRAPPER'] + 1 : undefined,
      }}
    >
      <nav className="flex items-center custom-container mx-auto justify-between">
        <div className="flex flex-grow items-center justify-between">
          {children}
        </div>
        <HamburgerMenu
          headerScrollTriggered={headerScrollTriggered}
          headerConfig={headerConfig}
          pageUrl={pageUrl}
          isReservationFlow={isReservationFlow}
          onLeaveReservationFlowClick={onLeaveReservationFlowClick}
          setIsCancelReservationModalOpen={setIsCancelReservationModalOpen}
          setNavigateToOnConfirm={setNavigateToOnConfirm}
          shouldWarnOnLeave={shouldWarnOnLeave}
        />
      </nav>
    </header>
  );
};

export default HeaderWrapper;

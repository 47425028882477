import { getPeriodPropertyValueForDate } from '@reservation-app/common-used-in-web';
export const getSelectableExtras = ({ selectedIntervals, }) => {
    const selectableExtras = [];
    selectedIntervals.forEach((selectedInterval) => {
        selectedInterval.reservableInterval.extras.forEach((extra) => {
            if (!selectableExtras.some((ext) => ext._id === extra._id)) {
                selectableExtras.push(Object.assign(Object.assign({}, extra), { intervals: [] }));
            }
        });
    });
    selectableExtras.forEach((extra) => {
        selectedIntervals.forEach((selectedInterval) => {
            if (selectedInterval.reservableInterval.extras.some((ext) => ext._id === extra._id)) {
                const extraPriceInfo = getPeriodPropertyValueForDate({
                    date: selectedInterval.date,
                    periodSensitiveProperty: extra.price,
                });
                extra.intervals.push({
                    startTime: selectedInterval.reservableInterval.timeInterval.startTime,
                    endTime: selectedInterval.reservableInterval.timeInterval.endTime,
                    displayStartEndTime: selectedInterval.reservableInterval.timeInterval
                        .displayStartEndTime,
                    date: selectedInterval.date,
                    priceInfo: extraPriceInfo,
                });
            }
        });
    });
    return selectableExtras;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { StepWrapper } from '../../layout/step-wrapper';
import classNames from 'classnames';
import { useGetTranslation, useActiveLanguage, BillingDetailsModes, ReservationSteps, SELECTED_COMPANY_NAME_LS_KEY, } from '@reservation-app/common-used-in-web';
import { TextInput, Checkbox } from '../../../components';
import { isWithPaymentStep } from '../../helpers/is-with-payment-step';
import { useRemoteMasterDataSlice, useModalStatesDataSlice, useSelectedValuesSlice, useBillingDetailsStepSpecificSlice, useStaticPropsSlice, useStepperStateSlice, } from '../../store';
import { ProfileSelector } from './profile-selector';
import { useMutation } from '@tanstack/react-query';
import { getContactInfoHash } from '../../../reservation-flow/helpers/get-contact-info-hash';
export const BillingDetailsStep = () => {
    var _a, _b, _c;
    const getTranslation = useGetTranslation();
    const { companyOfficialData, companyReservationConfig } = useRemoteMasterDataSlice();
    const { setIsAnyInputFocused } = useStepperStateSlice();
    const { setIsHtmlContentModalOpen, setHtmlContentModalText } = useModalStatesDataSlice();
    const { selectedEntityType } = useSelectedValuesSlice();
    const { billingDetails, setBillingDetails, billingDetailsTouchedState, setBillingDetailsTouchedState, billingDetailsValidationErrors, validateBillingDetails, } = useBillingDetailsStepSpecificSlice();
    const { cachedBillingDetails, setCachedBillingDetails, modifyBillingDetailsCache, } = useBillingDetailsStepSpecificSlice();
    const { apiClient } = useStaticPropsSlice();
    const { mutate: deleteContactInfo } = useMutation((contactInfoHash) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield apiClient.delete(`/contact-infos/${contactInfoHash}`);
        return response.data;
    }));
    const { rulesHtml, TermsAndConditionsComponent, isAdminPanel } = useStaticPropsSlice();
    const activeLanguage = useActiveLanguage();
    const isIndividual = billingDetails.type === BillingDetailsModes.INDIVIDUAL;
    const selectedCompanyName = localStorage.getItem(SELECTED_COMPANY_NAME_LS_KEY) ||
        process.env.GATSBY_COMPANY_NAME;
    return (React.createElement(StepWrapper, { step: ReservationSteps.BILLING_DETAILS, wrapperClassName: "!pb-[420px] 768:!pb-0" },
        (cachedBillingDetails === null || cachedBillingDetails === void 0 ? void 0 : cachedBillingDetails.length) > 1 && (React.createElement(ProfileSelector, { isAdminPanel: isAdminPanel, options: cachedBillingDetails, onSelect: (value) => {
                if (value.type === BillingDetailsModes.INDIVIDUAL || !value.type) {
                    setBillingDetails('type', value.type || BillingDetailsModes.INDIVIDUAL);
                    setBillingDetails('fullName', value.fullName || '');
                    setBillingDetails('email', value.email || '');
                    setBillingDetails('phoneNumber', value.phoneNumber || '');
                    setBillingDetails('address', value.address || '');
                }
                if (value.type === BillingDetailsModes.COMPANY) {
                    setBillingDetails('type', value.type);
                    setBillingDetails('companyOfficialName', value.companyOfficialName);
                    setBillingDetails('email', value.email);
                    setBillingDetails('phoneNumber', value.phoneNumber);
                    setBillingDetails('address', value.address);
                    setBillingDetails('companyCountry', value.companyCountry);
                    setBillingDetails('companyVatId', value.companyVatId);
                }
            }, onDelete: (billingDetailsOptionToDelete) => {
                setCachedBillingDetails(cachedBillingDetails.filter((billingDetailsOption) => {
                    if (billingDetailsOption.fullName ===
                        billingDetailsOptionToDelete.fullName &&
                        billingDetailsOption.phoneNumber ===
                            billingDetailsOptionToDelete.phoneNumber &&
                        !billingDetailsOptionToDelete.isMembershipCard) {
                        return false;
                    }
                    return true;
                }));
                if (isAdminPanel) {
                    deleteContactInfo(getContactInfoHash({
                        companyName: selectedCompanyName,
                        userInfo: billingDetailsOptionToDelete,
                    }));
                }
                else {
                    modifyBillingDetailsCache({
                        action: 'remove',
                        keyToRemove: billingDetailsOptionToDelete.email
                            ? billingDetailsOptionToDelete.email
                            : billingDetailsOptionToDelete.fullName,
                    });
                }
            }, buttonClassName: "mb-4" })),
        selectedEntityType.allowCompanyBilling && (React.createElement("div", { className: "flex border border-gray-800 rounded-full overflow-hidden mb-6 960:max-w-[330px]" },
            React.createElement("div", { onClick: () => {
                    setBillingDetails('type', BillingDetailsModes.INDIVIDUAL);
                }, className: classNames('set-biling-details-to-individual flex justify-center py-2 flex-grow cursor-pointer transition-all duration-300 max-w-[330px] w-full hover:bg-primary-100', {
                    'bg-primary-light font-semibold text-gray-900': isIndividual,
                    'bg-gray-100 text-gray-700': !isIndividual,
                }) }, getTranslation('individual')),
            React.createElement("div", { onClick: () => {
                    setBillingDetails('type', BillingDetailsModes.COMPANY);
                }, className: classNames('set-biling-details-to-company flex justify-center py-2 flex-grow cursor-pointer transition-all max-w-[330px] w-full hover:bg-primary-100', {
                    'bg-gray-100 text-gray-700': isIndividual,
                    'bg-primary-light font-semibold text-gray-900': !isIndividual,
                }) }, getTranslation('company')))),
        React.createElement("div", { className: "flex flex-col 768:flex-row" },
            React.createElement("div", { className: "768:max-w-[330px] w-full" },
                React.createElement("div", { className: "768:max-w-xs 1410:max-w-md" },
                    isIndividual && (React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-full-name mb-6", label: getTranslation('fullName'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.fullName, onChange: (e) => {
                            setBillingDetails('fullName', e.target.value);
                            if (billingDetailsTouchedState.fullName) {
                                validateBillingDetails('fullName');
                            }
                        }, onFocus: () => {
                            var _a, _b;
                            if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                !billingDetails.additionalMustAgreeTo1) {
                                return;
                            }
                            if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                !billingDetails.additionalMustAgreeTo2) {
                                return;
                            }
                            setIsAnyInputFocused(true);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('fullName', true);
                            validateBillingDetails('fullName');
                        }, error: billingDetailsTouchedState.fullName &&
                            billingDetailsValidationErrors.fullName })),
                    !isIndividual && (React.createElement("div", null,
                        React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-company-name mb-6", label: getTranslation('companyName'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.companyOfficialName, onChange: (e) => {
                                setBillingDetails('companyOfficialName', e.target.value);
                                if (billingDetailsTouchedState.companyOfficialName) {
                                    validateBillingDetails('companyOfficialName');
                                }
                            }, onFocus: () => {
                                var _a, _b;
                                if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                    !billingDetails.additionalMustAgreeTo1) {
                                    return;
                                }
                                if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                    !billingDetails.additionalMustAgreeTo2) {
                                    return;
                                }
                                setIsAnyInputFocused(true);
                            }, onBlur: () => {
                                setIsAnyInputFocused(false);
                                setBillingDetailsTouchedState('companyOfficialName', true);
                                validateBillingDetails('companyOfficialName');
                            }, error: billingDetailsTouchedState.companyOfficialName &&
                                billingDetailsValidationErrors.companyOfficialName }),
                        React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-comapny-vat-id mb-6", label: getTranslation('vatId'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.companyVatId, onChange: (e) => {
                                setBillingDetails('companyVatId', e.target.value);
                                if (billingDetailsTouchedState.companyVatId) {
                                    validateBillingDetails('companyVatId');
                                }
                            }, onFocus: () => {
                                var _a, _b;
                                if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                    !billingDetails.additionalMustAgreeTo1) {
                                    return;
                                }
                                if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                    !billingDetails.additionalMustAgreeTo2) {
                                    return;
                                }
                                setIsAnyInputFocused(true);
                            }, onBlur: () => {
                                setIsAnyInputFocused(false);
                                setBillingDetailsTouchedState('companyVatId', true);
                                validateBillingDetails('companyVatId');
                            }, error: billingDetailsTouchedState.companyVatId &&
                                billingDetailsValidationErrors.companyVatId }))),
                    React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-phone-number mb-6", label: getTranslation('phoneNumber'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.phoneNumber, onChange: (e) => {
                            setBillingDetails('phoneNumber', e.target.value);
                            if (billingDetailsTouchedState.phoneNumber) {
                                validateBillingDetails('phoneNumber');
                            }
                        }, onFocus: () => {
                            var _a, _b;
                            if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                !billingDetails.additionalMustAgreeTo1) {
                                return;
                            }
                            if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                !billingDetails.additionalMustAgreeTo2) {
                                return;
                            }
                            setIsAnyInputFocused(true);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('phoneNumber', true);
                            validateBillingDetails('phoneNumber');
                        }, error: billingDetailsTouchedState.phoneNumber &&
                            billingDetailsValidationErrors.phoneNumber }),
                    isAdminPanel && (React.createElement(Checkbox, { containerClassName: "send-email-to-clients flex items-start mb-6", checkboxClassNames: "w-5 h-5 border-gray-500", tickCheckedClassName: "text-2xl text-white w-5 h-5 stroke-2", checkedBgClassName: "border-primary-main bg-primary-main", value: billingDetails.shouldSendEmail, label: getTranslation('shouldSendEmail'), labelPosition: "top", labelClassName: "text-gray-900 font-semibold text-sm", onChange: (value) => {
                            setBillingDetails('shouldSendEmail', value);
                            if (billingDetailsTouchedState.shouldSendEmail) {
                                validateBillingDetails('shouldSendEmail');
                            }
                            if (value === false) {
                                validateBillingDetails('email');
                            }
                            if (value === true && billingDetailsTouchedState.email) {
                                validateBillingDetails('email');
                            }
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('shouldSendEmail', true);
                            validateBillingDetails('shouldSendEmail');
                        } })),
                    billingDetails.shouldSendEmail && (React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-email mb-6", label: "Email", labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.email, onChange: (e) => {
                            setBillingDetails('email', e.target.value);
                            if (billingDetailsTouchedState.email) {
                                validateBillingDetails('email');
                            }
                        }, onFocus: () => {
                            var _a, _b;
                            if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                !billingDetails.additionalMustAgreeTo1) {
                                return;
                            }
                            if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                !billingDetails.additionalMustAgreeTo2) {
                                return;
                            }
                            setIsAnyInputFocused(true);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('email', true);
                            validateBillingDetails('email');
                        }, error: billingDetailsTouchedState.email &&
                            billingDetailsValidationErrors.email })),
                    isWithPaymentStep({
                        companyReservationConfig,
                        selectedEntityType,
                    }) &&
                        !isAdminPanel &&
                        billingDetails.shouldSendEmail && (React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-address mb-6", label: getTranslation('addressWithIdCard'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.address, onChange: (e) => {
                            setBillingDetails('address', e.target.value);
                            if (billingDetailsTouchedState.address) {
                                validateBillingDetails('address');
                            }
                        }, onFocus: () => {
                            var _a, _b;
                            if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                !billingDetails.additionalMustAgreeTo1) {
                                return;
                            }
                            if (((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) &&
                                !billingDetails.additionalMustAgreeTo2) {
                                return;
                            }
                            setIsAnyInputFocused(true);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('address', true);
                            validateBillingDetails('address');
                        }, error: billingDetailsTouchedState.address &&
                            billingDetailsValidationErrors.address })))),
            !isIndividual && (React.createElement("div", { className: "768:max-w-[330px] w-full" },
                React.createElement("div", { className: "768:max-w-xs 1410:max-w-md" },
                    React.createElement("p", { className: "pb-2 mb-4 font-extrabold border-b border-gray-500 -gray-500" }, getTranslation('billingAddress')),
                    React.createElement(TextInput, { errorClassName: "text-sm -bottom-1", containerClassName: "billing-details-comapny-country mb-6", label: getTranslation('country'), labelClassName: "text-gray-900 font-semibold text-sm", value: billingDetails.companyCountry, onChange: (e) => {
                            setBillingDetails('companyCountry', e.target.value);
                            if (billingDetailsTouchedState.companyCountry) {
                                validateBillingDetails('companyCountry');
                            }
                        }, onFocus: () => {
                            var _a, _b;
                            if (((_a = selectedEntityType.additionalMustAgreeTo1) === null || _a === void 0 ? void 0 : _a.isActive) &&
                                !billingDetails.additionalMustAgreeTo1) {
                                return;
                            }
                            if ((_b = selectedEntityType.additionalMustAgreeTo2) === null || _b === void 0 ? void 0 : _b.isActive) {
                                return;
                            }
                            setIsAnyInputFocused(true);
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('companyCountry', true);
                            validateBillingDetails('companyCountry');
                        }, error: billingDetailsTouchedState.companyCountry &&
                            billingDetailsValidationErrors.companyCountry }))))),
        !isAdminPanel && (React.createElement("div", null,
            React.createElement("div", { className: "flex items-center mb-1" },
                React.createElement(Checkbox, { containerClassName: "billing-details-terms-and-conds-checkbox flex items-start", checkboxClassNames: "w-5 h-5 border-gray-500", tickCheckedClassName: "text-2xl text-white w-5 h-5 stroke-2", checkedBgClassName: "border-primary-main bg-primary-main", value: billingDetails.termsAndConditions, onChange: (value) => {
                        setBillingDetails('termsAndConditions', value);
                        if (billingDetailsTouchedState.termsAndConditions) {
                            validateBillingDetails('termsAndConditions');
                        }
                    }, onBlur: () => {
                        setIsAnyInputFocused(false);
                        setBillingDetailsTouchedState('termsAndConditions', true);
                        validateBillingDetails('termsAndConditions');
                    } }),
                React.createElement("div", { className: "text-sm font-semibold text-gray-900" },
                    React.createElement("p", { className: "inline whitespace-nowrap" }, getTranslation('termsAndConditionsAcceptText')),
                    React.createElement("p", { onClick: () => {
                            setHtmlContentModalText(TermsAndConditionsComponent ? (React.createElement(TermsAndConditionsComponent, { pageContext: {
                                    siteUrl: companyOfficialData.siteUrl,
                                    companyOfficialData,
                                } })) : null);
                            setIsHtmlContentModalOpen(true);
                        }, className: "inline mx-1 font-semibold cursor-pointer text-primary-main" }, getTranslation('termsAndConditions')),
                    React.createElement("br", { className: "768:hidden" }),
                    selectedEntityType.withRulesToAgreeTo && (React.createElement("div", null,
                        React.createElement("p", { className: "inline whitespace-nowrap" }, getTranslation('termsAndConditionsAndTheText')),
                        React.createElement("p", { onClick: () => {
                                setHtmlContentModalText(rulesHtml);
                                setIsHtmlContentModalOpen(true);
                            }, className: "inline ml-1 mr-2 font-semibold cursor-pointer whitespace-nowrap text-primary-main" }, ((_a = selectedEntityType.rulesLinkText) === null || _a === void 0 ? void 0 : _a[activeLanguage]) ||
                            getTranslation('termsAndConditionRulesText')))))),
            (billingDetailsTouchedState === null || billingDetailsTouchedState === void 0 ? void 0 : billingDetailsTouchedState.termsAndConditions) &&
                (billingDetailsValidationErrors === null || billingDetailsValidationErrors === void 0 ? void 0 : billingDetailsValidationErrors.termsAndConditions) && (React.createElement("p", { className: "text-sm text-error-700" }, billingDetailsTouchedState.termsAndConditions &&
                billingDetailsValidationErrors.termsAndConditions)),
            ((_b = selectedEntityType.additionalMustAgreeTo1) === null || _b === void 0 ? void 0 : _b.isActive) && (React.createElement("div", null,
                React.createElement("div", { className: "flex items-center mt-4 mb-1" },
                    React.createElement(Checkbox, { containerClassName: "billing-details-terms-and-conds-checkbox flex items-start", checkboxClassNames: "w-5 h-5 border-gray-500", tickCheckedClassName: "text-2xl text-white w-5 h-5 stroke-2", checkedBgClassName: "border-primary-main bg-primary-main", value: billingDetails.additionalMustAgreeTo1, onChange: (value) => {
                            setBillingDetails('additionalMustAgreeTo1', value);
                            if (billingDetailsTouchedState.additionalMustAgreeTo1) {
                                validateBillingDetails('additionalMustAgreeTo1');
                            }
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('additionalMustAgreeTo1', true);
                            validateBillingDetails('additionalMustAgreeTo1');
                        } }),
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: selectedEntityType.additionalMustAgreeTo1.text[activeLanguage],
                        }, className: "text-sm font-semibold text-gray-900" })),
                (billingDetailsValidationErrors === null || billingDetailsValidationErrors === void 0 ? void 0 : billingDetailsValidationErrors.additionalMustAgreeTo1) &&
                    (billingDetailsTouchedState === null || billingDetailsTouchedState === void 0 ? void 0 : billingDetailsTouchedState.additionalMustAgreeTo1) && (React.createElement("p", { className: "text-sm text-error-700" }, billingDetailsTouchedState.additionalMustAgreeTo1 &&
                    billingDetailsValidationErrors.additionalMustAgreeTo1)))),
            ((_c = selectedEntityType.additionalMustAgreeTo2) === null || _c === void 0 ? void 0 : _c.isActive) && (React.createElement("div", null,
                React.createElement("div", { className: "flex items-center mt-4 mb-1" },
                    React.createElement(Checkbox, { containerClassName: "billing-details-terms-and-conds-checkbox flex items-start", checkboxClassNames: "w-5 h-5 border-gray-500", tickCheckedClassName: "text-2xl text-white w-5 h-5 stroke-2", checkedBgClassName: "border-primary-main bg-primary-main", value: billingDetails.additionalMustAgreeTo2, onChange: (value) => {
                            setBillingDetails('additionalMustAgreeTo2', value);
                            if (billingDetailsTouchedState.additionalMustAgreeTo2) {
                                validateBillingDetails('additionalMustAgreeTo2');
                            }
                        }, onBlur: () => {
                            setIsAnyInputFocused(false);
                            setBillingDetailsTouchedState('additionalMustAgreeTo2', true);
                            validateBillingDetails('additionalMustAgreeTo2');
                        } }),
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: selectedEntityType.additionalMustAgreeTo2.text[activeLanguage],
                        }, className: "text-sm font-semibold text-gray-900" }, selectedEntityType.additionalMustAgreeTo2.text[activeLanguage])),
                (billingDetailsValidationErrors === null || billingDetailsValidationErrors === void 0 ? void 0 : billingDetailsValidationErrors.additionalMustAgreeTo2) &&
                    (billingDetailsTouchedState === null || billingDetailsTouchedState === void 0 ? void 0 : billingDetailsTouchedState.additionalMustAgreeTo2) && (React.createElement("p", { className: "text-sm text-error-700" }, billingDetailsTouchedState.additionalMustAgreeTo2 &&
                    billingDetailsValidationErrors.additionalMustAgreeTo2))))))));
};

import React from 'react';
import { SelectPeriod } from '../select-period-and-entity/select-period';
import { useValidateRestrictions } from '../../hooks/use-validate-restrictions';
import { useSyncCachedValuesOfIntervalsSwitches } from '../../hooks/use-sync-cached-values-of-intervals-switches';
import { isStepBeforeAnotherStep, ReservationSteps, } from '@reservation-app/common-used-in-web';
import { useStepperStateSlice } from '../../store';
export const SelectPeriodStep = () => {
    const { steps } = useStepperStateSlice();
    const isSelectEntityStepBeforeSelectPeriodStep = isStepBeforeAnotherStep({
        steps,
        stepToCheckIfBehind: ReservationSteps.SELECT_ENTITY,
        stepToCheckIfAhead: ReservationSteps.SELECT_PERIOD,
    });
    useValidateRestrictions({
        isDisabled: !isSelectEntityStepBeforeSelectPeriodStep,
    });
    useSyncCachedValuesOfIntervalsSwitches();
    return React.createElement(SelectPeriod, { step: ReservationSteps.SELECT_PERIOD });
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { format } from 'date-fns';
import { isWithPaymentStep } from '../../helpers/is-with-payment-step';
import { getTimelessDate, ReservationSteps, getPeriodPropertyValueForDate, isDevEnv, useTranslationsStore, getServerErrorMessage, DEFAULT_SYNC_GROUP_KEY, } from '@reservation-app/common-used-in-web';
import { RESERVATION_FLOW_RESERVED_INTERVALS_QUERY_KEY, RESERVATION_FLOW_DATA_QUERY_KEY, } from '../../../query-keys';
import { getRestrictionValidationErrors } from '../../helpers/get-restriction-validation-errors';
import { getDevtoolsLogMessage } from '../get-devtools-log-message';
export const RESERVATION_PASSWORD_REQUIRED_LS_KEY = 'reservation-password-required';
export const SAVED_RESERVATION_PASSWORD_LS_KEY = 'saved-reservation-password';
export const getShowAvailableElementsOnlyCacheKey = (id, elementType) => {
    if (elementType === 'entities') {
        return `showOnlyAvailableEntities-${id}`;
    }
    if (elementType === 'intervals') {
        return `showOnlyAvailableIntervals-${id}`;
    }
    return undefined;
};
export const createStepperStateSlice = (set, get) => ({
    steps: null,
    setSteps: (steps, extraDevtoolsInfo) => set((state) => {
        state.stepperState.steps = steps;
    }, false, getDevtoolsLogMessage('setSteps', extraDevtoolsInfo)),
    currentStep: null,
    setCurrentStep: (currentStep, extraDevtoolsInfo) => set((state) => {
        state.stepperState.currentStep = currentStep;
    }, false, getDevtoolsLogMessage('setCurrentStep', extraDevtoolsInfo)),
    validationError: '',
    setValidationError: (validationError, extraDevtoolsInfo) => set((state) => {
        state.stepperState.validationError = validationError;
    }, false, getDevtoolsLogMessage('setValidationError', extraDevtoolsInfo)),
    validationWarning: '',
    setValidationWarning: (validationWarning, extraDevtoolsInfo) => set((state) => {
        state.stepperState.validationWarning = validationWarning;
    }, false, getDevtoolsLogMessage('setValidationWarning', extraDevtoolsInfo)),
    isNextStepLoading: null,
    setIsNextStepLoading: (isNextStepLoading, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isNextStepLoading = isNextStepLoading;
    }, false, getDevtoolsLogMessage('setIsNextStepLoading', extraDevtoolsInfo)),
    price: null,
    setPrice: (price, extraDevtoolsInfo) => set((state) => {
        state.stepperState.price = price;
    }, false, getDevtoolsLogMessage('setPrice', extraDevtoolsInfo)),
    bottomSheetSnapTo: null,
    setBottomSheetSnapTo: (bottomSheetSnapTo, extraDevtoolsInfo) => set((state) => {
        state.stepperState.bottomSheetSnapTo = bottomSheetSnapTo;
    }, false, getDevtoolsLogMessage('setBottomSheetSnapTo', extraDevtoolsInfo)),
    isPreviousButtonSpamBlockEnabled: false,
    setIsPreviousButtonSpamBlockEnabled: (isPreviousButtonSpamBlockEnabled, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isPreviousButtonSpamBlockEnabled =
            isPreviousButtonSpamBlockEnabled;
    }, false, getDevtoolsLogMessage('setIsPreviousButtonSpamBlockEnabled', extraDevtoolsInfo)),
    isBottomSheetOpen: null,
    setIsBottomSheetOpen: (isBottomSheetOpen, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isBottomSheetOpen = isBottomSheetOpen;
    }, false, getDevtoolsLogMessage('setIsBottomSheetOpen', extraDevtoolsInfo)),
    shouldSkipServicesOnPrev: false,
    setShouldSkipServicesOnPrev: (shouldSkipServicesOnPrev, extraDevtoolsInfo) => set((state) => {
        state.stepperState.shouldSkipServicesOnPrev = shouldSkipServicesOnPrev;
    }, false, getDevtoolsLogMessage('setShouldSkipServicesOnPrev', extraDevtoolsInfo)),
    reservationPassword: '',
    setReservationPassword: (reservationPassword, extraDevtoolsInfo) => set((state) => {
        state.stepperState.reservationPassword = reservationPassword;
    }, false, getDevtoolsLogMessage('setReservationPassword', extraDevtoolsInfo)),
    reservationPasswordError: '',
    setReservationPasswordError: (reservationPasswordError, extraDevtoolsInfo) => set((state) => {
        state.stepperState.reservationPasswordError = reservationPasswordError;
    }, false, getDevtoolsLogMessage('setReservationPasswordError', extraDevtoolsInfo)),
    isFinishingOrder: false,
    setIsFinishingOrder: (isFinishingOrder, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isFinishingOrder = isFinishingOrder;
    }, false, getDevtoolsLogMessage('setIsFinishingOrder', extraDevtoolsInfo)),
    getCurrentCreateOrderDto: () => {
        const { static: { staticProps: { isAdminPanel }, }, stepperState: { reservationPassword }, selectedValues: { selectedEntityType, selectedEntity, selectedService, selectedIntervals, }, billingDetailsStepSpecific: { billingDetails }, } = get();
        const shouldSendEmail = isAdminPanel
            ? billingDetails.shouldSendEmail
            : selectedEntityType.withEmailSendingToClients;
        return {
            password: reservationPassword,
            membershipCode: reservationPassword,
            selectedEntityId: selectedEntity._id,
            selectedEntityTypeId: selectedEntityType._id,
            selectedServiceId: selectedService._id,
            userInfo: Object.assign(Object.assign({}, billingDetails), { email: billingDetails.email ? billingDetails.email.trim() : '', fullName: billingDetails.fullName ? billingDetails.fullName.trim() : '', companyCountry: billingDetails.companyCountry
                    ? billingDetails.companyCountry.trim()
                    : '', companyName: billingDetails.companyOfficialName
                    ? billingDetails.companyOfficialName.trim()
                    : '', companyVatId: billingDetails.companyVatId
                    ? billingDetails.companyVatId.trim()
                    : '', address: billingDetails.address ? billingDetails.address.trim() : '', phoneNumber: billingDetails.phoneNumber
                    ? billingDetails.phoneNumber.trim()
                    : '', shouldSendEmail: !!shouldSendEmail, updatedAt: undefined }),
            selectedIntervals: selectedIntervals.map((selectedInterval) => {
                return Object.assign(Object.assign({}, selectedInterval), { date: format(getTimelessDate(selectedInterval.date), 'yyyy-MM-dd'), numberOfPersons: selectedInterval.numberOfPersons, startTime: selectedInterval.reservableInterval.timeInterval.startTime, endTime: selectedInterval.reservableInterval.timeInterval.endTime, reservableInterval: undefined });
            }),
        };
    },
    goToNextStep: (stepsToSkip, extraDevtoolsInfo) => {
        set((state) => {
            const stepsToGo = stepsToSkip ? 1 + stepsToSkip : 1;
            const nextStepIndex = state.stepperState.steps.findIndex(({ step }) => step === state.stepperState.currentStep) + stepsToGo;
            const nextStep = state.stepperState.steps[nextStepIndex].step;
            state.stepperState.currentStep = nextStep;
        }, false, getDevtoolsLogMessage('goToNextStep', extraDevtoolsInfo));
    },
    resetReservationFlow: (queryClient) => {
        const { remoteMasterData: { setIsDerivingInitialStateFromRemoteData, setEntities, setSyncGroupKeys, setExtras, setPromotions, setEntityTypes, setServices, setCompanyReservationConfig, setCompanyBuildInfo, setAlerts, }, stepperState: { setCurrentStep, setValidationError, setValidationWarning, setIsBottomSheetOpen, setIsNextStepLoading, setIsPreviousButtonSpamBlockEnabled, setShouldSkipServicesOnPrev, setIsFinishingOrder, setReservationPassword, setReservationPasswordError, setShouldAnimateNextButton, }, modalStates: { setIsCancelReservationModalOpen, setIsConflictingSelectedIntervalsModalOpen, setConflictingSelectedIntervals, setNavigateToOnConfirm, setIsRestrictionErrorModalOpen, setRestrictionErrorModalMessage, setRestrictionErrorModalSelectedInterval, }, selectedValues: { setSelectedEntityType, setSelectedService, setSelectedIntervals, setSelectedEntity, setSelectedSyncGroupKey, setSelectedNrOfPersons, setSelectedBulkNumberOfPersons, }, periodAndEntityStepSpecific: { setSelectedDate, setIsReservableIntervalsListShowing, setIsBirdEyeModalOpen, setEntityGalleryModalSelectedEntity, setIsEntityGalleryModalOpen, }, extrasStepSpecific: { setOpenExtras, setSelectedExtras }, } = get();
        setCurrentStep(ReservationSteps.SELECT_ENTITY_TYPE);
        setIsNextStepLoading(false);
        setIsBottomSheetOpen(false);
        setValidationError('');
        setValidationWarning('');
        setIsPreviousButtonSpamBlockEnabled(false);
        setShouldSkipServicesOnPrev(false);
        setIsFinishingOrder(false);
        setReservationPassword('');
        setReservationPasswordError('');
        setShouldAnimateNextButton(false);
        setIsCancelReservationModalOpen(false);
        setIsConflictingSelectedIntervalsModalOpen(false);
        setNavigateToOnConfirm('');
        setConflictingSelectedIntervals([]);
        setIsRestrictionErrorModalOpen(false);
        setRestrictionErrorModalMessage('');
        setRestrictionErrorModalSelectedInterval(null);
        setSelectedEntityType(null);
        setSelectedService(null);
        setSelectedIntervals([]);
        setSelectedEntity(null);
        setSelectedSyncGroupKey(DEFAULT_SYNC_GROUP_KEY);
        setSelectedNrOfPersons(null);
        setSelectedBulkNumberOfPersons(null);
        setIsDerivingInitialStateFromRemoteData(true);
        setEntities([]);
        setSyncGroupKeys([]);
        setExtras([]);
        setPromotions([]);
        setEntityTypes([]);
        setServices([]);
        setCompanyReservationConfig(null);
        setCompanyBuildInfo(null);
        setAlerts([]);
        setSelectedDate(undefined);
        setIsReservableIntervalsListShowing(false);
        setIsBirdEyeModalOpen(false);
        setEntityGalleryModalSelectedEntity(null);
        setIsEntityGalleryModalOpen(false);
        setOpenExtras({});
        setSelectedExtras([]);
        queryClient.removeQueries(RESERVATION_FLOW_DATA_QUERY_KEY, {
            exact: true,
        });
        queryClient.removeQueries(RESERVATION_FLOW_RESERVED_INTERVALS_QUERY_KEY, {
            exact: true,
        });
    },
    tryToEnterPasswordFromLocalStorage: (entityType, extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { selectedValues: { selectedEntityType }, static: { staticProps: { isAdminPanel, apiClient }, }, billingDetailsStepSpecific: { setBillingDetails }, } = get();
        const getTranslation = useTranslationsStore.getState().getTranslation;
        const relevantEntityType = entityType || selectedEntityType;
        let isPasswordAcceptedAndRestored = false;
        if ((relevantEntityType.withPassword ||
            relevantEntityType.withMembershipCard) &&
            !isAdminPanel) {
            let savedPassword = localStorage.getItem(SAVED_RESERVATION_PASSWORD_LS_KEY);
            const urlParams = new URLSearchParams(window.location.search);
            const membershipCodeQueryParam = urlParams.get('membershipCode');
            if (membershipCodeQueryParam) {
                savedPassword = membershipCodeQueryParam;
            }
            if (savedPassword) {
                try {
                    const membershipCard = (_a = (yield apiClient({
                        method: 'POST',
                        url: relevantEntityType.withPassword
                            ? '/orders/verify-password'
                            : '/orders/verify-membership-code',
                        data: {
                            password: relevantEntityType.withPassword
                                ? savedPassword
                                : undefined,
                            membershipCode: relevantEntityType.withMembershipCard
                                ? savedPassword
                                : undefined,
                            entityTypeId: relevantEntityType._id,
                        },
                    }))) === null || _a === void 0 ? void 0 : _a.data;
                    if (relevantEntityType.withMembershipCard) {
                        setBillingDetails('fullName', membershipCard.fullName);
                        setBillingDetails('phoneNumber', membershipCard.phoneNumber);
                        setBillingDetails('email', membershipCard.email);
                    }
                    set((state) => {
                        state.stepperState.reservationPassword = savedPassword;
                        isPasswordAcceptedAndRestored = true;
                    }, false, getDevtoolsLogMessage('tryToEnterPasswordFromLocalStorage:verifySuccess', extraDevtoolsInfo));
                }
                catch (e) {
                    set((state) => {
                        state.modalStates.isWarningTextModalOpen = true;
                        if (relevantEntityType.withPassword) {
                            state.modalStates.warningTextModalText =
                                getTranslation('passwordHasChanged');
                        }
                        else if (relevantEntityType.withMembershipCard) {
                            state.modalStates.warningTextModalText = getServerErrorMessage(e, getTranslation);
                        }
                    }, false, getDevtoolsLogMessage('tryToEnterPasswordFromLocalStorage:verifyError', extraDevtoolsInfo));
                    localStorage.removeItem(SAVED_RESERVATION_PASSWORD_LS_KEY);
                }
            }
        }
        return isPasswordAcceptedAndRestored;
    }),
    handleGoToNextStepFromSelectEntityType: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { goToNextStep, tryToEnterPasswordFromLocalStorage }, selectedValues: { selectedEntityType }, remoteMasterData: { entities }, static: { staticProps: { isVerifyAvailabilityMode }, }, } = get();
        let isPasswordAcceptedAndRestored;
        if (!isVerifyAvailabilityMode && selectedEntityType.withPassword) {
            isPasswordAcceptedAndRestored =
                yield tryToEnterPasswordFromLocalStorage();
        }
        set((state) => {
            var _a;
            state.periodAndEntityStepSpecific.selectedDate = undefined;
            const filteredServices = state.remoteMasterData.services.filter((service) => {
                if (getPeriodPropertyValueForDate({
                    periodSensitiveProperty: service.entityTypesIds,
                    date: new Date(),
                }).entityTypeId === state.selectedValues.selectedEntityType._id) {
                    return true;
                }
                return false;
            });
            if (filteredServices.length === 1) {
                state.selectedValues.selectedService = filteredServices[0];
                const filteredByEntityTypeServices = entities
                    .filter((entity) => filteredServices[0].entitiesIds.some((entityId) => entityId === entity._id))
                    .sort((a, b) => a.displayOrder - b.displayOrder);
                if (filteredByEntityTypeServices.length === 1) {
                    state.selectedValues.selectedEntity =
                        filteredByEntityTypeServices[0];
                }
            }
            if (((_a = state.selectedValues.selectedEntityType.steps) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const steps = state.selectedValues.selectedEntityType.steps.filter(({ step }) => {
                    if (state.static.staticProps.isAdminPanel &&
                        (step === ReservationSteps.PAYMENT_DETAILS ||
                            step === ReservationSteps.PASSWORD)) {
                        return false;
                    }
                    if (state.static.staticProps.isVerifyAvailabilityMode &&
                        (step === ReservationSteps.BILLING_DETAILS ||
                            step === ReservationSteps.PAYMENT_DETAILS ||
                            step === ReservationSteps.PASSWORD)) {
                        return false;
                    }
                    if (state.selectedValues.selectedEntityType.withPassword &&
                        isPasswordAcceptedAndRestored &&
                        step === ReservationSteps.PASSWORD) {
                        return false;
                    }
                    return true;
                });
                state.stepperState.steps = steps;
            }
            state.billingDetailsStepSpecific.billingDetails.shouldSendEmail =
                state.selectedValues.selectedEntityType.withEmailSendingToClients;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromSelectEntityType', extraDevtoolsInfo));
        goToNextStep();
    }),
    handleGoToNextStepFromSelectService: (extraDevtoolsInfo) => {
        const { stepperState: { goToNextStep }, } = get();
        set((state) => {
            state.periodAndEntityStepSpecific.selectedDate = undefined;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromSelectService', extraDevtoolsInfo));
        goToNextStep();
    },
    handleGoToNextStepFromSelectPeriodAndEntity: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { goToNextStep, createOrder, reservationPassword }, selectedValues: { selectedEntity, selectedIntervals, selectedService, selectedEntityType, }, static: { staticProps: { isAdminPanel }, }, } = get();
        const activeLanguage = useTranslationsStore.getState().activeLanguage;
        const getTranslation = useTranslationsStore.getState().getTranslation;
        const validationError = getRestrictionValidationErrors({
            selectedEntity,
            selectedIntervals,
            selectedService,
            isAdmin: isAdminPanel,
        });
        if (validationError.length > 0) {
            set((state) => {
                state.stepperState.validationError = validationError
                    .map((error) => error[activeLanguage])
                    .join(' \n');
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromSelectPeriodAndEntity', extraDevtoolsInfo));
            return;
        }
        if (selectedEntityType.withMembershipCard && !isAdminPanel) {
            try {
                set((state) => {
                    state.stepperState.isFinishingOrder = true;
                    state.stepperState.isNextStepLoading = true;
                });
                yield createOrder();
                if (reservationPassword) {
                    localStorage.setItem(SAVED_RESERVATION_PASSWORD_LS_KEY, reservationPassword);
                }
                set((state) => {
                    state.stepperState.isFinishingOrder = false;
                    state.stepperState.isNextStepLoading = false;
                }, false, getDevtoolsLogMessage('handleGoToNextStepFromSelectPeriodAndEntity:createOrderSuccess', extraDevtoolsInfo));
                goToNextStep();
            }
            catch (serverError) {
                console.error({ serverError });
                set((state) => {
                    state.modalStates.serverSideError = getServerErrorMessage(serverError, getTranslation);
                    state.modalStates.isServerSideErrorModalOpen = true;
                    state.stepperState.isNextStepLoading = false;
                    state.stepperState.isFinishingOrder = false;
                    return;
                }, false, getDevtoolsLogMessage('handleGoToNextStepFromSelectPeriodAndEntity:verifyError', extraDevtoolsInfo));
            }
        }
        else {
            goToNextStep();
        }
    }),
    handleGoToNextStepFromExtras: () => {
        const { stepperState: { goToNextStep }, } = get();
        goToNextStep();
    },
    createOrder: () => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { getCurrentCreateOrderDto }, static: { staticProps: { apiClient }, }, } = get();
        const requestBody = getCurrentCreateOrderDto();
        yield apiClient({
            method: 'POST',
            url: '/reserved-intervals/verify-available-dates',
            data: requestBody,
        });
        yield apiClient.post('/orders', requestBody);
    }),
    handleGoToNextStepFromBillingDetails: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { remoteMasterData: { companyReservationConfig }, stepperState: { reservationPassword, getCurrentCreateOrderDto, goToNextStep, createOrder, }, selectedValues: { selectedEntityType }, billingDetailsStepSpecific: { validateBillingDetails, modifyBillingDetailsCache, }, paymentDetailsStepSpecific: { setPaymentIntentClientSecret }, static: { staticProps: { apiClient, isAdminPanel }, }, } = get();
        const getTranslation = useTranslationsStore.getState().getTranslation;
        const areAllDetailsValid = validateBillingDetails(undefined, true);
        if (!areAllDetailsValid) {
            return;
        }
        set((state) => {
            state.stepperState.isNextStepLoading = true;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromBillingDetails:beforeVerify', extraDevtoolsInfo));
        try {
            const requestBody = getCurrentCreateOrderDto();
            if (isWithPaymentStep({
                companyReservationConfig,
                selectedEntityType,
            }) &&
                !isAdminPanel) {
                yield apiClient({
                    method: 'POST',
                    url: '/reserved-intervals/verify-available-dates',
                    data: requestBody,
                });
                const response = yield apiClient.post('/orders/payment-intents', requestBody);
                setPaymentIntentClientSecret(response.data.paymentIntentClientSecret);
            }
            if (!isWithPaymentStep({
                companyReservationConfig,
                selectedEntityType,
            }) ||
                isAdminPanel) {
                set((state) => {
                    state.stepperState.isFinishingOrder = true;
                });
                yield createOrder();
                if (selectedEntityType.withPassword && reservationPassword) {
                    localStorage.setItem(SAVED_RESERVATION_PASSWORD_LS_KEY, reservationPassword);
                }
            }
            set((state) => {
                state.stepperState.isFinishingOrder = false;
                state.stepperState.isNextStepLoading = false;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromBillingDetails:createOrderSuccess', extraDevtoolsInfo));
        }
        catch (serverError) {
            console.error({ serverError });
            set((state) => {
                state.modalStates.serverSideError = getServerErrorMessage(serverError, getTranslation);
                state.modalStates.isServerSideErrorModalOpen = true;
                state.stepperState.isNextStepLoading = false;
                state.stepperState.isFinishingOrder = false;
                return;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromBillingDetails:verifyError ', extraDevtoolsInfo));
            return;
        }
        modifyBillingDetailsCache({ action: 'addCurrentBillingDetails' });
        goToNextStep();
    }),
    handleGoToNextStepFromPassword: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { reservationPassword, goToNextStep }, static: { staticProps: { apiClient }, }, selectedValues: { selectedEntityType }, } = get();
        const getTranslation = useTranslationsStore.getState().getTranslation;
        set((state) => {
            state.stepperState.isNextStepLoading = true;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromPassword:beforeVerify', extraDevtoolsInfo));
        try {
            yield apiClient({
                method: 'POST',
                url: selectedEntityType.withPassword
                    ? '/orders/verify-password'
                    : '/orders/verify-membership-code',
                data: {
                    password: selectedEntityType.withPassword
                        ? reservationPassword
                        : undefined,
                    membershipCode: selectedEntityType.withMembershipCard
                        ? reservationPassword
                        : undefined,
                    entityTypeId: selectedEntityType._id,
                },
            });
            localStorage.setItem(SAVED_RESERVATION_PASSWORD_LS_KEY, reservationPassword);
            set((state) => {
                state.stepperState.isNextStepLoading = false;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromPassword:verifySuccess', extraDevtoolsInfo));
        }
        catch (serverError) {
            set((state) => {
                state.stepperState.reservationPasswordError = getServerErrorMessage(serverError, getTranslation);
                state.stepperState.isNextStepLoading = false;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromPassword:verifyError', extraDevtoolsInfo));
            return;
        }
        goToNextStep();
    }),
    handleGoToNextStepFromPayment: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { getCurrentCreateOrderDto, goToNextStep }, paymentDetailsStepSpecific: { handlePayment }, static: { staticProps: { apiClient }, }, } = get();
        const getTranslation = useTranslationsStore.getState().getTranslation;
        set((state) => {
            state.stepperState.isNextStepLoading = true;
            state.stepperState.isFinishingOrder = true;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromPayment:beforeVerify', extraDevtoolsInfo));
        try {
            if (!isDevEnv) {
                yield apiClient({
                    method: 'POST',
                    url: '/reserved-intervals/verify-available-dates',
                    data: getCurrentCreateOrderDto(),
                });
            }
            const payload = yield handlePayment();
            if (payload.error) {
                let currentValidationError;
                if (payload.error.code === 'invalid_expiry_month_past') {
                    currentValidationError = getTranslation('invalidExpiryMonthPast');
                }
                else if (payload.error.code === 'incomplete_expiry') {
                    currentValidationError = getTranslation('incompleteExpiry');
                }
                else if (payload.error.code === 'incomplete_number') {
                    currentValidationError = getTranslation('incompleteNumber');
                }
                else if (payload.error.code === 'invalid_number') {
                    currentValidationError = getTranslation('invalidNumber');
                }
                else if (payload.error.code === 'incomplete_cvc') {
                    currentValidationError = getTranslation('incompleteCvc');
                }
                else if (payload.error.code === 'payment_intent_authentication_failure') {
                    currentValidationError = getTranslation('paymentIntentAuthenticationFailure');
                }
                else if (payload.error.code === 'card_declined' &&
                    payload.error.decline_code === 'insufficient_funds') {
                    currentValidationError = getTranslation('cardDeclinedInsufficientFunds');
                }
                else {
                    currentValidationError = getTranslation('paymentFailed', {
                        reason: payload.error.message,
                    });
                }
                set((state) => {
                    state.modalStates.serverSideError = getServerErrorMessage(currentValidationError, getTranslation);
                    state.modalStates.isServerSideErrorModalOpen = true;
                    state.stepperState.isNextStepLoading = false;
                    state.stepperState.isFinishingOrder = false;
                }, false, getDevtoolsLogMessage('handleGoToNextStepFromPayment:paymentError', extraDevtoolsInfo));
                return;
            }
            else {
                setTimeout(() => {
                    set((state) => {
                        state.stepperState.isFinishingOrder = false;
                        state.stepperState.isNextStepLoading = false;
                    }, false, getDevtoolsLogMessage('handleGoToNextStepFromPayment:paymentSuccess', extraDevtoolsInfo));
                }, 1000);
            }
        }
        catch (serverError) {
            console.error({ serverError });
            set((state) => {
                state.modalStates.serverSideError = getServerErrorMessage(serverError, getTranslation);
                state.modalStates.isServerSideErrorModalOpen = true;
                state.stepperState.isNextStepLoading = false;
                state.stepperState.isFinishingOrder = false;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromPayment:verifyError', extraDevtoolsInfo));
            return;
        }
        goToNextStep();
    }),
    handleGoToNextStepFromCompleteVerification: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        const { stepperState: { getCurrentCreateOrderDto, setIsNextStepLoading, setIsFinishingOrder, goToNextStep, }, modalStates: { setIsServerSideErrorModalOpen, setServerSideError }, static: { staticProps: { apiClient }, }, } = get();
        const getTranslation = useTranslationsStore.getState().getTranslation;
        set((state) => {
            state.stepperState.isFinishingOrder = true;
            state.stepperState.isNextStepLoading = true;
        }, false, getDevtoolsLogMessage('handleGoToNextStepFromCompleteVerification:beforeVerify', extraDevtoolsInfo));
        try {
            yield apiClient({
                method: 'POST',
                url: '/reserved-intervals/verify-available-dates',
                data: getCurrentCreateOrderDto(),
            });
            set((state) => {
                state.stepperState.isNextStepLoading = false;
                state.stepperState.isFinishingOrder = false;
            }, false, getDevtoolsLogMessage('handleGoToNextStepFromCompleteVerification:verifySuccess', extraDevtoolsInfo));
            goToNextStep();
        }
        catch (serverError) {
            console.error({ serverError });
            setServerSideError(getServerErrorMessage(serverError, getTranslation));
            setIsServerSideErrorModalOpen(true);
            setIsNextStepLoading(false);
            setIsFinishingOrder(false);
            return;
        }
    }),
    handleSwitchToReservationFlowAfterAvailabilityCheck: (extraDevtoolsInfo) => __awaiter(void 0, void 0, void 0, function* () {
        let isPasswordAccepted;
        const { selectedValues: { selectedEntityType }, stepperState: { reservationPassword }, static: { staticProps: { apiClient }, }, } = get();
        if (selectedEntityType.withPassword) {
            const getTranslation = useTranslationsStore.getState().getTranslation;
            const enteredPassword = reservationPassword ||
                localStorage.getItem(SAVED_RESERVATION_PASSWORD_LS_KEY);
            if (enteredPassword) {
                try {
                    yield apiClient({
                        method: 'POST',
                        url: '/orders/verify-password',
                        data: {
                            password: enteredPassword,
                            entityTypeId: selectedEntityType._id,
                        },
                    });
                    isPasswordAccepted = true;
                    localStorage.setItem(SAVED_RESERVATION_PASSWORD_LS_KEY, enteredPassword);
                }
                catch (serverError) {
                    set((state) => {
                        if (state.modalStates.isPasswordModalOpen) {
                            state.stepperState.reservationPasswordError =
                                getServerErrorMessage(serverError, getTranslation);
                            state.stepperState.isNextStepLoading = false;
                        }
                    }, false, getDevtoolsLogMessage('handleSwitchToReservationFlowAfterAvailabilityCheck:verifyError', extraDevtoolsInfo));
                }
            }
        }
        set((state) => {
            var _a;
            if (state.selectedValues.selectedEntityType.withPassword &&
                !isPasswordAccepted) {
                state.modalStates.isPasswordModalOpen = true;
                return;
            }
            state.static.staticProps.isVerifyAvailabilityMode = false;
            let relevantSteps;
            if (((_a = state.selectedValues.selectedEntityType.steps) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                relevantSteps = state.selectedValues.selectedEntityType.steps;
            }
            else {
                relevantSteps = state.remoteMasterData.companyReservationConfig.steps;
            }
            if (state.selectedValues.selectedEntityType.withPassword) {
                if (state.modalStates.isPasswordModalOpen) {
                    state.modalStates.isPasswordModalOpen = false;
                }
                state.stepperState.steps = relevantSteps.filter(({ step }) => step !== ReservationSteps.PASSWORD);
            }
            else {
                state.stepperState.steps = relevantSteps;
            }
            state.stepperState.currentStep = ReservationSteps.BILLING_DETAILS;
        }, false, getDevtoolsLogMessage('handleSwitchToReservationFlowAfterAvailabilityCheck', extraDevtoolsInfo));
    }),
    handleGoToPreviousStep: (extraDevtoolsInfo) => set((state) => {
        const { stepperState: { currentStep }, remoteMasterData: { companyReservationConfig }, } = state;
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
        let stepsToGo = 1;
        state.stepperState.validationError = '';
        if (currentStep === ReservationSteps.SELECT_SERVICE) {
            state.selectedValues.selectedService = null;
        }
        if (currentStep === ReservationSteps.SELECT_ENTITY) {
            state.selectedValues.selectedEntity = null;
        }
        if (currentStep === ReservationSteps.SELECT_PERIOD) {
            state.selectedValues.selectedIntervals = [];
            state.selectedValues.selectedBulkNumberOfPersons = null;
            state.periodAndEntityStepSpecific.selectedDate = undefined;
            state.periodAndEntityStepSpecific.isReservableIntervalsListShowing =
                false;
        }
        if (currentStep === ReservationSteps.SELECT_PERIOD_AND_ENTITY) {
            if (state.stepperState.shouldSkipServicesOnPrev === true) {
                stepsToGo = 2;
            }
            state.selectedValues.selectedEntity = null;
            state.selectedValues.selectedIntervals = [];
            state.selectedValues.selectedBulkNumberOfPersons = null;
            state.periodAndEntityStepSpecific.selectedDate = undefined;
            state.periodAndEntityStepSpecific.isReservableIntervalsListShowing =
                false;
        }
        if (currentStep === ReservationSteps.SELECT_PERIOD_AND_SERVICE) {
            state.selectedValues.selectedService = null;
            state.selectedValues.selectedIntervals = [];
        }
        const previousStepIndex = state.stepperState.steps.findIndex(({ step }) => step === state.stepperState.currentStep) - stepsToGo;
        const previousStep = state.stepperState.steps[previousStepIndex].step;
        if (previousStep === ReservationSteps.SELECT_ENTITY_TYPE &&
            companyReservationConfig.steps.length === 0) {
            state.stepperState.steps = [
                state.remoteMasterData.entityTypes[0].steps.find(({ step }) => step === ReservationSteps.SELECT_ENTITY_TYPE),
            ];
        }
        state.stepperState.currentStep = previousStep;
    }, false, getDevtoolsLogMessage('handleGoToPreviousStep', extraDevtoolsInfo)),
    defaultDate: getTimelessDate(new Date()),
    setDefaultDate: (defaultDate, extraDevtoolsInfo) => set((state) => {
        state.stepperState.defaultDate = defaultDate;
    }, false, getDevtoolsLogMessage('setDefaultDate', extraDevtoolsInfo)),
    isFocused: false,
    setIsFocused: (isFocused, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isFocused = isFocused;
    }, false, getDevtoolsLogMessage('setIsFocused', extraDevtoolsInfo)),
    isAnyInputFocused: false,
    setIsAnyInputFocused: (isAnyInputFocused, extraDevtoolsInfo) => set((state) => {
        state.stepperState.isAnyInputFocused = isAnyInputFocused;
    }, false, getDevtoolsLogMessage('setIsAnyInputFocused', extraDevtoolsInfo)),
    shouldAnimateNextButton: false,
    setShouldAnimateNextButton: (shouldAnimateNextButton, extraDevtoolsInfo) => set((state) => {
        state.stepperState.shouldAnimateNextButton = shouldAnimateNextButton;
    }, false, getDevtoolsLogMessage('setShouldAnimateNextButton', extraDevtoolsInfo)),
});

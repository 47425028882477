export function getOwnerDocument(node) {
    var _a;
    return node instanceof Element ? (_a = node.ownerDocument) !== null && _a !== void 0 ? _a : document : document;
}
function getActiveElement(element) {
    const doc = element instanceof HTMLElement ? getOwnerDocument(element) : document;
    return doc.activeElement === element;
}
let supportsPreventScrollCached = null;
function supportsPreventScroll() {
    if (supportsPreventScrollCached == null) {
        supportsPreventScrollCached = false;
        try {
            const div = document.createElement('div');
            div.focus({
                get preventScroll() {
                    supportsPreventScrollCached = true;
                    return true;
                },
            });
        }
        catch (e) {
        }
    }
    return supportsPreventScrollCached;
}
function getScrollableElements(element) {
    const doc = getOwnerDocument(element);
    let parent = element.parentNode;
    const scrollableElements = [];
    const rootScrollingElement = doc.scrollingElement || doc.documentElement;
    while (parent instanceof HTMLElement && parent !== rootScrollingElement) {
        if (parent.offsetHeight < parent.scrollHeight ||
            parent.offsetWidth < parent.scrollWidth) {
            scrollableElements.push({
                element: parent,
                scrollTop: parent.scrollTop,
                scrollLeft: parent.scrollLeft,
            });
        }
        parent = parent.parentNode;
    }
    if (rootScrollingElement instanceof HTMLElement) {
        scrollableElements.push({
            element: rootScrollingElement,
            scrollTop: rootScrollingElement.scrollTop,
            scrollLeft: rootScrollingElement.scrollLeft,
        });
    }
    return scrollableElements;
}
function restoreScrollPosition(scrollableElements) {
    for (const { element, scrollTop, scrollLeft } of scrollableElements) {
        element.scrollTop = scrollTop;
        element.scrollLeft = scrollLeft;
    }
}
export function isHTMLElement(element) {
    return element instanceof HTMLElement;
}
function isInputElement(element) {
    return (isHTMLElement(element) &&
        element.tagName.toLowerCase() === 'input' &&
        'select' in element);
}
export function focus(element, options = {}) {
    const { isActive = getActiveElement, nextTick = true, preventScroll, } = options;
    if (isActive(element))
        return -1;
    function triggerFocus() {
        if (supportsPreventScroll()) {
            element.focus({ preventScroll });
        }
        else {
            element.focus();
            if (preventScroll) {
                const scrollableElements = getScrollableElements(element);
                restoreScrollPosition(scrollableElements);
            }
        }
        if (isInputElement(element)) {
            element.select();
        }
    }
    if (nextTick) {
        return requestAnimationFrame(triggerFocus);
    }
    triggerFocus();
    return -1;
}

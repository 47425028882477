import { useState, useCallback, useLayoutEffect } from 'react';
function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();
    return {
        width: rect.width,
        height: rect.height,
        top: rect.x,
        left: rect.y,
        x: rect.x,
        y: rect.y,
        right: rect.right,
        bottom: rect.bottom,
    };
}
export const useDimensions = ({ liveMeasure = false, } = {}) => {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);
    const ref = useCallback((n) => {
        setNode(n);
    }, []);
    useLayoutEffect(() => {
        if (node) {
            const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
            measure();
            if (liveMeasure) {
                window.addEventListener('resize', measure);
                window.addEventListener('scroll', measure);
                return () => {
                    window.removeEventListener('resize', measure);
                    window.removeEventListener('scroll', measure);
                };
            }
        }
    }, [node, liveMeasure]);
    return [dimensions, ref, node];
};
